export const KEY_ACCOUNT_LIST = 'accountList';
export const KEY_ACCOUNT_REGISTER = 'accountRegister';
export const KEY_ACCOUNT_ROLE_LIST = 'accountRoleList';
export const KEY_ACCOUNT_ROLE_CHANGE = 'accountRoleChange';
export const KEY_ACCOUNT_LOGIN_HISTORY = 'accountLoginHistory';

export const KEY_AMD_LIST = 'amdList';
export const KEY_APP_OS_VERSION_LIST = 'appOsVersionList';
export const KEY_APP_REVIEW_LIST = 'appReviewList';
export const KEY_APP_REVIEW_TARGET_LIST = 'appReviewTargetList';

export const KEY_CHALLENGE_ADVERTISER_LIST = 'challengeAdvertiserList';
export const KEY_CHALLENGE_ADVERTISEMENT_LIST = 'challengeAdvertisementList';
export const KEY_CHALLENGE_ADVERTISEMENT_DETAIL =
  'challengeAdvertisementDetail';
export const KEY_CHALLENGE_ATTEND_USER_LIST = 'challengeAttendUserList';
export const KEY_CHALLENGE_AUDIO_CONTENT_LIST = 'challengeAudioContentList';

export const KEY_CHALLENGE_BANNER_LIST = 'challengeBannerList';
export const KEY_CHALLENGE_BRAND_POINT_ADVERTISER_LIST =
  'challengeBrandPointAdvertiserList';
export const KEY_CHALLENGE_CLASS_TYPE_LIST = 'challengeClassTypeList';
export const KEY_CHALLENGE_COMMUNITY_FILTER_LIST =
  'challengeCommunityFilterList';
export const KEY_CHALLENGE_COMMUNITY_FILTER_INFO =
  'challengeCommunityFilterInfo';
export const KEY_CHALLENGE_FILTER_LOAD_LIST = 'challengeFilterLoadList';
export const KEY_CHALLENGE_INFO = 'challengeInfo';
export const KEY_CHALLENGE_LABEL_LIST = 'challengeLabelList';
export const KEY_CHALLENGE_LIST = 'challengeList';
export const KEY_CHALLENGE_LIST_BY_PORTAL = 'challengeListByPortalId';
export const KEY_CHALLENGE_LOCAL_STORE_LIST = 'challengeLocalStoreList';
export const KEY_CHALLENGE_RESULT_DOWNLOAD = 'challengeResultDownload';
export const KEY_CHALLENGE_RESULT_LIST = 'challengeResultList';
export const KEY_CHALLENGE_REWARD_TICKET_GOAL_ACHIEVER_LIST =
  'challengeRewardTicketGoalAchieverList';
export const KEY_CHALLENGE_REWARD_COUPON_GOAL_ACHIEVER_LIST =
  'challengeRewardCouponGoalAchieverList';
export const KEY_CHALLENGE_REWARD_LIST = 'challengeRewardList';
export const KEY_CHALLENGE_REWARD_LIST_BY_USER_ID =
  'challengeRewardListbyUserId';
export const KEY_CHALLENGE_REWARD_USER_LIST = 'challengeRewardUserList';
export const KEY_CHALLENGE_REWARD_USER_APPLY_FORM =
  'challengeRewardUserApplyForm';
export const KEY_CHALLENGE_REQUEST_LIST = 'challengeRequestList';
export const KEY_CHALLENGE_REQUEST_LIST_BY_OPERATOR =
  'challengeRequestListByOperator';
export const KEY_CHALLENGE_REQUEST_DETAIL = 'challengeRequestDetail';

export const KEY_COMMUNITY_BANNER_LIST = 'communityBannerList';
export const KEY_COMMUNITY_DAILYSTEP_LIST = 'communityDailyStepList';
export const KEY_COMMUNITY_GROUP_DETAIL_LIST = 'communityGroupDetailList';
export const KEY_COMMUNITY_LIST = 'communityList';
export const KEY_COMMUNITY_LIST_BY_PORTAL = 'communityListByPortal';
export const KEY_COMMUNITY_MEMBER_LIST = 'communityMemberList';
export const KEY_COMMUNITY_OPERATOR_LIST = 'communityOperatorList';
export const KEY_COMMUNITY_REQUEST_LIST = 'communityRequestList';
export const KEY_COMMUNITY_REQUEST_LIST_BY_OPERATOR =
  'communityRequestListByOperator';
export const KEY_COMMUNITY_REQUEST_DETAIL = 'communityRequestDetail';
export const KEY_COMMUNITY_TYPE_MEMBER_LIST = 'communityTypeMemberList';
export const KEY_COMMUNITY_USER_STEP_LIST = 'communityUserStepList';
export const KEY_COMMUNITY_YEAR_REPORT_INFO = 'communityYearReportInfo';

export const KEY_CONTENT_MAMANGE_LIST = 'contentManageList';
export const KEY_CONTENT_MAMANGE_CONTENT_DETAIL_INFO =
  'contentManageContentDetailInfo';
export const KEY_CONTENT_MAMANGE_CONTENT_LIST = 'contentManageContentList';
export const KEY_CONTENT_MAMANGE_TEMPLATE_CONTENT_LIST =
  'contentManageTemplateContentList';
export const KEY_CONTENT_MAMANGE_KEYWORD_LIST = 'contentManageKeywordList';
export const KEY_CONTENT_MAMANGE_MAP = 'contentManageMap';
export const KEY_CONTENT_MANAGE_TARGET_LIST = 'contentManageTargetList';

export const KEY_CONTRACT_LIST = 'contractList';
export const KEY_CONTRACT_INFO_LIST = 'contractInfoList';
export const KEY_CONTRACT_REPORT_MONTHLY = 'contracReportMonthly';

export const KEY_DASHBOARD_MEMO_LIST = 'dashboardMemoList';
export const KEY_DASHBOARD_SHORTCUT_LIST = 'dashboardShortcutList';

export const KEY_DATAPORTAL_ACCOUNT_EXIST = 'dataportalAccountExist';
export const KEY_DATAPORTAL_ACCOUNT_LIST = 'dataportalAccountList';
export const KEY_DATAPORTAL_ACCOUNT_INFO = 'dataportalAccountInfo';
export const KEY_DATAPORTAL_ACCOUNT_AMD_LIST = 'dataportalAccountAmdList';
export const KEY_DATAPORTAL_ACCOUNT_DETAIL_LIST = 'dataportalAccountDetailList';
export const KEY_DATAPORTAL_CONNECT_POPUP_LIST = 'dataportalConnectPopupList';
export const KEY_DATAPORTAL_DEPOSIT_LIST = 'dataportalDepositList';
export const KEY_DATAPORTAL_FAQ_LIST = 'dataportalFaqList';
export const KEY_DATAPORTAL_GUIDE_MAMANGE_CONTENT_LIST =
  'dataportalGuideManageContentList';
export const KEY_DATAPORTAL_GUIDE_MAMANGE_MAP = 'dataportalGuideManageMap';
export const KEY_DATAPORTAL_NOTICE_LIST = 'dataportalNoticeList';
export const KEY_DATAPORTAL_PAYMENT_LIST = 'dataportalPaymentList';
export const KEY_DATAPORTAL_REFUND_LIST = 'dataportalRefundList';
export const KEY_DATAPORTAL_QNA_LIST = 'dataportalQnaList';
export const KEY_DATAPORTAL_TRANSACTION_LIST = 'dataportalTransactionList';

export const KEY_ERROR_REPORT_LIST = 'errorReportList';

export const KEY_FIELD_CHALLENGE_COUNT = 'fieldChallengeCount';
export const KEY_FIELD_NORMAL_CHARACTER_LIST = 'fieldNormalCharacterList';
export const KEY_FIELD_FIXED_CHARACTER_LIST = 'fieldFixedCharacterList';
export const KEY_FIELD_DETAIL_INFO = 'fieldDetailInfo';
export const KEY_FIELD_GEOMETRY_LIST = 'fieldGeometryList';
export const KEY_FIELD_LIST = 'fieldList';
export const KEY_FIELD_REQUEST_LIST = 'fieldRequestList';
export const KEY_FIELD_REQUEST_LIST_BY_OPERATOR = 'fieldRequestListByOperator';
export const KEY_FIELD_REQUEST_DETAIL = 'fieldRequestDetail';
export const KEY_FIELD_SPOT_LIST = 'fieldSpotList';

export const KEY_GALLERY_IMAGE_LIST = 'galleryImageList';
export const KEY_GEOHASH_GROUP_LIST = 'geohashGroupList';
export const KEY_GEOHASH_RECENT_LOCATION_LIST = 'geohashRecentLocationList';

export const KEY_LOCAL_STORE_NOTICE_LIST = 'localStoreNoticeList';
export const KEY_LOCAL_STORE_OWNER_LIST = 'localStoreOwnerList';
export const KEY_LOCAL_STORE_OWNER_STORE_LIST = 'localStoreOwnerStoreList';
export const KEY_LOCAL_STORE_REQUEST_LIST = 'localStoreRequestList';
export const KEY_LOCAL_STORE_REWARD_LIST = 'localStoreRewardList';
export const KEY_LOCAL_STORE_LIST = 'localStoreList';

export const KEY_NOTICE_LIST = 'noticeList';

export const KEY_OPERATION_GUIDE_LIST = 'operationGuide';

export const KEY_OPERATING_METRICS_NEW_USER = 'operatingMetricsNewUser';
export const KEY_OPERATING_METRICS_TOTAL_USER = 'operatingMetricsTotalUser';
export const KEY_OPERATING_METRICS_ACTIVE_TOTAL_USER =
  'operatingMetricsActiveTotalUser';
export const KEY_OPERATING_METRICS_MAU = 'operatingMetricsMau';
export const KEY_OPERATING_METRICS_DAU = 'operatingMetricsDau';
export const KEY_OPERATING_METRICS_NEW_COMMUNITY =
  'operatingMetricsNewCommunity';
export const KEY_OPERATING_METRICS_NEW_CHALLENGE =
  'operatingMetricsNewChallenge';

export const KEY_POINT_USE_PLACE_LIST = 'pointUsePlaceList';
export const KEY_POPULAR_SERACH_TERM = 'popularSearchTerm';

export const KEY_PROGRAM_ATTEND_USER_LIST = 'programAttendUserList';
export const KEY_PROGRAM_COMMUNITY_FILTER_INFO = 'programCommunityFilterInfo';
export const KEY_PROGRAM_FILTER_INFO = 'programFilterInfo';
export const KEY_PROGRAM_LIST = 'programList';
export const KEY_PROGRAM_LIST_BY_PORTAL = 'programListByPortalId';
export const KEY_PROGRAM_MAGAZINE_LIST = 'programMagazineList';
export const KEY_PROGRAM_MEMBER_LIST = 'programMemberList';
export const KEY_PROGRAM_MISSION_MOVIE_DETAIL_INFO =
  'programMissionMovieDetailInfo';
export const KEY_PROGRAM_MISSION_MAGAZINE_DETAIL_INFO =
  'programMissionMagazineDetailInfo';
export const KEY_PROGRAM_MISSION_QNA_DETAIL_INFO =
  'programMissionQnaDetailInfo';
export const KEY_PROGRAM_MISSION_LIFESTYLE_DETAIL_INFO =
  'programMissionLifeStyleDetailInfo';
export const KEY_PROGRAM_MISSION_QNA_QUESTION_LIST =
  'programMissionQnaQuestionList';
export const KEY_PROGRAM_REQUEST_LIST = 'programRequestList';
export const KEY_PROGRAM_REQUEST_LIST_BY_OPERATOR =
  'programRequestListByOperator';
export const KEY_PROGRAM_REWARD_LIST = 'programRewardList';
export const KEY_PROGRAM_REWARD_COUPON_GOAL_ACHIEVER_LIST =
  'programRewardCouponGoalAchieverList';
export const KEY_PROGRAM_REWARD_DRAWING_APPLY = 'programRewardDrawingApplyList';
export const KEY_PROGRAM_REWARD_DRAWING_LIST = 'programRrewardDrawingList';
export const KEY_PROGRAM_REWARD_DRAWING_DETAIL_INFO =
  'programRewardDrawingDetailInfo';
export const KEY_PROGRAM_REWARD_DRAWING_DETAIL_LIST =
  'programRewardDrawingDetailList';
export const KEY_PROGRAM_REWARD_DRAWING_RESULT =
  'programRewardDrawingResultList';
export const KEY_PROGRAM_REWARD_DRAWING_USER_LIST =
  'programRewardDrawingUserList';
export const KEY_PROGRAM_REWARD_USER_LIST = 'programRewardUserList';
export const KEY_PROGRAM_REWARD_TICKET_GOAL_ACHIEVER_LIST =
  'programRewardTicketGoalAchieverList';
export const KEY_PROGRAM_TARGET_LIST = 'programTargetList';
export const KEY_PROGRAM_TEMPLATE_CODE_LIST = 'programTemplateCodetList';
export const KEY_PROGRAM_CURRICULUM_LIST = 'programCurriculumList';

export const KEY_PUSH_LIST = 'pushList';

export const KEY_QUESTION_ANSWER_ATTEND_USER_LIST =
  'questionAnswerAttendUserList';
export const KEY_QUESTION_ANSWER_REQUEST_LIST = 'questionAnswerRequestList';
export const KEY_QUESTION_ANSWER_REQUEST_LIST_BY_OPERATOR =
  'questionAnswerRequestListByOperator';
export const KEY_QUESTION_ANSWER_LIST = 'questionAnswerList';
export const KEY_QUESTION_ANSWER_RECIPIENT_LIST = 'questionAnswerRecipientList';
export const KEY_QUESTION_ANSWER_RESULT_LIST = 'questionAnswerResultList';
export const KEY_QUESTION_ANSWER_REWARD_LIST = 'questionAnswerRewardList';
export const KEY_QUESTION_ANSWER_REWARD_COUPON_GOAL_ACHIEVER_LIST =
  'questionAnswerRewardCouponGoalAchieverList';
export const KEY_QUESTION_ANSWER_REWARD_DRAWING_APPLY =
  'questionAnswerRewardDrawingApplyList';
export const KEY_QUESTION_ANSWER_REWARD_DRAWING_LIST =
  'questionAnswerRewardDrawingList';
export const KEY_QUESTION_ANSWER_REWARD_DRAWING_DETAIL_LIST =
  'questionAnswerRewardrawingDetailList';
export const KEY_QUESTION_ANSWER_REWARD_DRAWING_RESULT =
  'questionAnswerRewardrawingResult';
export const KEY_QUESTION_ANSWER_REWARD_DRAWING_POINT_RESULT =
  'questionAnswerRewardrawingPointResult';
export const KEY_QUESTION_ANSWER_REWARD_DRAWING_USER_LIST =
  'questionAnswerRewardrawingUserList';
export const KEY_QUESTION_ANSWER_REWARD_DRAWING_DETAIL_INFO =
  'questionAnswerRewardrawingDetailInfo';
export const KEY_QUESTION_ANSWER_USER_ANSWER_LIST =
  'questionAnswerUserAnswerList';
export const KEY_QUESTION_ANSWER_REWARD_USER_LIST =
  'questionAnswerRewardUserList';
export const KEY_QUESTION_ANSWER_REWARD_TICKET_GOAL_ACHIEVER_LIST =
  'questionAnswerRewardTicketGoalAchieverList';

export const KEY_REWARD_DRAWING_APPLY = 'rewardDrawingApplyList';
export const KEY_REWARD_DRAWING_LIST = 'rewardDrawingList';
export const KEY_REWARD_DRAWING_DETAIL_INFO = 'rewardDrawingDetailInfo';
export const KEY_REWARD_DRAWING_DETAIL_LIST = 'rewardDrawingDetailList';
export const KEY_REWARD_DRAWING_RESULT = 'rewardDrawingResultList';
export const KEY_REWARD_DRAWING_POINT_RESULT = 'rewardDrawingPointResultList';
export const KEY_REWARD_DRAWING_USER_LIST = 'rewardDrawingUserList';

export const KEY_SERVICE_COUPON_INFO = 'serviceCouponInfo';
export const KEY_SERVICE_COUPON_LIST = 'serviceCouponList';
export const KEY_SERVICE_COUPON_HISTORY = 'serviceCouponHistory';
export const KEY_SERVICE_COUPON_USE_HISTORY = 'serviceCouponUseHistory';

export const KEY_STAMPTOUR_REQUEST_LIST = 'stamptourRequestList';
export const KEY_STAMPTOUR_REQUEST_DETAIL = 'stamptourRequestDetail';
export const KEY_STAMPTOUR_DETAIL_INFO = 'stamptourDetailInfo';
export const KEY_STAMPTOUR_LIST = 'stamptourList';

export const KEY_TARGET_LIST = 'targetList';
export const KEY_TARGET_PUSH_LIST = 'targetPushList';

export const KEY_TIMELINE_LIST = 'timelineList';

export const KEY_USER_BLACK_LIST = 'userBlackList';
export const KEY_USER_BRAND_POINT_HISTORY = 'userBrandPointHistory';
export const KEY_USER_COMMUNITY_LIST = 'userCommunityList';
export const KEY_USER_CHALLENGE_LIST = 'userChallengeList';
export const KEY_USER_DETAIL_INFO = 'userDetailInfo';
export const KEY_USER_EMAIL_CHANGE = 'userEmailChange';
export const KEY_USER_LIST = 'userList';
export const KEY_USER_LIST_BY_PHONENOS = 'userListByPhonenos';
export const KEY_USER_PROGRAM_LIST = 'userProgramList';
export const KEY_USER_PROGRAM_REPORT = 'userProgramReport';
export const KEY_USER_STEP_LIST = 'userStepList';

export const KEY_USER_DAILY_STEP_LIST = 'userDailyStepList';
export const KEY_USER_AUTH_CODE_LIST = 'userAuthCodeList';
export const KEY_USER_WEARABLE_LIST = 'userWearableList';

export const KEY_WALKCOURSE_REQUEST_LIST = 'walkcourseRequestList';
export const KEY_WALKCOURSE_REQUEST_LIST_BY_OPERATOR =
  'walkcourseRequestListByOperator';
export const KEY_WALKCOURSE_REQUEST_DETAIL = 'walkcourseRequestDetail';
export const KEY_WALKCOURSE_DETAIL_INFO = 'walkcourseDetailInfo';
export const KEY_WALKCOURSE_LIST = 'walkcourseList';
export const KEY_WALKCOURSE_WALKWAY_COUNT = 'walkcourseWalkwayCount';

export const KEY_WALKWAY_DETAIL_INFO = 'walkwayDetailInfo';
export const KEY_WALKWAY_INFO_BY_COMMUNITY_ID = 'walkwayInfoByCommunityId';
export const KEY_WALKWAY_LOCAL_LIST = 'walkwayLocalList';
export const KEY_WALKWAY_LOCAL_SIMPLE_LIST = 'walkwayLocalSimpleList';
export const KEY_WALKWAY_POINT_LIST = 'walkwayPointList';

// KEY WORD LIST
export const KEY_WORD_EMAIL = 'email';
export const KEY_WORD_PHONE = 'phone';
export const KEY_WORD_PREFIX_COMPANY = 'C-';
export const KEY_WORD_PREFIX_CONTRACT = 'T-';
export const KEY_WORD_DUMMY_ID = '00000000000000000000000000000000';
export const KEY_WORD_NEXT_QUESTION = '다음 질문을 표시';
export const KEY_WORD_NONE_QUESTION = '질문 표시 안함';
export const KEY_WORD_SURVEY = 'survey';
export const KEY_WORD_QUIZ = 'quiz';
export const KEY_WORD_LIFESTYLE = 'lifeStyle';
export const KEY_WORD_SURVEY_STRING = '설문';
export const KEY_WORD_QUIZ_STRING = '퀴즈';
export const KEY_WORD_LIFESTYLE_STRING = '생활습관';
