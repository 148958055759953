import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import {
  IDataPortalRequest,
  IDataPortal,
  IDataPortalQnA,
  IDataPortalFaq,
  IDataPortalNotice,
} from '../../models/dataportal';
import { throwError } from '../helper';

/*
 * DataPortal
 */

export const getDataPortalAccountList = (
  searchKey: string,
  searchWord: string
) =>
  xapi
    .get(
      `/admin/v2/apis/dataportal/account?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getDataPortalAccountDetailList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/dataportal/account-detail?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getDataPortalAccountInfo = (portalId: string) =>
  xapi.get(`/admin/v2/apis/dataportal/account/${portalId}/info`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getDataPortalAccountExist = (portalId: string) =>
  xapi.get(`/admin/v2/apis/dataportal/account/${portalId}/exist`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getDataPortalAccountAmdList = () =>
  xapi.get(`/admin/v2/apis/dataportal/account/amd`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getDataPortalQnAList = (data: IDataPortalRequest) =>
  xapi
    .get(
      `/admin/v2/apis/support/qna?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getDataPortalQnAListByOperator = (data: IDataPortalRequest) =>
  xapi
    .get(
      `/admin/v2/apis/support/qna-operator?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postDataPortalAccountRegister = (data: IDataPortal) =>
  xapi.post('/admin/v2/apis/dataportal/account', data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postQnARegister = (data: IDataPortalQnA) =>
  xapi.post(`/admin/v2/apis/support/qna/${data.id}/answer`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteDataPortalAccount = (id: string) =>
  xapi.delete(`/admin/v2/apis/dataportal/account/${id}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteDataPortalCompany = (id: string) =>
  xapi.delete(`/admin/v2/apis/dataportal/account/${id}/company`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postDataPortalTesterRegister = (data: any) =>
  xapi.post('/admin/v2/apis/dataportal/tester', data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

/*
 * FAQ
 */

export const getDataPortalFaqList = (data: IDataPortalFaq) =>
  xapi
    .get(
      `/admin/v2/apis/dataportal/faq?page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postDataPortalFaq = (data: IDataPortalFaq) =>
  xapi
    .post(`/admin/v2/apis/dataportal/faq`, {
      title: data.title,
      content: data.content,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putDataPortalFaq = (data: IDataPortalFaq) =>
  xapi
    .put(`/admin/v2/apis/dataportal/faq/${data.idx}`, {
      title: data.title,
      content: data.content,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteDataPortalFaq = (data: IDataPortalFaq) =>
  xapi.post(`/admin/v2/apis/dataportal/faq/delete`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

/*
 * Notice
 */

export const getDataPortalNoticeList = (data: IDataPortalNotice) =>
  xapi
    .get(
      `/admin/v2/apis/dataportal/notice?page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postDataPortalNotice = (data: IDataPortalNotice) =>
  xapi
    .post(`/admin/v2/apis/dataportal/notice`, {
      title: data.title,
      content: data.content,
      image: data.image,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putDataPortalNotice = (data: IDataPortalNotice) =>
  xapi
    .put(`/admin/v2/apis/dataportal/notice/${data.idx}`, {
      title: data.title,
      content: data.content,
      image: data.image,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteDataPortalNotice = (data: IDataPortalNotice) =>
  xapi.post(`/admin/v2/apis/dataportal/notice/delete`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

/*
 * Connect Popup
 */

export const getDataPortalConnectPopupList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/dataportal/connect-popup?page=${data.page}&per_page=${data.rowsPerPage}&operator=${data.operator}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postDataPortalConnectPopup = (data: any) =>
  xapi.post(`/admin/v2/apis/dataportal/connect-popup`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putDataPortalConnectPopup = (data: any) =>
  xapi
    .put(`/admin/v2/apis/dataportal/connect-popup/${data.idx}`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteDataPortalConnectPopup = (data: any) =>
  xapi
    .post(`/admin/v2/apis/dataportal/connect-popup/delete`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

/*
 * Guide Manage
 */
export const getPortalGuideManageMap = (data: any) =>
  xapi
    .get(`/admin/v2/apis/dataportal/guide-manage/map?sortWay=${data.sortWay}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getPortalGuideManageContentList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/dataportal/guide-manage/content?categoryId=${data.categoryId}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}&sortWay=${data.sortWay}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postDataPortalGuide = (data: any) =>
  xapi.post(`/admin/v2/apis/dataportal/guide-manage/guide`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putDataPortalGuide = (data: any) =>
  xapi
    .put(`/admin/v2/apis/dataportal/guide-manage/guide/${data.idx}`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteDataPortalGuide = (data: any) =>
  xapi
    .post(`/admin/v2/apis/dataportal/guide-manage/guide/delete`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postDataPortalGuideCategory = (data: any) =>
  xapi
    .post(`/admin/v2/apis/dataportal/guide-manage/guide-category`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
