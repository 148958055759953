import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import React, { Dispatch, MouseEvent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SwAlert } from '../views/SwAlert';
import {
  KEY_WORD_LIFESTYLE_STRING,
  KEY_WORD_QUIZ_STRING,
  KEY_WORD_SURVEY_STRING,
} from '../../../common/key';

export const CategoryPaper = styled(Paper)({
  width: '100%',
  display: 'flex',
  margin: '1rem 1rem',
  padding: '.5rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'none',
  cursor: 'pointer',
  boxShadow: '0px 2px 1px -1px rgb(ff ff ff / 4%)',
});

const categoryTitles = [
  { title: KEY_WORD_SURVEY_STRING, value: 1 },
  { title: KEY_WORD_QUIZ_STRING, value: 2 },
  { title: KEY_WORD_LIFESTYLE_STRING, value: 3 },
];

interface Props {
  open: boolean;
  onSelected: (title: string) => void;
  onClose: () => void;
}

export const SwConnectMissionType: React.FC<Props> = ({
  open,
  onSelected,
  onClose,
}) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const clickHandler = (idx: number) => {
    onSelected(categoryTitles[idx].title);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            height: '30vh',
            width: '30vh',
          },
        }}
      >
        <DialogContent sx={{ p: '1rem 1rem' }}>
          <Box className='flex_between'>
            <Typography className='title'>미션 타입 선택</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ p: 0, m: 0 }} />
          <Box>
            {categoryTitles.map((row: any, idx: number) => (
              <Box sx={{ m: '1rem' }} key={`category_button_${idx}`}>
                <Button
                  variant='outlined'
                  color='info'
                  fullWidth
                  onClick={() => {
                    clickHandler(idx);
                  }}
                >{`${row.title}`}</Button>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
      </Dialog>
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={dialogMessage}
      />
    </>
  );
};
