import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import RestartIcon from '@mui/icons-material/RestartAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import { IGuideTool } from '../../../models/challenge';
import {
  CurriculumPaper,
  RowsCell,
  MultilineTextField,
} from '../../styles/Styles';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { SwCurriculumMissionItem } from './SwCurriculumMissionItem';
import {
  getCurriculumDration,
  getWeekDates,
  getWeekNumber,
  timestamp2Localestring,
  TOOLTIP_FONT_SIZE,
} from '../../../common/helper';
import { SwMissionRecordingDetail } from './SwMissionRecordingDetail';
import { SwCurriculumTypeSelector } from './SwCurriculumTypeSelector';
import {
  curriculumType,
  curriculumTypeList,
  missionRecordings,
} from './SwProgramCommon';
import { SwMissionMovie } from './SwMissionMovie';
import { SwMissionMagazine } from './SwMissionMagazine';
import { SwMissionLifeStyle } from './SwMissionLifeStyle';
import { SwMissionQna } from './SwMissionQna';
import {
  postProgramCurriculumWeekAdd,
  postProgramCurriculumWeekDelete,
  putProgramCurriculumMissionList,
  putProgramCurriculumWeekTitle,
  putProgramRecordItem,
  putProgramTemplateCodeRecordItem,
  updateProgramHealthPoint,
} from '../../../common/api/ApiProgram';
import {
  RESULT_OK,
  RESULT_WEEK_LIST_OVER_DURATION,
} from '../../../common/resultCode';

const DataChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const formtool = {
  title: '소제목',
  content: '안내내용',
};

const infoType = [
  { id: 99, title: '선택' },
  { id: 0, title: '연락처' },
  { id: 1, title: '카드번호' },
  { id: 2, title: '생년월일' },
  { id: 3, title: '주소' },
  { id: 4, title: '객관식' },
  { id: 5, title: '주관식' },
];

const applyFormTemplate = [
  {
    type: 0,
    title: '연락처',
    content: '연락처를 입력해주세요',
  },
  {
    type: 1,
    title: '카드번호',
    content: '카드번호 16자리를 입력해주세요',
  },
  {
    type: 2,
    title: '생년월일',
    content: '생년월일 6자리를 입력해주세요',
  },
  {
    type: 3,
    title: '주소',
    content: '주소를 입력해주세요',
  },
  {
    type: 4,
    title: '질문을 입력해주세요',
    content: '댭변을 입력해주세요',
  },
  {
    type: 5,
    title: '질문을 입력해주세요',
    content: '안내내용을 입력해주세요',
  },
];

const curriculumTemplate = [
  {
    week: 0,
    title: '공통 미션',
    duration: '전체 기간',
  },
  {
    week: 1,
    title: '1 주차',
    duration: '(23.06.01~23.06.07)',
  },
  {
    week: 2,
    title: '2 주차',
    duration: '(23.06.08~23.06.14)',
  },
  {
    week: 3,
    title: '3 주차',
    duration: '(23.06.15~23.06.21)',
  },
  {
    week: 4,
    title: '4 주차',
    duration: '(23.06.22~23.06.28)',
  },
];

const movieTemplate = [
  '영상 제목이 나옵니다-1',
  '영상 제목이 나옵니다-2',
  '영상 제목이 나옵니다-3',
  '영상 제목이 나옵니다-4',
  '영상 제목이 나옵니다-5',
];

const magazineTemplate = [
  '매거진 제목이 나옵니다-1',
  '매거진 제목이 나옵니다-2',
  '매거진 제목이 나옵니다-3',
  '매거진 제목이 나옵니다-4',
  '매거진 제목이 나옵니다-5',
];

const qnaTemplate = [
  '질의응답 제목이 나옵니다-1',
  '질의응답 제목이 나옵니다-2',
  '질의응답 제목이 나옵니다-3',
  '질의응답 제목이 나옵니다-4',
  '질의응답 제목이 나옵니다-5',
];

const lifeStyleTemplate = [
  '생활습관 제목이 나옵니다-1',
  '생활습관 제목이 나옵니다-2',
  '생활습관 제목이 나옵니다-3',
  '생활습관 제목이 나옵니다-4',
  '생활습관 제목이 나옵니다-5',
];

const TOOLTIP_RECORDING_MISSION =
  '프로그램 기간 중 건강 점수를 부여하지 않고 자유롭게 건강상태를 기록할 수 있는 미션입니다.';
const TOOLTIP_COMMON_MISSION =
  '주차 내에 해야 달성 인정이 되는 기존 미션과 달리 프로그램 진행 기간 중에만 완료하면 달성이 인정이 되는 미션입니다.';

const MAX_FORM_PAGE = 10;
const MAX_CURRICULUM_COUNT = 16;
const COMMON_MISSION_INDEX = 0;
const RECORD_MISSION_INDEX = 99;

interface Props {
  draggableName?: string;
  formList: any[];
  setFormList: Dispatch<React.SetStateAction<any[]>>;
  programInfo: any;
  showDuration?: boolean;
  saveCurriculum: boolean;
  onReloadCurriculum: (id: string, type: string) => void;
}

export const SwCurriculumList: React.FC<Props> = ({
  draggableName = 'draggable',
  formList,
  setFormList,
  programInfo,
  showDuration = true,
  saveCurriculum,
  onReloadCurriculum,
}) => {
  const location = useLocation();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [healthPoint, setHealthPoint] = useState<number>(0);
  const [type, setType] = useState<number>(99);
  const [formTitle, setFormTitle] = useState<any>('');
  const [formDuration, setFormDuration] = useState<any>('');
  const [formPage, setFormPage] = useState(1);
  const [weekList, setWeekList] = useState<any[]>([]);
  const [movieList, setMovieList] = useState<any[]>([]);
  const [magazineList, setMagazineList] = useState<any[]>([]);
  const [qnaList, setQnaList] = useState<any[]>([]);
  const [lifeStyleList, setLifeStyleList] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [optionList, setOptionList] = useState<any[]>([]);
  const [optionsCount, setOptionsCount] = useState<number>(0);
  const formItems = document.querySelectorAll(`.${draggableName}`);
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [weekTitle, setWeekTitle] = useState<string>('');
  const [movieData, setMovieData] = useState<any>('');
  const [magazineData, setMagazineData] = useState<any>('');
  const [qnaData, setQnaData] = useState<any>('');
  const [lifeStyleData, setLifeStyleData] = useState<any>('');
  const [selectedCurriculumType, setSelectedCurriculumType] =
    useState<number>(-1);
  const [formIndex, setFormIndex] = useState<number>(RECORD_MISSION_INDEX);
  const [selectedWeekId, setSelectedWeekId] = useState<number>(0);
  const [startWeekDate, setStartWeekDate] = useState<string>('');
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [openRecordingDetail, setOpenRecordingDetail] =
    useState<boolean>(false);
  const [missionRecordingsIndex, setMissionRecordingsIndex] =
    useState<number>(0);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isTemplateCode, setIsTemplateCode] = useState<boolean>(true);
  const [openCurriculumType, setOpenCurriculumType] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openMissionMovie, setOpenMissionMovie] = useState<boolean>(false);
  const [openMissionMagazine, setOpenMissionMagazine] =
    useState<boolean>(false);
  const [openMissionQna, setOpenMissionQna] = useState<boolean>(false);
  const [openMissionLifeStyle, setOpenMissionLifeStyle] =
    useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();

  const reloadCurriculum = (reqType?: string) => {
    console.log('reload curriculum :', programInfo);
    onReloadCurriculum(
      programInfo.curriculumId,
      reqType === undefined ? '' : reqType
    );
  };

  const {
    mutate: putProgramCurriculumMissionListMutate,
    isError: isMutateListError,
  } = useMutation(putProgramCurriculumMissionList, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('순서가 변경되었습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `순서를 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('순선변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: putProgramTemplateRecordItemMutate,
    isError: isMutateTemplateRecordItemtError,
  } = useMutation(putProgramTemplateCodeRecordItem, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('기록형 미션 항목이 변경되었습니다.');
        isTemplateCode
          ? reloadCurriculum('template')
          : reloadCurriculum('program');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `기록형 미션 항목을 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '기록형 미션 항목 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: putProgramRecordItemMutate,
    isError: isMutateRecordItemtError,
  } = useMutation(putProgramRecordItem, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('기록형 미션 항목이 변경되었습니다.');
        isTemplateCode
          ? reloadCurriculum('template')
          : reloadCurriculum('program');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `기록형 미션 항목을 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '기록형 미션 항목 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const { mutate: putProgramWeekTitleMutate, isError: isMutateWeekTitleError } =
    useMutation(putProgramCurriculumWeekTitle, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('주차 제목이 변경되었습니다.');
          isTemplateCode
            ? reloadCurriculum('template')
            : reloadCurriculum('program');
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `주차 제목을 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '주차 제목 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const updateWeekAndMissionList = (
    weekFlag: boolean,
    newCurriculumType: curriculumType
  ) => {
    const newWeekList = weekFlag
      ? weekList.map((item: any, idx: number) => ({
          weekId: item.weekId,
          weekSeq: idx,
          weekTitle: item.weekTitle,
        }))
      : [];

    const newMissionList: any[] = [];
    newCurriculumType === curriculumType.movie &&
      movieList.map((itemList: any, idx) => {
        itemList !== undefined &&
          itemList.map((item: any, i: number) =>
            newMissionList.push({ missionId: item.missionId, seq: i })
          );
      });
    newCurriculumType === curriculumType.magazine &&
      magazineList.map((itemList: any, idx) => {
        itemList !== undefined &&
          itemList.map((item: any, i: number) =>
            newMissionList.push({ missionId: item.missionId, seq: i })
          );
      });
    newCurriculumType === curriculumType.qna &&
      qnaList.map((itemList: any, idx) => {
        itemList !== undefined &&
          itemList.map((item: any, i: number) =>
            newMissionList.push({ missionId: item.missionId, seq: i })
          );
      });
    newCurriculumType === curriculumType.lifestyle &&
      lifeStyleList.map((itemList: any, idx) => {
        itemList !== undefined &&
          itemList.map((item: any, i: number) =>
            newMissionList.push({ missionId: item.missionId, seq: i })
          );
      });

    const newData = {
      curriculumId: programInfo.curriculumId,
      weekList: newWeekList,
      missionList: newMissionList,
      isTemplateCode: isTemplateCode ? 'templatecode' : 'program',
    };
    putProgramCurriculumMissionListMutate(newData);
  };

  function addFormInfo() {
    if (formTitle.length > 0 && formDuration.length > 0) {
      const newTitle = formTitle;
      const newDuration = formDuration;
      const newFormLists = formList;
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        title: newTitle,
        duration: newDuration,
      };
      return newFormLists;
    }
    return [];
  }
  const saveOptions = (opts: any[]) => {
    const newOptions = opts.map(opt => opt);
    const newFormList = formList.map((frm: any, idx: number) => {
      const newData =
        idx === formIndex
          ? {
              title: frm.title,
              duration: frm.duration,
            }
          : frm;
      return newData;
    });
    setFormList(newFormList);
  };

  const addFormOptions = () => {
    const newOptions = [...options, { idx: 0, content: '' }];
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const changeOption = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const newOptions = options.map((opt: any, i: number) => {
      const newData =
        i === idx
          ? {
              idx: i,
              content: evt.target.value,
            }
          : opt;
      return newData;
    });
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const removeFormOption = (idx: number) => {
    const newOptions = options.filter((_form, i) => i !== idx);
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const changePageContents = () => {
    // console.log('ChangePageContents>>>>>>> :', guideLists);
    setFormTitle(formList[formIndex] ? formList[formIndex].title : '-');
    setFormDuration(formList[formIndex] ? formList[formIndex].duration : '-');
  };

  const addFormView = () => {
    if (formList.length === MAX_FORM_PAGE) {
      setDialogMessage(
        `리워드 폼은 최대 ${MAX_FORM_PAGE}까지 추가할 수 있습니다.`
      );
      setOpenSnackbar(true);
      return;
    }

    if (type !== 99) {
      const newFormData = applyFormTemplate[type];
      const isExist = formList.filter(
        (item: any) => Number(item.type) === type
      );
      if (
        (type === 0 || type === 1 || type === 2 || type === 3) &&
        isExist !== undefined &&
        isExist.length > 0
      ) {
        setDialogMessage(`중복 추가 불가합니다.`);
        setOpenSnackbar(true);
        return;
      }
      const newForm = [...formList, newFormData];
      setFormList(newForm);
    }
  };

  const updateFormView = () => {
    if (formTitle.length > 0 && formDuration.length > 0) {
      const newTitle = formTitle;
      const newDuration = formDuration;
      const newFormLists = formList;
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        title: newTitle,
        duration: newDuration,
      };
      setFormList(newFormLists);
      console.log('updateFormView :', newFormLists);
    }
  };

  const removeFormView = (idx: number) => {
    console.log(formPage);
    changePageContents();
    const newForm = formList.filter((_form: any, i: number) => i !== idx);
    // const newGuidePage = guidePage - 1 < 1 ? guidePage + 1 : guidePage - 1;
    setFormList(newForm);
  };

  const updateRecordItem = (items: any) => {
    if (isTemplateCode) {
      const newData = {
        recordItem: JSON.stringify(items),
        defaultCurriculum: programInfo.defaultCurriculum,
      };
      if (
        programInfo.defaultCurriculum === undefined ||
        programInfo.defaultCurriculum === null
      ) {
        setOpenSnackbar(true);
        setDialogMessage('디폴트 커리큘럼 아이디가 없습니다.');
        return;
      }
      console.log('updateRecordItem :', newData);
      putProgramTemplateRecordItemMutate(newData);
    } else {
      const newData = {
        recordItem: JSON.stringify(items),
        programId: programInfo.programId,
      };
      console.log('updateRecordItem :', newData);
      putProgramRecordItemMutate(newData);
    }
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      const items: string[] = [];
      missionRecordings.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.keyword]);
        items.push(row.keyword);
      });
      updateRecordItem(items);
    } else {
      setCheckedAll(false);
      updateRecordItem([]);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
      updateRecordItem([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
      updateRecordItem(checkedButtons.filter(button => button !== id));
    }
  };

  function changeLocation(data: any, over: number, current: number) {
    const newData = [...data];
    const tmp = newData[over];
    newData[over] = newData[current];
    newData[current] = tmp;

    return newData;
  }

  const changeMissionLocation = (over: number, current: number) => {
    const newMovieList = changeLocation(movieList, over, current);
    setMovieList(newMovieList);

    const newMagazineList = changeLocation(magazineList, over, current);
    setMagazineList(newMagazineList);

    const newQnaList = changeLocation(qnaList, over, current);
    setQnaList(newQnaList);

    const newLifeStyleList = changeLocation(lifeStyleList, over, current);
    setLifeStyleList(newLifeStyleList);
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: weekList,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    console.log(
      'drag -> drop > newOver',
      newOver,
      ', newCurrent :',
      newCurrent
    );
    setWeekList(newList);
    changeMissionLocation(Number(newOver), Number(newCurrent));

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    evt.currentTarget.style.border = '';
    formItems.forEach(item => item.classList.remove('drag_over'));
    updateWeekAndMissionList(true, curriculumType.none);
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setWeekList(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    evt.currentTarget.style.border = '';
    formItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    formItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newFormIndex = Number(evt.currentTarget.dataset.position);
    setFormIndex(newFormIndex);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newFormIndex : null,
    });

    if (
      ((isTemplateCode === false && newFormIndex >= 0) || isTemplateCode) &&
      newFormIndex !== RECORD_MISSION_INDEX
    ) {
      const newFormData = weekList[newFormIndex];
      console.log('-> newFormData :', newFormData);
      setSelectedWeekId(newFormData.weekId);
      setFormTitle(newFormData.title);
      setWeekTitle(newFormData.weekTitle);
      setFormDuration(newFormData.duration);
    }
  };

  const rollBackWeekAdd = (data: any) => {
    const newList = weekList.filter(
      (item: any, idx: number) => data.weekId !== item.weekId
    );
    setWeekList(newList);
  };

  const { mutate: postCurriculumWeekAddMutate, isError: isMutateError } =
    useMutation(postProgramCurriculumWeekAdd, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('등록되었습니다.');
          reloadCurriculum('');
          setTimeout(() => {
            isTemplateCode
              ? reloadCurriculum('template')
              : reloadCurriculum('program');
          }, 1000);
        } else if (res.resultCode === RESULT_WEEK_LIST_OVER_DURATION) {
          setOpenSnackbar(true);
          setDialogMessage('설정된 참여 기간을 초과한 주차입니다');
          rollBackWeekAdd(res.week);
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
          rollBackWeekAdd(res.week);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          `등록이 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
        // rollBackWeekAdd(res.week);
      },
    });

  const addCurriculumWeek = () => {
    if (weekList.length >= MAX_CURRICULUM_COUNT + 1) {
      setDialogMessage(
        `생성할 수 있는 주차수(${MAX_CURRICULUM_COUNT})를 초과했습니다.`
      );
      setOpenSnackbar(true);
      return;
    }
    const newUuid = newId();
    const newData = {
      title: `${weekList.length} 주차`,
      duration: '',
    };
    const newList = [...weekList, newData];
    setWeekList(newList);

    const newPostData = {
      curriculumId: programInfo.curriculumId,
      weekId: newUuid,
      weekSeq: weekList.length,
      weekTitle: '',
      startDate: '',
      endDate: '',
      isTemplateCode: isTemplateCode ? 'templatecode' : 'program',
    };
    postCurriculumWeekAddMutate(newPostData);
  };

  const {
    mutate: postCurriculumWeekDeleteMutate,
    isError: isMutateDeleteError,
  } = useMutation(postProgramCurriculumWeekDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage(isReset ? '초기화되었습니다' : '삭제되었습니다.');
        reloadCurriculum('');
        setTimeout(() => {
          isTemplateCode
            ? reloadCurriculum('template')
            : reloadCurriculum('program');
        }, 1000);

        setFormIndex(RECORD_MISSION_INDEX);
      } else {
        setOpenDialog(true);
        setDialogMessage(
          isReset
            ? `초기화하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
            : `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        isReset
          ? '초기화가 실패했습니다. 다시 시도해주시기 바랍니다.'
          : '삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const { mutate: updateProgramHealthPointMute, isError: isMutatePointError } =
    useMutation(updateProgramHealthPoint, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(false);
          setDialogMessage('획득 가능한 점수가 변경되었습니다.');
        } else {
          setOpenSnackbar(false);
          setDialogMessage(
            `획득 가능한 점수를 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(false);
        setDialogMessage(
          '획득 가능한 점수 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const resetWeekData = () => {
    const newMovieList = movieList.filter(
      (item: any, idx: number) => idx !== formIndex
    );
    const newMagazineList = magazineList.filter(
      (item: any, idx: number) => idx !== formIndex
    );
    const newQnaList = qnaList.filter(
      (item: any, idx: number) => idx !== formIndex
    );
    const newLifeStyleList = lifeStyleList.filter(
      (item: any, idx: number) => idx !== formIndex
    );

    setMovieList(newMovieList);
    setMagazineList(newMagazineList);
    setQnaList(newQnaList);
    setLifeStyleList(newLifeStyleList);
  };

  const resetWeek = () => {
    const newWeek = weekList[formIndex];

    resetWeekData();

    const newData = {
      curriculumId: programInfo.curriculumId,
      weekId: newWeek.weekId,
      type: 'reset',
      isTemplateCode: isTemplateCode ? 'templatecode' : 'program',
    };
    setIsReset(true);
    postCurriculumWeekDeleteMutate(newData);
  };

  const deleteCurriculumWeek = () => {
    const newWeek = weekList[formIndex];

    resetWeekData();

    const newList = weekList.filter(
      (item: any, idx: number) => idx !== formIndex
    );
    setWeekList(newList);
    setFormIndex(-1);

    const newData = {
      curriculumId: programInfo.curriculumId,
      weekId: newWeek.weekId,
      type: 'delete',
      isTemplateCode: isTemplateCode ? 'templatecode' : 'program',
    };
    setIsReset(false);
    postCurriculumWeekDeleteMutate(newData);
  };

  const checkDeletCurriculumWeek = () => {
    console.log('formIndex:', formIndex);
    if (formIndex === RECORD_MISSION_INDEX) {
      setDialogMessage(`기록형 미션 항목은 삭제 불가능합니다.`);
      setOpenDialog(true);
    } else if (formIndex === COMMON_MISSION_INDEX) {
      // recording mission
      setDialogMessage(`공통미션 항목은 삭제 불가능합니다.`);
      setOpenDialog(true);
    } else {
      setOpenConfirm(true);
    }
  };

  const showCurriculumTypeSelector = () => {
    setOpenCurriculumType(true);
  };

  const makeCurriculumMission = () => {
    const flatFormList = formList.flat(400);
    const newWeekList: any[] = [];
    const newMovieList: any[] = [];
    const newMagazineList: any[] = [];
    const newQnaList: any[] = [];
    const newLifeStyleList: any[] = [];
    let newHealthPoint: number = 0;
    flatFormList.map((item: any, idx: number) => {
      newWeekList[item.weekSeq] = {
        weekSeq: item.weekSeq,
        weekId: item.weekId,
        weekTitle: item.weekTitle,
      };
      if (item.missionType === 1) {
        if (!newMovieList[item.weekSeq]) newMovieList[item.weekSeq] = [];
        const detail = {
          missionType: item.missionType,
          weekId: item.weekId,
          missionTitle: item.missionTitle,
          successScore: item.successScore,
          successCount: item.successCount,
          missionId: item.missionId,
        };
        newHealthPoint += item.successCount * item.successScore;
        newMovieList[item.weekSeq][item.missionSeq] = detail;
      }
      if (item.missionType === 2) {
        if (!newMagazineList[item.weekSeq]) newMagazineList[item.weekSeq] = [];
        const detail = {
          missionType: item.missionType,
          weekId: item.weekId,
          missionTitle: item.missionTitle,
          successScore: item.successScore,
          successCount: item.successCount,
          missionId: item.missionId,
        };
        newHealthPoint += item.successCount * item.successScore;
        newMagazineList[item.weekSeq][item.missionSeq] = detail;
      }
      if (item.missionType === 3) {
        if (!newQnaList[item.weekSeq]) newQnaList[item.weekSeq] = [];
        const detail = {
          missionType: item.missionType,
          weekId: item.weekId,
          missionTitle: item.missionTitle,
          successScore: item.successScore,
          successCount: item.successCount,
          missionId: item.missionId,
          qnaType: item.qnaType,
        };
        newHealthPoint += item.successCount * item.successScore;
        newQnaList[item.weekSeq][item.missionSeq] = detail;
      }
      if (item.missionType === 4) {
        if (!newLifeStyleList[item.weekSeq])
          newLifeStyleList[item.weekSeq] = [];
        const detail = {
          missionType: item.missionType,
          weekId: item.weekId,
          missionTitle: item.missionTitle,
          successScore: item.successScore,
          successCount: item.successCount,
          missionId: item.missionId,
        };
        newHealthPoint += item.successCount * item.successScore;
        newLifeStyleList[item.weekSeq][item.missionSeq] = detail;
      }
    });
    // console.log('newWeekList :', newWeekList);
    // console.log('newMovieList :', newMovieList);
    if (healthPoint !== newHealthPoint) {
      const newData = {
        curriculumId: programInfo.curriculumId,
        totalPoint: newHealthPoint,
      };
      updateProgramHealthPointMute(newData);
    }
    setHealthPoint(newHealthPoint);
    setWeekList(newWeekList);
    setMovieList(newMovieList);
    setMagazineList(newMagazineList);
    setQnaList(newQnaList);
    setLifeStyleList(newLifeStyleList);
  };

  const saveMissionMovie = () => {
    console.log('movie data: ', movieData);
  };

  const saveWeekTitle = () => {
    const newWeekList = weekList.map((week: any) => {
      if (week.weekId === selectedWeekId) {
        week.weekTitle = weekTitle;
      }
      return week;
    });
    setWeekList(newWeekList);

    const newData = {
      curriculumId: programInfo !== null ? programInfo.curriculumId : '',
      weekId: selectedWeekId,
      weekTitle: weekTitle,
    };
    putProgramWeekTitleMutate(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      saveWeekTitle();
    }
  };

  // useEffect(() => {
  //   console.log('-> options :', options);
  //   setOptionList(options);
  // }, [options]);
  useEffect(() => {
    if (selectedCurriculumType === curriculumType.movie) {
      const newData = { ...movieData };
      newData.weekId = selectedWeekId;
      newData.missionId = '';
      setMovieData(newData);
      setOpenMissionMovie(true);
    } else if (selectedCurriculumType === curriculumType.magazine) {
      const newData = { ...magazineData };
      newData.weekId = selectedWeekId;
      newData.missionId = '';
      setMagazineData(newData);
      setOpenMissionMagazine(true);
    } else if (selectedCurriculumType === curriculumType.qna) {
      const newData = { ...qnaData };
      newData.weekId = selectedWeekId;
      newData.missionId = '';
      newData.cmd = 'new';
      setQnaData(newData);
      setOpenMissionQna(true);
    } else if (selectedCurriculumType === curriculumType.lifestyle) {
      const newData = { ...lifeStyleData };
      newData.weekId = selectedWeekId;
      newData.missionId = '';
      setLifeStyleData(newData);
      setOpenMissionLifeStyle(true);
    }
  }, [selectedCurriculumType]);

  useEffect(() => {
    console.log('programInfo: ', programInfo);
  }, [programInfo]);

  useEffect(() => {
    if (movieList !== undefined && movieList.length > 0) {
      const detail = {
        missionType: movieData.missionType,
        missionTitle: movieData.missionTitle,
        successScore: movieData.successScore,
        successCount: movieData.successCount,
        missionId: movieData.missionId,
        link: movieData.link,
        image: movieData.image,
      };
      const newMovieList = [...movieList];
      const index =
        newMovieList[formIndex] !== undefined &&
        newMovieList[formIndex] !== null
          ? newMovieList[formIndex].length
          : 0;
      if (newMovieList[formIndex] === undefined) newMovieList[formIndex] = [];
      newMovieList[formIndex][index] = detail;
      setMovieList(newMovieList);
    }
  }, [movieData]);

  useEffect(() => {
    if (magazineList !== undefined && magazineList.length > 0) {
      const detail = {
        missionType: magazineData.missionType,
        missionTitle: magazineData.missionTitle,
        successScore: magazineData.successScore,
        successCount: magazineData.successCount,
        missionId: magazineData.missionId,
        link: magazineData.link,
        image: magazineData.image,
      };
      const newMagazineList = [...magazineList];
      const index =
        newMagazineList[formIndex] !== undefined &&
        newMagazineList[formIndex] !== null
          ? newMagazineList[formIndex].length
          : 0;
      if (newMagazineList[formIndex] === undefined)
        newMagazineList[formIndex] = [];
      newMagazineList[formIndex][index] = detail;
      setMagazineList(newMagazineList);
    }
  }, [magazineData]);

  useEffect(() => {
    if (qnaList !== undefined && qnaList.length > 0 && qnaData.cmd === 'new') {
      const detail = {
        missionType: qnaData.missionType,
        missionTitle: qnaData.missionTitle,
        successScore: qnaData.successScore,
        successCount: qnaData.successCount,
        missionId: qnaData.missionId,
        link: qnaData.link,
        image: qnaData.image,
        cmd: qnaData.cmd,
      };
      const newQnaList = [...qnaList];
      const index =
        newQnaList[formIndex] !== undefined && newQnaList[formIndex] !== null
          ? newQnaList[formIndex].length
          : 0;
      if (newQnaList[formIndex] === undefined) newQnaList[formIndex] = [];
      newQnaList[formIndex][index] = detail;
      setQnaList(newQnaList);
    }
  }, [qnaData]);

  useEffect(() => {
    if (lifeStyleList !== undefined && lifeStyleList.length > 0) {
      const detail = {
        missionType: lifeStyleData.missionType,
        missionTitle: lifeStyleData.missionTitle,
        successScore: lifeStyleData.successScore,
        successCount: lifeStyleData.successCount,
        missionId: lifeStyleData.missionId,
        link: lifeStyleData.link,
        image: lifeStyleData.image,
      };
      const newLifeStyleList = [...lifeStyleList];

      const index =
        newLifeStyleList[formIndex] !== undefined &&
        newLifeStyleList[formIndex] !== null
          ? newLifeStyleList[formIndex].length
          : 0;
      if (newLifeStyleList[formIndex] === undefined)
        newLifeStyleList[formIndex] = [];

      newLifeStyleList[formIndex][index] = detail;
      setLifeStyleList(newLifeStyleList);
    }
  }, [lifeStyleData]);

  // useEffect(() => {
  //   if (saveCurriculum) {
  //     updateWeekAndMissionList();
  //   }
  // }, [saveCurriculum]);

  useEffect(() => {
    console.log('formList: ', formList);
    if (formList != null && formList.length > 0) {
      makeCurriculumMission();
      changePageContents();

      const newRecordItem =
        programInfo !== null &&
        programInfo.recordItem !== undefined &&
        programInfo.recordItem !== null
          ? JSON.parse(programInfo.recordItem)
          : [];
      setCheckedButtons(newRecordItem);

      if (programInfo !== null) {
        const newStartWeekDate = timestamp2Localestring(
          programInfo.attendStartDate,
          1000
        ).split(' ')[0]; // ex: '2023-06-16'
        setStartWeekDate(newStartWeekDate);
      }
    }

    let newIsTemplateCode = false;
    if (location.pathname.includes('templatecode')) newIsTemplateCode = true;
    else newIsTemplateCode = false;
    setIsTemplateCode(newIsTemplateCode);

    const now = new Date(Date.now()).getTime() / 1000;
    if (
      programInfo !== undefined &&
      programInfo !== null &&
      programInfo.registerStatus === 4
    ) {
      if (newIsTemplateCode === true && programInfo.programCount > 0)
        setDisabled(true);
      else if (newIsTemplateCode === false && programInfo.attendStartDate < now)
        setDisabled(true);
      else setDisabled(false);
    } else setDisabled(false);
    // for test
    // else {
    // setFormList(curriculumTemplate);
    // setMovieList(movieTemplate);
    // setMagazineList(magazineTemplate);
    // setQnaList(qnaTemplate);
    // setLifeStyleList(lifeStyleTemplate);
    // }
  }, [formList, programInfo]);

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        divider={
          <Divider
            orientation='vertical'
            flexItem
            sx={{ p: 0, m: '1.5rem 2rem 0' }}
          />
        }
      >
        <Box>
          <CurriculumPaper
            variant='outlined'
            sx={{
              width: '32rem',
              color: 'white',
              background:
                'hsl(221.02564102564105, 95.1219512195122%, 48.23529411764706%)',
            }}
          >
            <Typography className='title'>획득 가능한 건강 점수</Typography>
            <Typography className='title'>{healthPoint}</Typography>
          </CurriculumPaper>
          <Box className='flex_between' key='form_record'>
            <CurriculumPaper
              className={
                formIndex === RECORD_MISSION_INDEX
                  ? clsx(`${draggableName} selected_row`)
                  : draggableName
              }
              id='0'
              variant='outlined'
              data-position={RECORD_MISSION_INDEX}
              onClick={selectList}
              sx={{ width: '32rem', margin: '.2rem 1.7rem' }}
            >
              <DragHandleIcon />
              <Box className='flex_start' sx={{ width: '40%' }}>
                기록형 미션
                <Tooltip
                  title={
                    <Typography fontSize={TOOLTIP_FONT_SIZE}>
                      {TOOLTIP_RECORDING_MISSION}
                    </Typography>
                  }
                  followCursor
                >
                  <HelpOutlineIcon color='info' sx={{ ml: '.5rem' }} />
                </Tooltip>
              </Box>

              <Box className='flex_end' sx={{ width: '40%' }}>
                {showDuration ? '전체기간' : ''}
              </Box>
              {weekList.length > 1 && <ChevronRightIcon />}
            </CurriculumPaper>
          </Box>
          {weekList?.map((item: any, idx: number) => (
            <Box className='flex_between' key={`form_${idx}`}>
              {idx === 0 ? (
                <CurriculumPaper
                  className={
                    formIndex === idx
                      ? clsx(`${draggableName} selected_row`)
                      : draggableName
                  }
                  id={(idx + 1).toString()}
                  variant='outlined'
                  data-position={idx}
                  onClick={selectList}
                  sx={{ width: '32rem', margin: '.2rem 1.7rem' }}
                >
                  <DragHandleIcon />
                  <Box className='flex_start' sx={{ width: '40%' }}>
                    {idx === 0 ? '공통미션' : item.weekTitle}
                    <Tooltip
                      title={
                        <Typography fontSize={TOOLTIP_FONT_SIZE}>
                          {TOOLTIP_COMMON_MISSION}
                        </Typography>
                      }
                      followCursor
                    >
                      <HelpOutlineIcon color='info' sx={{ ml: '.5rem' }} />
                    </Tooltip>
                  </Box>
                  <Box className='flex_end' sx={{ width: '40%' }}>
                    {item !== undefined ? item.duration : ''}
                  </Box>
                  {weekList.length > 1 && <ChevronRightIcon />}
                </CurriculumPaper>
              ) : (
                <CurriculumPaper
                  className={
                    formIndex === idx
                      ? clsx(`${draggableName} selected_row`)
                      : draggableName
                  }
                  id={idx.toString()}
                  variant='outlined'
                  onDragStart={dragStart}
                  onDragEnd={dragEnd}
                  onDragOver={dragOver}
                  onDrop={drop}
                  onDragLeave={dragLeave}
                  onDragEnter={dragEnter}
                  draggable
                  data-position={idx}
                  onClick={selectList}
                  sx={{ width: '32rem', margin: '.2rem 1.7rem' }}
                >
                  <DragHandleIcon />
                  {item !== undefined ? (
                    <Box className='flex_start' sx={{ width: '60%' }}>
                      <Typography
                        className='title'
                        sx={{ width: '5rem' }}
                      >{`${idx} 주차>`}</Typography>
                      <Typography className='title' sx={{ width: '15rem' }}>
                        {item.weekTitle}
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  )}

                  <Box className='flex_end' sx={{ width: '40%' }}>
                    {showDuration
                      ? getCurriculumDration(
                          new Date(Date.now()).getFullYear(),
                          getWeekNumber(startWeekDate),
                          idx - 1
                        )
                      : ''}
                  </Box>
                  {weekList.length > 1 && <ChevronRightIcon />}
                </CurriculumPaper>
              )}
            </Box>
          ))}
          <Box>
            <Box
              className='flex_center'
              sx={{
                margin: '1rem 1.7rem',
              }}
            >
              <Button
                disabled={disabled}
                variant='outlined'
                sx={{ width: '100%' }}
                onClick={() => addCurriculumWeek()}
              >
                <AddIcon />
                주차 추가
              </Button>
            </Box>
            <Box
              className='flex_center'
              sx={{
                margin: '1rem 1.7rem',
              }}
            >
              <Button
                disabled={disabled}
                variant='outlined'
                sx={{ width: '100%' }}
                onClick={() => {
                  checkDeletCurriculumWeek();
                }}
              >
                <RemoveIcon />
                주차 삭제
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          {formIndex === RECORD_MISSION_INDEX ? (
            <Paper>
              <Box
                className='flex_between'
                sx={{ width: '95%', margin: '1rem 1.7rem' }}
              >
                <Typography sx={{ fontSize: '1.5rem' }}>기록형 미션</Typography>
              </Box>
              <CurriculumPaper
                sx={{
                  width: '50%',
                  boxShadow: '0px 2px 1px 1px rgb(0 0 0 / 20%)',
                }}
              >
                <Box className='flex_center'>
                  <Checkbox
                    disabled={disabled}
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                  <Typography className='title' sx={{ fontSize: '1rem' }}>
                    제목
                  </Typography>
                </Box>
                <Box className='flex_start'>
                  <Typography className='title' sx={{ fontSize: '1rem' }}>
                    상세보기
                  </Typography>
                </Box>
              </CurriculumPaper>
              {missionRecordings.map((row: any, idx: number) => (
                <CurriculumPaper
                  key={`recording_idx_${idx}`}
                  sx={{
                    width: '50%',
                    margin: '.2rem 1.7rem',
                  }}
                >
                  <Box className='flex_start' sx={{ width: '50%' }}>
                    <Checkbox
                      disabled={disabled}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, row.keyword);
                      }}
                      checked={checkedButtons.includes(row.keyword)}
                    />
                    <Box>
                      <Typography className='title'>{row.title}</Typography>
                    </Box>
                  </Box>
                  <Box className='flex_end' sx={{ width: '50%' }}>
                    <Box>
                      <Button
                        variant='outlined'
                        color='info'
                        onClick={() => {
                          setMissionRecordingsIndex(idx);
                          setOpenRecordingDetail(true);
                        }}
                      >
                        상세보기
                      </Button>
                    </Box>
                  </Box>
                </CurriculumPaper>
              ))}
            </Paper>
          ) : (
            <Paper>
              <Box
                className='flex_between'
                sx={{ width: '95%', margin: '1rem 1.7rem' }}
              >
                <Box>
                  <Typography sx={{ fontSize: '1.5rem' }}>
                    {formIndex === 0 ? '공통미션' : `${formIndex} 주차 미션`}
                  </Typography>
                </Box>
                {!disabled && (
                  <Box className='flex_between'>
                    <Box
                      className='flex_center'
                      sx={{
                        margin: '1rem 1.7rem',
                      }}
                    >
                      <Button
                        variant='outlined'
                        color='info'
                        onClick={resetWeek}
                      >
                        <RestartIcon />
                        초기화
                      </Button>
                    </Box>
                    <Box
                      className='flex_center'
                      sx={{
                        margin: '1rem 1.7rem',
                      }}
                    >
                      <Button
                        variant='outlined'
                        onClick={() => showCurriculumTypeSelector()}
                      >
                        <AddIcon />
                        항목 추가
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className='flex_between'>
                <TextField
                  sx={{ ml: '1.7rem', width: '87%' }}
                  value={weekTitle}
                  onKeyDown={evt => {
                    handleKeyDown(evt);
                  }}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setWeekTitle(evt.target.value.substring(0, 30))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>{weekTitle?.length} / 30</span>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  sx={{ mr: '2rem' }}
                  variant='outlined'
                  onClick={() => saveWeekTitle()}
                >
                  저장
                </Button>
              </Box>
              <CurriculumPaper
                sx={{
                  width: '95%',
                  boxShadow: '0px 2px 1px 1px rgb(0 0 0 / 20%)',
                }}
              >
                <Box sx={{ width: '40%' }} className='flex_center'>
                  <Typography
                    className='title'
                    sx={{ ml: '8rem', fontSize: '1rem' }}
                  >
                    제목
                  </Typography>
                </Box>
                <Box sx={{ width: '50%' }} className='flex_start'>
                  <Typography
                    className='title'
                    sx={{ width: '20%', fontSize: '1rem' }}
                  >
                    횟수
                  </Typography>
                  <Typography
                    className='title'
                    sx={{ width: '20%', fontSize: '1rem' }}
                  >
                    건강 점수
                  </Typography>
                  <Typography
                    className='title'
                    sx={{ width: '20%', fontSize: '1rem' }}
                  >
                    상세보기
                  </Typography>
                  <Typography
                    className='title'
                    sx={{ fontSize: '1rem' }}
                  >{` `}</Typography>
                  <Typography
                    className='title'
                    sx={{ fontSize: '1rem' }}
                  >{` `}</Typography>
                  <Typography
                    className='title'
                    sx={{ fontSize: '1rem' }}
                  >{` `}</Typography>
                </Box>
              </CurriculumPaper>
              <Box>
                <Box>
                  <SwCurriculumMissionItem
                    missionType={curriculumType.movie}
                    formIndex={formIndex}
                    formList={movieList}
                    setFormList={setMovieList}
                    onReloadCurriculum={reloadCurriculum}
                    disabled={disabled}
                  />
                  <Divider />
                </Box>
                <Box>
                  <SwCurriculumMissionItem
                    missionType={curriculumType.magazine}
                    formIndex={formIndex}
                    formList={magazineList}
                    setFormList={setMagazineList}
                    onReloadCurriculum={reloadCurriculum}
                    disabled={disabled}
                  />
                  <Divider />
                </Box>
                <Box>
                  <SwCurriculumMissionItem
                    missionType={curriculumType.qna}
                    formIndex={formIndex}
                    formList={qnaList}
                    setFormList={setQnaList}
                    onReloadCurriculum={reloadCurriculum}
                    disabled={disabled}
                  />
                  <Divider />
                </Box>
                <Box>
                  <SwCurriculumMissionItem
                    missionType={curriculumType.lifestyle}
                    formIndex={formIndex}
                    formList={lifeStyleList}
                    setFormList={setLifeStyleList}
                    onReloadCurriculum={reloadCurriculum}
                    disabled={disabled}
                  />
                </Box>
              </Box>
            </Paper>
          )}
        </Box>
      </Stack>
      <SwMissionRecordingDetail
        title={missionRecordings[missionRecordingsIndex].title}
        items={missionRecordings[missionRecordingsIndex].items}
        open={openRecordingDetail}
        onClose={() => setOpenRecordingDetail(false)}
      />
      <SwCurriculumTypeSelector
        curriculumTypeList={curriculumTypeList}
        curriculumType={selectedCurriculumType}
        setCurriculumType={setSelectedCurriculumType}
        open={openCurriculumType}
        onClose={() => {
          setOpenCurriculumType(false);
        }}
      />
      <SwMissionMovie
        data={movieData}
        setData={setMovieData}
        open={openMissionMovie}
        onClose={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionMovie(false);
        }}
        onSave={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionMovie(false);
        }}
        onDelete={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionMovie(false);
        }}
      />
      <SwMissionMagazine
        data={magazineData}
        setData={setMagazineData}
        open={openMissionMagazine}
        onClose={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionMagazine(false);
        }}
        onSave={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionMagazine(false);
        }}
      />
      <SwMissionQna
        data={qnaData}
        setData={setQnaData}
        open={openMissionQna}
        onClose={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionQna(false);
        }}
        onSave={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionQna(false);
        }}
      />
      <SwMissionLifeStyle
        data={lifeStyleData}
        setData={setLifeStyleData}
        open={openMissionLifeStyle}
        onClose={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionLifeStyle(false);
        }}
        onSave={() => {
          setSelectedCurriculumType(-1);
          reloadCurriculum();
          setOpenMissionLifeStyle(false);
        }}
      />

      <SwConfirmDialog
        contents={`${formIndex !== 0 ? formIndex : ''}${
          formIndex !== 0 ? '주차> ' : ''
        }${
          formIndex === 0
            ? '공통미션'
            : weekList[formIndex]
            ? weekList[formIndex].weekTitle
            : ''
        } \n\n삭제 시 주차 내 미션이 모두 사라집니다. 삭제하시겠습니까?`}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          deleteCurriculumWeek();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
