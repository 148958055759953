import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IPush, IPushCancel, IPushUpdate } from '../../models/push';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Report
 */

export const getErrorReportList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/logserver/error-report?appVersion=${data.appVersion}&startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
