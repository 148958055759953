import { Dispatch, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { removeNatCodePhoneNumber } from '../../../common/helper';
import { SwSelectGpsContent } from '../../commonComponent/SwSelectGpsContent';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';

interface Props {
  open: boolean;
  onClose: () => void;
  challengeInfo: any;
  connectedInfo: any;
  setConnectedInfo: Dispatch<React.SetStateAction<any>>;
}

export const ChallengeAudioGpsContent: React.FC<Props> = ({
  open,
  onClose,
  challengeInfo,
  connectedInfo,
  setConnectedInfo,
}) => {
  const [challengeType, setChallengeType] = useState<any>(-1);
  const [content, setContent] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [challengeId, setChallengeId] = useState<string>('');
  const [requestData, setRequestData] = useState<boolean>(false);
  const [selectedTarget, setSelectedTarget] = useState<any>();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const onAddStamp = () => {
    setConnectedInfo({ ...selectedTarget });
    onClose();
  };

  useEffect(() => {
    if (open) {
      connectedInfo
        ? setSelectedTarget({ ...connectedInfo })
        : setSelectedTarget(null);
      setChallengeType(challengeInfo.challengeType);
    } else {
      setSelectedTarget(null);
      setOpenConfirm(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '88vh',
          margin: '22%',
          minWidth: '345px',
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {challengeType === 20 ? '스탬프 연결하기' : '장소 콘텐츠 연결하기'}
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent>
        <SwSelectGpsContent
          challengeInfo={challengeInfo}
          targetInfo={selectedTarget}
          setTargetInfo={setSelectedTarget}
        />
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        <Box className='flex_between'>
          <Box>
            {selectedTarget ? (
              <Typography>{`‘${
                selectedTarget.name.length > 10
                  ? `${selectedTarget.name.substring(0, 10)}...`
                  : selectedTarget.name
              }’가 선택되었습니다.`}</Typography>
            ) : (
              ''
            )}
          </Box>
          <Box>
            <Button
              variant='outlined'
              color='info'
              sx={{ mr: '1rem', ml: '1rem' }}
              onClick={() => {
                if (selectedTarget) {
                  setOpenConfirm(true);
                } else {
                  setOpenSnackbar(true);
                  setDialogMessage('연결할 대상을 선택해주세요');
                }
              }}
            >
              취소
            </Button>
            <Button variant='contained' color='info' onClick={onAddStamp}>
              추가하기
            </Button>
          </Box>
        </Box>
      </DialogActions>
      <SwConfirmDialog
        contents='선택한 내용이 모두 사라집니다. 뒤로 가시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setConnectedInfo(null);
          onClose();
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </Dialog>
  );
};
