import styled from '@emotion/styled';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useRef, useEffect, useState } from 'react';

interface ScrollPickerProps {
  isOpen: boolean;
  list: string[];
  defaultIndex: number;
  onSelectedChange?: (selected: string | number) => void;
}

const PickerList = ({
  isOpen,
  list,
  defaultIndex,
  onSelectedChange,
}: ScrollPickerProps) => {
  const ref = useRef<HTMLUListElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    onSelectedChange && onSelectedChange(list[index]);
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedIndex(-1);
    }
  }, [isOpen]);

  return (
    <List
      sx={{
        border: '1px solid #ebebeb',
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 200,
        '& ul': { padding: 0 },
      }}
    >
      {list.map((sectionId: any, idx: number) => (
        <ListItemButton
          selected={selectedIndex === idx}
          onClick={event => handleListItemClick(event, idx)}
        >
          <ListItem key={`${sectionId}`}>
            <ListItemText
              sx={{
                height: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '10px',
              }}
              primary={`${sectionId}`}
            />
          </ListItem>
        </ListItemButton>
      ))}
    </List>
  );
};

export default PickerList;
