import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProgramMemberSetting } from './ProgramMemberSetting';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import {
  RowsCell,
  SelectTextField,
  SwSwitch,
  TableTopButton,
  WhiteTextField,
} from '../../styles/Styles';
import { xapi } from '../../../hooks/session';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwLoadUser } from '../../commonComponent/SwLoadUser';
import SwLoading from '../../commonComponent/spinner/SwLoading';

import { KEY_PROGRAM_MEMBER_LIST } from '../../../common/key';
import {
  encodeSearchWord,
  getDeletedReason,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import {
  deleteProgramMember,
  downloadProgramMemberList,
  getProgramMemberList,
  postProgramMemberByPhonenos,
} from '../../../common/api/ApiProgram';
import { getBloodPressureGroup } from '../../../common/helperProgram';

interface Props {
  programInfo: any;
}

const MAX_LOAD_PHONE_LENGTH = 500;

const MEMBER_TYPE = '멤버';
const memberColumns = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '연락처',
  '거주 지역',
  '활동 지역',
  '성별',
  '나이',
  '프로그램 참가일시',
  '총 걸음수',
  '혈압군',
  '미션달성 현황',
  '건강 점수',
  '응모 횟수',
  '리워드 발급 횟수',
  '이수증 발급 여부',
  '승인여부',
  '탈퇴일자',
  '탈퇴사유',
];

const searchOption = [
  {
    title: '닉네임',
    value: 'name',
  },
  {
    title: '연락처',
    value: 'phonenum',
  },
  {
    title: '거주지역',
    value: 'residence',
  },
  {
    title: '활동지역',
    value: 'activity',
  },
  {
    title: '성별',
    value: 'gender',
  },
  {
    title: '나이',
    value: 'age',
  },
];

export const ProgramMembers: React.FC<Props> = ({ programInfo }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const operatorRef = useRef<any>();
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();
  const [programId, setProgramId] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [settingOpen, setSettingOpen] = useState(false);
  const [programMembers, setProgramMembers] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalRecordsWithoutDeleted, setTotalRecordsWithoutDeleted] =
    useState(0);
  const [dailyStatType, setDailyStatType] = useState<any>(0);
  const [request, setRequest] = useState<boolean>(false);
  const [loadUserList, setLoadUserList] = useState<any>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(true);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openLoadUser, setOpenLoadUser] = useState<number>(0);
  const [openSwitch, setOpenSwitch] = useState<boolean>(true);
  const [closeSwitch, setCloseSwitch] = useState<boolean>(true);

  const { refetch: programMemberRefetch } = useQuery(
    KEY_PROGRAM_MEMBER_LIST,
    () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const searchKey = selectRef.current.value;
      const searchWord = searchRef.current.value;
      const newData = {
        programId: programId,
        searchKey: searchKey,
        searchWord:
          searchKey === 'groupname'
            ? searchWord === '기본그룹'
              ? 'no-group'
              : encodeSearchWord(searchKey, searchWord)
            : encodeSearchWord(searchKey, searchWord),
        type: 0,
        status: status,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
      };
      console.log('programMemberRefetch newData :', newData);
      setLoading(true);
      if (programId !== undefined && programId.length > 0)
        return getProgramMemberList(newData);
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          console.log('programMemberRefetch res:', res.member);
          setProgramMembers(res.member);
          setTotalRecords(res.pagination?.totalRecordCount);
          setTotalRecordsWithoutDeleted(
            res.pagination?.totalRecordCountWithoutDeleted
          );
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetchMember = (newPage: number) => {
    queryClient.prefetchQuery(KEY_PROGRAM_MEMBER_LIST, () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const searchKey = selectRef.current.value;
      const searchWord = searchRef.current.value;
      const newData = {
        programId: programId,
        searchKey: searchKey,
        searchWord:
          searchKey === 'groupname'
            ? searchWord === '기본그룹'
              ? 'no-group'
              : encodeSearchWord(searchKey, searchWord)
            : encodeSearchWord(searchKey, searchWord),
        type: 0,
        status: status,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      setLoading(true);
      return getProgramMemberList(newData);
    });
  };

  const {
    mutate: deleteProgramMemberMutate,
    isError: isMutateNameDeleteError,
  } = useMutation(deleteProgramMember, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setSnackbarMessage('프로그램 탈퇴처리했습니다.');
        programMemberRefetch();
        setCheckedButtons([]);
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(
          `프로그램 멤버 탈퇴하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setSnackbarMessage(
        '프로그램 탈퇴 처리가 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: postProgramMemberByPhonenosMutate,
    isError: isMutateMemberError,
  } = useMutation(postProgramMemberByPhonenos, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setSnackbarMessage('프로그램 멤버를 추가했습니다.');
        programMemberRefetch();
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(
          `프로그램 멤버 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setSnackbarMessage(
        `프로그램 멤버 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
      );
    },
  });

  const addMember = (userPhonenos: any[]) => {
    const newData = {
      programId: programId,
      type: 0,
      phonenos: userPhonenos,
    };
    postProgramMemberByPhonenosMutate(newData);
  };

  const deleteMembers = () => {
    const newUserIds = checkedButtons;
    const newData = {
      programId: programId,
      userIds: newUserIds,
    };
    if (newUserIds.length === 0) {
      setOpenSnackbar(true);
      setSnackbarMessage('탈퇴 처리할 멤버를 선택해 주세요');
      return;
    }
    console.log('newData: ', newData);
    deleteProgramMemberMutate(newData);
  };

  const downloadMember = () => {
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const searchKey = selectRef.current.value;
    const searchWord = searchRef.current.value;
    const newData = {
      programId: programId,
      searchKey: searchKey,
      searchWord:
        searchKey === 'groupname'
          ? searchWord === '기본그룹'
            ? 'no-group'
            : encodeSearchWord(searchKey, searchWord)
          : encodeSearchWord(searchKey, searchWord),
      type: 0,
      status: status,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
    };
    downloadProgramMemberList(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetchMember(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      programMembers
        ?.filter((row: any) => row.deleted === 0)
        .map((row: any, idx: number) => {
          setCheckedButtons(current => [...current, row.userId]);
        });
    } else {
      setCheckedAll(false);
    }
    console.log('handleSelectAllClick:', checkedButtons);
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const onChangeSetting = () => {
    setSettingOpen(false);
    programMemberRefetch();
  };

  const searchMember = () => {
    setPage(1);
    programMemberRefetch();
  };

  const isMember = (phoneno: string) => {
    if (dialogTitle === MEMBER_TYPE) {
      const newMember = programMembers.filter(
        (item: any) => item.phoneno === phoneno && item.deleted === 0
      );
      if (newMember.length > 0) return true;
    }
    return false;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchMember();
    }
  };

  const downloadProgramLocal = (param: any) =>
    xapi
      .get(
        `/admin/v2/apis/program/information/download/${param.downloadType}?programId=${param.programId}&type=${param.type}&searchKey=${param.searchKey}&searchWord=${param.searchWord}&startDate=${param.startDate}&endDate=${param.endDate}`,
        { responseType: 'blob' }
      )
      .then(res => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `program_${param.downloadType}.xlsx`;
        document.body.appendChild(a);
        a.click();
        setTimeout((_: any) => {
          window.URL.revokeObjectURL(url);
        }, 600000);
        a.remove();
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error('err: ', err);
      });

  const downloadProgramData = (type: string) => {
    const newData = {
      downloadType: type,
      programId: programId,
      type: type === 'program-daily-step' ? '0' : '1',
      searchKey: selectRef.current.value,
      searchWord: searchRef.current.value,
      startDate: 0,
      endDate: 0,
    };
    setLoading(true);
    downloadProgramLocal(newData);
  };

  const downloadStat = (type: number) => {
    type === 1
      ? downloadProgramData('program-daily-step')
      : type === 2
      ? downloadProgramData('program-daily-step-deleted')
      : type === 3
      ? downloadProgramData('program-daily-step-adv')
      : '';
  };

  useEffect(() => {
    setPage(1);
    prefetchMember(1);
  }, [openSwitch, closeSwitch]);

  useEffect(() => {
    if (loadUserList.length > 0) addMember(loadUserList);
  }, [loadUserList]);

  useEffect(() => {
    programMemberRefetch();
  }, [rowsPerPage]);

  useEffect(() => {
    if (rangeDate[0] !== null && rangeDate[1] !== null) {
      programMemberRefetch();
    }
  }, [rangeDate]);

  useEffect(() => {
    if (programId.length > 0) {
      setRangeDate([null, null]);
      programMemberRefetch();
    }
  }, [programId]);

  useEffect(() => {
    if (programInfo) {
      setPage(1);
      setProgramId(programInfo.programId);
    }
  }, [programInfo]);

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box className='flex_between' sx={{ mt: '2rem' }}>
        <SelectSearchBox
          searchOption={searchOption}
          searchWord={searchRef}
          optionValue={selectRef}
          defaultOption='name'
          isSearching={() => searchMember()}
        />
        <Box className='flex_center'>
          <Box sx={{ mr: '1rem' }}>
            <TextField
              select
              sx={{ width: '17rem' }}
              value={dailyStatType}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                downloadStat(Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={0}>
                일자별 통계 다운로드 선택
              </MenuItem>
              <MenuItem key={1} value={1}>
                참여자 일일 걸음 수 다운로드
              </MenuItem>
              <MenuItem key={2} value={2}>
                참여자 일일 걸음 수 다운로드(탈퇴자포함)
              </MenuItem>
            </TextField>
          </Box>
          <div>
            <Button
              variant='outlined'
              color='info'
              onClick={() => setOpenConfirm(true)}
            >
              멤버 탈퇴
            </Button>
            <Button
              variant='contained'
              color='info'
              sx={{ ml: '1rem' }}
              onClick={() => {
                setDialogTitle(MEMBER_TYPE);
                setSettingOpen(true);
              }}
            >
              멤버 추가
            </Button>
            <Button
              variant='contained'
              color='info'
              sx={{ ml: '1rem' }}
              onClick={() => {
                setDialogTitle(MEMBER_TYPE);
                setOpenLoadUser(2);
              }}
            >
              복사하여 추가
            </Button>
          </div>
        </Box>
      </Box>
      <Paper sx={{ m: '1rem 0', p: '1rem' }}>
        <Box className='flex_between'>
          <TableTitle
            title='멤버(탈퇴제외)'
            count={programMembers && totalRecordsWithoutDeleted}
          />
          <Box className='flex_center'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <TableTopButton
              color='info'
              variant='outlined'
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </TableTopButton>
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
            <TableTopButton
              color='info'
              variant='outlined'
              onClick={downloadMember}
            >
              데이터 다운로드
              <FileDownloadRoundedIcon color='success' />
            </TableTopButton>
          </Box>
        </Box>
        <Box sx={{ pb: '1rem' }}>
          <Box className='flex_end'>
            <Typography sx={{ mr: '.4rem' }}>승인</Typography>
            <SwSwitch
              defaultChecked={true}
              value={openSwitch}
              onChange={() => setOpenSwitch(!openSwitch)}
            />
            <Typography sx={{ ml: '1rem', mr: '.4rem' }}>미승인</Typography>
            <SwSwitch
              defaultChecked={true}
              value={closeSwitch}
              onChange={() => setCloseSwitch(!closeSwitch)}
            />
          </Box>
        </Box>
        <Table>
          <TableHead sx={{ bgcolor: '#F9FAFC' }}>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={e => {
                    handleSelectAllClick(e);
                  }}
                  checked={checkedAll}
                />
              </TableCell>
              {memberColumns.map((column, idx) => (
                <TableCell
                  key={idx.toString()}
                  align={idx === 5 ? 'right' : 'inherit'}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {programMembers.length > 0 ? (
              programMembers?.map((member: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      id={member.userId}
                      disabled={member.deleted === 1}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, member.userId);
                      }}
                      checked={checkedButtons.includes(member.userId)}
                    />
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.name}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.nickName === null
                      ? '-'
                      : member.useNickName === 1
                      ? 'O'
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.nickName !== null ? member.nickName : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.phoneno}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                    sx={{
                      color:
                        member.residenceAuthYN === 'Y'
                          ? 'blue'
                          : member.residenceAddress !== null
                          ? 'red'
                          : '',
                    }}
                  >
                    {member.residenceAddress !== null
                      ? member.residenceAddress
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                    sx={{
                      color:
                        member.activityAuthYN === 'Y'
                          ? 'blue'
                          : member.activityAddress !== null
                          ? 'red'
                          : '',
                    }}
                  >
                    {member.activityAddress !== null
                      ? member.activityAddress
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.gender}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.age}세
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {timestamp2Localestring(member.attendDate, 1000)}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.sumStep}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {getBloodPressureGroup(member.bloodPressureGroup)}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.missionProgress}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.totalScore}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.rewardDrawCount}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.rewardCount}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.hasCertificate === 0 ? '-' : 'O'}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.attendStatus === 1 ? '승인' : '미승인'}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.deleted === 1
                      ? timestamp2Localestring(member.deletedDate, 1000)
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      member.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {member.deleted === 1
                      ? getDeletedReason(member.deletedReason)
                      : '-'}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>검색결과 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {programMembers.length > 0 && totalRecords && (
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={programMembers && Math.ceil(totalRecords / rowsPerPage)}
          />
        )}
      </Paper>
      <ProgramMemberSetting
        programId={programId}
        open={settingOpen}
        onClose={() => setSettingOpen(!settingOpen)}
        onConfirm={onChangeSetting}
        title={dialogTitle}
        isMember={isMember}
      />
      <SwAlert
        title='test'
        contents='contentss'
        confirm={1}
        onClose={() => setAlertOpen(false)}
        onConfirm={() => setAlertOpen(false)}
        open={alertOpen}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
          setSnackbarMessage('');
        }}
        contents={snackbarMessage}
      />
      <SwConfirmDialog
        contents='선택한 멤버를 탈퇴처리하겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          deleteMembers();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
      <SwLoadUser
        open={!!openLoadUser}
        onclose={() => {
          setOpenLoadUser(0);
        }}
        setLoadList={setLoadUserList}
        maxSize={MAX_LOAD_PHONE_LENGTH}
      />
      {loading && <SwLoading />}
    </Box>
  );
};
