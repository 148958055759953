import React, { Dispatch, SetStateAction } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import koLocale from 'date-fns/locale/ko';
import { TextField } from '@mui/material';

interface Props {
  date: any;
  setDate: Dispatch<SetStateAction<any>>;
  width?: string;
  minDate?: any;
  maxDate?: any;
  label?: string;
  disabled?: boolean;
  onChangeValue: (newValue: any) => void;
}

export const SwDatePicker: React.FC<Props> = ({
  date,
  setDate,
  width = '400px',
  minDate,
  maxDate,
  label = '공개일시 선택',
  disabled = false,
  onChangeValue,
}) => (
  <>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={koLocale}>
      {/* <DesktopDatePicker */}
      <DatePicker
        views={['month', 'year', 'day']}
        mask='____/__/__'
        inputFormat='yyyy/MM/dd'
        value={date}
        onChange={newValue => {
          setDate(newValue);
          onChangeValue(newValue);
        }}
        label={label}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={props => (
          <TextField
            {...props}
            inputProps={{ ...props.inputProps, readOnly: true }}
            sx={{
              mb: 0,
              width: { width },
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                borderColor: '#D4D9E1 !important',
                height: '42px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D4D9E1 !important',
              },
              '& .MuiInputLabel-root': { top: -4 },
            }}
          />
        )}
      />
    </LocalizationProvider>
  </>
);

export default SwDatePicker;
