import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Collapse,
  ClickAwayListener,
} from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import Picker from './Picker';
import PickerList from './PickerList';

const TimePickerWrapper = styled.div<{ width: string; disabled: boolean }>`
  width: ${props => props.width};
  border: 1px solid #d4d9e1;
  border-radius: 4px;
  :hover {
    ${props =>
      !props.disabled &&
      css`
        border: 1px solid #bdbdbd;
        transform: scale(1);
      `}
  }
  height: 42px;
  margin-left: 10px;
  z-index: 50;
  background-color: '#fff';
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.54)' : '#333')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const TimeTextContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  padding-left: 14px;
  align-items: center;
`;

const TimePickerContents = styled(Collapse)`
  margin-top: 5px;
  display: flex;
  width: 100%;
  background-color: white;
  border: 1px solid #d4d9e1;
  border-radius: 4px;
`;

interface Props {
  value: Date;
  onChangeValue: (newValue: Date) => void;
  open: boolean;
  handleOpen: Dispatch<SetStateAction<boolean>>;
  width?: string;
  disabled?: boolean;
  intervalMinutes: number; // 분 단위
}

interface SelectedTime {
  hours: string;
  minutes: string;
  ampm: 'AM' | 'PM';
}

export const SwScrollTimePicker: React.FC<Props> = ({
  value,
  onChangeValue,
  open,
  handleOpen,
  width = '100%',
  disabled = false,
  intervalMinutes,
}) => {
  const [originTime, setOriginTime] = useState<SelectedTime>({
    hours: (value.getHours() % 12 || 12).toString().padStart(2, '0'),
    minutes: value.getMinutes().toString().padStart(2, '0'),
    ampm: value.getHours() < 12 ? 'AM' : 'PM',
  });
  const [selectedTime, setSelectedTime] = useState<SelectedTime>({
    hours: (value.getHours() % 12 || 12).toString().padStart(2, '0'),
    minutes: value.getMinutes().toString().padStart(2, '0'),
    ampm: value.getHours() < 12 ? 'AM' : 'PM',
  });

  useEffect(() => {
    // input창에서 시간 보여주기
    setOriginTime({
      hours: (value.getHours() % 12 || 12).toString().padStart(2, '0'),
      minutes: value.getMinutes().toString().padStart(2, '0'),
      ampm: value.getHours() < 12 ? 'AM' : 'PM',
    });
  }, [value]);

  const hoursList = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, '0')
  );
  const minutesList = Array.from(
    { length: Math.ceil(60 / intervalMinutes) },
    (_, i) => String(i * intervalMinutes).padStart(2, '0')
  );

  const ampmList = ['AM', 'PM'];

  const onCloseSelect = () => handleOpen(false);

  const handleSelectChange = (name: keyof SelectedTime, vl: string) => {
    setSelectedTime(prevTime => ({ ...prevTime, [name]: vl }));
  };

  useEffect(() => {
    const newDate = new Date(value);
    newDate.setHours(
      selectedTime.ampm === 'PM'
        ? selectedTime.hours === '12'
          ? 12
          : parseInt(selectedTime.hours, 10) + 12
        : selectedTime.hours === '12'
        ? 0
        : parseInt(selectedTime.hours, 10)
    );

    newDate.setMinutes(parseInt(selectedTime.minutes, 10));
    newDate.setSeconds(0);
    onChangeValue(newDate);
  }, [selectedTime]);

  return (
    <ClickAwayListener onClickAway={onCloseSelect}>
      <TimePickerWrapper width={width} disabled={disabled}>
        <TimeTextContainer onClick={() => !disabled && handleOpen(true)}>
          {`${originTime.ampm} ${originTime.hours}:${originTime.minutes} `}
        </TimeTextContainer>

        <TimePickerContents in={open}>
          <div style={{ display: 'flex' }}>
            <PickerList
              isOpen={open}
              list={ampmList}
              defaultIndex={selectedTime.ampm === 'AM' ? 1 : 2}
              onSelectedChange={e => handleSelectChange('ampm', e as string)}
            />

            <PickerList
              isOpen={open}
              list={hoursList}
              defaultIndex={Number(selectedTime.hours)}
              onSelectedChange={e => handleSelectChange('hours', e as string)}
            />

            <PickerList
              isOpen={open}
              list={minutesList}
              defaultIndex={Number(selectedTime.minutes) / intervalMinutes + 1}
              onSelectedChange={e => handleSelectChange('minutes', e as string)}
            />
          </div>
        </TimePickerContents>
      </TimePickerWrapper>
    </ClickAwayListener>
  );
};

export default SwScrollTimePicker;
