import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import useLocalStorage from '../../hooks/storage';
import { getAmdList } from '../../common/api/ApiGeohash';
import { KEY_AMD_LIST } from '../../common/key';

import {
  AddGroupTextField,
  CreatePaper,
  NoneBorderChip,
  TermPaper,
} from '../styles/Styles';
import SwLoading from './spinner/SwLoading';

interface Props {
  location: any[];
  setLocation: Dispatch<any[]>;
  showButton?: boolean;
  showDepth1?: boolean;
  limitKu?: boolean;
  selectOne?: boolean;
  includeAmd?: boolean;
  disabled?: boolean;
  mapId?: string;
}

export const SwPushSelectLocation: React.FC<Props> = ({
  location,
  setLocation,
  showButton = true,
  showDepth1 = true,
  limitKu = false,
  selectOne = false,
  includeAmd = false,
  disabled = false,
  mapId = 'map',
}) => {
  const locationSearch = useRef<any>();
  const [locationLimit, setLocationLimit] = useState<any>([]);
  const [amdList, setAmdList] = useState<any>();
  const [siList, setSiList] = useState<any>([]);
  const [dongList, setDongList] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [doIndex, setDoIndex] = useState<any>(0);
  const [siIndex, setSiIndex] = useState<any>(0);
  const [dongIndex, setDongIndex] = useState<any>(0);
  const [showDongSelector, setShowDongSelector] = useState<any>(true);
  const [loading, setLoading] = useState<any>(true);

  const settingMenu = (newAmdList: any) => {
    if (location === undefined || location === null) return;

    const address = location[0].split('>');

    if (address.length > 1) {
      const tmpDoIndex = newAmdList.doNames
        .map((item: any, idx: number) => (item === address[0] ? idx : -1))
        .filter((item: any) => item !== -1);
      const newDoIndex = tmpDoIndex !== null ? tmpDoIndex[0] + 1 : 0;
      setDoIndex(newDoIndex);

      if (newDoIndex > 0) setSiList(newAmdList.doList[newDoIndex - 1].siNames);

      const tmpSiIndex =
        newDoIndex === 0
          ? null
          : newAmdList.doList[newDoIndex - 1].siNames
              .map((item: any, idx: number) => (item === address[1] ? idx : -1))
              .filter((item: any) => item !== -1);
      const newSiIndex = tmpSiIndex !== null ? tmpSiIndex[0] + 1 : 0;
      setSiIndex(newSiIndex);

      if (newSiIndex > 0)
        setDongList(
          newAmdList.doList[newDoIndex - 1].siList[newSiIndex - 1].dongNames
        );

      if (address.length > 2) {
        const tmpDongIndex =
          newSiIndex === 0
            ? null
            : newAmdList.doList[newDoIndex - 1].siList[newSiIndex - 1].dongNames
                .map((item: any, idx: number) =>
                  item === address[1] ? idx : -1
                )
                .filter((item: any) => item !== -1);
        const newDongIndex = tmpDongIndex !== null ? tmpDongIndex[0] + 1 : 0;
        setDongIndex(newDongIndex);
      }
    }
  };

  const {
    data,
    isLoading,
    refetch: refetchAmd,
  } = useQuery(
    KEY_AMD_LIST,
    () => {
      setLoading(true);
      return getAmdList();
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          const newAmd = res.amd;
          setAmdList(newAmd);
          if (selectOne && showButton === false && location.length > 0) {
            settingMenu(newAmd);
          }
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const selectSiInfo = (selected: string) => {
    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      setSiList(amdList.doList[idx].siNames);
    }
    setSiIndex(0);
    setDongIndex(0);
    setShowDongSelector(true);
  };

  const selectDongInfo = (selected: string) => {
    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      const dongName = amdList.doList[doIndex - 1].siList[idx].dongNames;
      setDongList(dongName);
    }
    setDongIndex(0);
  };

  const deleteSomething = (index: number, from: string) => {
    const newSomething = locationLimit.filter(
      (_val: any, i: number) => i !== index
    );
    setLocationLimit([...newSomething]);
  };

  const addLocationLimit = () => {
    const doName = doIndex > 0 ? amdList.doNames[doIndex - 1] : '';
    const siName =
      siIndex > 0 ? amdList.doList[doIndex - 1].siNames[siIndex - 1] : '';
    const siAmdId =
      siIndex > 0 ? amdList.doList[doIndex - 1].siList[siIndex - 1].amdId : '';
    const dongName =
      siIndex > 0 && dongIndex > 1
        ? amdList.doList[doIndex - 1].siList[siIndex - 1].dongNames[
            dongIndex - 2
          ]
        : dongIndex === 1
        ? '전체'
        : '';

    const newLocaiton = `${doName}>${siIndex === 0 ? '' : siName}>${
      dongIndex === 0 ? '' : `${dongName}`
    }`.trimEnd();

    if (selectOne && includeAmd) {
      setLocationLimit([newLocaiton]);
      setLocation([{ location: newLocaiton, amd: siAmdId }]);
    } else if (selectOne) {
      setLocationLimit([newLocaiton]);
      setLocation([newLocaiton]);
    } else {
      const newData = locationLimit.filter((d: string) => d === newLocaiton);
      if (newData.length === 0) {
        const newLimit = [...locationLimit, newLocaiton];
        setLocationLimit(newLimit);
        setLocation(newLimit);
      }
    }
  };

  useEffect(() => {
    if (doIndex > 0) addLocationLimit();
    else setLocation([]);
  }, [doIndex, siIndex, dongIndex]);

  useEffect(() => {
    setLocationLimit(location);
    if (location.length === 0) {
      setDoIndex(0);
      setSiIndex(0);
      setDongIndex(0);
    }
  }, [location]);

  return (
    <>
      <div
        id={mapId}
        style={{ width: '100vw', height: '50vh', display: 'none' }}
      />
      <Box>
        <Box className='flex_center'>
          <TextField
            sx={{ width: '11rem', mr: '1rem', mb: 0 }}
            select
            disabled={disabled}
            defaultValue={0}
            value={doIndex}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setDoIndex(event.target.value);
              selectSiInfo(event.target.value);
            }}
          >
            <MenuItem value={0}>전체(전국)</MenuItem>
            {amdList &&
              amdList.doNames.map((option: string, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          {doIndex !== 0 && (
            <TextField
              sx={{ width: '11rem', mr: '1rem', mb: 0 }}
              select
              disabled={disabled}
              defaultValue={0}
              value={siIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setSiIndex(event.target.value);
                selectDongInfo(event.target.value);
              }}
            >
              <MenuItem value={0}>시,군,구</MenuItem>
              {siList.map((option: string, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          {doIndex !== 0 && siIndex !== 0 && dongList && (
            <TextField
              sx={{ width: '11rem', mr: '1rem', mb: 0 }}
              select
              disabled={disabled}
              defaultValue={0}
              value={dongIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setDongIndex(event.target.value);
              }}
            >
              <MenuItem value={0}>동,읍,면</MenuItem>
              <MenuItem value={1}>전체</MenuItem>
              {dongList.map((option: string, idx: number) => (
                <MenuItem key={idx + 2} value={idx + 2}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          {showButton && (
            <Button
              variant='contained'
              disabled={disabled}
              onClick={addLocationLimit}
            >
              추가
            </Button>
          )}
        </Box>
        {((doIndex > 0 && siIndex === 0) ||
          (siIndex > 1 && dongIndex === 0)) && (
          <Box>
            <Typography sx={{ m: '.5rem 0 ' }}>
              <span style={{ color: '#F63454' }}>
                {siIndex === 0
                  ? '시,군,구를 선택해주세요'
                  : '읍,면,동을 선택해주세요'}
              </span>
            </Typography>
          </Box>
        )}
      </Box>
      {loading && <SwLoading />}
    </>
  );
};
