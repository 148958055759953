import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Login } from './components/login/Index';
import { SessionProvider, useSession } from './hooks/session';
import Home from './components/home/Index';
import { Test } from './components/testfolder/Test';
import { Test2 } from './components/testfolder/Test2';
import { Users } from './components/userManagement/users/Index';
import { UserInfo } from './components/userManagement/users/UserInfo';
import { UserCert } from './components/userManagement/users/UserCert';
import { UserInquire } from './components/userManagement/users/UserInquire';
import { Push } from './components/userManagement/push/Index';
import { WalkPiece } from './components/userManagement/users/WalkPiece';
import { UserProgram } from './components/userManagement/users/UserProgram';
import { UserAttendCommunity } from './components/userManagement/users/UserAttendCommunity';
import { UserDayWalk } from './components/userManagement/users/UserDayWalk';
import { UserChallenge } from './components/userManagement/users/UserChallenge';
import { UserWearable } from './components/userManagement/users/UserWearable';
import { Dashboard } from './components/dashboard/Index';
import withAuthentication from './components/commonComponent/others/_withAuthentication';
import { EmpAdminister } from './components/empAdminister/Index';
import { AccountManagement } from './components/empAdminister/AccountManagement';
import { LoginHistory } from './components/empAdminister/LoginHistory';
import { Test4 } from './components/testfolder/Test4';
import { Timeline } from './components/userManagement/timeline/Index';
import { TimelineRegist } from './components/userManagement/timeline/TimelineRegist';
import { TimelineInquire } from './components/userManagement/timeline/TimelineInquire';
import { PushRegist } from './components/userManagement/push/PushRegist';
import { PushInquire } from './components/userManagement/push/PushInquire';
import { AdminPush } from './components/userManagement/push/AdminPush';
import { AdminTimeline } from './components/userManagement/timeline/AdminTimeline';
import { CommunityManage } from './components/appTab/community/Index';
import { CommunityCreate } from './components/appTab/community/CommunityCreate';
import { CommunityList } from './components/appTab/community/CommunityList';
import { ManageGuide } from './components/appTab/community/ManageGuide';
import { CommunityInfomation } from './components/appTab/community/CommunityInfomation';
import { CommunityGroup } from './components/appTab/community/CommunityGroup';
import { CommunityMembers } from './components/appTab/community/CommunityMembers';
import { CommunitySetting } from './components/appTab/community/CommunitySetting';
import { Test11 } from './components/testfolder/Test11';
import { CommunityDetail } from './components/appTab/community/CommunityDetail';
import { DailyStatistics } from './components/appTab/community/DailyStatistics';
import { Notice } from './components/appTab/notice/Index';
import { AccessRestrictionManage } from './components/appTab/accessrestriction/Index';
import { InAppMessage } from './components/appTab/inAppMessage/Index';
import { Walkway } from './components/appTab/walkway/Index';
import { ChallengeManagement } from './components/challenge/challengeManagement/Index';
import { ChallengeCreate } from './components/challenge/challengeManagement/ChallengeCreate';
import { ChallengeList } from './components/challenge/challengeManagement/ChallengeList';
import { PopularSearchTerm } from './components/challenge/challengeManagement/PopularSearchTerm';
import { ChallengeBanner } from './components/challenge/challengeManagement/ChallengeBanner';
import { ChallengeLabeling } from './components/challenge/challengeManagement/ChallengeLabeling';
import { Test44 } from './components/testfolder/Test44';
import { Test123 } from './components/testfolder/Test123';
import { Test1234 } from './components/testfolder/Test1234';
import { PortalUserManagement } from './components/dataportal/portalUserManagement/Index';
import { FAQ } from './components/dataportal/FAQ/Index';
import { PrivateInquiry } from './components/dataportal/privateInquiry/Index';
import { TeamNotice } from './components/dataportal/teamnotice/Index';
import { PortalConnectPopup } from './components/dataportal/portalconnectpopup/Index';
import { PortalGuideManage } from './components/dataportal/portalGuideManage/Index';
import { PartnerManagement } from './components/contractManagement/partnerMangement/Index';
import { Walkcourse } from './components/challenge/walkcourse/Index';
import { WalkcourseCreate } from './components/challenge/walkcourse/WalkcourseCreate';
import { WalkcourseInquire } from './components/challenge/walkcourse/WalkcourseInquire';
import { Stamptour } from './components/challenge/stampTour/Index';
import { StampTourCreate } from './components/challenge/stampTour/StampTourCreate';
import { ChallengeField } from './components/challenge/challengeField/Index';
import { ChallengeFieldCreate } from './components/challenge/challengeField/ChallengeFieldCreate';
import { ChallengeFieldInquire } from './components/challenge/challengeField/ChallengeFieldInquire';
import { RewardDrawing } from './components/challenge/rewardDrawing/Index';
import { RegionalFilter } from './components/challenge/regionalFilter/Index';
import { RegionalFilterCreate } from './components/challenge/regionalFilter/RegionalFilterCreate';
import { RegionalFilterInquire } from './components/challenge/regionalFilter/RegionalFilterInquire';
import { Apis } from './components/apicheck/Index';
import { DataportalApi } from './components/apicheck/DataportalApi';
import { AdminApi } from './components/apicheck/AdminApi';
import { QuestionAnswer } from './components/appTab/questionAnswer/questionAnswerManagement/Index';
import { QuestionAnswerCreate } from './components/appTab/questionAnswer/questionAnswerManagement/QuestionAnswerCreate';
import { QuestionAnswerList } from './components/appTab/questionAnswer/questionAnswerManagement/QuestionAnswerList';
import { DataportalPush } from './components/userManagement/push/DataportalPush';
import { DataportalTimeline } from './components/userManagement/timeline/DataportalTimeline';
import { ProgramManage } from './components/appTab/programManage/Index';
import { ProgramCreate } from './components/appTab/programManage/ProgramCreate';
import { ProgramList } from './components/appTab/programManage/ProgramList';
import { ProgramManageGuide } from './components/appTab/programManage/ProgramManageGuide';
import { SupplyPlan } from './components/contractManagement/servicePlan/Index';
import { GivePlan } from './components/contractManagement/servicePlan/GivePlan';
import { PlanInquire } from './components/contractManagement/servicePlan/PlanInquire';
import { StampTourInquire } from './components/challenge/stampTour/StampTourInquire';
import { AdvertiserManagement } from './components/contractManagement/advertiser/Index';
import { OperatingMetrics } from './components/operatingMetrics/Index';
import { UserCommunityChallengeMetrics } from './components/operatingMetrics/UserCommunityChallengeMetrics';
import { UserReject } from './components/userManagement/users/UserReject';
import { ProgramTemplateCode } from './components/appTab/programManage/ProgramTemplateCode';
import { ContentManage } from './components/appTab/contentManage/Index';
import { ContentManageMovie } from './components/appTab/contentManage/ContentManageMovie';
import { ContentManageMagazine } from './components/appTab/contentManage/ContentManageMagazine';
import { ContentManageQna } from './components/appTab/contentManage/ContentManageQna';
import { ContentManageLifeStyle } from './components/appTab/contentManage/ContentManageLifeStyle';
import { QuestionAnswerRewardDrawing } from './components/appTab/questionAnswer/rewardDrawing/Index';
import { UserPoint } from './components/userManagement/users/UserPoint';
import { LocalstoreManagement } from './components/localstore/localstoreManagement/Index';
import { LocalstoreCreate } from './components/localstore/localstoreManagement/LocalstoreCreate';
import { LocalstoreList } from './components/localstore/localstoreManagement/LocalstoreList';
import { LocalstoreOwnerManagement } from './components/localstore/localstoreOwnerManagement/Index';
import { LocalStoreNotice } from './components/localstore/localstoreNotice/Index';
import { ContentManageAudio } from './components/appTab/contentManage/ContentManageAudio';
import { PortalGuideList } from './components/dataportal/portalGuideManage/PortalGuideList';
import { PortalGuideCategoryManage } from './components/dataportal/portalGuideManage/PortalGuideCategoryManage';
import { PaymentManagement } from './components/contractManagement/paymentManagement/Index';
import { ReviewManagement } from './components/appTab/reviewManagement/Index';
import { ErrorReport } from './components/appTab/errorReport/Index';

declare global {
  interface Window {
    kakao: any;
    naver: any;
  }
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginVal, session } = useSession();
  const [param, setParam] = useState<string>('');

  useEffect(() => {
    const newParam = location.pathname.split('/')[1];
    setParam(newParam);
    return () => {
      setParam('');
    };
  }, [location.pathname]);

  return (
    <>
      {loginVal.value?.didLogin ? <Home /> : ''}
      {/* {loginVal.value?.didLogin ? <Home /> : ''} */}
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Dashboard />} />
        <Route path='/users' element={<Users />}>
          <Route path='userinquire' element={<UserInquire />} />
          <Route path='usercert' element={<UserCert />} />
          <Route path='userreject' element={<UserReject />} />
          <Route path='userinfo' element={<UserInfo />}>
            <Route path='walkpiece' element={<WalkPiece />} />
            <Route path='community' element={<UserAttendCommunity />} />
            <Route path='dailywalk' element={<UserDayWalk />} />
            <Route path='challenge' element={<UserChallenge />} />
            <Route path='wearable' element={<UserWearable />} />
            <Route path='program' element={<UserProgram />} />
            <Route path='point' element={<UserPoint />} />
          </Route>
        </Route>
        <Route path='/push' element={<Push />}>
          <Route path='regist' element={<PushRegist />} />
          <Route path='inquire' element={<PushInquire />}>
            <Route path='adminpush' element={<AdminPush />} />
            <Route path='dataportalpush' element={<DataportalPush />} />
          </Route>
        </Route>
        <Route path='/timeline' element={<Timeline />}>
          <Route path='regist' element={<TimelineRegist />} />
          <Route path='inquire' element={<TimelineInquire />}>
            <Route path='admintimeline' element={<AdminTimeline />} />
            <Route path='dataportaltimeline' element={<DataportalTimeline />} />
          </Route>
        </Route>
        <Route path='/questionAnswer' element={<QuestionAnswer />}>
          <Route path='create' element={<QuestionAnswerCreate />} />
          <Route path='list' element={<QuestionAnswerList />} />
          <Route path='drawing' element={<QuestionAnswerRewardDrawing />} />
        </Route>
        <Route path='/communitymanage' element={<CommunityManage />}>
          <Route path='create' element={<CommunityCreate />} />
          <Route path='list' element={<CommunityList />} />
          <Route path='listdetail' element={<CommunityDetail />}>
            <Route path='basicinfomation' element={<CommunityInfomation />} />
            <Route path='group' element={<CommunityGroup />} />
            <Route path='members' element={<CommunityMembers />} />
            <Route path='setting' element={<CommunitySetting />} />
          </Route>
          <Route path='dailystatistics' element={<DailyStatistics />} />
          <Route path='manageguide' element={<ManageGuide />} />
        </Route>
        <Route path='/programmanage' element={<ProgramManage />}>
          <Route path='create' element={<ProgramCreate />} />
          <Route path='list' element={<ProgramList />} />
          <Route path='templatecode' element={<ProgramTemplateCode />} />
          <Route path='manageguide' element={<ProgramManageGuide />} />
        </Route>
        <Route path='/contentmanage' element={<ContentManage />}>
          <Route path='contentmanage_movie' element={<ContentManageMovie />} />
          <Route
            path='contentmanage_magazine'
            element={<ContentManageMagazine />}
          />
          <Route path='contentmanage_audio' element={<ContentManageAudio />} />
          <Route path='contentmanage_qna' element={<ContentManageQna />} />
          <Route
            path='contentmanage_lifestyle'
            element={<ContentManageLifeStyle />}
          />
        </Route>
        <Route path='/empadminister' element={<EmpAdminister />}>
          <Route path='accountmanagement' element={<AccountManagement />} />
          <Route path='loginhistory' element={<LoginHistory />} />
        </Route>
        <Route path='/walkonnotice' element={<Notice />} />
        <Route
          path='/accessrestrictionmanage'
          element={<AccessRestrictionManage />}
        />
        <Route path='/inappmessage' element={<InAppMessage />} />
        <Route path='/walkway' element={<Walkway />} />
        <Route path='/reviewmanagement' element={<ReviewManagement />} />
        <Route path='/errorreport' element={<ErrorReport />} />
        <Route path='/challengemanagement' element={<ChallengeManagement />}>
          <Route path='create' element={<ChallengeCreate />} />
          <Route path='list' element={<ChallengeList />} />
          <Route path='popular' element={<PopularSearchTerm />} />
          <Route path='banner' element={<ChallengeBanner />} />
          <Route path='labeling' element={<ChallengeLabeling />} />
        </Route>
        <Route
          path='/portalusermanagement'
          element={<PortalUserManagement />}
        />
        <Route path='/walkcourse' element={<Walkcourse />}>
          <Route path='create' element={<WalkcourseCreate />} />
          <Route path='inquire' element={<WalkcourseInquire />} />
        </Route>
        <Route path='/stamptour' element={<Stamptour />}>
          <Route path='create' element={<StampTourCreate />} />
          <Route path='inquire' element={<StampTourInquire />} />
        </Route>
        <Route path='/challengeField' element={<ChallengeField />}>
          <Route path='create' element={<ChallengeFieldCreate />} />
          <Route path='inquire' element={<ChallengeFieldInquire />} />
        </Route>
        <Route path='/rewarddrawing' element={<RewardDrawing />} />
        <Route path='/FAQ' element={<FAQ />} />
        <Route path='/privateInquiry' element={<PrivateInquiry />} />
        <Route path='/teamNotice' element={<TeamNotice />} />
        <Route path='/portalconnectpopup' element={<PortalConnectPopup />} />
        <Route path='/portalguidemanage' element={<PortalGuideManage />}>
          <Route path='guidelist' element={<PortalGuideList />} />
          <Route
            path='categorymanage'
            element={<PortalGuideCategoryManage />}
          />
        </Route>
        <Route path='/localstore' element={<LocalstoreManagement />}>
          <Route path='create' element={<LocalstoreCreate />} />
          <Route path='list' element={<LocalstoreList />} />
        </Route>
        <Route
          path='/localstoreowner'
          element={<LocalstoreOwnerManagement />}
        />
        <Route path='/localstorenotice' element={<LocalStoreNotice />} />
        <Route path='/partnermanagement' element={<PartnerManagement />} />
        <Route path='/regionalfilter' element={<RegionalFilter />}>
          <Route path='create' element={<RegionalFilterCreate />} />
          <Route path='inquire' element={<RegionalFilterInquire />} />
        </Route>
        <Route path='/apis' element={<Apis />}>
          <Route path='dataportal' element={<DataportalApi />} />
          <Route path='admin' element={<AdminApi />} />
        </Route>
        <Route path='/supplyplan' element={<SupplyPlan />}>
          <Route path='giveplan' element={<GivePlan />} />
          <Route path='inquire' element={<PlanInquire />} />
        </Route>
        <Route path='/advertiser' element={<AdvertiserManagement />} />
        <Route path='/paymentmanagement' element={<PaymentManagement />} />
        <Route path='/operatingMetrics' element={<OperatingMetrics />}>
          <Route
            path='usercommunitychallenge'
            element={<UserCommunityChallengeMetrics />}
          />
        </Route>
        <Route path='/test' element={<Test />} />
        <Route path='/test2' element={<Test2 />} />
        <Route path='/test4' element={<Test4 />} />
        <Route path='/test11' element={<Test11 />} />
        <Route path='/test44' element={<Test44 />} />
        <Route path='/test123' element={<Test123 />} />
        <Route path='/test1234' element={<Test1234 />} />
      </Routes>
    </>
  );
}

// export default App;
export default withAuthentication(App);
