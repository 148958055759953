import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * App Review
 */

export const getAppReviewList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/app-review?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getAppReviewTargetList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/app-review/target?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postAppReview = (data: any) =>
  xapi.post(`/admin/v2/apis/app-review`, data);

export const changeAppReview = (data: any) =>
  xapi.put(`/admin/v2/apis/app-review/update/${data.allowReview}`, data);
