import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../../hooks/session';
import { debouncehook } from '../../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../../styles/Styles';
import { SwUuidCreate } from '../../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import { SwPagination } from '../../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../../common/helper';
import { ButtonBox, ContentTitle, MarginButton } from './QuestionAnswerStyles';
import { IUploadFile } from '../../../../models/common';
import { postUploadFile } from '../../../../common/api/ApiCommon';
import { registerQuestionAnswerMaster } from '../../../../common/api/ApiQuestionAnswer';
import { RESULT_OK } from '../../../../common/resultCode';

import { uploadFile } from '../../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../../commonComponent/SwSelectTags';
import { getFileSize } from '../../../../common/helperQuestionAnswer';
import SwLoading from '../../../commonComponent/spinner/SwLoading';
import { SwDateRangePickerWithoutDay } from '../../../commonComponent/dateSetting/SwDateRangePickerWithoutDay';
import { DataportalConnect } from '../../../commonComponent/DataportalConnect';
import { SwDatesPicker } from '../../../commonComponent/dateSetting/SwDatesPicker';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_UPLOAD_FILE_SIZE,
} from '../../../commonComponent/program/SwProgramCommon';
import { SwSelectLocation } from '../../../commonComponent/SwSelectLocation';
import { SwManageReceivers } from '../../../commonComponent/SwManageReceivers';
import { SwDatesPickerWithScroll } from '../../../commonComponent/dateSetting/SwDatesPickerWithScroll';

interface Props {
  questionAnswerInfo: any;
  setQuestionAnswerInfo: Dispatch<React.SetStateAction<any>>;
  reloadQuestionAnswer: (id: string) => void;
}

const guidetool = {
  title: '소제목',
  contents: '안내내용',
  image: '',
  imageFile: null,
  uploadUrl: '',
};

const SwReceivers = [
  { title: '선택', value: 'empty' },
  { title: '전체', value: 'all' },
  { title: '공식 커뮤니티', value: 'community' },
  { title: '챌린지', value: 'challenge' },
  { title: '거주 및 활동지역', value: 'region' },
  { title: '개인', value: 'user' },
  { title: '프로그램', value: 'program' },
];

const MAX_GUIDE_PAGE = 6;

export const QuestionAnswerForm: React.FC<Props> = ({
  questionAnswerInfo,
  setQuestionAnswerInfo,
  reloadQuestionAnswer,
}) => {
  const location = useLocation();
  const usingTicket = useRef<any>();
  const questionAnswerTitleRef = useRef<any>();
  const masterTypeRef = useRef<any>();
  const searchMasterTypeRef = useRef<any>();
  const companyNameRef = useRef<any>();
  const addTerm1Ref = useRef<any>();
  const addTerm2Ref = useRef<any>();
  const findReceiverRef = useRef<any>();
  const { loginVal } = useSession();

  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>();
  const [timelineImage, setTimelineImage] = useState<string>('');
  const [timelineImageFile, setTimelineImageFile] = useState<any>(null);
  const [timelineMessage, setTimelineMessage] = useState<string>('');
  const [timelineDates, setTimelineDates] = useState<any[]>([]);
  const [receiverType, setReceiverType] = useState<string>('empty');
  const [targetTitle, setTargetTitle] = useState<string[]>([]);
  const [receiverId, setReceiverId] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [regionLimit, setRegionLimit] = useState<any[]>([]);
  const [allReceivers, setAllReceivers] = useState<boolean>(false);
  const [communityReceivers, setCommunityReceivers] = useState<any[]>([]);
  const [challengeReceivers, setChallengeReceivers] = useState<any[]>([]);
  const [regionReceivers, setRegionReceivers] = useState<any[]>([]);
  const [userReceivers, setUserReceivers] = useState<any[]>([]);
  const [programReceivers, setProgramReceivers] = useState<any[]>([]);
  const [qnaType, setQnaType] = useState<number>(1);
  const [isRepeated, setIsRepeated] = useState<number>(1);

  const [selectedQuestionAnswer, setSelectedQuestionAnswer] =
    useState<boolean>(false);
  const [testType, setTestType] = useState<number>();
  const [masterType, setMasterType] = useState<any>([]);
  const [curriculumId, setCurriculumId] = useState<string>('');
  const [defaultCurriculumId, setDefaultCurriculumId] = useState<string>('');
  const [questionAnswerTitle, setQuestionAnswerTitle] = useState<string>('');
  const [questionAnswerGoalPoint, setQuestionAnswerGoalPoint] =
    useState<number>(0);
  const [questionAnswerTotalPoint, setQuestionAnswerTotalPoint] =
    useState<number>(0);
  const [questionAnswerUserLimit, setQuestionAnswerUserLimit] =
    useState<number>(0);
  const [questionAnswerDescription, setQuestionAnswerDescription] =
    useState<string>('');
  const [questionAnswerStepsMax, setQuestionAnswerStepsMax] =
    useState<number>(0);
  const [questionAnswerStepsMin, setQuestionAnswerStepsMin] =
    useState<number>(0);
  const [questionAnswerStepsPerPoint, setQuestionAnswerStepsPerPoint] =
    useState<number>(0);
  const [questionAnswerStatus, setQuestionAnswerStatus] = useState<number>(1);
  const [questionAnswerInfoDuration, setQuestionAnswerInfoDuration] =
    useState<string>('');
  const [questionAnswerStampTourId, setQuestionAnswerStampTourId] =
    useState<string>('');
  const [questionAnswerWalkCourseId, setQuestionAnswerWalkCourseId] =
    useState<string>('');
  const [questionAnswerWalkZoneId, setQuestionAnswerWalkZoneId] =
    useState<string>('');
  const [questionAnswerInfoReward, setQuestionAnswerInfoReward] =
    useState<string>('');
  const [questionAnswerInfoGoal, setQuestionAnswerInfoGoal] =
    useState<string>('');
  const [questionAnswerViewType, setQuestionAnswerViewType] =
    useState<any>('9');
  const [questionAnswerTestStime, setQuestionAnswerTestStime] =
    useState<number>(0);
  const [guideTitle, setGuideTitle] = useState<any>('');
  const [guideContent, setGuideContent] = useState<any>('');
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>(null);
  const [questionAnswerMainImage, setQuestionAnswerMainImage] =
    useState<string>('');
  const [questionAnswerMainImageFile, setQuestionAnswerMainImageFile] =
    useState<any>(null);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<any[]>([]);
  const [templateCode, setTemplateCode] = useState(1);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [qnaId, setQnaId] = useState<string>('');
  const [findDataPortal, setFindDataPortal] = useState<string>('');
  const [connectDataPortal, setConnectDataPortal] = useState<any>([]);
  const [dataPortal, setDataPortal] = useState<any>([]);
  const [customPopupText, setCustomPopupText] = useState<any>('');
  const [customPopupUseYn, setCustomPopupUseYn] = useState<any>(0);
  const [questionAnswerApplyStatus, setQuestionAnswerApplyStatus] =
    useState<any>(1);
  const [exposureRangeDate, setExposureRangeDate] = useState<any[]>([
    null,
    null,
  ]);
  const [participationRangeDate, setParticipationRangeDate] = useState<any[]>([
    null,
    null,
  ]);
  const [useQuestionAnswerPost, setUseQuestionAnswerPost] = useState<number>(0);
  const [extraTerms, setExtraTerms] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [withPush, setWithPush] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [disableType, setDisableType] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);
  const [openManageReceivers, setOpenManageReceivers] = useState<number>(0);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const resetReceivers = () => {
    setAllReceivers(false);
    setCommunityReceivers([]);
    setChallengeReceivers([]);
    setRegionReceivers([]);
    setUserReceivers([]);
    setProgramReceivers([]);
  };

  const resetPartialReceivers = (type: string) => {
    if (type === 'all') {
      setCommunityReceivers([]);
      setChallengeReceivers([]);
      setRegionReceivers([]);
      setUserReceivers([]);
      setProgramReceivers([]);
    } else if (type === 'community') {
      setAllReceivers(false);
      setChallengeReceivers([]);
      setRegionReceivers([]);
      setUserReceivers([]);
      setProgramReceivers([]);
    } else if (type === 'challenge') {
      setAllReceivers(false);
      setCommunityReceivers([]);
      setRegionReceivers([]);
      setUserReceivers([]);
      setProgramReceivers([]);
    } else if (type === 'user') {
      setAllReceivers(false);
      setCommunityReceivers([]);
      setChallengeReceivers([]);
      setRegionReceivers([]);
      setProgramReceivers([]);
    } else if (type === 'program') {
      setAllReceivers(false);
      setCommunityReceivers([]);
      setChallengeReceivers([]);
      setRegionReceivers([]);
      setUserReceivers([]);
    } else if (type === 'region') {
      setAllReceivers(false);
      setCommunityReceivers([]);
      setChallengeReceivers([]);
      setUserReceivers([]);
      setProgramReceivers([]);
    }
  };

  const {
    mutate: registerQuestionAnswerMasterMutation,
    isError: isMasterMutateError,
  } = useMutation(registerQuestionAnswerMaster, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        if (selectedQuestionAnswer) {
          setOpenSnackbar(true);
          setAlertMessage('질의응답 마스터 정보가 저장되었습니다.');
          resetReceivers();
          reloadQuestionAnswer(qnaId);
        } else {
          setAlertOpen(true);
          setAlertMessage('질의응답 생성 탭에서 작성중 내역을 확인해주세요.');
        }
      } else {
        showError(
          `질의응답 마스터 정보를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
      setLoading(false);
    },
    onError: error => {
      setLoading(false);
      showError(
        `질의응답 마스터 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const saveFileImage = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.files &&
      setQuestionAnswerMainImage(URL.createObjectURL(evt.target.files[0]));
  };

  const deleteFileImage = () => {
    URL.revokeObjectURL(questionAnswerMainImage);
    setQuestionAnswerMainImage('');
  };

  const addQuestionAnswerMaster = (
    newTimelineUploadUrl: string,
    newUploadUrl: string
  ) => {
    const newAttendStime =
      participationRangeDate[0] === null
        ? 0
        : new Date(participationRangeDate[0]).getTime() / 1000;
    const newAttendEtime =
      participationRangeDate[1] === null
        ? 0
        : new Date(participationRangeDate[1]).getTime() / 1000;
    const newMainImage =
      questionAnswerMainImageFile !== null
        ? newUploadUrl
        : questionAnswerMainImage.length > 0
        ? questionAnswerMainImage
        : '';
    const newTimelineImage =
      timelineImageFile !== null
        ? newTimelineUploadUrl
        : timelineImage.length > 0
        ? timelineImage
        : '';

    const newPortalId =
      connectDataportal !== undefined &&
      connectDataportal !== null &&
      connectDataportal.portalId !== undefined &&
      connectDataportal.portalId !== null
        ? connectDataportal?.portalId
        : 'ADMIN';

    const newQuestionAnswerApplyStatus =
      questionAnswerInfo !== undefined &&
      questionAnswerInfo !== null &&
      questionAnswerInfo.qnaId === qnaId
        ? questionAnswerApplyStatus
        : 1;

    const newDates = timelineDates.map(
      timelineDate => timelineDate.getTime() / 1000
    );

    // 받는사람 타입(1-전체 2-공식커뮤니티, 3-챌린지, 4-거주및활동지역, 5-개인, 6-프로그램)
    let newReceivers: any = [];
    if (allReceivers) {
      newReceivers = [...newReceivers, { type: 1, all: 1 }];
    }
    const newCommunity = communityReceivers.map(item => {
      const newData: any = {
        type: 2,
        all: item.all !== undefined && item.all !== null ? item.all : 1,
        id: item.id,
        name: item.name,
      };
      return newData;
    });
    newReceivers = [...newReceivers, ...newCommunity];

    const newChallenge = challengeReceivers.map(item => {
      const newData: any = {
        type: 3,
        all: item.all !== undefined && item.all !== null ? item.all : 1,
        id: item.id,
        name: item.name,
      };
      return newData;
    });
    newReceivers = [...newReceivers, ...newChallenge];

    const newUser = userReceivers.map(item => {
      const newData: any = {
        type: 5,
        all: item.all !== undefined && item.all !== null ? item.all : 1,
        id: item.id,
        name: item.name,
      };
      return newData;
    });
    newReceivers = [...newReceivers, ...newUser];

    const newProgram = programReceivers.map(item => {
      const newData: any = {
        type: 6,
        all: item.all !== undefined && item.all !== null ? item.all : 1,
        id: item.id,
        name: item.name,
      };
      return newData;
    });
    newReceivers = [...newReceivers, ...newProgram];

    const newRegion = regionReceivers.map(item => {
      const newData: any = {
        type: 4,
        all: item.all !== undefined && item.all !== null ? item.all : 1,
        location: item.location,
        amd: item.amd,
      };
      return newData;
    });
    newReceivers = [...newReceivers, ...newRegion];

    if (newMainImage === undefined || newMainImage.length === 0) {
      setAlertMessage('질의응답 이미지를 등록해 주세요.');
      setOpenSnackbar(true);
      return;
    }

    const newData: any = {
      qnaId: qnaId,
      type: qnaType,
      isRepeated: isRepeated,
      receivers: newReceivers,
      title: questionAnswerTitle,
      description: questionAnswerDescription,
      image: newMainImage,
      attendStartDate: newAttendStime,
      attendEndDate: newAttendEtime,
      registerStatus: newQuestionAnswerApplyStatus,
      status: questionAnswerStatus,
      portalId: newPortalId,
      portalIds: [newPortalId],
      timelineMessage: timelineMessage,
      timelineImage: newTimelineImage,
      timelineDates: newDates,
      withPush: withPush ? 1 : 0,
      goalPoint: questionAnswerGoalPoint,
    };

    console.log('registerQuestionAnswerMaster :', newData);
    setLoading(true);
    registerQuestionAnswerMasterMutation(newData);
  };

  const checkValid = () => {
    var val = 0;

    const receiversCount =
      (allReceivers === false ? 0 : 1) +
      communityReceivers.length +
      challengeReceivers.length +
      regionReceivers.length +
      userReceivers.length +
      programReceivers.length;
    const newDates = timelineDates.filter(
      timelineDate =>
        timelineDate.getTime() < participationRangeDate[0] ||
        timelineDate.getTime() > participationRangeDate[1]
    );

    if (val === 0 && qnaId.length === 0) val = 1;
    if (val === 0 && questionAnswerTitle.length === 0) val = 10;
    if (
      val === 0 &&
      (questionAnswerMainImage === null || questionAnswerMainImage.length === 0)
    )
      val = 11;
    if (val === 0 && receiversCount === 0) val = 12;
    if (val === 0 && timelineMessage.length === 0) val = 13;
    if (val === 0 && participationRangeDate[0] === null) val = 14;
    if (val === 0 && questionAnswerDescription.length === 0) val = 15;
    if (val === 0 && timelineDates.length === 0) val = 16;
    if (val === 0 && newDates !== null && newDates.length > 0) val = 17;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '질의응답 아이디를 입력해주세요.';
          break;
        case 2:
          message = '질의응답 약관에서 기관명을 입력해주세요';
          break;
        case 3:
          message = '질의응답 종류를 선택해주세요';
          break;
        case 10:
          message = '질의응답 제목을 입력해주세요.';
          break;
        case 11:
          message = '질의응답 이미지를 등록해 주세요.';
          break;
        case 12:
          message = '홈 화면 메시지 받는 사람을 설정해 주세요.';
          break;
        case 13:
          message = '홈 화면 메시지 내용을 입력해 주세요.';
          break;
        case 14:
          message = '질의응답 진행 기간을 설정해 주세요.';
          break;
        case 15:
          message = '질의응답 설명을 입력해 주세요.';
          break;
        case 16:
          message = '홈 화면 메시지 적용일시를 추가해 주세요.';
          break;
        case 17:
          message = '홈 화면 메시지 적용일시를 진행 기간 내로 설정해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setOpenSnackbar(true);
    }

    return val;
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (questionAnswerMainImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'questionAnswer',
        questionAnswerMainImageFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newTimelineUploadUrl = '';
    if (timelineImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'timeline',
        timelineImageFile
      );
      newTimelineUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    addQuestionAnswerMaster(newTimelineUploadUrl, newUploadUrl);
  }

  const ChangePageContents = () => {
    setGuideTitle(
      guideLists[guidePage - 1] ? guideLists[guidePage - 1].title : '소제목'
    );
    setGuideContent(
      guideLists[guidePage - 1]
        ? guideLists[guidePage - 1].contents
        : '안내내용'
    );

    const newImage = guideLists[guidePage - 1]
      ? guideLists[guidePage - 1].image
      : '';
    setGuideImage(newImage);
  };

  const removeQuestionAnswerGuideView = () => {
    console.log(guidePage);
    ChangePageContents();
    const newGuide = guideLists.filter((_guide, idx) => idx !== guidePage - 1);
    // const newGuidePage = guidePage - 1 < 1 ? guidePage + 1 : guidePage - 1;
    console.log('newguide>>>>>>>>>>>', newGuide);
    setGuideLists(newGuide);
    newGuide.length < guidePage && setGuidePage(guidePage - 1);
    // setGuidePage(guidePage);
  };

  const checkQuestionAnswer = () => {
    if (checkValid() !== 0) {
      return;
    }

    if (
      questionAnswerMainImageFile !== undefined &&
      questionAnswerMainImageFile !== null &&
      getFileSize(questionAnswerMainImageFile) >= MAX_UPLOAD_FILE_SIZE
    ) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return;
    }

    if (
      timelineImageFile !== undefined &&
      timelineImageFile !== null &&
      getFileSize(timelineImageFile) >= MAX_UPLOAD_FILE_SIZE
    ) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return;
    }

    uploadMultipleFiles();
  };

  const saveQuestionAnswerMaster = () => {
    console.log('saveQuestionAnswer !!!');
    checkQuestionAnswer();
  };

  const changeReceiverType = (value: any) => {
    if (findReceiverRef.current) findReceiverRef.current.value = '';
    if (value === 'all') {
      resetReceivers();
    }
    setReceiverType(value);
    if (value === 'all') {
      setAllReceivers(true);
    }
  };

  const deleteReceiver = (type: string, targetId: string) => {
    if (type === 'all') {
      setAllReceivers(false);
    } else if (type === 'community') {
      const newReceiver = communityReceivers.filter(
        item => item.id !== targetId
      );
      setCommunityReceivers(newReceiver);
    } else if (type === 'challenge') {
      const newReceiver = challengeReceivers.filter(
        item => item.id !== targetId
      );
      setChallengeReceivers(newReceiver);
    } else if (type === 'user') {
      const newReceiver = userReceivers.filter(item => item.id !== targetId);
      setUserReceivers(newReceiver);
    } else if (type === 'program') {
      const newReceiver = programReceivers.filter(item => item.id !== targetId);
      setProgramReceivers(newReceiver);
    } else if (type === 'region') {
      const newReceiver = regionReceivers.filter(item => item.amd !== targetId);
      setRegionReceivers(newReceiver);
    }
  };

  const handleSearch = (inputValue: string) => {
    const selected = SwReceivers.map(item => {
      if (item.value === receiverType) return item.title;
      return '';
    }).filter(value => value !== '');
    setTargetTitle(selected);
    setSearchKey(receiverType);
    setSearchWord(inputValue);
    setOpenType(0);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch(findReceiverRef.current?.value);
    }
  };

  const setupQuestionAnswerInfo = () => {
    if (questionAnswerInfo) {
      console.log('questionAnswerInfo -> :', questionAnswerInfo);
      setQnaType(questionAnswerInfo.type);
      setIsRepeated(questionAnswerInfo.isRepeated);
      setQuestionAnswerGoalPoint(questionAnswerInfo.goalPoint);
      setSelectedQuestionAnswer(true);
      setQnaId(questionAnswerInfo.qnaId);
      setQuestionAnswerTitle(questionAnswerInfo.title);
      setQuestionAnswerDescription(questionAnswerInfo.description);
      setQuestionAnswerStatus(questionAnswerInfo.status);
      setQuestionAnswerApplyStatus(questionAnswerInfo.registerStatus);

      setQuestionAnswerMainImage(
        questionAnswerInfo.image !== null ? questionAnswerInfo.image : ''
      );
      setQuestionAnswerMainImageFile(null);
      setTimelineImageFile(null);
      const newParticipationDate = [
        timestamp2Localestring(questionAnswerInfo.attendStartDate, 1000),
        timestamp2Localestring(questionAnswerInfo.attendEndDate, 1000),
      ];
      setParticipationRangeDate(newParticipationDate);
      setTimelineMessage(questionAnswerInfo.timelineMessage);
      setTimelineImage(
        questionAnswerInfo.timelineImage !== null
          ? questionAnswerInfo.timelineImage
          : ''
      );

      const parsedDates =
        questionAnswerInfo.timelineDates !== null
          ? JSON.parse(questionAnswerInfo.timelineDates)
          : [];

      const newDates =
        parsedDates !== null && parsedDates.length > 0
          ? parsedDates.map((newDate: any) => new Date(newDate * 1000))
          : [];
      setTimelineDates(newDates);

      const newCreatorList =
        questionAnswerInfo.creatorList !== undefined &&
        questionAnswerInfo.creatorList !== null
          ? JSON.parse(questionAnswerInfo.creatorList)
          : [];
      const newPortal = newCreatorList.map((item: any) => {
        const newData = {
          portalId: item,
          name: '',
        };
        return newData;
      });
      console.log('newCreatorList:', newCreatorList);
      setConnectDataportal({
        portalId: newCreatorList.length > 0 ? newCreatorList[0] : '',
        name: '',
      });

      const newReceivers =
        questionAnswerInfo.receivers !== undefined &&
        questionAnswerInfo.receivers !== null
          ? JSON.parse(questionAnswerInfo.receivers)
          : [];

      // 받는사람 타입(1-전체 2-공식커뮤니티, 3-챌린지, 4-거주및활동지역, 5-개인, 6-프로그램)
      let newCommunity: any[] = [];
      let newChallenge: any[] = [];
      let newUsers: any[] = [];
      let newRegion: any[] = [];
      let newProgram: any[] = [];
      if (newReceivers.length > 0) {
        newReceivers.map((item: any) => {
          if (item.target_type === 1) {
            item.target_all === 0
              ? setAllReceivers(false)
              : setAllReceivers(true);
          } else if (item.target_type === 2) {
            newCommunity = [
              ...newCommunity,
              {
                id: item.target_id,
                name: item.target_name,
                all: item.target_all,
              },
            ];
          } else if (item.target_type === 3) {
            newChallenge = [
              ...newChallenge,
              {
                id: item.target_id,
                name: item.target_name,
                all: item.target_all,
              },
            ];
          } else if (item.target_type === 5) {
            newUsers = [
              ...newUsers,
              {
                id: item.target_id,
                name: item.target_name,
                all: item.target_all,
              },
            ];
          } else if (item.target_type === 6) {
            newProgram = [
              ...newProgram,
              {
                id: item.target_id,
                name: item.target_name,
                all: item.target_all,
              },
            ];
          } else if (item.target_type === 4) {
            newRegion = [
              ...newRegion,
              {
                location: item.target_location,
                amd: item.target_amd,
                all: item.target_all,
              },
            ];
          }
        });
        setCommunityReceivers(newCommunity);
        setChallengeReceivers(newChallenge);
        setUserReceivers(newUsers);
        setRegionReceivers(newRegion);
        setProgramReceivers(newProgram);
        setWithPush(questionAnswerInfo.withPush === 1);
      } else {
        resetReceivers();
      }
    } else {
      location.pathname.includes('create')
        ? setSelectedQuestionAnswer(true)
        : setSelectedQuestionAnswer(false);
    }
  };

  useEffect(() => {
    console.log('searchResult :', searchResult);
    if (searchResult.length > 0) {
      if (receiverType === 'community') {
        resetPartialReceivers('community');
        const newSearchResult = [...searchResult];
        setCommunityReceivers(newSearchResult);
      } else if (receiverType === 'challenge') {
        resetPartialReceivers('challenge');
        const newSearchResult = [...searchResult];
        setChallengeReceivers(newSearchResult);
      } else if (receiverType === 'user') {
        resetPartialReceivers('user');
        const newSearchResult = [...userReceivers, ...searchResult];
        setUserReceivers(newSearchResult);
      } else if (receiverType === 'program') {
        resetPartialReceivers('program');
        const newSearchResult = [...searchResult];
        setProgramReceivers(newSearchResult);
      }
    }
  }, [searchResult]);

  useEffect(() => {
    console.log('regionLimit :', regionLimit);
    if (regionLimit.length > 0) setRegionReceivers([...regionLimit]);
  }, [regionLimit]);

  useEffect(() => {
    const newDate =
      participationRangeDate[0] !== null && participationRangeDate[1] !== null
        ? `${moment(new Date(participationRangeDate[0])).format(
            'YYYY/MM/DD'
          )} ~ ${moment(new Date(participationRangeDate[1])).format(
            'YYYY/MM/DD'
          )}`
        : '';
    setQuestionAnswerInfoDuration(newDate);
  }, [participationRangeDate]);

  useEffect(() => {
    resetReceivers();
    setupQuestionAnswerInfo();
  }, [questionAnswerInfo]);
  return (
    <>
      <CreatePaper sx={{ m: '0 0' }}>
        {/* <Box>
          <Typography className='title'>사용한 이용권</Typography>
          <TextField sx={{ width: '18rem' }} disabled defaultValue='' />
          <Divider />
        </Box> */}
        <Box>
          <SwUuidCreate
            title='질의응답'
            id={qnaId}
            setId={setQnaId}
            disabled={false}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>질의응답 제목</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={questionAnswerTitle}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setQuestionAnswerTitle(evt.target.value.substring(0, 30))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{questionAnswerTitle?.length} / 30</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>질의응답 설명</Typography>
          <MultilineTextField
            fullWidth
            multiline
            rows={6}
            value={questionAnswerDescription}
            onChange={evt =>
              setQuestionAnswerDescription(evt.target.value.substring(0, 500))
            }
            placeholder='질의응답 설명을 500자 이내로 작성해주세요.'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{questionAnswerDescription?.length} / 500</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>이미지</Typography>
          <SwFileSetting
            image={
              questionAnswerMainImageFile != null
                ? questionAnswerMainImage
                : questionAnswerMainImage !== ''
                ? `${imageUrlPrefix}${questionAnswerMainImage}`
                : ''
            }
            setImage={setQuestionAnswerMainImage}
            setFile={setQuestionAnswerMainImageFile}
            file={questionAnswerMainImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>진행기간</Typography>
          <SwDateRangePicker
            rangeDate={participationRangeDate}
            setRangeDate={setParticipationRangeDate}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>받는 사람</Typography>
          <Box sx={{ display: 'flex', mt: '.8rem', mb: '.5rem' }}>
            <TextField
              select
              sx={{ width: '12rem', mr: '1rem' }}
              defaultValue='empty'
              value={receiverType}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => changeReceiverType(event.target.value)}
            >
              {SwReceivers.map((receiver: any, idx: number) => (
                <MenuItem value={receiver.value} key={idx.toString()}>
                  {receiver.title}
                </MenuItem>
              ))}
            </TextField>
            <Box>
              {receiverType === 'challenge' ||
              receiverType === 'community' ||
              receiverType === 'program' ||
              receiverType === 'user' ? (
                <TextField
                  placeholder={`${
                    receiverType === 'challenge'
                      ? '챌린지 이름, 아이디'
                      : receiverType === 'community'
                      ? '커뮤니티 이름, 아이디'
                      : receiverType === 'program'
                      ? '프로그램 이름, 아이디'
                      : '닉네임, 연락처'
                  } 검색`}
                  inputRef={findReceiverRef}
                  onKeyDown={evt => {
                    handleKeyDown(evt);
                  }}
                  sx={{ width: '44rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            handleSearch(findReceiverRef.current?.value);
                          }}
                          sx={{ pr: 0 }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : receiverType === 'region' ? (
                <Box>
                  <SwSelectLocation
                    limitKu={true}
                    includeAmd={true}
                    selectOne={true}
                    showButton={false}
                    showDepth1={true}
                    location={regionLimit}
                    setLocation={setRegionLimit}
                    showContainer={false}
                    mapId='authRegion_map'
                  />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box>
            <Paper
              sx={{
                backgroundColor: '#FCFCFC',
                p: '2rem 2rem 1rem',
              }}
            >
              <Box sx={{ height: '15rem', overflowY: 'scroll' }}>
                {allReceivers === true && (
                  <Box className='flex_start'>
                    <Typography fontWeight={700}>전체</Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteReceiver('all', '')}
                    />
                  </Box>
                )}
                {communityReceivers.length > 0 && (
                  <Typography fontWeight={700} sx={{ mt: '.5rem' }}>
                    공식 커뮤니티
                  </Typography>
                )}
                {communityReceivers.length > 0 &&
                  communityReceivers.map((item: any, idx: number) => (
                    <Box key={idx.toString()} className='flex_start'>
                      <Typography>{`${item.name}, ${item.id}`}</Typography>
                      <ClearIcon
                        className='clear_icon_button'
                        onClick={() => deleteReceiver('community', item.id)}
                      />
                      {item.all === 0 && (
                        <Button
                          color='primary'
                          onClick={() => {
                            setReceiverId(item.id);
                            setOpenManageReceivers(2);
                          }}
                        >
                          (일부인원 확인)
                        </Button>
                      )}
                    </Box>
                  ))}
                {challengeReceivers.length > 0 && (
                  <Typography fontWeight={700} sx={{ mt: '.5rem' }}>
                    챌린지
                  </Typography>
                )}
                {challengeReceivers.length > 0 &&
                  challengeReceivers.map((item: any, idx: number) => (
                    <Box key={idx.toString()} className='flex_start'>
                      <Typography>{`${item.name}, ${item.id}`}</Typography>
                      <ClearIcon
                        className='clear_icon_button'
                        onClick={() => deleteReceiver('challenge', item.id)}
                      />
                      {item.all === 0 && (
                        <Button
                          color='primary'
                          onClick={() => {
                            setReceiverId(item.id);
                            setOpenManageReceivers(3);
                          }}
                        >
                          (일부인원 확인)
                        </Button>
                      )}
                    </Box>
                  ))}
                {regionReceivers.length > 0 && (
                  <Typography fontWeight={700} sx={{ mt: '.5rem' }}>
                    거주 및 활동지역
                  </Typography>
                )}
                {regionReceivers.length > 0 &&
                  regionReceivers.map((item: any, idx: number) => (
                    <Box key={idx.toString()} className='flex_start'>
                      <Typography>{`${item.location}`}</Typography>
                      <ClearIcon
                        className='clear_icon_button'
                        onClick={() => deleteReceiver('region', item.amd)}
                      />
                    </Box>
                  ))}
                {userReceivers.length > 0 && (
                  <Typography fontWeight={700} sx={{ mt: '.5rem' }}>
                    개인
                  </Typography>
                )}
                {userReceivers.length > 0 &&
                  userReceivers.map((item: any, idx: number) => (
                    <Box key={idx.toString()} className='flex_start'>
                      <Typography>{`${item.name}, ${item.id}`}</Typography>
                      <ClearIcon
                        className='clear_icon_button'
                        onClick={() => deleteReceiver('user', item.id)}
                      />
                    </Box>
                  ))}
                {programReceivers.length > 0 && (
                  <Typography fontWeight={700} sx={{ mt: '.5rem' }}>
                    프로그램
                  </Typography>
                )}
                {programReceivers.length > 0 &&
                  programReceivers.map((item: any, idx: number) => (
                    <Box key={idx.toString()} className='flex_start'>
                      <Typography>{`${item.name}, ${item.id}`}</Typography>
                      <ClearIcon
                        className='clear_icon_button'
                        onClick={() => deleteReceiver('program', item.id)}
                      />
                      {item.all === 0 && (
                        <Button
                          color='primary'
                          onClick={() => {
                            setReceiverId(item.id);
                            setOpenManageReceivers(6);
                          }}
                        >
                          (일부인원 확인)
                        </Button>
                      )}
                    </Box>
                  ))}
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Button
                  color='error'
                  onClick={() => {
                    resetReceivers();
                  }}
                >
                  <DeleteOutlineIcon />
                  전체 삭제
                </Button>
              </Box>
            </Paper>
          </Box>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>홈 화면 메시지 내용</Typography>
          <MultilineTextField
            fullWidth
            multiline
            rows={6}
            value={timelineMessage}
            onChange={evt =>
              setTimelineMessage(evt.target.value.substring(0, 500))
            }
            placeholder='홈 화면 메시지를 500자 이내로 작성해주세요.'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{timelineMessage.length} / 500</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>홈 화면 메시지 이미지</Typography>
          <SwFileSetting
            image={
              timelineImageFile != null
                ? timelineImage
                : timelineImage !== ''
                ? `${imageUrlPrefix}${timelineImage}`
                : ''
            }
            setImage={setTimelineImage}
            setFile={setTimelineImageFile}
            file={timelineImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>
            홈 화면 메시지 적용일시
            {timelineDates.length > 0 ? `(${timelineDates.length}/10)` : ''}
          </Typography>
          <SwDatesPickerWithScroll
            dates={timelineDates}
            setDates={setTimelineDates}
            dateRange={[
              new Date(participationRangeDate[0]),
              new Date(participationRangeDate[1]),
            ]}
            allowDeletePastDate={location.pathname.includes('create')}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Checkbox
              checked={withPush}
              onChange={() => setWithPush(!withPush)}
              sx={{ p: 0, mr: '1rem' }}
            />
            <Typography>
              홈 화면 메시지 발송시, 받는 사람에게 푸시 알림을 발송합니다.
            </Typography>
          </Box>
          <Typography sx={{ mt: '.5rem', color: 'rgba(0, 162, 255, 1)' }}>
            예시) 설문조사에 참여해 보세요!
          </Typography>
          <Divider />
        </Box>
        <Box>
          <DataportalConnect
            findDataportal={findDataportal}
            setFindDataportal={setFindDataportal}
            connectDataportal={connectDataportal}
            setConnectDataportal={setConnectDataportal}
          />
          <Divider />
        </Box>
        <Box>
          <Box>
            <Typography className='title'>상태</Typography>
            <TextField
              sx={{ width: '12rem' }}
              select
              value={
                questionAnswerStatus === undefined ? 1 : questionAnswerStatus
              }
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setQuestionAnswerStatus(Number(evt.target.value))}
            >
              <MenuItem key={0} value={0}>
                Close
              </MenuItem>
              <MenuItem key={1} value={1}>
                Open
              </MenuItem>
            </TextField>
          </Box>
          <Divider />
          <Box sx={{ mt: '1.5rem' }}>
            <ButtonBox className='flex_end'>
              <Box>
                <Button
                  variant='contained'
                  onClick={() => saveQuestionAnswerMaster()}
                >
                  정보 저장
                </Button>
              </Box>
            </ButtonBox>
          </Box>
        </Box>
      </CreatePaper>
      <SwSearchTarget
        title={targetTitle !== null ? targetTitle[0] : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwManageReceivers
        title={`${
          openManageReceivers === 2
            ? '커뮤니티'
            : openManageReceivers === 3
            ? '챌린지'
            : openManageReceivers === 6
            ? '프로그램'
            : ''
        } 인원 리스트`}
        qnaId={qnaId}
        targetId={receiverId}
        receivers={
          openManageReceivers === 2
            ? communityReceivers
            : openManageReceivers === 3
            ? challengeReceivers
            : openManageReceivers === 6
            ? programReceivers
            : []
        }
        targetType={openManageReceivers}
        open={openManageReceivers !== 0}
        onClose={() => {
          setOpenManageReceivers(0);
        }}
        onConfirm={() => {
          setOpenManageReceivers(0);
        }}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      {loading && <SwLoading />}
    </>
  );
};
