import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import {
  ICommunity,
  ICommunityCancel,
  ICommunityGroup,
  ICommunityReqeust,
} from '../../models/community';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Community
 */

export const getCommunityRequestList = () =>
  xapi.get(`/admin/v2/apis/community/creation-request`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getCommunityRequestListByOperator = () =>
  xapi.get(`/admin/v2/apis/community/creation-request-operator`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getCommunityRequestDetail = (communityId: string) =>
  xapi
    .get(`/admin/v2/apis/community/creation-request/${communityId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getCommunityGroupDetailList = (communityId: string) =>
  xapi.get(`/admin/v2/apis/community/${communityId}/group-detail`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getCommunityList = (data: ICommunityReqeust) =>
  xapi
    .get(
      `/admin/v2/apis/community?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getCommunityListByPortal = (data: ICommunityReqeust) =>
  xapi
    .get(
      `/admin/v2/apis/community/portalId/${data.portalId}?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getCommunityUserStepList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/community/${data.communityId}/step?startDate=${data.startDate}&endDate=${data.endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postCommunity = (data: ICommunity) =>
  xapi
    .post(`/admin/v2/apis/community/official/${data.official}`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const cancelCommunity = (data: ICommunityCancel) =>
  xapi.delete(
    `/admin/v2/apis/community/${data.CommunityId}/type/${data.type}/site/${data.site}`
  );

export const putCommunityChangeOfficial = (data: any) =>
  xapi
    .put(`/admin/v2/apis/community/${data.type}/${data.value}`, {
      communityIds: data.communityIds,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postCommunityGroup = (data: ICommunityGroup) =>
  xapi
    .post(`/admin/v2/apis/community/${data.communityId}/group`, {
      name: data.groupName,
      groupId: data.groupId,
      isDefault: data.isDefault,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putCommunityGroup = (data: ICommunityGroup) =>
  xapi.put(`/admin/v2/apis/community/${data.communityId}/group/name`, {
    name: data.groupName,
    groupId: data.groupId,
    isDefault: data.isDefault,
  });

export const deleteCommunityGroup = (data: ICommunityGroup) =>
  xapi.put(`/admin/v2/apis/community/${data.communityId}/group/delete`, {
    deleteGroupIds: data.deleteGroupIds,
  });

export const putCommunityChangeGroup = (data: any) =>
  xapi.put(`/admin/v2/apis/community/${data.communityId}/group/member`, {
    userIds: data.userIds,
    targetGroup: data.targetGroup,
  });

export const putCommunityGroupOrder = (data: any) =>
  xapi.put(`/admin/v2/apis/community/${data.communityId}/group/order`, {
    groups: data.groups,
  });

export const postCommunityMember = (data: any) =>
  xapi
    .post(`/admin/v2/apis/community/${data.communityId}/member`, {
      userIds: data.userIds,
      type: data.type,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postCommunityMemberByPhonenos = (data: any) =>
  xapi
    .post(`/admin/v2/apis/community/${data.communityId}/member-by-phonenos`, {
      phonenos: data.phonenos,
      type: data.type,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putCommunityChangeMemberType = (data: any) =>
  xapi.put(
    `/admin/v2/apis/community/${data.communityId}/member/type/${data.type}`,
    {
      userIds: data.userIds,
    }
  );

export const putCommunityChangeMemberTypeByPhonenos = (data: any) =>
  xapi.put(
    `/admin/v2/apis/community/${data.communityId}/member/type/${data.type}/by-phonenos`,
    {
      phonenos: data.phonenos,
    }
  );

export const getCommunityMemberList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/community/${data.communityId}/group/member?searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getCommunityMemberListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/community/${data.communityId}/group/member-by-phonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getCommunityTypeMemberList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/community/${data.communityId}/type/${data.type}/member?startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteCommunityMember = (data: any) =>
  xapi
    .put(`/admin/v2/apis/community/${data.communityId}/member/delete`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putCommunityOpenStep = (data: any) =>
  xapi.put(
    `/admin/v2/apis/community/${data.communityId}/open-step/${data.openStep}`,
    data
  );

export const getCommunityBannerList = (communityId: string) =>
  xapi.get(`/admin/v2/apis/community/${communityId}/banner`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postCommunityBanner = (data: any) =>
  xapi
    .post(`/admin/v2/apis/community/${data.communityId}/banner`, {
      stime: data.stime,
      etime: data.etime,
      data: data.data,
      priority: data.priority,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putCommunityBanner = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/community/${data.communityId}/banner/${data.bannerId}`,
      {
        stime: data.stime,
        etime: data.etime,
        data: data.data,
        priority: data.priority,
      }
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putCommunityBannerPriority = (data: any) =>
  xapi.put(`/admin/v2/apis/community/${data.communityId}/banner/priority`, {
    banners: data.banners,
  });

export const deleteCommunityBanner = (data: any) =>
  xapi
    .put(`/admin/v2/apis/community/${data.communityId}/banner/delete`, {
      bannerIds: data.bannerIds,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getCommunityDailyStepList = (data: ICommunityReqeust) =>
  xapi
    .get(
      `/admin/v2/apis/community/${data.communityId}/daily-stat/${data.statType}?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadCommunityRequestCaptureImage = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/community/${data.communityId}/request-capture-image/download?mainImage=${data.mainImage}&detailImage=${data.detailImage}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'community_year_info_image.zip';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 600000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadCommunityDailyStepList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/community/${data.communityId}/daily-stat/${data.statType}/download/${data.downloadType}?startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =
        data.downloadType === 'excel'
          ? 'community_daily_stat.xlsx'
          : 'community_daily_stat.pdf';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 600000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadCommunityMemberList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/community/${data.communityId}/member/download?type=${data.type}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'community_member.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 600000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
