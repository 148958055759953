import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useResize } from '../../../hooks/resize';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import {
  KEY_APP_REVIEW_LIST,
  KEY_ERROR_REPORT_LIST,
  KEY_NOTICE_LIST,
} from '../../../common/key';
import { getErrorReportList } from '../../../common/api/ApiErrorReport';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { ReportContentDrawup } from './ReportContentDrawup';

interface Props {}

const columns = [
  '닉네임',
  '아이디',
  '보고내용',
  '연락처',
  '최근 업데이트',
  '단말 모델 정보',
  '앱 버전 정보',
  '로그 보기',
];

export const ErrorReport: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [errorReportList, setErrorReportList] = useState<any>([]);
  const [appVersionList, setAppVersionList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [content, setContent] = useState<string>('');
  const [appVersion, setAppVersion] = useState<string>('all');
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openLog, setOpenLog] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: errorReportRefetch,
  } = useQuery(
    KEY_ERROR_REPORT_LIST,
    () => {
      const newData: any = {
        appVersion: appVersion === 'all' ? '' : appVersion,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        startDate: rangeDate[0] !== null ? rangeDate[0] / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1] / 1000 : 0,
      };
      return getErrorReportList(newData);
    },
    {
      onSuccess: res => {
        if (res != null) {
          setErrorReportList(res.report.report);
          setAppVersionList(res.report.versions);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (
    newSearchWord: string,
    newAppVersion: string,
    newPage: number
  ) => {
    queryClient.prefetchQuery(KEY_ERROR_REPORT_LIST, () => {
      const newData = {
        appVersion: newAppVersion,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
        startDate: rangeDate[0] !== null ? rangeDate[0] / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1] / 1000 : 0,
      };
      return getErrorReportList(newData);
    });
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const searchErrorReport = (key: string, name: string) => {
    setSearchKey(key);
    setSearchWord(name);
    prefetch(name, '', page);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchErrorReport('all', searchWord);
    }
  };

  useEffect(() => {
    if (checkRequest) {
      setPage(1);
      prefetch(searchWord, appVersion === 'all' ? '' : appVersion, 1);
    }
  }, [appVersion]);

  useEffect(() => {
    if (checkRequest) {
      setPage(1);
      prefetch(searchWord, '', 1);
    }
  }, [rangeDate]);

  useEffect(() => {
    errorReportRefetch();
  }, [page]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>오류 보고 관리</title>
      </Helmet>
      <Paper sx={{ m: '1rem 0', p: '1rem' }}>
        <Box className='flex_start'>
          <TextField
            placeholder='닉네임, 연락처, 아이디, 보고 내용으로 검색해 주세요.'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em', mt: '1rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => searchErrorReport('all', searchWord)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <SelectTextField
              sx={{ mt: '1rem' }}
              select
              value={appVersion}
              onChange={evt => {
                setAppVersion(evt.target.value);
              }}
            >
              <MenuItem key='all' value='all'>
                버전 전체
              </MenuItem>
              {appVersionList.map((option: any) => (
                <MenuItem key={option.toString()} value={option}>
                  {option}
                </MenuItem>
              ))}
            </SelectTextField>
          </Box>
          <Box className='flex_start' sx={{ ml: '1rem' }}>
            <SwDateRangePicker
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
            />
          </Box>
        </Box>
        <Box>
          <Typography>
            조회 <span className='bluecontent'>{totalRecords}</span> 개
          </Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {errorReportList?.map((row: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <TableCell>{row.userName}</TableCell>
                <TableCell>{row.userId}</TableCell>
                <TableCell sx={{ width: '30rem' }}>{row.reportTitle}</TableCell>
                <TableCell>{row.phoneno}</TableCell>
                <TableCell>
                  {timestamp2Localestring(row.createTime, 1000)}
                </TableCell>
                <TableCell>{row.model}</TableCell>
                <TableCell>{row.appVersion}</TableCell>
                <TableCell>
                  {' '}
                  <Button
                    variant='outlined'
                    color='info'
                    onClick={() => {
                      setContent(row.reportData);
                      setOpenLog(true);
                    }}
                  >
                    로그 보기
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {(errorReportList === null || errorReportList.length === 0) && (
              <TableRow>
                <TableCell colSpan={8}>검색결과 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={errorReportList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <ReportContentDrawup
        open={openLog}
        onClose={() => setOpenLog(false)}
        content={content}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={false}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </div>
  );
};
