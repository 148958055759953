import { Dispatch } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import {
  IChallengeCreate,
  IChallengeReqeust,
  IChallengePost,
  IChallengeFilter,
  IChallengeReward,
  IChallengeRewardBatch,
  IChallengeConfirm,
  IChallengeApplyStatus,
} from '../../models/challenge';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Challenge
 */

export const getChallengeRequestList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/creation-request?status=${data.status}&operator=${data.operator}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeRequestListByOperator = () =>
  xapi.get(`/admin/v2/apis/challenge/creation-request-operator`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getChallengeRequestDetail = (challengeId: string) =>
  xapi
    .get(`/admin/v2/apis/challenge/creation-request/${challengeId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeGroupDetailList = (challengeId: string) =>
  xapi.get(`/admin/v2/apis/challenge/${challengeId}/group-detail`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getChallengeList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&challengeFilter=${data.challengeFilter}&communityFilter=${data.communityFilter}&locationFilter=${data.locationFilter}&regionFilter=${data.regionFilter}&useTypeFilter=${data.useTypeFilter}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeInfo = (challengeId: any) =>
  xapi.get(`/admin/v2/apis/challenge/${challengeId}/info`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getChallengeRewardList = (challengeId: string) =>
  xapi.get(`/admin/v2/apis/challenge/${challengeId}/reward`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getChallengeRewardListByUserId = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/user/${data.userId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeAttendUserList = (data: IChallengeReqeust) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/attend-user?searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeRewardUserList = (data: IChallengeReqeust) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}/recipient?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeResultList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/result?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeListByPortal = (data: IChallengeReqeust) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/portalId/${data.portalId}?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeAdvertisementList = (data: IChallengePost) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/advertisement?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeAdvertisementDetail = (data: IChallengePost) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/advertisement/${data.advertisementId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeAdvertiserList = (data: IChallengePost) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/advertiser?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeCommunityFilterList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/filter/${data.type}/list?searchKey=${data.searchKey}&searchWord=${data.searchWord}&communityFilter=${data.communityFilter}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeCommunityFilterInfo = (targetId: any) =>
  xapi.get(`/admin/v2/apis/challenge/filter/${targetId}/info`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getChallengeCommunityFilterLoadList = (targetId: any) =>
  xapi.get(`/admin/v2/apis/challenge/filter/${targetId}/load`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const confirmChallengeRegister = (data: IChallengeConfirm) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/confirm/${data.testType}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const updateChallengeAttendContent = (data: any) =>
  xapi
    .post(`/admin/v2/apis/challenge/attend-user/add-after-delete`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeChallengeApplyStatus = (data: IChallengeApplyStatus) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/status/${data.status}?message=${data.message}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeChallengeHoldingStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/holding/${data.isHolding}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerChallengeMaster = (data: IChallengeCreate) =>
  xapi.post(`/admin/v2/apis/challenge/master`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const cloneChallengeMaster = (data: IChallengeCreate) =>
  xapi.post(`/admin/v2/apis/challenge/clone`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerChallengeFilter = (data: IChallengeFilter) =>
  xapi.post(`/admin/v2/apis/challenge/filter`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerChallengeReward = (data: IChallengeRewardBatch) =>
  xapi.post(`/admin/v2/apis/challenge/reward-one`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const addChallengeAttendUser = (data: any) =>
  xapi
    .post(`/admin/v2/apis/challenge/${data.challengeId}/attend-user/add`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteChallengeAttendUserBatch = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/attend-user/delete`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeChallengeAttendUser = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/attend-user/change-step`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteChallengeAttendUser = (data: any) =>
  xapi
    .delete(`/admin/v2/apis/challenge/${data.challengeId}/user/${data.userId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeChallengeAttendContent = (data: any) =>
  xapi.post(`/admin/v2/apis/challenge/attend/content`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteChallengeReward = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerChallengeAdvertisement = (data: IChallengePost) =>
  xapi
    .post(`/admin/v2/apis/challenge/${data.challengeId}/advertisement`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerChallengeAdvertiser = (data: IChallengePost) =>
  xapi.post(`/admin/v2/apis/challenge/advertiser`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteChallengeAdvertisement = (data: IChallengePost) =>
  xapi
    .delete(
      `/admin/v2/apis/challenge/${data.challengeId}/advertisement/${data.advertisementId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteChallengeAdvertisementByIndex = (data: IChallengePost) =>
  xapi
    .delete(
      `/admin/v2/apis/challenge/${data.challengeId}/advertisement/index/${data.idx}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteChallengeAdvertiser = (data: IChallengePost) =>
  xapi.delete(`/admin/v2/apis/challenge/advertiser/${data.idx}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteChallengeAdvertiserBrandPoint = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/challenge/advertiser/${data.advertiserid}/brand-point/${data.brandPointId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const drawManualCoupon = (data: IChallengeReqeust) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}/manual-coupon-draw/${data.drawType}`,
      data
    )
    .then(res => {
      if (res.status !== 200 && res.status !== 409) throwError(res.status);
      return res.data;
    });

export const drawManualTicket = (data: IChallengeReqeust) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}/manual-ticket-draw/${data.drawType}`,
      {
        userIds: data.userIds,
      }
    )
    .then(res => {
      if (res.status !== 200 && res.status !== 409) throwError(res.status);
      return res.data;
    });

export const downloadChallenge = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/download/${data.downloadType}?challengeId=${data.challengeId}&type=${data.type}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `challenge_${data.downloadType}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 600000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadChallengeList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/download/list?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&challengeFilter=${data.challengeFilter}&communityFilter=${data.communityFilter}&locationFilter=${data.locationFilter}&regionFilter=${data.regionFilter}&useTypeFilter=${data.useTypeFilter}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `challenge_list.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 600000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadChallengeAttendUser = (
  data: any,
  setLoading: Dispatch<React.SetStateAction<any>>
) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/attend-user/download/${data.includeTime}`,
      {
        responseType: 'blob',
      }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `challenge_attend_user_${data.includeTime}.zip`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
        setLoading(false);
      }, 600000);
      a.remove();
      setLoading(false);
    })
    .catch(err => {
      console.error('err: ', err);
      setLoading(false);
    });

// Challenge Class Type
export const getChallengeClassTypeList = (data: any) =>
  xapi.get(`/admin/v2/apis/challenge/class-type/${data.month}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerChallengeClassType = (data: any) =>
  xapi
    .post(`/admin/v2/apis/challenge/class-type/${data.month}`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// Popular Search Term
export const getPopularSearchTerm = (targetType: number) =>
  xapi
    .get(`/admin/v2/apis/challenge/popular-search-term/${targetType}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerPopularSearchTerm = (data: any) =>
  xapi.post(`/admin/v2/apis/challenge/popular-search-term`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

// Banner
export const getChallengeBannerList = () =>
  xapi.get(`/admin/v2/apis/challenge/banner`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postChallengeBanner = (data: any) =>
  xapi
    .post(`/admin/v2/apis/challenge/banner`, {
      stime: data.stime,
      etime: data.etime,
      data: data.data,
      priority: data.priority,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putChallengeBanner = (data: any) =>
  xapi
    .put(`/admin/v2/apis/challenge/banner/${data.bannerId}`, {
      stime: data.stime,
      etime: data.etime,
      data: data.data,
      priority: data.priority,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putChallengeBannerPriority = (data: any) =>
  xapi.put(`/admin/v2/apis/challenge/banner/priority`, {
    banners: data.banners,
  });

export const deleteChallengeBanner = (data: any) =>
  xapi
    .put(`/admin/v2/apis/challenge/banner/delete`, {
      bannerIds: data.bannerIds,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// Audio Content
export const getChallengeAudioContentList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/challenge/${data.challengeId}/audio-content`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerChallengeAudioContent = (data: any) =>
  xapi
    .post(`/admin/v2/apis/challenge/${data.challengeId}/audio-content`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteChallengeAudioContent = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/challenge/${data.challengeId}/audio-content/${data.audioContentId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const updateChallengeAudioContentCommonAd = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/challenge/${data.challengeId}/audio-content/common-ad`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// Brand Point Advertiser

export const getChallengeBrandPointAdvertiserList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/brand-point/advertiser?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// Point Use Place

export const getPointUsePlaceList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/brand-point/${data.brandPointId}/use-place?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerChallengePointUsePlace = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/brand-point/${data.brandPointId}/use-place`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteChallengePointUsePlace = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/challenge/brand-point/${data.brandPointId}/use-place/${data.idx}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// Local Store
export const getChallengeLocalStoreList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/local-store?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// Labeling
export const getChallengeLabelList = () =>
  xapi.get(`/admin/v2/apis/challenge/label`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postChallengeLabel = (data: any) =>
  xapi.post(`/admin/v2/apis/challenge/label`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putChallengeLabel = (data: any) =>
  xapi.put(`/admin/v2/apis/challenge/label/${data.labelId}`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });
