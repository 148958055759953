import {
  Box,
  Button,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import React, { Dispatch, SyntheticEvent, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  getRewardDrawingResult,
  getRewardSubmitUserDownload,
  postRewardDrawSubmit,
} from '../../../common/api/ApiReward';
import { DrawTable } from './DrawTable';
import { KEY_REWARD_DRAWING_RESULT } from '../../../common/key';
import {
  IReward,
  IRewardDrawing,
  IRewardDrawingUser,
} from '../../../models/reward';
import { RESULT_OK } from '../../../common/resultCode';
import { REWARD_STATE_SUBMIT } from './Index';
import { IPush } from '../../../models/push';
import { postPushSingle } from '../../../common/api/ApiPush';
import { ITimeline } from '../../../models/timeline';
import { postTimeline } from '../../../common/api/ApiTimeline';

const column = ['번호', '이름', '커뮤니티 닉네임 사용 여부', '커뮤니티 닉네임'];

interface Props {
  challengeId: string;
  rewardId: string;
  rewardInfo: any;
  rewardDrawUserList: IRewardDrawingUser;
  setRewardDrawUserList: Dispatch<React.SetStateAction<IRewardDrawingUser>>;
  changeState: any;
  setChangeState: Dispatch<React.SetStateAction<any>>;
}

export const GivingReward: React.FC<Props> = ({
  challengeId,
  rewardId,
  rewardInfo,
  rewardDrawUserList,
  setRewardDrawUserList,
  changeState,
  setChangeState,
}) => {
  const queryClient = useQueryClient();
  const [rewardDrawingResult, setRewardDrawingResult] = useState<any>([]);
  const [allUserList, setAllUserList] = useState<any>([]);
  const [winnerUserList, setWinnerUserList] = useState<any>([]);
  const [nonwinnerUserList, setNonwinnerUserList] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openSubmit, setOpenSubmit] = useState<boolean>(false);
  const [request, setRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);
  const [disabledClick, setDisabledClick] = useState<boolean>(false);

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const {
    data,
    isLoading,
    refetch: rewardDrawingResultRefetch,
  } = useQuery(
    KEY_REWARD_DRAWING_RESULT,
    () => {
      const newData: any = {
        type: 'all',
        rewardId: rewardId,
        searchKey: searchKey,
        searchWord: searchWord,
      };
      if (request && rewardId.length > 0)
        return getRewardDrawingResult(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('GivingReward drawing list :', res);
        if (res !== null) {
          setAllUserList(res.rewardResult);
          setWinnerUserList(
            res.rewardResult.filter(
              (user: any) =>
                user.rewardDrawResult === 1 || user.rewardDrawResult === 3
            )
          );
          setNonwinnerUserList(
            res.rewardResult.filter(
              (user: any) =>
                user.rewardDrawResult !== 1 && user.rewardDrawResult !== 3
            )
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchUser = (newRewardId: string) => {
    const newData: any = {
      type: 'all',
      rewardId: newRewardId,
      searchKey: searchKey,
      searchWord: searchWord,
    };
    queryClient.prefetchQuery(KEY_REWARD_DRAWING_RESULT, () =>
      getRewardDrawingResult(newData)
    );
  };

  const { mutate: postTimelineMutate, isError: isPushMutateError } =
    useMutation(postTimeline, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnakerDialog(true);
          setDialogMessage('타임라인을 등록했습니다.');
        } else {
          setDialogMessage(
            `타임라인 등록이 실패했습니다.(${res.resultCodeMsg})`
          );
          setOpenSnakerDialog(true);
        }
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '타임라인 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const registerTimeline = (type: string) => {
    const users = type === 'winner' ? winnerUserList : nonwinnerUserList;
    const newUseType = `${rewardInfo.rewardUseType}/${rewardInfo.rewardUseSubtype}`;
    const newRewardTitle = rewardInfo.rewardTitle;
    var newContent = {};

    if (type === 'winner') {
      newContent =
        newUseType === '4/0' // 신청양식 입력형
          ? `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 보관함으로 이동하여 쿠폰 수령을 위한 추가 정보를 입력해 주세요!`
          : newUseType === '5/1' || newUseType === '5/2' // 건강/기념증서
          ? `🏆[${newRewardTitle}] 증서에 당첨되셨습니다. 보관함으로 이동하여 증서를 확인해 주세요!`
          : `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 보관함으로 이동하여 쿠폰을 확인해 주세요!`; // 전체(신청양식 입력폼, 건강/기념 증서 제외)
    } else {
      newContent =
        newUseType === '5/1' || newUseType === '5/2' // 건강/기념증서
          ? `아쉽게도 [${newRewardTitle}] 증서에 당첨되지 않았습니다! 다음 기회에 다시 도전해 주세요.`
          : `아쉽게도 [${newRewardTitle}] 쿠폰에 당첨되지 않았습니다! 다음 기회에 다시 도전해 주세요.`; // 전체(신청양식 입력폼, 건강/기념 증서 제외)
    }
    users.map((user: any) => {
      const nowDate = new Date(Date.now());
      const newDates = nowDate.getTime() / 1000;
      const newData = {
        timestamp: rewardInfo.challengeTitle,
        text: newContent,
        imageUrl: '',
        action: type === 'winner' ? 7 : 0,
        extra: {},
        imgSize: { width: 0, height: 0 },
        ctr: 'no',
        name: user.name,
      };
      const newTimeline: ITimeline = {
        feedType: 7,
        receiver: {
          type: 'user',
          id: user.userId,
          name: user.name,
          healthItem: 1,
        },
        content: JSON.stringify(newData),
        site: 'webAdmin',
        requestDate: [newDates],
        repeat: {
          startDate: 0,
          endDate: 0,
        },
      };

      postTimelineMutate(newTimeline);
    });
  };

  const { mutate: postDrawSubmitMutation, isError: isMutateError } =
    useMutation(postRewardDrawSubmit, {
      onSuccess: res => {
        setLoading(false);
        setDisabledClick(false);
        if (res.resultCode === RESULT_OK) {
          setOpenSnakerDialog(true);
          setDialogMessage('지급하기를 진행했습니다.');
        } else {
          setOpenDialog(true);
          setDialogMessage(`지급하기 실패 : ${res.resultCodeMsg}`);
        }
      },
      onError: error => {
        setLoading(false);
        setDisabledClick(false);
        setOpenSnakerDialog(true);
        setDialogMessage(
          '지급하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const submit = () => {
    const newData: IRewardDrawing = {
      challengeId: challengeId,
      rewardId: rewardId,
      preDrawingCount: 0,
      rewardCount: 0,
    };
    console.log('postDrawSubmitMutation: ', newData);
    setLoading(true);
    setDisabledClick(true);
    postDrawSubmitMutation(newData);
  };

  const downloadSubmitUser = (type: string) => {
    getRewardSubmitUserDownload(rewardId, type);
  };

  const { mutate: pushSingleMutate, isError: isPushSingleMutateError } =
    useMutation(postPushSingle, {
      onSuccess: res => {},
      onError: error => {},
    });

  const sendPush = (type: string) => {
    const users = type === 'winner' ? winnerUserList : nonwinnerUserList;
    const newTitle = rewardInfo.challengeTitle; // 챌린지명
    const newRewardTitle = rewardInfo.rewardTitle;
    const newUseType = `${rewardInfo.rewardUseType}/${rewardInfo.rewardUseSubtype}`;
    const newContent =
      newUseType === '4/0' // 신청양식 입력형
        ? `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 쿠폰 수령을 위한 추가 정보를 입력해 주세요!`
        : newUseType === '5/1' || type === '5/2' // 건강/기념증서
        ? `🏆[${newRewardTitle}] 증서에 당첨되셨습니다. 보관함으로 이동하여 증서를 확인해 주세요!`
        : `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 보관함으로 이동하여 쿠폰을 확인해 주세요!`; // 전체(신청양식 입력폼, 건강/기념 증서 제외)

    users.map((user: any) => {
      const newPush: IPush = {
        receiver: {
          type: 'user',
          id: user.userId,
          name: user.name,
        },
        title: newTitle,
        content: newContent,
        link: 'walkon://home/popup',
        linkName: '',
        site: 'webAdmin',
        requestDate: [],
      };
      // console.log('sendPush :', newPush);
      pushSingleMutate(newPush);
    });
    setOpenSnakerDialog(true);
    setDialogMessage(
      type === 'winner'
        ? '당첨자에게 알림 발송이 완료되었습니다.'
        : '미당첨자에게 알림 발송이 완료되었습니다.'
    );
  };

  // useEffect(() => {
  //   if (request && rewardId.length > 0) {
  //     prefetchUser(rewardId);
  //   }
  // }, [rewardId]);

  useEffect(() => {
    console.log(
      'GivingReward changeState :',
      changeState,
      ', rewardDrawUserList:',
      rewardDrawUserList
    );
    console.log('rewardInfo :', rewardInfo);
    if (changeState === REWARD_STATE_SUBMIT && rewardId.length > 0) {
      prefetchUser(rewardId);
    } else {
      setWinnerUserList([]);
      setNonwinnerUserList([]);
    }
  }, [rewardDrawUserList]);

  // useEffect(() => {
  //   if (changeState === REWARD_STATE_SUBMIT && rewardDrawUserList) {
  //     console.log('GivingReward rewardDrawUserList :', rewardDrawUserList);
  //     if (rewardDrawUserList?.all.length > 0) {
  //       setAllUserList(rewardDrawUserList.all);
  //       const newApplyUserList = rewardDrawUserList.all.filter((user: any) => {
  //         const unexcept = rewardDrawUserList.unexcept?.filter((item: any) => {
  //           if (item.phoneno === user.phoneno) return true;
  //           return false;
  //         });
  //         const except = rewardDrawUserList.except?.filter((item: any) => {
  //           if (item.phoneno === user.phoneno) return true;
  //           return false;
  //         });
  //         if (
  //           (unexcept === null || unexcept?.length === 0) &&
  //           (except === null || except?.length === 0) &&
  //           user.rewardDrawResult !== 1
  //         )
  //           return true;
  //         return false;
  //       });
  //       setWinnerUserList(
  //         rewardDrawUserList?.all
  //           .filter(
  //             (item: any) =>
  //               item.rewardDrawResult === 1 ||
  //               item.rewardDrawResult === 3 ||
  //               item.hasBiResult === 1
  //           )
  //           .map(item => {
  //             const newItem = { name: item.name, phoneno: item.phoneno };
  //             return newItem;
  //           })
  //       );
  //       setNonwinnerUserList(
  //         rewardDrawUserList?.all
  //           .filter(
  //             (item: any) =>
  //               item.rewardDrawResult === 2 || item.hasBiResult === 1
  //           )
  //           .map(item => {
  //             const newItem = { name: item.name, phoneno: item.phoneno };
  //             return newItem;
  //           })
  //       );
  //     } else {
  //       setWinnerUserList([]);
  //       setNonwinnerUserList([]);
  //     }
  //   } else {
  //     setWinnerUserList([]);
  //     setNonwinnerUserList([]);
  //   }
  // }, [rewardDrawUserList]);

  return (
    <>
      <Paper sx={{ m: '2rem 0', p: '2rem' }}>
        <Box sx={{ mb: '1rem' }}>
          <Typography variant='h6' sx={{ mb: '1.5rem' }}>
            3. 쿠폰 지급하기
          </Typography>
          <Paper sx={{ m: '1rem 0', p: '1rem' }}>
            <Box className='flex_between'>
              <Typography sx={{ mb: '1.5rem' }}>
                당첨자 목록{' '}
                <span style={{ color: '#2581FF' }}>
                  {winnerUserList.length}
                </span>
                명
              </Typography>
              <Box>
                <Button
                  color='info'
                  variant='outlined'
                  sx={{ mr: '.5rem' }}
                  onClick={() => {
                    downloadSubmitUser('winner');
                  }}
                >
                  데이터 다운로드
                  <FileDownloadRoundedIcon color='success' />
                </Button>
                <Button
                  variant='outlined'
                  sx={{ mr: '.5rem' }}
                  onClick={() => {
                    sendPush('winner');
                    registerTimeline('winner');
                  }}
                >
                  알림 발송
                </Button>
                <Button
                  disabled={disabledClick}
                  variant='contained'
                  onClick={() => setOpenSubmit(true)}
                >
                  지급하기
                </Button>
              </Box>
            </Box>
            <DrawTable
              columns={column}
              rows={winnerUserList}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
            />
          </Paper>

          <Paper sx={{ ml: '0', mt: '3rem', p: '1rem' }}>
            <Box className='flex_between'>
              <Typography sx={{ mb: '1.5rem' }}>
                미당첨자 목록{' '}
                <span style={{ color: '#2581FF' }}>
                  {nonwinnerUserList.length}
                </span>
                명
              </Typography>
              <Box>
                <Button
                  color='info'
                  variant='outlined'
                  sx={{ mr: '.5rem' }}
                  onClick={() => downloadSubmitUser('nowinner')}
                >
                  데이터 다운로드
                  <FileDownloadRoundedIcon color='success' />
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => {
                    registerTimeline('nonwinner');
                  }}
                >
                  알림 발송
                </Button>
              </Box>
            </Box>
            <DrawTable
              columns={column}
              rows={nonwinnerUserList}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
            />
          </Paper>
        </Box>
        <SwSnackbar
          open={openSnakerDialog}
          onClose={() => setOpenSnakerDialog(false)}
          contents={dialogMessage}
        />
        <SwAlert
          open={openDialog}
          onConfirm={() => setOpenDialog(false)}
          title={dialogTitle}
          contents={dialogMessage}
        />
        <SwConfirmDialog
          contents='쿠폰을 지급하시겠습니까?'
          open={openSubmit}
          onClose={() => setOpenSubmit(false)}
          onConfirm={() => {
            submit();
            setOpenSubmit(false);
          }}
          confirm={1}
        />
        {loading && <SwLoading />}
      </Paper>
    </>
  );
};
