import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import moment from 'moment';
import axios from 'axios';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  DraggablePaper,
  RowsCell,
  MultilineTextField,
} from '../../styles/Styles';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  calculateDays,
  checkUrl,
  commaFormat,
  getAddUrl,
  MAX_STEP,
  STEP_STRIDE_CONST,
  STEP_TO_CALORIE_CONST,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  ContentTitle,
  CreatePaper,
  GridTitle,
  GridCell,
  Item,
  LocalstoreTypeBox,
  MarginButton,
} from './LocalstoreStyles';

import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { RESULT_OK } from '../../../common/resultCode';

import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import {
  KEY_CHALLENGE_BRAND_POINT_ADVERTISER_LIST,
  KEY_WORD_DUMMY_ID,
} from '../../../common/key';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { SwWeekAndTimePicker } from '../../commonComponent/dateSetting/SwWeekAndTimePicker';
import { registerLocalstoreMaster } from '../../../common/api/ApiLocalstore';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwImageSlider } from '../../commonComponent/others/SwImageSlider';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';

interface Props {
  localstoreInfo: any;
  setLocalstoreInfo: Dispatch<React.SetStateAction<any>>;
  reloadLocalstore: (id: string) => void;
  classTypeList: any;
}

const MAX_GUIDE_PAGE = 6;
const NO_SELECT_ADVERTISER = 99;

export const LocalstoreForm: React.FC<Props> = ({
  localstoreInfo,
  setLocalstoreInfo,
  reloadLocalstore,
  classTypeList,
}) => {
  const { loginVal } = useSession();
  const location = useLocation();
  const localstoreTitleRef = useRef<any>();
  const findAccountRef = useRef<any>();
  const [selectedLocalstore, setSelectedLocalstore] = useState<boolean>(false);
  const [testType, setTestType] = useState<number>();
  const [localstoreHidden, setLocalstoreHidden] = useState<number>(0);
  const [storeLocation, setStoreLocation] = useState<any>();
  const [localstoreTitle, setLocalstoreTitle] = useState<string>('');
  const [localstoreMainImage, setLocalstoreMainImage] = useState<string>('');
  const [localstoreMainImageFile, setLocalstoreMainImageFile] =
    useState<any>(null);
  const [imageList, setImageList] = useState<string[]>([]);
  const [localstoreType, setLocalstoreType] = useState(1);
  const [localstoreAddress, setLocalstoreAddress] = useState<string>('');
  const [localstoreLink, setLocalstoreLink] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [businessNo, setBusinessNo] = useState<any>('');
  const [storePhoneno, setStorePhoneno] = useState<any>('');
  const [homepage, setHomepage] = useState<any>('');
  const [phoneno, setPhoneno] = useState<any>('');
  const [accountId, setAccountId] = useState<any>('');
  const [description, setDescription] = useState<string>('');
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });
  const [openDate, setOpenDate] = useState<any[]>([]);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [localstoreStatus, setLocalstoreStatus] = useState<number>(1);
  const [localstoreId, setLocalstoreId] = useState<string>('');
  const [amdCode, setAmdCode] = useState<any>('');
  const [categoryNumber, setCategoryNumber] = useState<any>(0);
  const [ownerId, setOwnerId] = useState<any>('');
  const [ownerName, setOwnerName] = useState<any>('');
  const [localstoreApplyStatus, setLocalstoreApplyStatus] = useState<any>(1);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');
  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const {
    mutate: registerLocalstoreMasterMutation,
    isError: isMasterMutateError,
  } = useMutation(registerLocalstoreMaster, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        if (selectedLocalstore) {
          setOpenSnackbar(true);
          setAlertMessage('가게 마스터 정보가 저장되었습니다.');
          reloadLocalstore(localstoreId);
        } else {
          setAlertOpen(true);
          setAlertMessage('가게 생성 탭에서 작성중 내역을 확인해주세요.');
        }
      } else {
        showError(
          `가게 마스터 정보를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      showError(
        `가게 마스터 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const saveFileImage = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.files &&
      setLocalstoreMainImage(URL.createObjectURL(evt.target.files[0]));
  };
  const deleteFileImage = () => {
    URL.revokeObjectURL(localstoreMainImage);
    setLocalstoreMainImage('');
  };

  const addLocalstoreMaster = (
    newMainUploadUrl: string,
    newImageList: string[]
  ) => {
    const newMainImage =
      localstoreMainImageFile !== null
        ? newMainUploadUrl
        : localstoreMainImage.length > 0
        ? localstoreMainImage
        : '';

    const newSubImage =
      newImageList.length > 0 ? JSON.stringify(newImageList) : '[]';
    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;

    const newDates = openDate.map(item => {
      const tmpDate = new Date(item);
      return tmpDate.getTime() / 1000;
    });

    const newStore: any = {
      storeId: localstoreId,
      ownerId: ownerId,
      storeName: localstoreTitle,
      businessNumber: businessNo,
      address: storeLocation,
      detailAddress: detailAddress,
      approvalStatus: localstoreApplyStatus,
      storeStatus: localstoreStatus,
      categoryNumber: categoryNumber,
      amdCode: amdCode,
      storeLocationLat: newLa,
      storeLocationLng: newLo,
      openDate: newDates[0],
      storeOwnerName: ownerName,
      imgMain: newMainImage,
      imgSub: newSubImage,
      description: description,
      url: homepage,
      contact: storePhoneno,
    };

    const newData: any = {
      store: newStore,
    };

    registerLocalstoreMasterMutation(newData);
  };

  const checkValid = () => {
    var val = 0;

    if (val === 0 && localstoreId.length === 0) val = 1;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '가게 아이디를 입력해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    return val;
  };

  async function uploadMultipleFiles() {
    var newMainUploadUrl = '';
    if (localstoreMainImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'localstore',
        localstoreMainImageFile
      );
      newMainUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newImageList = await Promise.all(
      imageList.map(async (item: any, idx: number) => {
        let newUploadUrl = item;
        if (item.includes('blob:')) {
          const response = await axios.get(item, { responseType: 'blob' });
          const blob = response.data;

          const newUploadFile: any = await uploadFile('program', blob);
          newUploadUrl = newUploadFile.data.uploadUrl.file.path;
        }
        return newUploadUrl;
      })
    );

    addLocalstoreMaster(newMainUploadUrl, newImageList);
  }

  const checkLocalstore = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }

    uploadMultipleFiles();
  };

  const saveLocalstore = () => {
    checkLocalstore();
  };

  const searchAccount = () => {
    setOpenType(1);
    setTargetTitle('계정');
    setSearchKey('storeowner');
    setSearchWord(findAccountRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      if (type === 'account') searchAccount();
    }
  };

  useEffect(() => {
    console.log('searchResult :', searchResult, ', openType:', openType);
    if (searchResult.length > 0) {
      setOwnerId(searchResult[0].id);
      setOwnerName(searchResult[0].name);
      setPhoneno(searchResult[0].phoneno);
    }
  }, [searchResult]);

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      setAddress(newAddress);
      setStoreLocation(newAddress);
    }
  }, [targetData]);

  useEffect(() => {
    if (localstoreInfo) {
      setSelectedLocalstore(true);
      setLocalstoreId(localstoreInfo.storeId);
      setLocalstoreTitle(localstoreInfo.storeName);
      setBusinessNo(localstoreInfo.businessNumber);
      setOwnerName(localstoreInfo.storeOwnerName);
      const newOpenDate =
        localstoreInfo.openDate !== null && localstoreInfo.openDate !== 0
          ? [new Date(localstoreInfo.openDate * 1000)]
          : [];
      setOpenDate(newOpenDate);
      setStoreLocation(localstoreInfo.address);
      setAddress(localstoreInfo.address !== null ? localstoreInfo.address : '');
      setDetailAddress(localstoreInfo.detailAddress);
      localstoreInfo.storeLocationLat !== 0 &&
      localstoreInfo.storeLocationLng !== 0
        ? setResultLatLng(
            `${localstoreInfo.storeLocationLat} ${localstoreInfo.storeLocationLng}`
          )
        : setResultLatLng('');
      setDescription(localstoreInfo.description);
      setHomepage(localstoreInfo.url);
      setStorePhoneno(localstoreInfo.contact);
      setLocalstoreStatus(localstoreInfo.storeStatus);
      setLocalstoreApplyStatus(localstoreInfo.approvalStatus);
      setAmdCode(localstoreInfo.amdCode);
      setCategoryNumber(localstoreInfo.categoryNumber);
      setOwnerId(localstoreInfo.ownerId !== null ? localstoreInfo.ownerId : '');
      setPhoneno(
        localstoreInfo.ownerPhoneno !== null ? localstoreInfo.ownerPhoneno : ''
      );

      setLocalstoreMainImage(
        localstoreInfo.imgMain !== null
          ? localstoreInfo.imgMain.replace(
              'https://s3.ap-northeast-1.amazonaws.com/walkon-dev',
              ''
            )
          : ''
      );
      setLocalstoreMainImageFile(null);

      const newSubImage =
        localstoreInfo.imgSub !== null ? JSON.parse(localstoreInfo.imgSub) : [];
      setImageList(newSubImage);
    } else {
      location.pathname.includes('create')
        ? setSelectedLocalstore(true)
        : setSelectedLocalstore(false);
    }
    return () => {};
  }, [localstoreInfo]);

  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>가게 이름</Typography>
          <TextField
            sx={{ width: '48rem' }}
            inputRef={localstoreTitleRef}
            value={localstoreTitle}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setLocalstoreTitle(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <SwUuidCreate
            title='가게'
            id={localstoreId}
            setId={setLocalstoreId}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>사업자 등록번호</Typography>
          <TextField
            sx={{ width: '24rem' }}
            value={businessNo}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setBusinessNo(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>대표자 이름</Typography>
          <TextField
            sx={{ width: '24rem' }}
            value={ownerName}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setOwnerName(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Grid
            container
            sx={{
              width: '100%',
            }}
          >
            <GridTitle item xs={12}>
              <Item>개업 일자</Item>
            </GridTitle>
            <GridCell item xs={12}>
              <Box className='flex_start'>
                <SwDatesPickerWithScroll
                  dates={openDate}
                  setDates={setOpenDate}
                  allowPastDate={true}
                  showButton={false}
                  maxLimit={1}
                />
                <Button
                  sx={{ ml: '1rem', mr: '1rem' }}
                  color='info'
                  variant='outlined'
                  onClick={() => {
                    setOpenDate([]);
                  }}
                >
                  Reset
                </Button>
              </Box>
            </GridCell>
          </Grid>
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start' sx={{ mb: '.5rem' }}>
            <Typography className='title'>위치 정보(선택)</Typography>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='contained'
              color='info'
              onClick={() => {
                setOpenPostCode(1);
              }}
            >
              주소찾기
            </Button>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='outlined'
              color='info'
              onClick={() => {
                setStoreLocation('');
                setResultLatLng('');
                setAddress('');
                setDetailAddress('');
              }}
            >
              주소 초기화
            </Button>
          </Box>
          <SwFindLatLngbyKakaomap
            elementId='pointMap'
            setLatLng={setResultLatLng}
            value={resultLatLng}
            location={storeLocation}
            setLocation={setStoreLocation}
            showButton={false}
          />
          {openPostCode && (
            <SwSearchPostCode
              open={!!openPostCode}
              onClose={() => setOpenPostCode(0)}
              setTarget={setTargetData}
            />
          )}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상세주소(선택)</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={detailAddress}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setDetailAddress(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>업종</Typography>
          <TextField
            sx={{ width: '20rem' }}
            select
            value={categoryNumber}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setCategoryNumber(Number(evt.target.value));
            }}
          >
            <MenuItem key='class_type_0' value={0}>
              선택
            </MenuItem>
            {classTypeList &&
              classTypeList.map((item: any) => (
                <MenuItem key={`class_type_${item.number}`} value={item.number}>
                  {item.type}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        <Divider />
        <Box>
          <Typography className='title'>가게 대표 이미지(선택)</Typography>
          <SwFileSetting
            image={
              localstoreMainImageFile !== null
                ? localstoreMainImage
                : localstoreMainImage !== ''
                ? `${imageUrlPrefix}${localstoreMainImage}`
                : ''
            }
            setImage={setLocalstoreMainImage}
            setFile={setLocalstoreMainImageFile}
            file={localstoreMainImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>가게 소개 이미지(선택)</Typography>
          <SwImageSlider
            disabled={false}
            imageList={imageList}
            setImageList={setImageList}
          />
        </Box>
        <Box>
          <Typography className='title'>가게 소개글(선택)</Typography>
          <TextField
            multiline
            rows={5}
            onChange={evt => setDescription(evt.target.value.substring(0, 500))}
            value={description}
            placeholder='내용을 입력해 주세요.'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{description?.length} / 500</span>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '50rem',
                height: 'auto',
                padding: '.5rem',
              },
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>가게 홈페이지(선택)</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={homepage}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setHomepage(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>가게 전화번호(선택)</Typography>
          <TextField
            sx={{ width: '24rem' }}
            value={storePhoneno}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setStorePhoneno(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box className='flex_start'>
          <Typography className='title'>계정 연결</Typography>
          <TextField
            placeholder='계정을 검색해 주세요.'
            sx={{ marginRight: '1rem', width: '21rem' }}
            inputRef={findAccountRef}
            onKeyDown={evt => {
              handleKeyDown(evt, 'account');
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => {
                      searchAccount();
                    }}
                    sx={{ pr: 0 }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            disabled={true}
            sx={{ width: '24rem' }}
            value={ownerId}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => {
                      findAccountRef.current.value = '';
                      setOwnerId('');
                      setPhoneno('');
                    }}
                    sx={{ pr: 0 }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
        <Box>
          <Typography className='title'>휴대폰 번호</Typography>
          <TextField disabled={true} sx={{ width: '24rem' }} value={phoneno} />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            value={localstoreStatus === undefined ? 1 : localstoreStatus}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setLocalstoreStatus(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
        <Box sx={{ mt: '1.5rem' }}>
          <ButtonBox className='flex_end'>
            <Box>
              <Button variant='contained' onClick={() => saveLocalstore()}>
                정보 저장
              </Button>
            </Box>
          </ButtonBox>
        </Box>
      </CreatePaper>
      <SwSearchTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
