import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import useLocalStorage from '../../hooks/storage';
import { getAmdList } from '../../common/api/ApiGeohash';
import { KEY_AMD_LIST, KEY_APP_OS_VERSION_LIST } from '../../common/key';

import {
  AddGroupTextField,
  CreatePaper,
  NoneBorderChip,
  TermPaper,
} from '../styles/Styles';
import { getAppOsVersionList } from '../../common/api/ApiPush';
import SwLoading from './spinner/SwLoading';

interface Props {
  osVersion: string;
  setOsVersion: Dispatch<string>;
  showVersion: boolean;
}

export const SwPushSelectOsVersion: React.FC<Props> = ({
  osVersion,
  setOsVersion,
  showVersion = false,
}) => {
  const locationSearch = useRef<any>();
  const [versionList, setVersionList] = useState<any[]>([]);
  const [versionIndex, setVersionIndex] = useState<number>(0);
  const [osType, setOsType] = useState<string>('android');
  const [loading, setLoading] = useState<any>(true);

  const {
    data,
    isLoading,
    refetch: refetchAppOsVersion,
  } = useQuery(KEY_APP_OS_VERSION_LIST, () => getAppOsVersionList(osType), {
    onSuccess: res => {
      setLoading(false);
      if (res !== null) {
        setVersionIndex(0);
        setVersionList(res.versions);
      }
    },
    onError: e => {
      setLoading(false);
      console.log(e);
    },
  });

  useEffect(() => {
    setLoading(true);
    refetchAppOsVersion();
  }, [osType]);

  useEffect(() => {
    const osData = osVersion.split('>');
    if (!showVersion) {
      setOsType(osData[0]);
    } else if (showVersion && osData.length === 2) {
      setOsType(osData[0]);
      const newVersion = versionList
        .map((version: any, idx: number) => {
          const newIndex = version === osData[1] ? idx : -1;
          return newIndex;
        })
        .filter((newData: any) => newData !== -1);
      if (newVersion !== null && newVersion.length > 0) {
        setVersionIndex(Number(newVersion[0] + 1));
      } else {
        setVersionIndex(0);
      }
    } else setVersionIndex(0);
  }, [osVersion]);

  useEffect(() => {
    if (!showVersion) {
      setLoading(true);
      refetchAppOsVersion();
    }
  }, [showVersion]);

  return (
    <>
      <Box>
        <Box className='flex_start'>
          <TextField
            select
            sx={{ width: '14rem' }}
            value={osType}
            defaultValue='android'
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setOsType(event.target.value);
              setOsVersion(`${event.target.value}>`);
            }}
          >
            <MenuItem value='android'>안드로이드</MenuItem>
            <MenuItem value='ios'>아이폰</MenuItem>
          </TextField>
          {showVersion && (
            <TextField
              sx={{ width: '11rem', ml: '1rem', mb: 0 }}
              select
              defaultValue={0}
              value={versionIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                const newIndx = Number(event.target.value);
                setVersionIndex(newIndx);
                setOsVersion(
                  newIndx === 0
                    ? `${osType}>`
                    : `${osType}>${versionList[newIndx - 1]}`
                );
              }}
            >
              <MenuItem key={0} value={0}>
                선택
              </MenuItem>
              {versionList.map((option: string, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
        {loading && <SwLoading />}
      </Box>
    </>
  );
};
