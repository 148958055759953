import { Box, Button, Paper, styled, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, {
  ChangeEvent,
  Dispatch,
  useState,
  useEffect,
  useRef,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SwAlert } from './views/SwAlert';
import { checkImageFileName } from '../../common/helper';

interface Props {
  image: string;
  setImage: any;
  file: any;
  setFile: any;
  width?: string;
  height?: string;
  disabled?: boolean;
  onDelete?: () => void;
}

const NoImagePaper = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  color: '#C2CAD4',
  padding: '1.5rem',
  margin: '1rem 0',
  cursor: 'pointer',
});

const NoImageIcon = styled(AddOutlinedIcon)({
  border: '1px dashed #C2CAD4',
  borderRadius: '50%',
  width: '3em',
  height: 'auto',
});

export const SwFileSetting: React.FC<Props> = ({
  image,
  setImage,
  file,
  setFile,
  width = '10rem',
  height = 'auto',
  disabled = false,
  onDelete,
}) => {
  const fileInputRef = useRef<any>();
  const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [imageId, setImageId] = useState<string>(newId());
  const [lclImage, setLclImage] = useState<string>('');
  const saveFileImage = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && !checkImageFileName(evt.target.files[0].name)) {
      setOpenDialog(true);
      setDialogMessage(
        '지원하지 않는 이미지 포맷입니다. 다시 시도해 주시기 바랍니다.'
      );
      return;
    }
    evt.target.files && setFile(evt.target.files[0]);
    evt.target.files && setImage(URL.createObjectURL(evt.target.files[0]));
    evt.target.files && setLclImage(URL.createObjectURL(evt.target.files[0]));
    evt.target.files &&
      console.log('imagess>>>>>>>>', URL.createObjectURL(evt.target.files[0]));
  };
  const deleteFileImage = () => {
    fileInputRef.current.value = '';
    URL.revokeObjectURL(image);
    setLclImage('');
    setImage('');
    setFile(null);
    if (onDelete !== undefined && onDelete !== null) onDelete();
  };

  useEffect(() => {
    console.log('image :', image);
    setLclImage(image);
  }, [image]);

  return (
    <>
      {lclImage !== null && lclImage.length > 0 ? (
        <Box sx={{ m: '1rem 0' }}>
          <img
            src={lclImage}
            alt={lclImage}
            style={{
              maxWidth: '25vw',
              borderRadius: '14px',
              width: width,
              height: height,
            }}
          />
          <Button disabled={disabled} onClick={deleteFileImage} color='info'>
            삭제
          </Button>
        </Box>
      ) : (
        <NoImagePaper
          aria-hidden
          onClick={() => {
            if (disabled === false) {
              deleteFileImage();
              const imageFile = document.getElementById(
                `fileUpload_${imageId}`
              );
              imageFile?.click();
            }
          }}
          sx={{ width: width }}
        >
          <NoImageIcon />
          <Typography sx={{ fontSize: '.75rem' }}>이미지 선택</Typography>
        </NoImagePaper>
      )}
      <input
        type='file'
        id={`fileUpload_${imageId}`}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={saveFileImage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
