/* eslint-disable import/no-duplicates */
import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import koLocale from 'date-fns/locale/ko';
import { Badge, Box, Button, styled, TextField } from '@mui/material';
import { isSameDay, isWithinInterval } from 'date-fns';
import { isNullishCoalesce } from 'typescript';
import moment from 'moment';
import SwScrollTimePicker from '../datepicker/SwScrollTimePicker';

interface Props {
  rangeDate: any[];
  setRangeDate: Dispatch<SetStateAction<any[]>>;
  minStartDate?: any;
  rangeDateTime?: any;
  setRangeDateTime?: Dispatch<SetStateAction<any>>;
  disabled?: boolean;
}

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop: any) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: '#f0f4c3',
    color: '#ff8a65',
    '&:hover, &:focus': {
      backgroundColor: '#7cb342',
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as ComponentType<CustomPickerDayProps>;

export const SwDateRangePickerWithScroll: React.FC<Props> = ({
  rangeDate,
  setRangeDate,
  minStartDate,
  rangeDateTime,
  setRangeDateTime,
  disabled = false,
}) => {
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);
  const [tempRangeDate, setTempRangeDate] = useState<any[]>([null, null]);
  const datetimeRangeSetting = () => {
    const newData = moment(rangeDateTime)
      .format('YYYY-MM-DD HH:mm:ss')
      .split(' ')[1];
    const newStartDate = new Date(`${rangeDate[0].toDateString()} ${newData}`);
    const newEndDate = new Date(`${rangeDate[1].toDateString()} ${newData}`);
    setRangeDate([newStartDate, newEndDate]);
  };
  const start: any = rangeDate[0] === null ? null : new Date(rangeDate[0]);
  const end: any = rangeDate[1] === null ? null : new Date(rangeDate[1]);
  const [isOpenTimeDialog, setIsOpenTimeDialog] = useState(false);

  const renderPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    // if (openStartDate) {
    //   const startDay = rangeDate[0] && rangeDate[0].toLocaleDateString();
    //   const startDate = new Date(startDay);
    //   return (
    //     <Badge
    //       key={date.toString()}
    //       overlap='circular'
    //       badgeContent={
    //         date.toString() === startDate.toString() ? '🚀' : undefined
    //       }
    //     >
    //       <PickersDay {...pickersDayProps} />
    //     </Badge>
    //   );
    // }
    // if (rangeDate[0] && !rangeDate[1]) {
    //   const startDay = rangeDate[0] && rangeDate[0].toLocaleDateString();
    //   const startDate = new Date(startDay);
    //   return (
    //     <Badge
    //       key={date.toString()}
    //       overlap='circular'
    //       badgeContent={
    //         date.toString() === startDate.toString() ? '🚀' : undefined
    //       }
    //     >
    //       <PickersDay {...pickersDayProps} />
    //     </Badge>
    //   );
    // }

    const dayIsBetween =
      start === null || end === null
        ? false
        : isWithinInterval(date, { start, end });
    const isFirstDay = start === null ? true : isSameDay(date, start);
    const isLastDay = end === null ? true : isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };
  return (
    <Box className='flex_center' sx={{ pt: '.4rem' }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={koLocale}
      >
        <DatePicker
          disabled={disabled}
          mask='____/__/__'
          inputFormat='yyyy/MM/dd'
          label='시작일 선택'
          renderInput={props => (
            <TextField
              {...props}
              sx={{ '& .MuiInputLabel-root': { top: -4 }, mb: 0 }}
            />
          )}
          value={start}
          minDate={minStartDate}
          maxDate={end}
          onChange={(newValue: Date | null) => {
            const newRangeDate = [...rangeDate];
            if (newValue) {
              newRangeDate[0] = new Date(newValue.toDateString());
              newRangeDate[1] =
                newRangeDate[1] === null ? null : newRangeDate[1];
              setRangeDate(newRangeDate);
              if (newRangeDate[1] === null) setOpenEndDate(!openEndDate);
            }
          }}
          // renderDay={renderPickerDay}
        />
        <span style={{ margin: '0 .5rem', fontSize: '1rem' }}>~</span>
        <DatePicker
          disabled={disabled}
          open={openEndDate}
          onOpen={() => setOpenEndDate(!openEndDate)}
          onClose={() => setOpenEndDate(false)}
          mask='____/__/__'
          inputFormat='yyyy/MM/dd'
          label='종료일 선택'
          renderInput={props => (
            <TextField
              {...props}
              sx={{ '& .MuiInputLabel-root': { top: -4 }, mb: 0 }}
            />
          )}
          value={end || tempRangeDate[1]}
          minDate={start}
          onChange={(newValue: Date | null) => {
            const newRangeDate = [...rangeDate];
            if (newValue) {
              const newDate = new Date(`${newValue.toDateString()} 23:59:59`);
              newRangeDate[0] === null ? Date.now() : newRangeDate[0];
              newRangeDate[1] = newDate;
              rangeDateTime === null
                ? setTempRangeDate([...newRangeDate])
                : setRangeDate([...newRangeDate]);
              setOpenEndDate(false);
            }
          }}
          // renderDay={(day, _value, DayComponentProps) => {
          //   const startDay = rangeDate[0] && rangeDate[0].toLocaleDateString();
          //   const startDate = new Date(startDay);
          //   return (
          //     <Badge
          //       key={day.toString()}
          //       overlap='circular'
          //       badgeContent={
          //         day.toString() === startDate.toString() ? '🛫' : undefined
          //       }
          //     >
          //       <PickersDay {...DayComponentProps} />
          //     </Badge>
          //   );
          // }}
          renderDay={renderPickerDay}
        />
        {rangeDateTime && (
          <SwScrollTimePicker
            disabled={disabled}
            value={rangeDateTime}
            onChangeValue={(newValue: any) => {
              setRangeDateTime && setRangeDateTime(newValue);
              console.log('newValuy>>>>>>>>>>', newValue);
            }}
            open={isOpenTimeDialog}
            handleOpen={setIsOpenTimeDialog}
            width='220px'
            intervalMinutes={1}
          />
        )}
      </LocalizationProvider>
      <Button
        disabled={disabled}
        variant='outlined'
        size='small'
        color='info'
        sx={{ padding: '0rem', ml: '.5em' }}
        onClick={() => setRangeDate([null, null])}
      >
        Reset
      </Button>
      {rangeDateTime && (
        <Button
          disabled={disabled}
          variant='outlined'
          color='info'
          sx={{ ml: '.5em' }}
          onClick={datetimeRangeSetting}
        >
          저장
        </Button>
      )}
    </Box>
  );
};
