import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { KEY_DATAPORTAL_ACCOUNT_LIST } from '../../common/key';
import { getDataPortalAccountList } from '../../common/api/ApiDataPortal';
import { SwSearchDataPortal } from './SwSearchDataPortal';
import { SwSearchTarget } from './SwSearchTarget';

interface Props {
  title: string;
  targetInfo: any;
  setTargetInfo: Dispatch<React.SetStateAction<any>>;
}

export const SwSearchUsers: React.FC<Props> = ({
  title,
  targetInfo,
  setTargetInfo,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('user');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [portalId, setPortalId] = useState<string>('');

  const changeValue = (
    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearchWord(evt.target.value);

  const searchTarget = () => {
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchTarget();
    }
  };

  useEffect(() => {
    if (searchResult && searchResult.length > 0) {
      const newData = [...targetInfo, ...searchResult];
      setTargetInfo(newData);
    }
    return () => {};
  }, [searchResult]);

  return (
    <>
      <Box>
        {title && title.length > 0 && (
          <Typography className='title'>{title}</Typography>
        )}
        <TextField
          value={searchWord}
          onChange={changeValue}
          onKeyDown={handleKeyDown}
          sx={{ width: '26rem', mr: '1em' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={searchTarget}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <SwSearchTarget
        title={title !== null ? title[0] : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        targetData={[]}
        setTarget={setSearchResult}
      />
    </>
  );
};
