import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultilineTextField } from '../styles/Styles';
import { SwDatesPicker } from './dateSetting/SwDatesPicker';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';
import { RESULT_OK } from '../../common/resultCode';
import {
  addChallengeAttendUser,
  changeChallengeAttendUser,
  deleteChallengeAttendUserBatch,
} from '../../common/api/ApiChallenge';
import { validCheck } from '../../common/helper';
import { KEY_WORD_PHONE } from '../../common/key';

interface Props {
  challengeId: string;
  attendType: number;
  open: boolean;
  onclose: () => void;
}

export const SwLoadDeleteAttendUser: React.FC<Props> = ({
  challengeId,
  attendType,
  open,
  onclose,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<any>('');
  const [attendDates, setAttendDates] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const { mutate: deleteChallengeAttendUserMutation, isError: addMutateError } =
    useMutation(deleteChallengeAttendUserBatch, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('요청한 사용자를 챌린지 사용자에서 삭제했습니다.');
        } else {
          showError(
            `챌린지 참여자를 삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
        onclose();
      },
      onError: error => {
        showError(
          `챌린지 참여자를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const checkContent = () => {
    const newContent = content.split('\n');
    const validContent = newContent.filter((row: any) =>
      validCheck(KEY_WORD_PHONE, row)
    );
    const invalidContent = newContent.filter(
      (row: any) => row !== '' && !validCheck(KEY_WORD_PHONE, row)
    );

    if (
      challengeId === undefined ||
      challengeId === null ||
      challengeId === ''
    ) {
      showError(`챌린지를 선택한 후 다시 시도해 주세요.`);
      return;
    }

    if (validContent.length === 0) {
      showError(`사용자 폰번호를 추가후 다시 시도해 주세요.`);
      return;
    }

    const newAttendUserData = {
      challengeId: challengeId,
      phonenos: validContent,
    };
    deleteChallengeAttendUserMutation(newAttendUserData);
  };

  useEffect(() => {
    if (open) {
      setContent('');
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '24vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography>참여자 삭제</Typography>
            </Box>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box className='flex_between'>
            <Box>
              <Typography sx={{ m: '1rem 0 ' }}>
                유저 폰번호(최대 500명)
              </Typography>

              <MultilineTextField
                placeholder='예시
                01012345678
                01098765432'
                multiline
                rows={20}
                value={content}
                onChange={evt => setContent(evt.target.value)}
                sx={{
                  height: '100%',
                  width: '20vw',
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={() => checkContent()}
          >
            완료
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
