import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  ColumsCell,
  RowsCell,
  SelectTextField,
  TableButton,
} from '../../styles/Styles';
import { PartnerTable } from '../../commonComponent/table/PartnerTable';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { KEY_DATAPORTAL_REFUND_LIST } from '../../../common/key';
import {
  commaFormat,
  encodeSearchWord,
  getMessagePaymentType,
  timestamp2Localestring,
} from '../../../common/helper';
import {
  downloadChallengeData,
  getDataPortalRefundList,
  putDataPortalRefundConfirm,
} from '../../../common/api/ApiContract';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';

interface Props {}

const columns = [
  '고객명',
  '결제 내용',
  '결제 금액',
  '결제 방식',
  '취소 요청 일자',
  '취소 요청 금액',
  '취소 현황',
  '승인 일자',
  '승인 여부',
];

export const PAYMENT_TRANSACTION_TYPE = 'refund';

export const RefundManage: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const searchInputRef = useRef<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [requestList, setRequestList] = useState<any>([]);
  const [transactionList, setTransactionList] = useState<any>([]);
  const [sortValue, setSortValue] = useState(0);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [portalId, setPortalId] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: refundRefetch,
  } = useQuery(
    KEY_DATAPORTAL_REFUND_LIST,
    () => {
      if (checkRequest) {
        const newData: any = {
          type: PAYMENT_TRANSACTION_TYPE,
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(
            searchKey,
            searchInputRef.current?.value
          ),
          startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
          endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        };
        return getDataPortalRefundList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res && res.transaction) {
          const newDeposit = res.transaction.map((item: any) => {
            const newData = [
              `${item.portalName}(${item.portalId})`,
              item.type === 1 ? '문자 이용권' : '문자 서비스',
              commaFormat(String(item.requestAmount)),
              getMessagePaymentType(item.paymentType),
              timestamp2Localestring(item.createDate, 1000),
              item.refundAmount,
              item.status === 1 ? '취소 요청' : '승인 완료',
              item.approveDate !== null
                ? timestamp2Localestring(item.approveDate, 1000)
                : '-',
            ];
            return newData;
          });
          setRequestList(newDeposit);
          setTransactionList(res.transaction);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const refundPrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_DATAPORTAL_REFUND_LIST, () => {
      const newData: any = {
        type: PAYMENT_TRANSACTION_TYPE,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchInputRef.current?.value),
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      };
      getDataPortalRefundList(newData);
    });
  };

  const { mutate: confirmRefundMutate, isError: isMutateError } = useMutation(
    putDataPortalRefundConfirm,
    {
      onSuccess: () => {
        setOpenSnacker(true);
        setDialogMessage('환불 확인처리했습니다.');
        refundRefetch();
      },
      onError: error => {
        setOpenSnacker(true);
        setDialogMessage(
          '환불 확인중 오류가 발생했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    }
  );

  const searchPayment = () => {
    refundRefetch();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchPayment();
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const downloadChallenge = () => {
    const newData = {
      portalId: portalId,
      page: 0,
      rowsPerPage: 0,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
    };
    downloadChallengeData(newData);
  };

  const confirmRefund = () => {
    const newData: any = {
      id: transactionList[selectedIndex].transactionId,
    };
    confirmRefundMutate(newData);
  };

  useEffect(() => {
    if (rangeDate[0] !== null && rangeDate[1] !== null) {
      refundRefetch();
    }
  }, [rangeDate]);

  useEffect(() => {
    refundRefetch();
  }, []);

  return (
    <>
      <Box className='flex_between' sx={{ p: '2.5rem 0 .5rem' }}>
        <Typography variant='h3' sx={{ fontWeight: 500 }}>
          취소/환불 내역
        </Typography>
        <TextField
          placeholder='고객명, 결제 내용으로 검색해 주세요.'
          inputRef={searchInputRef}
          onKeyDown={evt => {
            handleKeyDown(evt);
          }}
          sx={{ width: '30rem' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={() => searchPayment()}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Paper sx={{ p: '1.5rem', m: '1rem 0' }}>
        <Box className='flex_between' sx={{ mb: '1rem' }}>
          <Box>
            취소/환불 내역{' '}
            <span style={{ color: '#2581FF' }}>{totalRecords}</span> 개
          </Box>
          {/* <Box className='flex_center'>
            <Box sx={{ mr: '.5rem' }}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Box>
            <SelectTextField
              select
              value={sortValue}
              onChange={evt => {
                setSortValue(Number(evt.target.value));
              }}
              sx={{ mr: '.5rem' }}
            >
              <MenuItem value={0}>전체</MenuItem>
              <MenuItem value={1}>진행중</MenuItem>
              <MenuItem value={2}>종료</MenuItem>
            </SelectTextField>
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
            <TableButton
              color='info'
              variant='outlined'
              onClick={downloadChallenge}
            >
              데이터 다운로드
              <FileDownloadRoundedIcon
                color='success'
                sx={{ fontSize: '1.3rem' }}
              />
            </TableButton>
          </Box> */}
        </Box>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <ColumsCell key={idx.toString()}>{column}</ColumsCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {requestList.map((row: any, idx: number) => (
                  <TableRow key={idx.toString()} className='whitecolor'>
                    {columns
                      .filter((column, i) => i < columns.length - 1)
                      .map((column, i) => (
                        <RowsCell
                          key={`refund_key_${i}`}
                          className={
                            transactionList[idx].status === 1
                              ? 'specified_row'
                              : 'whitecolor'
                          }
                        >
                          {row[i] !== null && row[i] !== '' ? row[i] : '-'}
                        </RowsCell>
                      ))}
                    <RowsCell key={`refund_key_${columns.length}`}>
                      <Button
                        disabled={true} // transactionList[idx].status !== 1}
                        color='info'
                        variant='contained'
                        sx={{ m: '.5rem .5rem' }}
                        onClick={() => {
                          setSelectedIndex(idx);
                          setDialogMessage(
                            `승인 시, 결제사에 환불 처리를 요청합니다.\n승인 처리 하시겠습니까?`
                          );
                          setOpenConfirm(1);
                        }}
                      >
                        {transactionList[idx].status === 1
                          ? '승인 대기'
                          : '승인'}
                      </Button>
                    </RowsCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </Box>
      </Paper>
      <SwConfirmDialog
        contents={dialogMessage}
        open={!!openConfirm}
        onClose={() => setOpenConfirm(0)}
        onConfirm={() => {
          setOpenConfirm(0);
          confirmRefund();
        }}
        confirm={1}
      />
    </>
  );
};
