import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

import SearchIcon from '@mui/icons-material/Search';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SelectTextField } from '../../styles/Styles';
import { SwAlert } from '../../commonComponent/views/SwAlert';

import { getServiceCouponHistory } from '../../../common/api/ApiContract';
import { KEY_SERVICE_COUPON_HISTORY } from '../../../common/key';
import { IServiceCoupon } from '../../../models/service_coupon';
import { commaFormat, timestamp2Localestring } from '../../../common/helper';

interface Props {
  selectedCoupon: any;
}

const searchItems = [
  {
    value: 'company',
    title: '기관명',
  },
  {
    value: 'portal',
    title: '포탈 아이디',
  },
  {
    value: 'manager',
    title: '담당자',
  },
];

const ColumsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  maxWidth: '10rem',
});

const RowsCellRight = styled(RowsCell)({
  borderRight: '1px solid rgba(224, 224, 224, 1)',
});

const columns = [
  '지급 대상 기관명',
  '서비스 이름',
  '지급 개수',
  '사용 개수',
  '지급 일시',
  '포탈 아이디',
  '지급 담당자',
];

export const PlanHistory: React.FC<Props> = ({ selectedCoupon }) => {
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [couponHistory, setCouponHistory] = useState<any>([]);
  const [couponId, setCouponId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: couponHistoryRefetch,
  } = useQuery(
    KEY_SERVICE_COUPON_HISTORY,
    () => {
      if (couponId.length > 0) {
        return getServiceCouponHistory(selectedCoupon);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('serviceCouponHistory: ', res);
        const newList =
          res &&
          res.serviceCoupon &&
          res.serviceCoupon.serviceCouponHistory &&
          res.serviceCoupon.serviceCouponHistory.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate);
            const newData = {
              type: item.type,
              idx: item.idx,
              couponId: item.couponId,
              targetId: item.targetId,
              targetName: item.targetName,
              amount: item.amount,
              creator: item.creator,
              createDate: newDate,
            };
            return newData;
          });
        setCouponHistory(newList);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (id: string) => {
    queryClient.prefetchQuery(KEY_SERVICE_COUPON_HISTORY, () => {
      getServiceCouponHistory(id);
    });
  };

  useEffect(() => {
    console.log('couponId: ', couponId);
    if (couponId.length > 0) {
      couponHistoryRefetch();
    }
  }, [couponId]);

  useEffect(() => {
    console.log('selectedCoupon: ', selectedCoupon);
    if (selectedCoupon !== null) {
      setCouponId(selectedCoupon.id);
    } else {
      setCouponHistory([]);
    }
  }, [selectedCoupon]);

  return (
    <>
      <Box sx={{ p: '0rem 0' }}>
        <Paper sx={{ m: '2rem 0', p: '2rem' }}>
          <TableContainer>
            <Box className='flex_between' sx={{ m: '0rem' }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                지급 내역 상세보기
              </Typography>
            </Box>
            <Divider sx={{ m: '0rem' }} />
            <Table>
              <TableBody>
                <TableRow key={0}>
                  <RowsCellRight key={0}>서비스 이름</RowsCellRight>
                  <RowsCell key={1}>{selectedCoupon?.couponName}</RowsCell>
                </TableRow>
                <TableRow key={1}>
                  <RowsCellRight key={0}>지급 개수</RowsCellRight>
                  <RowsCell key={1}>
                    {selectedCoupon
                      ? commaFormat(`${selectedCoupon?.couponCount}`)
                      : 0}
                  </RowsCell>
                </TableRow>
                <TableRow key={2}>
                  <RowsCellRight key={0}>사용개수</RowsCellRight>
                  <RowsCell key={1}>
                    {selectedCoupon
                      ? commaFormat(`${selectedCoupon?.couponUseCount}`)
                      : 0}
                  </RowsCell>
                </TableRow>
                {selectedCoupon && selectedCoupon.type === 'messaging' && (
                  <TableRow key={3}>
                    <RowsCellRight key={0}>환불개수</RowsCellRight>
                    <RowsCell key={1}>
                      {selectedCoupon
                        ? commaFormat(`${selectedCoupon?.couponRefundCount}`)
                        : 0}
                    </RowsCell>
                  </TableRow>
                )}
                {couponHistory?.map((row: any, idx: number) => (
                  <TableRow key={idx + 4}>
                    <RowsCellRight key={0}>
                      {row.type === 'community'
                        ? `사용 커뮤니티 ${idx + 1}`
                        : row.type === 'challenge'
                        ? `사용 챌린지 ${idx + 1}`
                        : row.type === 'program'
                        ? `사용 프로그램 ${idx + 1}`
                        : row.type === 'messaging'
                        ? `사용 메세지 ${idx + 1}`
                        : `사용 메세지 ${idx + 1}`}
                    </RowsCellRight>
                    <RowsCell key={1}>
                      {row.type !== 'messaging'
                        ? `${row.targetName} (${row.targetId})`
                        : `${row.targetName} : ${commaFormat(
                            `${row.amount}`
                          )} (${row.targetId})`}
                    </RowsCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};
