import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { MultilineTextField } from '../../styles/Styles';
import {
  TOOLTIP_CHALLEGE_POST_ADVERTISER,
  TOOLTIP_FONT_SIZE,
  checkUrl,
  getAddUrl,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  ButtonBox,
  ChallengeTypeBox,
  ChallengeTypeTitle,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ChallengeStyles';
import {
  IGuideTool,
  IChallengePost,
  IChallengeCreate,
  DefaultChallengeCreate,
} from '../../../models/challenge';
import {
  getChallengeAdvertisementList,
  getChallengeAdvertisementDetail,
  registerChallengeAdvertisement,
  deleteChallengeAdvertisement,
  deleteChallengeAdvertisementByIndex,
} from '../../../common/api/ApiChallenge';
import {
  KEY_CHALLENGE_ADVERTISEMENT_LIST,
  KEY_CHALLENGE_ADVERTISEMENT_DETAIL,
} from '../../../common/key';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';

const columns = ['번호', '내용', '광고주', '광고 저장일시', '삭제'];
const repeatItem = ['one', 'custom', 'daily'];

interface Props {
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
}

export const ChallengePost: React.FC<Props> = ({
  challengeInfo,
  setChallengeInfo,
}) => {
  const [requestList, setRequestList] = useState<any>([]);
  const [advertisementList, setAdvertisementList] = useState<any>([]);
  const [advertiserList, setAdvertiserList] = useState<any>([]);
  const [advertisement, setAdvertisement] = useState<any>();
  const [advertisementId, setAdvertisementId] = useState<string>('');
  const [advertiser, setAdvertiser] = useState<any>(0);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [challengeId, setChallengeId] = useState<string>('');
  const [useChallengePost, setUseChallengePost] = useState<number>(0);
  const [content, setContent] = useState<string>('');
  const [adImg, setAdImg] = useState<string>('');
  const [advertiseImage, setAdvertiseImage] = useState<string>('');
  const [advertiseImageFile, setAdvertiseImageFile] = useState<any>(null);
  const [advertiseImageUrl, setAdvertiseImageUrl] = useState<any>();
  const [actionText, setActionText] = useState<string>('');
  const [actionUrl, setActionUrl] = useState<string>('');
  const [actionClickCount, setActionClickCount] = useState<number>(0);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [repeatType, setRepeatType] = useState<string>('one');
  const [repeatData, setRepeatData] = useState<any>();
  const [startDates, setStartDates] = useState<any[]>([]);
  const [endDates, setEndDates] = useState<any[]>([]);
  const [deletedDate, setDeletedDate] = useState<any>();
  const [degreeDay, setDegreeDay] = useState<any>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const resetAdvertisement = () => {
    setAdvertiser(0);
    setContent('');
    setAdvertiseImageFile(null);
    setAdvertiseImage('');
    setActionText('');
    setActionUrl('');
    setStartDates([]);
    setEndDates([]);
    setDegreeDay('');
    setRepeatType('one');
    setActionClickCount(0);
    setAdvertisementId('');
  };

  const {
    data,
    isLoading,
    refetch: challengeAdvertisementRefetch,
  } = useQuery(
    KEY_CHALLENGE_ADVERTISEMENT_LIST,
    () => {
      console.log('challengeId: ', challengeId);
      if (challengeId.length > 0) {
        const newData: IChallengePost = {
          challengeId: challengeId,
        };
        return getChallengeAdvertisementList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res !== null) {
          const newAdList = res.advertisement.advertise.map(
            (item: any, idx: number) => {
              const newDate =
                item.createTime !== null
                  ? timestamp2Localestring(item.createTime, 1000)
                  : '-';
              const newAd = {
                sequence: idx + 1,
                idx: item.idx,
                title: item.content,
                advertiser:
                  item.advertiserName !== null ? item.advertiserName : 'x',
                createTime: newDate,
              };
              return newAd;
            }
          );

          setRequestList(newAdList);
          setAdvertisementList(res.advertisement.advertise);
          setAdvertiserList(res.advertisement.advertiser);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: challengeAdvertisementDetailRefetch } = useQuery(
    KEY_CHALLENGE_ADVERTISEMENT_DETAIL,
    () => {
      console.log('challengeId: ', challengeId);
      if (challengeId.length > 0) {
        const newData: IChallengePost = {
          challengeId: advertisement.challengeId,
          advertisementId: advertisement.advertisementId,
        };
        return getChallengeAdvertisementDetail(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail res :', res);
        if (res !== null) {
          const ad = res.advertisementDetail;
          const newAdvertiser = advertiserList
            .map((item: any, i: number) => {
              if (item.name === advertisement.advertiserName) return i;
              return -1;
            })
            .filter((item: any) => item !== -1);
          const newEndDates = ad.endDates
            .split(',')
            .map((item: any) => {
              var newDate = item.split('-')[1];
              newDate = newDate.length > 10 ? newDate.substr(0, 10) : newDate;
              return new Date(newDate * 1000);
            })
            .filter((item: any, i: any) => i > 0);
          setAdvertiser(newAdvertiser.length > 0 ? newAdvertiser[0] + 1 : 0);
          setContent(ad.content);
          setAdvertiseImageFile(null);
          setAdImg(ad.img);
          setAdvertiseImage(`${ad.imageUrlPrefix}${ad.img}`);
          setActionText(ad.linkContent);
          setActionUrl(ad.link);
          setStartDates([new Date(ad.startDate * 1000)]);
          setEndDates(newEndDates);
          setDegreeDay(ad.adPeriod !== null ? ad.adPeriod : 0);
          setRepeatType(repeatItem[ad.repeatType]);
          setActionClickCount(ad.clickCount);
          setRepeatData(ad.endDates);
          setAdvertisementId(ad.advertisementId);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerAdvertisementMutation,
    isError: isRegisterMutateError,
  } = useMutation(registerChallengeAdvertisement, {
    onSuccess: () => {
      setOpenSnakerDialog(true);
      setDialogMessage('광고가 등록되었습니다.');
      challengeAdvertisementRefetch();
      resetAdvertisement();
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '광고를 등록하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const registerAdvertisement = (newImageUrl: string) => {
    const currentDate = new Date();
    const newRepeatType = repeatItem
      .map((item: any, idx: number) => {
        if (item === repeatType) return idx;
        return -1;
      })
      .filter((item: any) => item !== -1);
    const newAdvertiser = advertiserList[advertiser - 1];
    const newStartDates = startDates.map(item => item.getTime() / 1000);
    var newEndDates = endDates.map(item => item.getTime() / 1000);
    if (repeatType !== 'custom') {
      newEndDates = [newStartDates[0], ...newEndDates].sort();
    } else {
      let i = 1;
      const oneDay = 86400;
      const repeatCount =
        Math.floor(
          (new Date(newEndDates[0]).getTime() -
            new Date(newStartDates[0]).getTime()) /
            (oneDay * Number(degreeDay))
        ) + 1;
      const newRangeDate = [newStartDates[0]];
      do {
        if (!repeatCount) {
          break;
        }
        newRangeDate.push(
          new Date(newStartDates[0] + oneDay * i * degreeDay).getTime()
        );
        i += 1;
      } while (i < repeatCount);
      newEndDates = newRangeDate.sort();
    }
    const newUrl = [
      advertiseImageFile !== null ? newImageUrl : adImg.length > 0 ? adImg : '',
    ];
    const newData: IChallengePost = {
      challengeId: challengeId,
      advertisementId: advertisementId,
      id: newAdvertiser.id,
      name: newAdvertiser.name,
      profile: newAdvertiser.profile,
      content: content,
      img: JSON.stringify(newUrl),
      actionButtonText: actionText,
      actionButtonUrl: checkUrl(getAddUrl(actionUrl)),
      repeatType: newRepeatType.length > 0 ? newRepeatType[0] : 0,
      startDate: newEndDates[0],
      endDate: newEndDates[newEndDates.length - 1],
      endDates: newEndDates,
      period: degreeDay,
      createTime: currentDate.getTime() / 1000,
    };

    console.log('ChallengePost : ', newData);
    registerAdvertisementMutation(newData);
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setAdvertiseImageUrl(res.uploadUrl.file.path);
        console.log('uploadFile uploadUrl:', res.uploadUrl.file.path);
        registerAdvertisement(res.uploadUrl.file.path);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const checkAdvertisement = () => {
    console.log('advertiseImageFile :', advertiseImageFile);
    console.log('advertiseImage :', advertiseImage);
    if (advertiseImageFile === null) {
      registerAdvertisement('');
    } else {
      const uploadfile: IUploadFile = {
        type: 'advertisement',
        file: advertiseImageFile,
      };
      uploadFileMutate(uploadfile);
    }
  };

  const { mutate: deleteAdvertisementMutation, isError: isDeleteMutateError } =
    useMutation(deleteChallengeAdvertisement, {
      onSuccess: () => {
        setOpenSnakerDialog(true);
        setDialogMessage('광고가 삭제되었습니다.');
        challengeAdvertisementRefetch();
        resetAdvertisement();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '광고를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: deleteAdvertisementByIndexMutation,
    isError: isDeleteByIndexMutateError,
  } = useMutation(deleteChallengeAdvertisementByIndex, {
    onSuccess: () => {
      setOpenSnakerDialog(true);
      setDialogMessage('광고가 삭제되었습니다.');
      challengeAdvertisementRefetch();
      resetAdvertisement();
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '광고를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteAdvertisement = (idx: number) => {
    const newAd = advertisementList[idx];
    const newData: IChallengePost = {
      advertisementId: newAd.advertisementId,
      challengeId: challengeId,
    };
    console.log('deleteAdvertisementMutation :', newData);
    deleteAdvertisementMutation(newData);
  };

  const deleteAdvertisementByIndex = (idx: number) => {
    const newAd = advertisementList[idx];
    const newData: IChallengePost = {
      idx: newAd.idx,
      challengeId: challengeId,
    };
    console.log('deleteAdvertisementMutation :', newData);
    deleteAdvertisementMutation(newData);
  };

  const onChangeRepeatType = (evt: any) => {
    const newValue = evt.target.value;
    setRepeatType(newValue);
  };

  const clickRow = (idx: number) => {
    console.log('click row :', idx);
    const ad = advertisementList[idx];
    setAdvertisement(ad);
  };

  useEffect(() => {
    console.log('deletedDate :', new Date(deletedDate).getTime() / 1000);
  }, [deletedDate]);

  useEffect(() => {
    console.log('advertisement :', advertisement);
    challengeAdvertisementDetailRefetch();
  }, [advertisement]);

  useEffect(() => {
    console.log('startDates :', startDates);
  }, [startDates]);

  useEffect(() => {
    console.log('advertisementList: ', advertisementList);
  }, [advertisementList]);

  useEffect(() => {
    challengeAdvertisementRefetch();
  }, [challengeId]);

  useEffect(() => {
    if (challengeInfo) {
      console.log('ChallengeOpenRegion challengeInfo :', challengeInfo);
      setChallengeId(challengeInfo.challengeId);
      setImageUrlPrefix(challengeInfo.imageUrlPrefix);
    }
  }, [challengeInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Paper>
        <Typography sx={{ m: '1rem 0 ' }}>챌린지 게시판 광고</Typography>
        <TableContainer
          sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()} onClick={() => clickRow(idx)}>
                  <TableCell>{row.sequence}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.advertiser}</TableCell>
                  <TableCell>{row.createTime}</TableCell>
                  <TableCell sx={{ p: '.1rem .6rem' }}>
                    <Button
                      color='info'
                      variant='contained'
                      onClick={() => deleteAdvertisement(idx)}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Divider />
      <Box sx={{ p: '0rem 0' }}>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>광고주</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_CHALLEGE_POST_ADVERTISER}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <TextField
            select
            sx={{ width: '15rem' }}
            value={advertiser}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setAdvertiser(Number(event.target.value));
            }}
          >
            <MenuItem key={0} value={0}>
              광고주를 선택하세요
            </MenuItem>
            {advertiserList.map((item: any, idx: number) => (
              <MenuItem key={idx + 1} value={idx + 1}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>내용</Typography>
          <TextField
            placeholder='내용입력'
            fullWidth
            value={content}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setContent(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>이미지</Typography>
          <SwFileSetting
            setImage={setAdvertiseImage}
            image={advertiseImage}
            setFile={setAdvertiseImageFile}
            file={advertiseImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>액션버튼</Typography>
          <Typography className='title'>문구내용</Typography>
          <TextField
            placeholder='내용입력'
            fullWidth
            value={actionText}
            onChange={evt => {
              setActionText(evt.target.value);
            }}
          />
          <Typography className='title'>링크(URL)</Typography>
          <TextField
            placeholder='링크입력'
            fullWidth
            value={actionUrl}
            onChange={evt => {
              setActionUrl(evt.target.value);
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>광고 시작일</Typography>
          <Box className='flex_start'>
            <Box>
              <SwDatesPickerWithScroll
                dates={startDates}
                setDates={setStartDates}
                showButton={false}
              />
            </Box>
          </Box>
          {repeatType === 'custom' && (
            <Box className='flex_start' sx={{ mt: '.5rem' }}>
              <Typography className='title'>반복 종료일을 </Typography>
              <SwDatesPickerWithScroll
                dates={endDates}
                setDates={setEndDates}
                showButton={false}
              />
              <Typography className='title'>까지</Typography>
              <TextField
                sx={{
                  display: 'flex',
                  width: '4rem',
                  justifyContent: 'center',
                }}
                value={degreeDay}
                onChange={evt => {
                  setDegreeDay(evt.target.value);
                }}
              />
              <Typography className='title'>일 간격으로 반복</Typography>
            </Box>
          )}
          {repeatType === 'daily' && (
            <Box>
              <Typography className='title'>
                시작일 포함한 광고날짜 지정{' '}
              </Typography>
              <SwDatesPickerWithScroll
                dates={endDates}
                setDates={setEndDates}
                showButton={true}
                setDeletedDate={setDeletedDate}
              />
            </Box>
          )}
          <div className='flex_center'>
            <FormGroup sx={{ flexDirection: 'row' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value='one'
                    checked={repeatType === 'one'}
                    onChange={evt => onChangeRepeatType(evt)}
                  />
                }
                label='반복없음'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value='custom'
                    checked={repeatType === 'custom'}
                    onChange={evt => onChangeRepeatType(evt)}
                  />
                }
                label='반복설정'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value='daily'
                    checked={repeatType === 'daily'}
                    onChange={evt => onChangeRepeatType(evt)}
                  />
                }
                label='반복할 날짜 지정'
              />
            </FormGroup>
          </div>
          <Divider />
        </Box>
        <Typography className='title'>액션버튼 클릭 수</Typography>
        <TextField disabled value={actionClickCount} />
        <Divider />
      </Box>
      <Box className='flex_between' sx={{ mt: '0rem' }}>
        <Button variant='outlined' onClick={resetAdvertisement}>
          신규 작성
        </Button>
        <Button variant='contained' onClick={checkAdvertisement}>
          정보 저장
        </Button>
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Paper>
  );
};
