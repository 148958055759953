import {
  Box,
  Button,
  Collapse,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SwBarChart } from '../../commonComponent/chart/SwBarChart';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import {
  getUserStepList,
  downloadUserStepList,
  downloadUserTimelineList,
  downloadUserTimelineListByAmdCode,
  downloadUserStepListByAmdCode,
} from '../../../common/api/ApiUser';
import { KEY_USER_STEP_LIST } from '../../../common/key';
import { elapsedTime, timestamp2Localestring } from '../../../common/helper';
import { SwSwitch } from '../../styles/Styles';
import { useSession } from '../../../hooks/session';

interface Props {}

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.9rem .6rem',
});
const PaginationBox = styled(Box)({
  padding: '.6rem',
  display: 'flex',
  justifyContent: 'center',
});

const columns = ['날짜', '시간', '걸음수', '동기화 시간'];

export const WalkPiece: React.FC<Props> = () => {
  const location = useLocation();
  const { loginVal } = useSession();
  const [userData, setUserData] = useState<any>(location.state);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [ascendOrder, setAscendOrder] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };
  const [stepforChart, setStepforChart] = useState<any>([]);

  const {
    data,
    isLoading,
    refetch: stepRefetch,
  } = useQuery(
    KEY_USER_STEP_LIST,
    () => {
      if (userData.id !== '') {
        return getUserStepList(
          userData.id,
          rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          rangeDate[1] === null ? 0 : rangeDate[1].getTime()
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          console.log('res step: ', res.userStep);
          let newSteps =
            res.userStep &&
            res.userStep.map((step: any, idx: number) => {
              const date = timestamp2Localestring(step.stime * 1000).split(
                ' '
              )[0];
              const endTime = timestamp2Localestring(step.etime * 1000).split(
                ' '
              )[1];
              const startTime = timestamp2Localestring(step.stime * 1000).split(
                ' '
              )[1];
              const diff = elapsedTime(step.stime, step.etime, 1000);
              const newStep = step.steps;
              return {
                steps: newStep,
                time: `${date} ${startTime}~${endTime} (${diff})`,
                timestamp: step.timestamp,
              };
            });
          // .reverse();
          if (!ascendOrder) newSteps = newSteps.reverse();
          console.log('res reversed step: ', newSteps);
          setStepforChart(newSteps);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const onChangeOrder = () => {
    setAscendOrder(!ascendOrder);
    stepRefetch();
  };

  useEffect(() => {
    if (userData.id !== '') stepRefetch();
  }, [userData]);

  useEffect(() => {
    if (location.state) {
      setUserData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    stepRefetch();
  }, [rangeDate]);

  const onDownloadFile = () => {
    downloadUserStepList(
      userData.id,
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime()
    );
  };

  const onDownloadTimelineFile = () => {
    downloadUserTimelineList(
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime()
    );
  };

  const onDownloadStepFileByAmdCode = () => {
    downloadUserStepListByAmdCode(
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
      28
    );
  };

  const onDownloadTimelineFileByAmdCode = () => {
    downloadUserTimelineListByAmdCode(
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
      28
    );
  };

  return (
    <>
      <Box
        className='flex_center'
        sx={{ justifyContent: 'flex-end', marginTop: '-1.4rem' }}
      >
        <Button
          color='info'
          variant='outlined'
          sx={{ mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadFile}
        >
          데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button>
        {/* <Button
          color='info'
          variant='outlined'
          sx={{ mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadTimelineFile}
        >
          타임라인 데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button> */}
        {/* <Button
          color='info'
          variant='outlined'
          sx={{ mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadStepFileByAmdCode}
        >
          인천광역시 사용자 걸음 데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button> */}
        {/* <Button
          color='info'
          variant='outlined'
          sx={{ mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadTimelineFileByAmdCode}
        >
          인천광역시 사용자 타임라인 데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button> */}
      </Box>
      <div
        style={{
          width: '90vw',
          height: '40vh',
          minWidth: '800px',
          marginTop: '1.5rem',
        }}
      >
        <SwBarChart
          data={stepforChart.map((step: any, idx: number) => {
            const newSteps = step.steps;
            return {
              steps: newSteps,
              time: step.time,
            };
          })}
        />
        <Paper sx={{ p: '0 1.5rem 1.5rem', m: '1.4rem 0' }}>
          <Box
            sx={{
              p: '1rem .5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{ p: '1.2rem .5rem', color: '#9C9C9C' }}
              className='flex_center'
            >
              <ErrorOutlineIcon sx={{ mr: '.5rem', fontSize: '1.2rem' }} />
              <Typography sx={{ fontSize: '.75rem' }}>
                걸음 조각이란 서버 업데이트 시간을 기준으로 걸음 수 데이터를
                표현한 것입니다.
              </Typography>
            </Box>
            <Box className='flex_center' sx={{ mr: '5rem' }}>
              <Collapse orientation='horizontal' in={openFilter}>
                <SwDateRangePicker
                  rangeDate={rangeDate}
                  setRangeDate={setRangeDate}
                />
              </Collapse>
              <Button
                color='info'
                variant='outlined'
                sx={{ ml: '1rem', mr: '1rem' }}
                onClick={() => setOpenFilter(!openFilter)}
              >
                <FilterAltOutlinedIcon />
                필터
                <ArrowBackIosNewOutlinedIcon
                  sx={{ fontSize: '1rem', ml: '.5rem' }}
                />
              </Button>
              <Box className='flex_end'>
                <Typography sx={{ mr: '.4rem' }}>최신순</Typography>
                <SwSwitch
                  defaultChecked={true}
                  value={ascendOrder}
                  onChange={() => onChangeOrder()}
                />
              </Box>
            </Box>
          </Box>
          <Table sx={{ maxWidth: 'max-content' }}>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell
                    key={idx.toString()}
                    sx={{ fontSize: '.88rem', fontWeight: 400, p: '.5rem' }}
                    size='small'
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stepforChart.length > 0 ? (
                stepforChart?.map(
                  (step: any, idx: number) =>
                    rowsPerPage * (page - 1) <= idx &&
                    rowsPerPage * page > idx && (
                      <TableRow key={idx.toString()}>
                        <RowsCell>{step.time.split(' ')[0]}</RowsCell>
                        <RowsCell>{`${step.time.split(' ')[1]} ${
                          step.time.split(' ')[2]
                        }`}</RowsCell>
                        <RowsCell>{step.steps}</RowsCell>
                        <RowsCell>
                          {/* {step.timestamp} */}
                          {step.timestamp.split('T').join(' ').slice(0, 19)}
                        </RowsCell>
                      </TableRow>
                    )
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <PaginationBox>
            <Pagination
              color='primary'
              variant='outlined'
              siblingCount={3}
              page={page}
              onChange={handleChangePage}
              count={Math.ceil(stepforChart.length / rowsPerPage)}
            />
          </PaginationBox>
        </Paper>
      </div>
    </>
  );
};
