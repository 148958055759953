import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { NoneBorderChip } from '../../styles/Styles';
import { SwSearchTemplateCode } from './SwSearchTemplateCode';
import { SwSelectTemplate } from './SwSelectTemplate';
import { SwConnectMissionType } from './SwConnectMissionType';
import {
  KEY_WORD_LIFESTYLE,
  KEY_WORD_QUIZ,
  KEY_WORD_QUIZ_STRING,
  KEY_WORD_SURVEY,
  KEY_WORD_SURVEY_STRING,
} from '../../../common/key';

const MissionBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

interface Props {
  relatedMissions: any[];
  changeHandler: (mission: any) => void;
}

export const SwConnectMission: React.FC<Props> = ({
  relatedMissions,
  changeHandler,
}) => {
  const searchRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [selectTarget, setSelectTarget] = useState<any>();
  const [categoryType, setCategoryType] = useState<any>(1);
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [openMissionType, setOpenMissionType] = useState<boolean>(false);
  const [surveyMissions, setSurveyMissions] = useState<any[]>([]);
  const [quizMissions, setQuizMissions] = useState<any[]>([]);
  const [lifeStyleMissions, setLifeStyleMissions] = useState<any[]>([]);

  const searchRelatedContent = () => {
    const newSearchWord = searchRef.current.value;
    setOpenMissionType(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchRelatedContent();
    }
  };

  const deleteMission = (missionList: any[], idx: number) => {
    const newData = missionList.filter((mission: any, i: number) => idx !== i);
    return newData;
  };

  const deleteMissionHandler = (idx: number, type: string) => {
    let newTarget: any;
    if (type === KEY_WORD_SURVEY) {
      const newMissions = deleteMission(surveyMissions, idx);
      setSurveyMissions(newMissions);
      newTarget = {
        survey: newMissions,
        quiz: quizMissions,
        lifestyle: lifeStyleMissions,
      };
    } else if (type === KEY_WORD_LIFESTYLE) {
      const newMissions = deleteMission(lifeStyleMissions, idx);
      setLifeStyleMissions(newMissions);
      newTarget = {
        survey: surveyMissions,
        quiz: quizMissions,
        lifestyle: newMissions,
      };
    } else if (type === KEY_WORD_QUIZ) {
      const newMissions = deleteMission(quizMissions, idx);
      setQuizMissions(newMissions);
      newTarget = {
        survey: surveyMissions,
        quiz: newMissions,
        lifestyle: lifeStyleMissions,
      };
    }
    changeHandler(newTarget);
  };

  const deleteAllMissionHandler = (type: string) => {
    let newTarget: any;
    if (type === KEY_WORD_SURVEY) {
      setSurveyMissions([]);
      newTarget = {
        survey: [],
        quiz: quizMissions,
        lifestyle: lifeStyleMissions,
      };
    } else if (type === KEY_WORD_LIFESTYLE) {
      setLifeStyleMissions([]);
      newTarget = {
        survey: surveyMissions,
        quiz: quizMissions,
        lifestyle: [],
      };
    } else if (type === KEY_WORD_QUIZ) {
      setQuizMissions([]);
      newTarget = {
        survey: surveyMissions,
        quiz: [],
        lifestyle: lifeStyleMissions,
      };
    }
    changeHandler(newTarget);
  };

  const addMission = (missionList: any[], mission: any) => {
    const path = mission.menuPath.join('>');
    const newData = [
      ...missionList,
      {
        relatedContentId: mission.contentId,
        path: path,
        title: mission.title,
        type: mission.type,
      },
    ];
    return newData;
  };

  useEffect(() => {
    if (selectTarget !== undefined && selectTarget.length > 0) {
      const newSelectTarget = selectTarget[0];
      let newTarget: any;
      if (newSelectTarget.type === 3) {
        const newMissions = selectTarget.map((mission: any, idx: number) => {
          const path = mission.menuPath.join('>');
          const newData: any = {
            relatedContentId: mission.contentId,
            path: path,
            title: mission.title,
            type: mission.type,
          };
          return newData;
        });
        const newSurvey = [...surveyMissions, ...newMissions];
        newTarget = {
          survey: newSurvey,
          quiz: quizMissions,
          lifestyle: lifeStyleMissions,
        };
        setSurveyMissions(newSurvey);
      } else if (newSelectTarget.type === 4) {
        const newMissions = addMission(lifeStyleMissions, newSelectTarget);
        setLifeStyleMissions(newMissions);
        newTarget = {
          survey: surveyMissions,
          quiz: quizMissions,
          lifestyle: newMissions,
        };
      } else if (newSelectTarget.type === 5) {
        const newMissions = selectTarget.map((mission: any, idx: number) => {
          const path = mission.menuPath.join('>');
          const newData: any = {
            relatedContentId: mission.contentId,
            path: path,
            title: mission.title,
            type: mission.type,
          };
          return newData;
        });
        const newQuiz = [...quizMissions, ...newMissions];
        newTarget = {
          survey: surveyMissions,
          quiz: newQuiz,
          lifestyle: lifeStyleMissions,
        };
        setQuizMissions(newQuiz);
      }
      changeHandler(newTarget);
    }
  }, [selectTarget]);

  useEffect(() => {
    if (relatedMissions) {
      const newSurveyMissions: any[] = [];
      const newQuizMissions: any[] = [];
      const newLifeStyleMissions: any[] = [];
      relatedMissions.map((mission: any, idx: number) => {
        const newMission = {
          contentId: mission.contentId,
          relatedContentId: mission.relatedContentId,
          title: mission.title,
          path: mission.path,
          seq: mission.seq,
          type: mission.type,
        };
        if (mission.type === 3) {
          newSurveyMissions.push(newMission);
        } else if (mission.type === 4) {
          newLifeStyleMissions.push(newMission);
        } else if (mission.type === 5) {
          newQuizMissions.push(newMission);
        }
      });
      setSurveyMissions(newSurveyMissions);
      setLifeStyleMissions(newLifeStyleMissions);
      setQuizMissions(newQuizMissions);

      const newTarget = {
        survey: newSurveyMissions,
        quiz: newQuizMissions,
        lifestyle: newLifeStyleMissions,
      };
      changeHandler(newTarget);
    }
  }, [relatedMissions]);

  return (
    <>
      <Box>
        <Box>
          <Typography className='title'>연관 질의응답/생활습관 미션</Typography>
          <TextField
            placeholder='콘텐츠 제목으로 검색'
            sx={{ width: '20rem' }}
            inputRef={searchRef}
            onKeyDown={evt => {
              handleKeyDown(evt);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => {
                      searchRelatedContent();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {surveyMissions && surveyMissions.length > 0 && (
          <Paper
            sx={{
              backgroundColor: '#FCFCFC',
              p: '2rem 2rem 1rem',
              mb: '1rem',
            }}
          >
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              <Typography fontWeight={500}>설문</Typography>
              {surveyMissions &&
                surveyMissions.map((item: any, idx: number) => (
                  <MissionBox key={idx.toString()}>
                    <Typography>{`${item.path}>${item.title}`}</Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteMissionHandler(idx, KEY_WORD_SURVEY)}
                    />
                  </MissionBox>
                ))}
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                color='error'
                onClick={() => {
                  deleteAllMissionHandler(KEY_WORD_SURVEY);
                }}
              >
                <DeleteOutlineIcon />
                전체 삭제
              </Button>
            </Box>
          </Paper>
        )}
        {quizMissions && quizMissions.length > 0 && (
          <Paper
            sx={{
              backgroundColor: '#FCFCFC',
              p: '2rem 2rem 1rem',
              mb: '1rem',
            }}
          >
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              <Typography fontWeight={500}>퀴즈</Typography>
              {quizMissions &&
                quizMissions.map((item: any, idx: number) => (
                  <MissionBox key={idx.toString()}>
                    <Typography>
                      <Typography>{`${item.path}>${item.title}`}</Typography>
                    </Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteMissionHandler(idx, KEY_WORD_QUIZ)}
                    />
                  </MissionBox>
                ))}
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                color='error'
                onClick={() => {
                  deleteAllMissionHandler(KEY_WORD_QUIZ);
                }}
              >
                <DeleteOutlineIcon />
                전체 삭제
              </Button>
            </Box>
          </Paper>
        )}
        {lifeStyleMissions && lifeStyleMissions.length > 0 && (
          <Paper
            sx={{
              backgroundColor: '#FCFCFC',
              p: '2rem 2rem 1rem',
            }}
          >
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              <Typography fontWeight={500}>생활 습관</Typography>
              {lifeStyleMissions &&
                lifeStyleMissions.map((item: any, idx: number) => (
                  <MissionBox key={idx.toString()}>
                    <Typography>
                      <Typography>{`${item.path}>${item.title}`}</Typography>
                    </Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() =>
                        deleteMissionHandler(idx, KEY_WORD_LIFESTYLE)
                      }
                    />
                  </MissionBox>
                ))}
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                color='error'
                onClick={() => {
                  deleteAllMissionHandler(KEY_WORD_LIFESTYLE);
                }}
              >
                <DeleteOutlineIcon />
                전체 삭제
              </Button>
            </Box>
          </Paper>
        )}
      </Box>
      <SwConnectMissionType
        open={openMissionType}
        onClose={() => {
          setOpenMissionType(false);
        }}
        onSelected={(mission: any) => {
          mission === KEY_WORD_SURVEY_STRING
            ? setCategoryType(3)
            : mission === KEY_WORD_QUIZ_STRING
            ? setCategoryType(5)
            : setCategoryType(4);
          setOpenTemplate(true);
        }}
      />
      <SwSelectTemplate
        categoryType={categoryType}
        open={openTemplate}
        onClose={() => {
          setCategoryType(0);
          setOpenTemplate(false);
        }}
        setTarget={setSelectTarget}
      />
    </>
  );
};
