import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useResize } from '../../../hooks/resize';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import { KEY_APP_REVIEW_LIST, KEY_NOTICE_LIST } from '../../../common/key';
import {
  changeAppReview,
  getAppReviewList,
} from '../../../common/api/ApiAppReview';
import { AppReviewAdd } from './AppReviewAdd';

interface Props {}

const columns = [
  '유형',
  '고객명',
  '이름',
  '종류',
  '진행기간',
  '참여 인원수',
  '아이디 복사',
];

export const ReviewManagement: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [appReviewList, setAppReviewList] = useState<any>([]);
  const [selectAllowReview, setSelectAllowReview] = useState<any>();
  const [edit, setEdit] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAllowReviewDialog, setOpenAllowReviewDialog] =
    useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: appReviewRefetch,
  } = useQuery(
    KEY_APP_REVIEW_LIST,
    () => {
      const newData: any = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getAppReviewList(newData);
    },
    {
      onSuccess: res => {
        if (res != null) {
          setAppReviewList(res.appReview);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newSearchWord: string, newPage: number) => {
    queryClient.prefetchQuery(KEY_APP_REVIEW_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
      };
      return getAppReviewList(newData);
    });
  };

  const { mutate: changeAppReivewMutate, isError: isMutateError } = useMutation(
    changeAppReview,
    {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('요청이 중단되었습니다.');
        appReviewRefetch();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '요청 중단중 오류가 발생했습니다. 다시 시도해주시기 바랍니다.'
        );
        appReviewRefetch();
      },
    }
  );

  const checkStopAppReview = () => {
    const newTargets = appReviewList
      ?.filter((row: any, idx: number) => checkedButtons.includes(row.targetId))
      .map((row: any, idx: number) => {
        const newData: any = { type: row.type, targetId: row.targetId };
        return newData;
      });
    const newData: any = {
      allowReview: 0, // stop
      targets: newTargets,
    };
    changeAppReivewMutate(newData);
  };

  const confirmAllowReivew = () => {
    setOpenAllowReviewDialog(false);
    setEdit(true);
    setSelectAllowReview(null);
    appReviewRefetch();
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      appReviewList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.targetId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const searchAppReview = (key: string, name: string) => {
    setSearchKey(key);
    setSearchWord(name);
    prefetch(name, page);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchAppReview('all', searchWord);
    }
  };

  const copyId = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log('er>', error);
    }
  };

  useEffect(() => {
    appReviewRefetch();
  }, [page]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>리뷰 요청 관리</title>
      </Helmet>
      <Paper sx={{ m: '1rem 0', p: '1rem' }}>
        <Box className='flex_between'>
          <TextField
            placeholder='챌린지 또는 프로그램 이름, 아이디 검색'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => searchAppReview('all', searchWord)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <Button
              variant='contained'
              onClick={() => setOpenAllowReviewDialog(true)}
            >
              <AddIcon />
              추가하기
            </Button>
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={handleSelectAllClick}
                  checked={checkedAll}
                />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {appReviewList?.map((row: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    id={row.targetId}
                    onChange={e => {
                      changeHandler(e.currentTarget.checked, row.targetId);
                    }}
                    checked={checkedButtons.includes(row.targetId)}
                  />
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.creator}</TableCell>
                <TableCell>{row.targetName}</TableCell>
                <TableCell>{row.targetType}</TableCell>
                <TableCell>
                  {`${
                    timestamp2Localestring(row.attendStartTime, 1000).split(
                      ' '
                    )[0]
                  }~${
                    timestamp2Localestring(row.attendEndTime, 1000).split(
                      ' '
                    )[0]
                  }`}
                </TableCell>
                <TableCell>{row.attendCount}</TableCell>
                <TableCell>
                  {' '}
                  <Button
                    variant='outlined'
                    color='info'
                    onClick={() => copyId(row.targetId)}
                  >
                    복사
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {(appReviewList === null || appReviewList.length === 0) && (
              <TableRow>
                <TableCell colSpan={8}>검색결과 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={appReviewList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '1rem' }}>
        <Button variant='outlined' color='info' onClick={checkStopAppReview}>
          요청 중단
        </Button>
      </Box>
      <AppReviewAdd
        open={openAllowReviewDialog}
        onClose={() => {
          setOpenAllowReviewDialog(false);
          appReviewRefetch();
        }}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={false}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </div>
  );
};
