import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useQuery } from 'react-query';
import { SwSearchCommunityFilter } from '../../commonComponent/SwSearchCommunityFilter';
import { KEY_COMMUNITY_GROUP_DETAIL_LIST } from '../../../common/key';
import { getCommunityGroupDetailList } from '../../../common/api/ApiCommunity';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { IGroupBattleCommunity, IGroupFilter } from '../../../models/challenge';

interface Props {
  filters: IGroupFilter[];
  setFilters: (newFilters: IGroupFilter[]) => void;
  groupBattleCommunity: IGroupBattleCommunity[];
  setGroupBattleCommunity: Dispatch<
    React.SetStateAction<IGroupBattleCommunity[]>
  >;
}
const columns = ['타입', '이름', '멤버 수', '아이디'];

const GroupChoice: React.FC<Props> = ({
  filters,
  setFilters,
  groupBattleCommunity,
  setGroupBattleCommunity,
}) => {
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSearchCommunity, setOpenSearchCommunity] =
    useState<boolean>(false);
  const [groups, setGroups] = useState<any>([]);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const checkedAll = useMemo(() => {
    const filterIds = new Set(filters.map(item => item.targetId));
    const result = groups.every((item: any) => filterIds.has(item.groupId));
    return result;
  }, [filters, groups]);

  const {
    data,
    isLoading,
    refetch: communityGroupRefetch,
  } = useQuery(
    KEY_COMMUNITY_GROUP_DETAIL_LIST,
    () => {
      const communityId =
        groupBattleCommunity && groupBattleCommunity[0].targetId;
      if (communityId.length > 0)
        return getCommunityGroupDetailList(communityId);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        setGroups(res.group);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const changeValue = (
    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchWord(evt.target.value);
  };

  const connectingCommunity = () => {
    if (filters.length) {
      setDialogMessage('한 커뮤니티 내의 그룹끼리만 추가 가능합니다.');
      setOpenDialog(true);
      return;
    }
    setOpenSearchCommunity(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      connectingCommunity();
    }
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters([]);
    if (event.target.checked) {
      const newGroups = groups.map((group: any, idx: number) => ({
        filterType: 'group',
        targetId: group.groupId,
      }));
      setFilters(newGroups);
    }
  };

  const changeHandler = (checked: boolean, item: any) => {
    if (checked) {
      setFilters([
        ...filters,
        {
          filterType: 'group',
          targetId: item.groupId,
        },
      ]);
    } else {
      setFilters(filters.filter(prev => prev.targetId !== item.groupId));
    }
  };

  useEffect(() => {
    // 선택한 커뮤니티가 있으면
    if (groupBattleCommunity && groupBattleCommunity[0]) {
      communityGroupRefetch();
    }
  }, [groupBattleCommunity]);

  return (
    <>
      <Box>
        <Typography className='title'>커뮤니티 및 그룹 선택</Typography>
        <TextField
          value={searchWord}
          onChange={changeValue}
          onKeyDown={handleKeyDown}
          sx={{ width: '26rem', mr: '1em' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  sx={{ pr: 0 }}
                  onClick={() => {
                    connectingCommunity();
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width: '26rem' }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={() => {}}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={
            groupBattleCommunity ? groupBattleCommunity[0]?.communityName : ''
          }
        />
        {groupBattleCommunity && groupBattleCommunity[0] && (
          <TableContainer sx={{ mt: 2 }}>
            <Table stickyHeader>
              <TableHead sx={{ bgcolor: '#F9FAFC' }}>
                <TableRow>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      onChange={e => {
                        handleSelectAllClick(e);
                      }}
                      checked={checkedAll}
                    />
                  </TableCell>
                  {columns.map((column, idx) => (
                    <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {groups?.map((row: any, idx: number) => (
                  <TableRow key={idx.toString()}>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        id={row.groupId}
                        onChange={e => {
                          changeHandler(e.currentTarget.checked, row);
                        }}
                        checked={filters
                          .map(el => el.targetId)
                          .includes(row.groupId)}
                      />
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>group</TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.groupName}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>{row.count}</TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.groupId}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <SwSearchCommunityFilter
        title='커뮤니티 이름, 아이디 검색'
        searchKey='community'
        searchWord={searchWord} // 검색어
        communityFilter={true} // 커뮤니티로만 검색
        open={openSearchCommunity}
        onClose={() => {
          setOpenSearchCommunity(false);
        }}
        setTarget={setGroupBattleCommunity}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
    </>
  );
};

export default GroupChoice;
