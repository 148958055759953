import {
  Box,
  Button,
  Chip,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { StampTourForm } from './StampTourForm';
import { StamptourMap } from './StamptourMap';
import {
  getRegionCategory,
  generateUuidId,
  timestamp2Localestring,
  addHyphenToPhoneno,
} from '../../../common/helper';
import {
  IStamptour,
  IStamptourDetail,
  IStamptourInfo,
  DefaultStamptourInfo,
  DefaultStamptourDetail,
} from '../../../models/stamptour';
import {
  getStamptourRequestList,
  getStamptourRequestDetail,
  registerStamptour,
  changeStampApplyStatus,
} from '../../../common/api/ApiStamptour';
import {
  KEY_STAMPTOUR_REQUEST_LIST,
  KEY_STAMPTOUR_REQUEST_DETAIL,
} from '../../../common/key';
import { SwCreationRequestReject } from '../../commonComponent/SwCreationRequestReject';

const requestColumnlists = [
  '스탬프 코스 이름',
  '신청일자',
  '신청자',
  '연락처',
  '상태',
  '담당자',
];
const clickColumnlist = ['스탬프 코스 이름', '신청일자', '신청자', '담당자'];

interface Props {}

export const StampTourCreate: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const [commandType, setCommandType] = useState<number>(0); // 0: no-action, 1: save, 2:update, 3: delete
  const [gpsData, setGpsData] = useState<any>([]);
  const [mapReload, setMapReload] = useState(0);
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [selectRequest, setSelectRequest] = useState<any>([]);
  const [stamptourList, setStamptourList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [mapInfo, setMapInfo] = useState<any>();
  const [stamptourDetail, setStamptourDetail] = useState<IStamptourDetail>(
    DefaultStamptourDetail
  );
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [stamptourId, setStamptourId] = useState<string>('');
  const [stamptourName, setStamptourName] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [stamptourData, setStamptourData] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: stmaptourRequestRefetch,
  } = useQuery(
    KEY_STAMPTOUR_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getStamptourRequestList();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newStamptour =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newDate = timestamp2Localestring(item.createTime, 1000);
            const newData = [
              item.stampTourName,
              newDate,
              item.creator,
              item.creatorPhoneno !== null && item.creatorPhoneno !== ''
                ? addHyphenToPhoneno(item.creatorPhoneno)
                : '-',
              item.registerStatus === 2
                ? '접수요청'
                : item.registerStatus === 3
                ? '반려'
                : '-',
              item.editor,
            ];
            return newData;
          });
        console.log('newStamptour:', newStamptour);
        setRequestList(newStamptour);
        setStamptourList(res ? res.creationRequest : []);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: stamptourRequestDetailRefetch } = useQuery(
    KEY_STAMPTOUR_REQUEST_DETAIL,
    () => {
      console.log('stamptourId:', stamptourId);
      if (stamptourId.length > 0) return getStamptourRequestDetail(stamptourId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          const tmpRoute = res.creationRequestDetail.stamp.map((stamp: any) => {
            const newDesc: any[] = [];
            if (stamp.description === null) {
              newDesc.push({
                description:
                  stamp.stampDescription !== null ? stamp.stampDescription : '',
                image:
                  stamp.stampImgpathMain !== null ? stamp.stampImgpathMain : '',
                idx: 1,
              });
            }
            const temp = {
              id: stamp.stampId,
              name: stamp.stampName,
              lat: stamp.stampPointLat,
              lng: stamp.stampPointLng,
              radius: stamp.stampRadius,
              description:
                stamp.description !== null
                  ? stamp.description
                  : JSON.stringify(newDesc),
              image: stamp.stampImgpathMain,
              className: stamp.className,
              link: stamp.link,
              phoneNo: stamp.phoneNo,
              address: stamp.address,
              stampAddress: stamp.stampAddress,
            };
            return temp;
          });
          console.log('stamp :', tmpRoute);
          setGpsData(tmpRoute);

          const newCircleData = {
            lat: res.creationRequestDetail.stamptour.courseCenterPointY,
            lng: res.creationRequestDetail.stamptour.courseCenterPointX,
            radius: res.creationRequestDetail.stamptour.courseCenterRadius,
          };

          const newStamptourData = {
            gpsData: tmpRoute,
            circleData: newCircleData,
          };
          setStamptourData(newStamptourData);

          setStamptourDetail(res.creationRequestDetail);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newStamptourId: string) => {
    queryClient.prefetchQuery(KEY_STAMPTOUR_REQUEST_DETAIL, () =>
      getStamptourRequestDetail(newStamptourId)
    );
  };

  const {
    mutate: changeStampApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeStampApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage('스탬프 수정을 요청했습니다.');
      stmaptourRequestRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '스탬프 수정을 요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const onReload = () => {
    stmaptourRequestRefetch();
  };

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[0], Chosen[1], Chosen[2], Chosen[4]];
    // setIsSend(false);
    setChosenRequest([newChosen]);
    setStamptourId(stamptourList[i].stampTourId);
    prefetchDetail(stamptourList[i].stampTourId);
    setSelectedRow(i);
  };

  const onRejectMessage = (message: string) => {
    if (message.length > 0) {
      const newData = {
        stamptourId: stamptourId,
        status: 3, // 1-작성중 2-접수요청 3-반려 4-접수완료
        message: message,
      };
      changeStampApplyStatusMutation(newData);
    }
  };

  useEffect(() => {
    console.log('map info : ', mapInfo);
  }, [mapInfo]);

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      stmaptourRequestRefetch();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <>
      <Box>
        <Box sx={{ m: '1.5rem 0' }}>
          <Chip
            icon={<FiberNewOutlinedIcon />}
            label='확인'
            variant='outlined'
            color='primary'
            onClick={() => setCheckRequest(!checkRequest)}
          />
        </Box>
        <Collapse in={checkRequest}>
          <Paper sx={{ cursor: 'pointer', mb: '1rem' }}>
            <Box sx={{ m: '1rem 1rem' }}>
              <Typography className='title'>
                요청 {requestList?.length}개
              </Typography>
              <TableContainer sx={{ maxHeight: '27rem' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {requestColumnlists.map((column, idx) => (
                        <TableCell
                          key={idx.toString()}
                          sx={{ p: '.4rem .7rem !important' }}
                          className={clsx(
                            { request_head: false },
                            'request_tablehead'
                          )}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestList?.map((row: any, idx: number) => (
                      <TableRow
                        key={idx.toString()}
                        onClick={() => chooseRequest(idx)}
                        className={
                          selectedRow === idx ? 'selected_row' : 'whitecolor'
                        }
                      >
                        {row.map((rowItem: any, rowIdx: number) => (
                          <TableCell
                            key={rowIdx.toString()}
                            sx={{ p: '.6rem .7rem' }}
                            className={
                              selectedRow === idx && row[3] === '접수요청'
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row[3] === '접수요청'
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                          >
                            {row[rowIdx]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
          <RequestTable columns={clickColumnlist} rows={chosenRequest} />
        </Collapse>
      </Box>
      <StamptourMap
        stamptourData={stamptourData}
        mapInfo={mapInfo}
        setMapInfo={setMapInfo}
      />
      <StampTourForm
        commandType={commandType}
        setCommandType={setCommandType}
        mapInfo={mapInfo}
        stamptourDetail={stamptourDetail}
        setStamptourDetail={setStamptourDetail}
        onReload={onReload}
      />
      <Box className='flex_between' sx={{ m: '2rem 0' }}>
        <Button
          variant='outlined'
          color='error'
          onClick={() => window.location.reload()}
        >
          신규 작성
        </Button>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={() => setRejectOpen(true)}
          >
            수정 요청
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              setCommandType(1);
            }}
          >
            저장
          </Button>
        </Box>
      </Box>
      <SwCreationRequestReject
        title='스탬프'
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onRejectMessage={onRejectMessage}
      />
    </>
  );
};
