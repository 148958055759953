import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useSession, xapi } from '../../../hooks/session';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import {
  DraggablePaper,
  RowsCell,
  MultilineTextField,
} from '../../styles/Styles';
import {
  checkNaN,
  generateUuidId,
  inforDataTemplate,
  NO_SELECT_ADVERTISER,
  timestamp2Localestring,
  timestamp2string,
  TOOLTIP_FONT_SIZE,
  TOOLTIP_PROGRAM_REWARD_GIVE_TYPE,
  TOOLTIP_PROGRAM_REWARD_GOAL_POINT_IN_PROGRAM,
  TOOLTIP_PROGRAM_REWARD_GOAL_POINT_IN_REWARD,
  TOOLTIP_PROGRAM_REWARD_LOCATION,
  TOOLTIP_PROGRAM_REWARD_MAX_COUNT,
  TOOLTIP_PROGRAM_REWARD_RANGE,
  TOOLTIP_PROGRAM_REWARD_USE_TYPE,
} from '../../../common/helper';
import {
  deleteProgramReward,
  getProgramRewardList,
  registerProgramReward,
  updateProgramGoalPoint,
} from '../../../common/api/ApiProgram';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  KEY_CHALLENGE_REWARD_LIST,
  KEY_PROGRAM_REWARD_LIST,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NONE_QUESTION,
} from '../../../common/key';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwRewardApplyForm } from '../../commonComponent/SwRewardApplyForm';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { getFileSize } from '../../../common/helperProgram';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_UPLOAD_FILE_SIZE,
} from '../../commonComponent/program/SwProgramCommon';
import { RESULT_OK } from '../../../common/resultCode';
import { brandPointAdvertiserState } from '../../../common/atom';

const PointPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '8rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.2rem',
  boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
  borderBottom: '1px solid white',
  fontSize: '1rem',
});

const columns = [
  '순서',
  '리워드 이름',
  '수량',
  '목표 건강 점수',
  '지급 방법',
  '사용 방법',
  '상태',
  '삭제',
];

const guidetool: any = {
  title: '소제목',
  content: '안내내용',
  image: '',
  imageFile: null,
  uploadUrl: '',
};

const MAX_GUIDE_PAGE = 6;

const USE_BUTTON = '0/0';
const GIFTYCON = '0/1';
const COUPON = '3/0';
const BARCODE = '3/1';
const APPLY_FORM = '4/0';
const HEALTH_TICKET = '5/1';
const MEMORIAL_TICKET = '5/2';

interface Props {
  rewardGivetype: any;
  rewardUsetype: any;
  programInfo: any;
  setProgramInfo: Dispatch<React.SetStateAction<any>>;
  saveRewardCount: (count: number) => void;
  reloadProgram: (id: string) => void;
}

export const ProgramReward: React.FC<Props> = ({
  rewardGivetype,
  rewardUsetype,
  programInfo,
  setProgramInfo,
  saveRewardCount,
  reloadProgram,
}) => {
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const guideTitleRef = useRef<any>();
  const guideContentsRef = useRef<any>();
  const scrollRef = useRef<any>();
  const infoTitleRef = useRef<any>();
  const infoContentRef = useRef<any>();
  const { loginVal } = useSession();
  const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [goalPoint, setGoalPoint] = useState<number>(0);
  const [programGoalPoint, setProgramGoalPoint] = useState<number>(0);
  const [programTotalPoint, setProgramTotalPoint] = useState<number>(0);
  const [programId, setProgramId] = useState<string>('');
  const [templateCode, setTemplateCode] = useState(1);
  const [rewardId, setRewardId] = useState<string>('0');
  const [giveType, setGiveType] = useState<string>('2');
  const [useType, setUseType] = useState<string>('0/0');
  const [isDuplicate, setIsDuplicate] = useState<string>('0');
  const [rewardMaxCount, setRewardMaxCount] = useState<number>(0);
  const [address, setAddress] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [guideTitle, setGuideTitle] = useState<any>('');
  const [guideContent, setGuideContent] = useState<any>('');
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<any[]>([]);
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>('');
  const [formList, setFormList] = useState<any[]>([]);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [rewardImage, setRewardImage] = useState<string>('');
  const [rewardImageFile, setRewardImageFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [rewardLocation, setRewardLocation] = useState<string>('');
  const [rewardStatus, setRewardStatus] = useState<number>(1);
  const [rewardSequence, setRewardSequence] = useState<number>(1);
  const [rewardPointPerGet, setRewardPointPerGet] = useState<number>(0);
  const [rewardStampPerGet, setRewardStampPerGet] = useState<number>(0);
  const [uploadCouponFile, setUploadCouponFile] = useState<any>(null);
  const [uploadCouponFileName, setUploadCouponFileName] = useState<any>('');
  const [uploadCouponUrl, setUploadCouponUrl] = useState<any>('');
  const [couponLink, setCouponLink] = useState<any>('');
  const [couponCount, setCouponCount] = useState<any>(0);
  const [brandPointIndex, setBrandPointIndex] =
    useState<number>(NO_SELECT_ADVERTISER);
  const [applyformVersion, setApplyformVersion] = useState<number>(0);
  const [rewardValue, setRewardValue] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [disabledEdit, setDisabledEdit] = useState<boolean>(false);
  const [openPoint, setOpenPoint] = useState<boolean>(false);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [selectTabId, setSelectTabId] = useState<number>(-1);
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });
  const [infoData, setInfoData] = useState<any>(inforDataTemplate);
  const guideItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  var guideIndex = 0;

  const refreshProgramReward = () => {
    setRewardId(newId());
    setTitle('');
    setRewardImage('');
    setRewardImageFile(null);
    setGuideImage('');
    setGuideImageFile(null);
    setIsDuplicate('0');
    setSelectedRow(-1);
    setFormList(inforDataTemplate);
    setGiveType('2');
    setUseType('0/0');
    setRangeDate([null, null]);
    setGoalPoint(0);
    setRewardMaxCount(0);
    setRewardLocation('');
    setResultLatLng('');
    setApplyformVersion(0);
    setRewardValue(0);
  };

  const resetReward = () => {
    refreshProgramReward();

    setRequestList([]);
  };

  const {
    data,
    isLoading,
    refetch: programRewardRefetch,
  } = useQuery(
    KEY_PROGRAM_REWARD_LIST,
    () => {
      console.log('programId: ', programId);
      if (programId.length > 0) return getProgramRewardList(programId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('getProgramRewardList res :', res);
        if (res !== null) {
          const newRewardList = res.reward.map((item: any, idx: number) => {
            const newGiveType =
              rewardGivetype[item.rewardGiveType].rewardGivetypeDescription;
            const newUseType = rewardUsetype
              .filter(
                (useItem: any) =>
                  useItem.rewardUsetype === item.rewardUseType &&
                  useItem.rewardUsesubtype === item.rewardUsetypeSub
              )
              .map((useItem: any) => useItem.rewardUsetypeDescription);

            const newReward = {
              sequence: idx + 1,
              title: item.rewardTitle,
              count: item.rewardMaxCount,
              goal: item.rewardGoal !== null ? item.rewardGoal : 0,
              giveType: newGiveType,
              useType: newUseType,
              status: item.rewardStatus === 0 ? 'Close' : 'Open',
            };
            return newReward;
          });

          resetReward();

          setRequestList(newRewardList);
          setRewardList(res.reward);
          saveRewardCount(newRewardList.length);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerProgramRewardMutation,
    isError: isRewardrMutateError,
  } = useMutation(registerProgramReward, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('프로그램 리워드 정보가 저장되었습니다.');
        reloadProgram(programId);
        programRewardRefetch();
      } else {
        setOpenSnackbar(true);
        setAlertMessage(`리워드 정보 저장 오류 : ${res.resultCodeMsg}`);
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '프로그램 리워드 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteProgramRewardMutation,
    isError: isRewardrDeleteMutateError,
  } = useMutation(deleteProgramReward, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setAlertMessage('프로그램 리워드 정보가 삭제되었습니다.');
      reloadProgram(programId);
      programRewardRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '프로그램 리워드 정보를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: updateProgramGoalPointMutation,
    isError: isGoalPointMutateError,
  } = useMutation(updateProgramGoalPoint, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage('프로그램 이수증 발급 목표가 변경되었습니다.');
      reloadProgram(programId);
      programRewardRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '프로그램 이수증 발급 목표를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const ChangePageContents = () => {
    setGuideTitle(
      guideLists[guidePage - 1] ? guideLists[guidePage - 1].title : '소제목'
    );
    setGuideContent(
      guideLists[guidePage - 1] ? guideLists[guidePage - 1].content : '안내내용'
    );

    const newImage = guideLists[guidePage - 1]
      ? guideLists[guidePage - 1].image
      : '';
    setGuideImage(newImage);
  };

  function addGuideInfo() {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = guideLists;
      const newGuideList = guideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuideList,
        title: newTitle,
        content: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      return newGuideLists;
    }
    return [];
  }

  const onChangeRewardGuideView = (_event: any, newguide: number) => {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }
    setGuidePage(newguide);
    setGuideImage(guideLists[newguide - 1]?.image);
    setGuideImageFile(guideLists[newguide - 1]?.imageFile);
  };

  const addRewardGuideView = () => {
    if (guideLists.length === MAX_GUIDE_PAGE) {
      setAlertMessage('리워드 안내 내용은 최대 6까지 추가할 수 있습니다.');
      setAlertOpen(true);
      return;
    }

    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      const newGuide = [...newGuideLists, guidetool];
      setGuideLists(newGuide);
      setGuideImage('');
      setGuideImageFile(null);
      setGuidePage(guidePage + 1);
    }
  };

  const updateRewardGuideView = () => {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = [...guideLists];
      const newGuide = newGuideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        content: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      setGuideLists(newGuideLists);
    }
  };

  const removeRewardGuideView = () => {
    console.log(guidePage);
    ChangePageContents();
    const newGuide = guideLists.filter((_guide, idx) => idx !== guidePage - 1);
    // const newGuidePage = guidePage - 1 < 1 ? guidePage + 1 : guidePage - 1;
    setGuideLists(newGuide);
    newGuide.length < guidePage && setGuidePage(guidePage - 1);
    // setGuidePage(guidePage);
    if (newGuide.length === 0) {
      setGuideTitle('');
      setGuideContent('');
      setGuidePage(1);
    }
  };

  const removeReward = (idx: number) => {
    const newData = {
      programId: programId,
      rewardId: rewardList[idx].rewardId,
    };
    deleteProgramRewardMutation(newData);
  };

  const clickRow = (event: any, idx: number) => {
    setRewardSequence(idx + 1);

    if (event.target.classList.contains('MuiButton-root')) {
      return;
    }
    setSelectedRow(idx);
    setRewardId(rewardList[idx].rewardId);
    setGiveType(rewardList[idx].rewardGiveType);
    const newUseType = `${rewardList[idx].rewardUseType}/${rewardList[idx].rewardUsetypeSub}`;
    setUseType(newUseType);
    setIsDuplicate(rewardList[idx].rewardIsDuplicate);
    const newRangeDate = [
      timestamp2Localestring(rewardList[idx].rewardUseStime, 1000),
      timestamp2Localestring(rewardList[idx].rewardUseEtime, 1000),
    ];
    setRangeDate(newRangeDate);
    setRewardMaxCount(rewardList[idx].rewardMaxCount);
    setTitle(rewardList[idx].rewardTitle);
    setRewardImage(
      rewardList[idx].rewardImgpathMain?.length > 0
        ? rewardList[idx].rewardImgpathMain
        : ''
    );
    setRewardLocation(rewardList[idx].rewardAddress);
    setRewardPointPerGet(rewardList[idx].rewardPointPerGet);
    setRewardStampPerGet(rewardList[idx].rewardStampPerGet);
    setRewardStatus(rewardList[idx].rewardStatus);

    setCouponLink(rewardList[idx].rewardCouponLink);
    setUploadCouponFileName('');
    setUploadCouponFile(null);

    setGoalPoint(
      rewardList[idx].rewardGoal !== null ? rewardList[idx].rewardGoal : 0
    );

    setGuidePage(1);

    const newDescription =
      rewardList[idx].rewardDescription !== undefined &&
      rewardList[idx].rewardDescription !== null
        ? JSON.parse(rewardList[idx].rewardDescription)
        : [];
    console.log('newDescription :', newDescription);
    const newGuideList =
      newDescription.length > 0
        ? newDescription.map((item: any) => {
            const newGuide = {
              title:
                item.title !== undefined
                  ? item.title
                  : item.t !== undefined
                  ? item.t
                  : '',
              content:
                item.content !== undefined
                  ? item.content
                  : item.c !== undefined
                  ? item.c
                  : '',
              image:
                item.image !== undefined
                  ? item.image
                  : item.i !== undefined
                  ? item.i
                  : '',
              imageFile: null,
            };
            return newGuide;
          })
        : [];
    setGuideLists(newGuideList);

    const applyForm =
      rewardList[idx].rewardApplyform !== undefined &&
      rewardList[idx].rewardApplyform !== null
        ? JSON.parse(rewardList[idx].rewardApplyform)
        : [];
    const newApplyForm =
      newUseType === APPLY_FORM && applyForm.length > 0
        ? applyForm.map((item: any) => {
            const tmpOptins =
              item.options !== undefined && item.options.length > 0
                ? JSON.parse(item.options)
                : [];
            const newOptions = tmpOptins.map((opt: any, i: number) => {
              const d = { idx: i, content: opt.option_name };
              return d;
            });

            const newSubOptions = tmpOptins.map((opt: any, i: number) => {
              const d = applyForm.filter(
                (frm: any) => frm.questionId === opt.child_question_id
              );
              return d !== null && d.length > 0
                ? d[0].questionTitle
                : KEY_WORD_NONE_QUESTION;
            });
            const newData = {
              type: item.questionType,
              title: item.questionTitle,
              content: item.placehoder,
              options: newOptions,
              hasSubOption: item.hasSubOption,
              subOptions: newSubOptions !== null ? newSubOptions : [],
              parentTitle:
                item.parentTitle !== undefined && item.parentTitle !== null
                  ? item.parentTitle
                  : '',
              parentOptionIndex: -1,
            };
            return newData;
          })
        : inforDataTemplate;
    setInfoData(newApplyForm);
    setFormList(newApplyForm);
    setSelectTabId(-1);

    const newIdx = brandPointAdvertiserList
      .map((item: any, i: number) =>
        item.brandPointId === rewardList[idx].brandPointId ? i : -1
      )
      .filter(item => item !== -1);

    if (newIdx !== null && newIdx.length > 0) {
      setBrandPointIndex(newIdx[0]);
    } else {
      setBrandPointIndex(NO_SELECT_ADVERTISER);
    }
    setApplyformVersion(rewardList[idx].applyformVersion);
    setRewardValue(rewardList[idx].rewardValue);
  };

  const addProgramReward = (
    newRewarId: string,
    newMainImageUrl: string,
    newGuideList: any
  ) => {
    const newUseSTime =
      rangeDate[0] === null ? 0 : new Date(rangeDate[0]).getTime() / 1000;
    const newUseETime =
      rangeDate[1] === null ? 0 : new Date(rangeDate[1]).getTime() / 1000;
    const newMainImage =
      rewardImageFile !== null
        ? newMainImageUrl
        : rewardImage.length > 0
        ? rewardImage
        : '';
    const newCreator = programInfo.programCreator;
    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;
    const newDescription =
      newGuideList.length > 0
        ? newGuideList.map((item: any, idx: number) => {
            const newData = {
              idx: `${idx + 1}`.toString(),
              title: item.title,
              content: item.content,
              image: item.uploadUrl !== null ? item.uploadUrl : '',
            };
            return newData;
          })
        : [];
    const newUseType = useType.split('/');

    let applyForm = '';
    let applyFormOld = '';
    if (useType === APPLY_FORM) {
      const newFormOld = formList.map((item: any, idx: number) => {
        console.log('item:', item);
        const newOptions =
          item.options !== undefined &&
          item.options !== null &&
          item.options.length > 0
            ? item.options.map((opt: any) => opt.content)
            : null;
        const newData = {
          k: item.title,
          v: '',
          i: newOptions !== null ? newOptions.join(',') : item.content,
        };
        return newData;
      });
      applyFormOld = JSON.stringify(newFormOld);

      const newForm = formList.map((item: any, idx: number) => {
        const newOptions =
          item.options !== undefined &&
          item.options !== null &&
          item.options.length > 0
            ? item.options.map((opt: any) => opt.content)
            : null;
        const subOptions = item.subOptions.filter(
          (subOption: string) => subOption !== KEY_WORD_NEXT_QUESTION
        );
        const addTitle = newOptions !== null ? newOptions.join(',') : '';
        const newData =
          newOptions !== null
            ? {
                type: item.type,
                k: item.title,
                v: '',
                i: item.content,
                options: newOptions,
                hasSubOption: item.hasSubOption,
                subOption: subOptions,
              }
            : {
                type: item.type,
                k: item.title,
                v: '',
                i: item.content,
              };
        return newData;
      });
      applyForm = JSON.stringify(newForm);
    }

    const newBrandPointId =
      useType === '6/0'
        ? brandPointAdvertiserList[brandPointIndex].brandPointId
        : '';

    if (newMainImage === undefined || newMainImage.length === 0) {
      setAlertMessage('리워드 이미지를 선택해주세요');
      setAlertOpen(true);
      return;
    }

    const newData: any = {
      rewardId: newRewarId,
      programId: programId,
      rewardTitle: title,
      rewardSponsored: programInfo.programCreatorName,
      rewardMaxCount: Number(rewardMaxCount),
      rewardUseStime: newUseSTime,
      rewardUseEtime: newUseETime,
      rewardUseDay: 0,
      rewardStatus: rewardStatus,
      rewardUseType: Number(newUseType[0]),
      rewardImgpathMain: newMainImage,
      rewardImgpathSub: '',
      rewardDescription: JSON.stringify(newDescription),
      rewardPointPerGet: rewardPointPerGet,
      rewardStampPerGet: rewardStampPerGet,
      rewardAddress: address,
      rewardContact: '',
      rewardCouponLink: couponLink,
      rewardIsDuplicate: Number(isDuplicate),
      rewardGiveType: Number(giveType),
      rewardSummary: '',
      rewardLocationLat: newLa,
      rewardLocationLng: newLo,
      rewardCreator: programInfo.programCreator,
      rewardEditor: '',
      rewardUsetypeSub: Number(newUseType[1]),
      rewardApplyform: useType === APPLY_FORM ? applyForm : '',
      rewardGoal: goalPoint,
      brandPointId: newBrandPointId,
      applyformVersion: applyformVersion,
      rewardValue: rewardValue,
    };

    const newRewards: any = {
      programId: programId,
      goalPoint: programGoalPoint,
      rewards: [newData],
    };
    console.log('addReward newData: ', newRewards);
    registerProgramRewardMutation(newRewards);
  };

  const checkValid = () => {
    const newCreator = programInfo.creator;
    const newForm = formList.map((item: any, idx: number) => {
      const newOptions =
        item.options !== undefined &&
        item.options !== null &&
        item.options.length > 0
          ? item.options.map((opt: any) => opt.content)
          : null;
      const addTitle = newOptions !== null ? newOptions.join(',') : '';
      const newData =
        newOptions !== null
          ? {
              type: item.type,
              k: item.title,
              v: '',
              i: item.content,
              options: newOptions,
            }
          : {
              type: item.type,
              k: item.title,
              v: '',
              i: item.content,
            };
      return newData;
    });

    var val = 0;
    if (val === 0 && (rangeDate[0] === null || rangeDate[1] === null)) val = 1;
    if (val === 0 && Number(rewardMaxCount) === 0) val = 2;
    if (val === 0 && title.length === 0) val = 3;
    if (val === 0 && useType === APPLY_FORM && newForm.length === 0) val = 4;
    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '리워드 사용 기간을 지정해주세요.';
          break;
        case 2:
          message = '리워드 수량을 입력해주세요';
          break;
        case 3:
          message = '리워드 이름을 입력해주세요';
          break;
        case 4:
          message = '정보 입력양식을 입력해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    return val;
  };

  async function uploadMultipleFiles(newRewarId: string) {
    var newUploadUrl = '';
    if (rewardImageFile !== null) {
      const newUploadFile: any = await uploadFile('program', rewardImageFile);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newGuideList = await Promise.all(
      guideLists.map(async (item: any, idx: number) => {
        var imageUrl = null;
        var newUploadFile: any = null;
        if (item.imageFile !== null) {
          newUploadFile = await uploadFile('program', item.imageFile);
        }

        return {
          title: item.title,
          content: item.content,
          image:
            item.image === null || item.image === undefined ? '' : item.image,
          imageFile: item.imageFile,
          uploadUrl:
            item.imageFile !== null
              ? newUploadFile.data.uploadUrl.file.path
              : null,
        };
      })
    );

    addProgramReward(newRewarId, newUploadUrl, newGuideList);
  }

  const checkProgramReward = (newRewarId: string) => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }

    // Guide 마지막 페이지 수정사항 저장
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }

    if (
      rewardImageFile !== null &&
      getFileSize(rewardImageFile) >= MAX_UPLOAD_FILE_SIZE
    ) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return;
    }

    const newGuideImages = guideLists.filter(
      (item: any, idx: number) =>
        item.imageFile !== undefined &&
        item.imageFile !== null &&
        getFileSize(item.imageFile) >= MAX_UPLOAD_FILE_SIZE
    );
    if (newGuideImages.length > 0) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return;
    }

    uploadMultipleFiles(newRewarId);
  };

  const uploadCoupon = () => {
    if (uploadCouponFile !== null) {
      const uploadfile: IUploadFile = {
        type: 'program',
        file: uploadCouponFile,
      };
      console.log('program reward coupon uploadfile :', uploadfile);
      var formData = new FormData();
      formData.append('file', uploadCouponFile);

      xapi
        .post(
          `/admin/v2/apis/upload/program/${programId}/reward/${rewardId}/coupon`,
          formData
        )
        .then(res => {
          if (res.status !== 200) throw new Error('http 에러');
          console.log('Upload Coupon List :', res);
          setOpenSnackbar(true);
          setAlertMessage(
            `쿠폰 ${res.data.coupon.length}개가 등록되어 있습니다.`
          );
          setCouponCount(res.data.coupon.length);
          programRewardRefetch();
          return res.data;
        });
    }
  };

  const copyProgramReward = () => {
    const newRewardId = generateUuidId();
    setRewardId(newRewardId);
    checkProgramReward(newRewardId);
  };

  const registerCoupon = () => {
    console.log('registerCoupon !!!');
    uploadCoupon();
  };

  const resetGiveUseType = () => {
    if (rewardGivetype) {
      console.log('ProgramReward rewardGivetype :', rewardGivetype);
      setGiveType('2');
    }

    if (rewardUsetype) {
      console.log('ProgramReward rewardUsetype :', rewardUsetype);
      const useTypeDefault = rewardUsetype.filter(
        (item: any) => item.rewardUsetypeName === '직원 확인하기'
      );
      if (useTypeDefault.length > 0) {
        setUseType(
          `${useTypeDefault[0].rewardUsetype}/${useTypeDefault[0].rewardUsesubtype}`
        );
      }
    }
  };

  const clickInfoData = (i: number) => {
    setSelectTabId(i);
    infoTitleRef.current.value = infoData[i].title;
    infoContentRef.current.value = infoData[i].content;
  };

  const onChangeInfoData = () => {
    const newData = infoData.map((item: any, idx: number) => {
      const newInfo =
        idx === selectTabId
          ? {
              title: infoTitleRef.current.value,
              content: infoContentRef.current.value,
            }
          : item;
      return newInfo;
    });
    setInfoData(newData);
  };

  // Guide Drag & Drop
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: guideLists,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setGuideLists(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setGuideLists(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    guideItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    guideIndex = Number(evt.currentTarget.dataset.position);
    setGuidePage(guideIndex + 1);
    setListEvent({
      ...listEvent,
      clicked: newContain ? guideIndex : null,
    });
    setGuideTitle(guideLists[guideIndex].title);
    setGuideContent(guideLists[guideIndex].content);
    setGuideImage(guideLists[guideIndex].image);
    setGuideImageFile(guideLists[guideIndex].imageFile);
  };

  const changeProgramGoalPoint = (newGoalPoint: number) => {
    const newData: any = {
      programId: programId,
      goalPoint: newGoalPoint,
    };
    updateProgramGoalPointMutation(newData);
  };

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      setAddress(newAddress);
      setRewardLocation(newAddress);
    }
  }, [targetData]);

  useEffect(() => {
    console.log('reward formList:', formList);
  }, [formList]);

  useEffect(() => {
    setAddress(rewardLocation);
  }, [rewardLocation]);

  useEffect(() => {
    console.log('ProgramReward RewardList: ', rewardList);
  }, [rewardList]);

  useEffect(() => {
    updateRewardGuideView();
  }, [guideTitle, guideContent]);

  useEffect(() => {
    if (guideLists.length > 0) ChangePageContents();
  }, [guidePage, guideLists]);

  useEffect(() => {
    programRewardRefetch();
  }, [programId]);

  useEffect(() => {
    resetGiveUseType();
  }, [rewardGivetype, rewardUsetype]);

  useEffect(() => {
    if (programInfo) {
      console.log('ProgramReward programInfo :', programInfo);
      setProgramId(programInfo.programId);
      setTemplateCode(programInfo.type);
      setProgramGoalPoint(programInfo.goalPoint);
      setProgramTotalPoint(programInfo.totalPoint);
    }
    resetReward();
    resetGiveUseType();
  }, [programInfo]);

  return (
    <>
      <Paper sx={{ p: '2rem 1.5rem', mb: '1rem' }}>
        <Box className='flex_start'>
          <Typography sx={{ m: '1rem 0 ' }}>
            이수증 발급 목표 설정 <span style={{ color: '#F63454' }}>*</span>
          </Typography>
          <Tooltip
            title={
              <Typography fontSize={TOOLTIP_FONT_SIZE}>
                {TOOLTIP_PROGRAM_REWARD_GOAL_POINT_IN_PROGRAM}
              </Typography>
            }
            followCursor
          >
            <HelpOutlineIcon sx={{ color: '#999999' }} />
          </Tooltip>
        </Box>
        <Box className='flex_start'>
          <Box sx={{ height: '2rem', mb: '1rem' }}>
            <TextField
              sx={{
                marginRight: '1rem',
                '& .MuiFormHelperText-root': {
                  color:
                    programGoalPoint <= programTotalPoint
                      ? '#000000'
                      : '#F63454',
                },
              }}
              value={programGoalPoint}
              helperText={
                programGoalPoint > programTotalPoint
                  ? '최대 건강 점수보다 높습니다.'
                  : ''
              }
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                const newValue: number =
                  checkNaN(evt.target.value, programGoalPoint) <=
                  programTotalPoint
                    ? checkNaN(evt.target.value, programGoalPoint)
                    : programTotalPoint;
                setProgramGoalPoint(newValue);
                changeProgramGoalPoint(newValue);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>점</span>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ marginRight: '1rem', height: '2rem', m: '1rem, 0' }}>
            <Typography>/</Typography>
          </Box>
          <Box sx={{ height: '2rem', mb: '1rem' }}>
            <TextField
              value={programTotalPoint}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>점</span>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ p: '2rem 1.5rem' }}>
        <Paper>
          <Typography sx={{ m: '1rem 0 ' }}>리워드 목록</Typography>
          <TableContainer
            sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <TableCell
                      key={`reward_column_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {requestList?.map((row: any, idx: number) => (
                  <TableRow
                    key={`reward_reqeust_${idx}`}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                    onClick={event => clickRow(event, idx)}
                  >
                    <TableCell>{row.sequence}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.count}</TableCell>
                    <TableCell>
                      {row.goal <= programTotalPoint ? (
                        <>
                          <span>{row.goal}</span> / {programTotalPoint}
                        </>
                      ) : (
                        <>
                          <span style={{ color: '#F63454' }}>{row.goal}</span> /{' '}
                          {programTotalPoint}
                        </>
                      )}
                    </TableCell>
                    <TableCell>{row.giveType}</TableCell>
                    <TableCell>{row.useType}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell
                      className='removeButton'
                      sx={{ p: '.1rem .6rem' }}
                    >
                      <Button
                        color='info'
                        variant='contained'
                        onClick={() => {
                          setOpenMore(2);
                        }}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={{ p: '1.5rem 0' }}>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
          >
            <Box>
              <Typography className='title'>리워드 순서</Typography>
              <TextField
                sx={{ width: '8rem' }}
                type='number'
                value={rewardSequence}
              />
            </Box>
            <SwUuidCreate
              disabled={disabledEdit}
              title='리워드'
              id={rewardId}
              setId={setRewardId}
            />
          </Stack>
          <Divider />
          <Box>
            <Box className='flex_start'>
              <Typography sx={{ m: '1rem 0 ' }}>
                리워드 발급 목표 설정{' '}
                <span style={{ color: '#F63454' }}>*</span>
              </Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_PROGRAM_REWARD_GOAL_POINT_IN_REWARD}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <Box className='flex_start'>
              <Box sx={{ height: '2rem', mb: '1rem' }}>
                <TextField
                  sx={{
                    marginRight: '1rem',
                    '& .MuiFormHelperText-root': {
                      color:
                        goalPoint <= programTotalPoint ? '#000000' : '#F63454',
                    },
                  }}
                  value={goalPoint}
                  helperText={
                    goalPoint > programTotalPoint
                      ? '최대 건강 점수보다 높습니다.'
                      : ''
                  }
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setGoalPoint(
                      checkNaN(evt.target.value, goalPoint) <= programTotalPoint
                        ? checkNaN(evt.target.value, goalPoint)
                        : programTotalPoint
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>점</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ marginRight: '1rem', height: '2rem', m: '1rem, 0' }}>
                <Typography>/</Typography>
              </Box>
              <Box sx={{ height: '2rem', mb: '1rem' }}>
                <TextField
                  value={programTotalPoint}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>점</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 2rem 0' }}
              />
            }
          >
            <Box>
              <Box className='flex_start'>
                <Typography className='title'>지급 방법</Typography>
                <Tooltip
                  title={
                    <Typography fontSize={TOOLTIP_FONT_SIZE}>
                      {TOOLTIP_PROGRAM_REWARD_GIVE_TYPE}
                    </Typography>
                  }
                  followCursor
                >
                  <HelpOutlineIcon sx={{ color: '#999999' }} />
                </Tooltip>
              </Box>
              <TextField
                disabled={disabledEdit}
                select
                sx={{ width: '20rem' }}
                value={giveType}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setGiveType(evt.target.value)}
              >
                {rewardGivetype !== undefined &&
                  rewardGivetype
                    .filter(
                      (item: any) => item.rewardGivetypeName === 'DRAWING_END'
                    )
                    .map((item: any, idx: number) => (
                      <MenuItem
                        key={`givetype_${idx}`}
                        value={item.rewardGivetype}
                      >
                        {item.rewardGivetypeDescription}
                      </MenuItem>
                    ))}
              </TextField>
            </Box>
            <Box>
              <Box className='flex_start'>
                <Typography className='title'>사용 방법</Typography>
                <Tooltip
                  title={
                    <Typography fontSize={TOOLTIP_FONT_SIZE}>
                      {TOOLTIP_PROGRAM_REWARD_USE_TYPE}
                    </Typography>
                  }
                  followCursor
                >
                  <HelpOutlineIcon sx={{ color: '#999999' }} />
                </Tooltip>
              </Box>
              <TextField
                disabled={disabledEdit}
                select
                sx={{ width: '20rem' }}
                value={useType}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setUseType(evt.target.value)}
              >
                {rewardUsetype !== undefined &&
                  rewardUsetype
                    .filter(
                      (item: any) =>
                        item.rewardUsetype === 0 ||
                        item.rewardUsetype === 4 ||
                        item.rewardUsetype === 6
                    )
                    .map((item: any, idx: number) => (
                      <MenuItem
                        key={`usetype_${idx}`}
                        value={`${item.rewardUsetype}/${item.rewardUsesubtype}`}
                      >
                        {item.rewardUsetypeName}
                      </MenuItem>
                    ))}
              </TextField>
            </Box>
            {useType === '6/0' && (
              <Box sx={{ ml: '1rem' }}>
                <Typography className='title'>포인트 지급 브랜드</Typography>
                <TextField
                  select
                  sx={{ width: '10rem', mr: '.5rem' }}
                  value={brandPointIndex}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setBrandPointIndex(Number(event.target.value));
                  }}
                >
                  <MenuItem key='advertiser_99' value={NO_SELECT_ADVERTISER}>
                    선택
                  </MenuItem>
                  {brandPointAdvertiserList.map((item: any, idx: number) => (
                    <MenuItem key={`advertiser_${idx}`} value={idx}>
                      {item.pointName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            {useType === '6/0' && brandPointIndex !== 99 && (
              <Box sx={{ ml: '1rem' }}>
                <Typography className='title'>지급 포인트</Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ marginRight: '1rem' }}
                  value={rewardValue}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setRewardValue(Number(evt.target.value))}
                />
              </Box>
            )}
            <Box>
              <Typography className='title'>중복 발급</Typography>
              <TextField
                disabled={disabledEdit}
                select
                sx={{ width: '10rem' }}
                value={isDuplicate}
                // onChange={(
                //   evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                // ) => setIsDuplicate(evt.target.value)}
              >
                <MenuItem key={0} value={0}>
                  중복발급 불가
                </MenuItem>
                {/* <MenuItem key={1} value={1}>
                  중복발급 가능
                </MenuItem> */}
              </TextField>
            </Box>
          </Stack>
          <Divider />
          {useType === '3/0' || useType === '3/1' ? (
            <Box>
              <div className='flex_center'>
                <Typography className='title' sx={{ width: '8rem' }}>
                  쿠폰 사용처 링크
                </Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '28rem', mb: '.5rem' }}
                  value={couponLink}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setCouponLink(evt.target.value)}
                />
              </div>

              <div className='flex_center'>
                <Typography className='title' sx={{ width: '8rem' }}>
                  쿠폰 번호 넣기
                </Typography>
                <Box className='flex_center' sx={{ mb: '1rem' }}>
                  <TextField
                    disabled
                    sx={{ width: '28rem', m: '0 .5rem 0 0' }}
                    value={
                      uploadCouponFile !== null
                        ? uploadCouponFile?.name
                        : uploadCouponFileName
                    }
                  />
                  <Button
                    variant='contained'
                    disabled={disabledEdit}
                    onClick={() => {
                      const newFile1 = document.getElementById('adminfile1');
                      newFile1?.click();
                    }}
                  >
                    엑셀 파일선택
                  </Button>
                  <input
                    className='nonedisplay'
                    id='adminfile1'
                    type='file'
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      evt.target.files &&
                        setUploadCouponFile(evt.target.files[0]);
                      evt.target.value = '';
                    }}
                  />
                </Box>
              </div>

              <div className='flex_center'>
                <Box>
                  <Typography className='title'>
                    {`쿠폰 개수 : ${couponCount}`}
                  </Typography>
                  <Button
                    disabled={disabledEdit}
                    variant='outlined'
                    onClick={() => {
                      registerCoupon();
                    }}
                  >
                    쿠폰 번호 등록
                  </Button>
                </Box>
              </div>
              <Divider />
            </Box>
          ) : useType === '4/0' ? (
            <>
              <SwRewardApplyForm
                draggableName='draggable_form'
                formList={formList}
                setFormList={setFormList}
              />
              <Divider />
            </>
          ) : (
            <></>
          )}
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>리워드 사용 기간</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_PROGRAM_REWARD_RANGE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <SwDateRangePicker
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
              disabled={disabledEdit}
            />
            <Divider />
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>리워드 수량</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_PROGRAM_REWARD_MAX_COUNT}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              disabled={disabledEdit}
              sx={{ width: '10rem' }}
              value={rewardMaxCount}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setRewardMaxCount(Number(evt.target.value))}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>리워드 이름</Typography>
            <TextField
              disabled={disabledEdit}
              sx={{ width: '28rem' }}
              value={title}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setTitle(evt.target.value)}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>리워드 이미지</Typography>
            <SwFileSetting
              disabled={disabledEdit}
              image={
                rewardImageFile !== null
                  ? rewardImage
                  : rewardImage !== ''
                  ? `${imageUrlPrefix}${rewardImage}`
                  : ''
              }
              setImage={setRewardImage}
              setFile={setRewardImageFile}
              file={rewardImageFile}
            />
            <Divider />
          </Box>
          <Box>
            <Box className='flex_between'>
              <Typography className='title'>리워드 안내 내용 (선택)</Typography>
              <div>
                <Button disabled={disabledEdit} onClick={updateRewardGuideView}>
                  저장
                </Button>
                <Button disabled={disabledEdit} onClick={addRewardGuideView}>
                  추가
                </Button>
                {guideLists.length > 0 && (
                  <Button
                    disabled={disabledEdit}
                    onClick={removeRewardGuideView}
                  >
                    삭제
                  </Button>
                )}
              </div>
            </Box>
            <TextField
              disabled={disabledEdit}
              sx={{ width: '16rem' }}
              value={guideTitle}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setGuideTitle(evt.target.value)}
            />
            <TextField
              value={guideContent}
              fullWidth
              multiline
              rows={5}
              sx={{
                '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' },
              }}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setGuideContent(evt.target.value)}
            />
            <SwFileSetting
              disabled={disabledEdit}
              image={
                guideImageFile !== null
                  ? guideImage
                  : guideImage !== ''
                  ? `${imageUrlPrefix}${guideImage}`
                  : ''
              }
              setImage={setGuideImage}
              setFile={setGuideImageFile}
              file={guideImageFile}
            />
            {/* <SwPagination
            page={guidePage}
            handleChangePage={onChangeRewardGuideView}
            count={guideLists.length}
          /> */}
            <Typography className='title'>리워드 안내 목록</Typography>
            {guideLists?.map((item: any, idx: number) => (
              <DraggablePaper
                key={`draggable_${idx}`}
                className='draggable'
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                onDragOver={dragOver}
                onDrop={drop}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                draggable
                data-position={idx}
                onClick={selectList}
                sx={{ width: '50%' }}
              >
                <div>
                  <span>{idx}. </span>
                  <span>{item.title}</span>
                </div>
                <DragHandleIcon />
              </DraggablePaper>
            ))}
            <Divider />
          </Box>
          <Box>
            <Box className='flex_start'>
              <Box className='flex_start'>
                <Typography className='title'>위치 정보(선택)</Typography>
                <Tooltip
                  title={
                    <Typography fontSize={TOOLTIP_FONT_SIZE}>
                      {TOOLTIP_PROGRAM_REWARD_LOCATION}
                    </Typography>
                  }
                  followCursor
                >
                  <HelpOutlineIcon sx={{ color: '#999999' }} />
                </Tooltip>
              </Box>
              <Button
                sx={{ ml: '1.5rem' }}
                variant='contained'
                color='info'
                onClick={() => {
                  setOpenPostCode(1);
                }}
              >
                주소찾기
              </Button>
            </Box>
            <SwFindLatLngbyKakaomap
              disabled={disabledEdit}
              elementId='pointMap'
              setLatLng={setResultLatLng}
              value={resultLatLng}
              location={rewardLocation}
              setLocation={setRewardLocation}
            />
            {openPostCode && (
              <SwSearchPostCode
                open={!!openPostCode}
                onClose={() => setOpenPostCode(0)}
                setTarget={setTargetData}
              />
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>상태</Typography>
            <TextField
              disabled={disabledEdit}
              sx={{ width: '12rem' }}
              select
              defaultValue={1}
              value={rewardStatus}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setRewardStatus(Number(evt.target.value))}
            >
              <MenuItem key={0} value={0}>
                Close
              </MenuItem>
              <MenuItem key={1} value={1}>
                Open
              </MenuItem>
            </TextField>
            <Divider />
          </Box>
        </Box>
        <Box className='flex_between' sx={{ mt: '0rem' }}>
          <Box>
            <Button
              disabled={disabledEdit}
              variant='outlined'
              color='primary'
              sx={{ mr: '1rem' }}
              onClick={refreshProgramReward}
            >
              신규작성
            </Button>
          </Box>
          <Box>
            <Button
              disabled={disabledEdit}
              variant='outlined'
              color='info'
              sx={{ mr: '1rem' }}
              onClick={copyProgramReward}
            >
              복사하기
            </Button>
            <Button
              disabled={disabledEdit}
              variant='contained'
              onClick={() => checkProgramReward(rewardId)}
            >
              정보 저장
            </Button>
          </Box>
        </Box>
        <SwSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          contents={alertMessage}
        />
        <SwAlert
          open={alertOpen}
          onConfirm={() => setAlertOpen(false)}
          title={alertTitle}
          contents={alertMessage}
        />
        {openMore === 2 && (
          <Dialog
            open={!!openMore}
            sx={{
              '& .MuiDialog-container': {
                height: '85%',
              },
            }}
          >
            <Divider sx={{ p: 0, m: 0 }} />
            <DialogContent sx={{ p: '1rem 2rem' }}>
              <Typography sx={{ mt: '0.7rem' }}>
                {`'${
                  rewardList[rewardSequence - 1].rewardTitle
                }' 쿠폰을 삭제하시겠습니까?`}
              </Typography>
            </DialogContent>
            <Divider sx={{ p: 0, m: 0 }} />
            <DialogActions>
              <Button
                color='info'
                fullWidth
                size='small'
                onClick={() => setOpenMore(0)}
              >
                취소
              </Button>
              <Button
                color='primary'
                fullWidth
                size='small'
                onClick={() => {
                  removeReward(rewardSequence - 1);
                }}
              >
                확인
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    </>
  );
};
