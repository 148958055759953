import {
  Badge,
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { NoneBorderChip, SwSwitch } from '../../styles/Styles';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { SwSelectGeohashGroup } from '../../commonComponent/SwSelectGeohashGroup';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import {
  ageFormat,
  ageUnFormat,
  getRegionAmdCode,
  getRegionCategory,
  splitLocaitonLimit,
} from '../../../common/helper';
import { RegionFilterMap } from '../regionalFilter/RegionFilterMap';
import {
  ButtonBox,
  ChallengeTypeBox,
  ChallengeTypeTitle,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ChallengeStyles';
import { SwLoadPortalFilter } from '../../commonComponent/SwLoadPortalFilter';
import {
  KEY_CHALLENGE_COMMUNITY_FILTER_INFO,
  KEY_CHALLENGE_COMMUNITY_FILTER_LIST,
} from '../../../common/key';
import {
  getChallengeCommunityFilterList,
  getChallengeCommunityFilterInfo,
  registerChallengeFilter,
} from '../../../common/api/ApiChallenge';
import { IChallengeFilter } from '../../../models/challenge';
import { SwSearchCommunityFilter } from '../../commonComponent/SwSearchCommunityFilter';

interface Props {
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
  reloadChallenge: (id: string) => void;
}

const CommunityBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

export const ChallengeOpenRegion: React.FC<Props> = ({
  challengeInfo,
  setChallengeInfo,
  reloadChallenge,
}) => {
  const [filterList, setFilterList] = useState<any>([]);
  const [regionLimit, setRegionLimit] = useState<string[]>([]);
  const [authRegionLimit, setAuthRegionLimit] = useState<any[]>([]);
  const [saveAuthRegionLimit, setSaveAuthRegionLimit] = useState<any[]>([]);
  const [authAddressLimit, setAuthAddressLimit] = useState<any[]>([]);
  const [saveAuthAddressLimit, setSaveAuthAddressLimit] = useState<any[]>([]);
  const [locationLimit, setLocationLimit] = useState<any[]>([]);
  const [saveLocationLimit, setSaveLocationLimit] = useState<any[]>([]);
  const [geohashGroupLimit, setGeohashGroupLimit] = useState<any>([]);
  const [geohashList, setGeohashList] = useState<any>([]);
  const [geohashData, setGeohashData] = useState<any>([]);
  const [openTarget, setOpenTarget] = useState<boolean>(false);
  const [gender, setGender] = useState<any>(0);
  const [age, setAge] = useState<any>([]);
  const [targetId, setTargetId] = useState<any>('');
  const [filterLoad, setFilterLoad] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [openSwitchCommunity, setOpenSwitchCommunity] =
    useState<boolean>(false);
  const [openSwitchAuthRegionRestrict, setOpenSwitchAuthRegionRestrict] =
    useState<boolean>(false);
  const [openSwitchAuthAddressRestrict, setOpenSwitchAuthAddressRestrict] =
    useState<boolean>(false);
  const [openSwitchLocation, setOpenSwitchLocation] = useState<boolean>(false);
  const [openSwitchGender, setOpenSwitchGender] = useState<boolean>(false);
  const [openSwitchAge, setOpenSwitchAge] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [searchTarget, setSearchTarget] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('community');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSearchCommunity, setOpenSearchCommunity] =
    useState<boolean>(false);
  const [searchCommunity, setSearchCommunity] = useState<any[]>([]);

  const { refetch: challengeCommunityFilterInfoRefetch } = useQuery(
    KEY_CHALLENGE_COMMUNITY_FILTER_INFO,
    () => {
      const newTargetId = searchWord;
      console.log(
        'challengeCommunityFilterInfoRefetch targetId: ',
        newTargetId
      );
      if (searchTarget) return getChallengeCommunityFilterInfo(newTargetId);
      return null;
    },
    {
      onSuccess: res => {
        setSearchTarget(false);
        console.log('challengeCommunityFilterInfoRefetch res:', res);
        if (res !== null) {
          const newData = [...filterList, ...res.filter];
          setFilterList(newData);
        }
      },
      onError: e => {
        setSearchTarget(false);
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeFilterMutation,
    isError: isFilterMutateError,
  } = useMutation(registerChallengeFilter, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage('챌린지 필터를 저장하었습니다.');
      reloadChallenge(challengeInfo?.challengeId);
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '챌린지 필터를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteFilter = (index: number, from: string) => {
    const newSomething = filterList.filter(
      (_val: any, i: number) => i !== index
    );
    setFilterList([...newSomething]);
  };

  const deleteLocation = (index: number, from: string) => {
    const deleted = saveLocationLimit[index].location;
    const newLocation = saveLocationLimit.filter(
      (_val: any, i: number) => _val.location !== deleted
    );
    if (newLocation.length !== saveLocationLimit.length)
      setSaveLocationLimit([...newLocation]);
  };

  const deleteAuthRegion = (index: number, from: string) => {
    const deleted = saveAuthRegionLimit[index].location;
    const newLocation = saveAuthRegionLimit.filter(
      (_val: any, i: number) => _val.location !== deleted
    );
    if (newLocation.length !== saveAuthRegionLimit.length)
      setSaveAuthRegionLimit([...newLocation]);
  };

  const deleteAuthAddress = (index: number, from: string) => {
    const deleted = saveAuthAddressLimit[index].location;
    const newLocation = saveAuthAddressLimit.filter(
      (_val: any, i: number) => _val.location !== deleted
    );
    if (newLocation.length !== saveAuthAddressLimit.length)
      setSaveAuthAddressLimit([...newLocation]);
  };

  const deleteRegion = (index: number, from: string) => {
    const deleted = regionLimit[index];
    setRegionLimit([]);

    console.log('deleteRegion regionLimit : ', regionLimit);
    console.log('deleteRegion geohashGroupLimit: ', geohashGroupLimit);
    const newGeohash = geohashGroupLimit.filter(
      (_val: any, i: number) => _val.name !== deleted
    );
    console.log('deleteRegion newGeohash : ', newGeohash);
    if (newGeohash.length !== geohashGroupLimit.length) {
      setGeohashGroupLimit([...newGeohash]);
    }
  };

  const deleteAllLocation = () => {
    setSaveLocationLimit([]);
  };

  const deleteAllAuthRegion = () => {
    setSaveAuthRegionLimit([]);
  };

  const deleteAllAuthAddress = () => {
    setSaveAuthAddressLimit([]);
  };

  const deleteAllRegion = () => {
    setRegionLimit([]);
    setGeohashGroupLimit([]);
  };

  const addCommunity = () => {
    console.log('addCommunity');
    setSearchTarget(true);
  };

  function getFilterCount() {
    const newFilters = openSwitchCommunity
      ? filterList.map((item: any) => {
          const newData = {
            filterType: item.filterType,
            targetId: item.targetId,
          };
          return newData;
        })
      : [];
    const newGeohashs = openSwitchLocation
      ? geohashList.map((item: any) => {
          const newData = {
            filterType: 1,
            targetHash: item,
          };
          return newData;
        })
      : [];
    const newRegions = openSwitchLocation
      ? geohashGroupLimit.map((item: any) => {
          const newData = {
            filterType: 2,
            filterTarget: item.id,
            targetAddress: item.name,
          };
          return newData;
        })
      : [];
    const newLocation = openSwitchLocation
      ? saveLocationLimit.map((item: any) => {
          const newData = {
            filterType: 1,
            targetAddress: item.location,
            targetAmd: item.amd,
          };
          return newData;
        })
      : [];
    const newAges = openSwitchAge
      ? ageUnFormat(age.replaceAll('~', ',~,').split(','))
      : [];
    const newGender = openSwitchGender ? gender : 0;

    if (
      newFilters.length === 0 &&
      newGeohashs.length === 0 &&
      newLocation.length === 0 &&
      newAges.length === 0 &&
      newGender === 0
    ) {
      return false;
    }
    return true;
  }

  const addFilter = () => {
    console.log('addFilter geohashGroupLimit: ', geohashGroupLimit);
    const newFilters = openSwitchCommunity
      ? filterList.map((item: any) => {
          const newData = {
            filterType: item.filterType,
            targetId: item.targetId,
          };
          return newData;
        })
      : [];
    const newGeohashs = openSwitchLocation
      ? geohashList.map((item: any) => {
          const newData = {
            filterType: 1,
            targetHash: item,
          };
          return newData;
        })
      : [];
    const newRegions = openSwitchLocation
      ? geohashGroupLimit.map((item: any) => {
          const newData = {
            filterType: 2,
            filterTarget: item.id,
            targetAddress: item.name,
          };
          return newData;
        })
      : [];
    const newLocation = openSwitchLocation
      ? saveLocationLimit.map((item: any) => {
          const newData = {
            filterType: 1,
            targetAddress: item.location,
            targetAmd: item.amd,
          };
          return newData;
        })
      : [];
    const newAuthRegion = openSwitchAuthRegionRestrict
      ? saveAuthRegionLimit.map((item: any) => {
          const newData = {
            targetType: 0,
            targetAddress: item.location,
            targetAmd: item.amd,
          };
          return newData;
        })
      : [];
    const newAuthAddress = openSwitchAuthAddressRestrict
      ? saveAuthAddressLimit.map((item: any) => {
          const newData = {
            targetType: 0,
            targetAddress: item.location,
            targetAmd: item.amd,
          };
          return newData;
        })
      : [];
    const newAges = openSwitchAge
      ? ageUnFormat(age.replaceAll('~', ',~,').split(','))
      : [];
    const newGender = openSwitchGender ? gender : 0;

    const newData: IChallengeFilter = {
      challengeId: challengeInfo?.challengeId,
      filters: newFilters,
      geohashs: newRegions.length > 0 ? newGeohashs : [],
      region: newRegions,
      location: newLocation,
      authRegions: newAuthRegion,
      authAddress: newAuthAddress,
      ages: newAges,
      gender: newGender,
    };

    if (newData.challengeId === undefined) {
      setOpenSnackbar(true);
      setAlertMessage('필터를 추가할 챌린지를 선택하세요');
      return;
    }

    // if (newData.filters.length === 0) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('추가할 필터가 없습니다.');
    //   return;
    // }
    console.log('addFilter newData :', newData);
    registerChallengeFilterMutation(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      // addCommunity();
      setOpenSearchCommunity(true);
    }
  };

  useEffect(() => {
    if (searchTarget) challengeCommunityFilterInfoRefetch();
  }, [searchTarget]);

  useEffect(() => {
    const newFilterList = filterList.map((item: any) => item.targetId);
    const newSearchResult = searchCommunity.filter(
      (item: any) => !newFilterList.includes(item.targetId)
    );
    const newData = [...filterList, ...newSearchResult];
    setFilterList(newData);
    return () => {};
  }, [searchCommunity]);

  // useEffect(() => {
  //   setRegionLimit([]);
  //   const newGeohash = geohashGroupLimit.map(
  //     (_val: any, i: number) => _val.name
  //   );
  //   setRegionLimit([...locationLimit, ...newGeohash]);
  // }, [locationLimit]);

  useEffect(() => {
    setSaveLocationLimit([...saveLocationLimit, ...locationLimit]);
  }, [locationLimit]);

  useEffect(() => {
    setSaveAuthRegionLimit([...saveAuthRegionLimit, ...authRegionLimit]);
  }, [authRegionLimit]);

  useEffect(() => {
    setSaveAuthAddressLimit([...saveAuthAddressLimit, ...authAddressLimit]);
  }, [authAddressLimit]);

  useEffect(() => {
    // setRegionLimit([]);
    if (geohashGroupLimit.length > 0) {
      const isLoad = geohashGroupLimit.filter(
        (_val: any, i: number) => _val.load === undefined
      );

      const newGeohash = geohashGroupLimit.map(
        (_val: any, i: number) => _val.name
      );
      const newGeohashData = geohashGroupLimit
        .filter((_val: any, i: number) => _val.group !== '')
        .map((_val: any, i: number) =>
          _val.group === '' ? '' : JSON.parse(_val.group)
        );
      setGeohashData(newGeohashData);
      setRegionLimit([...newGeohash]);
    }
  }, [geohashGroupLimit]);

  useEffect(() => {
    console.log('setFilterLoad: ', filterLoad);
    if (filterLoad !== undefined) {
      var newData: any[] = [];
      newData.push(...filterLoad.community);
      newData.push(...filterLoad.group);
      setFilterList(newData);
      setOpenSwitchCommunity(
        filterLoad?.community.length > 0 || filterLoad?.group.length > 0
      );
      setSaveLocationLimit(filterLoad.location);

      if (filterLoad.region.length === 0) {
        setGeohashData([]);
        setRegionLimit([]);
      } else {
        setGeohashData([]);
      }
      // console.log('newGeohashData.length :', newGeohashData.length);
      // console.log('newLocationList.length :', newLocationList.length);
      setOpenSwitchLocation(
        filterLoad.region.length > 0 || filterLoad.location.length > 0
      );

      setGender(filterLoad.gender);
      setOpenSwitchGender(filterLoad.gender > 0);

      setAge(filterLoad.age.join(',').replaceAll(',~,', '~'));
      setOpenSwitchAge(filterLoad.age !== null && filterLoad.age.length > 0);
    }
  }, [filterLoad]);

  useEffect(() => {
    if (challengeInfo) {
      const newFilters = JSON.parse(
        challengeInfo.challengeFilters.replace('\n', '')
      );
      const newFilterList =
        newFilters &&
        newFilters.map((item: any) => {
          const newData = {
            filterType: item.FILTER_TYPE,
            challengeId: item.CHALLENGE_ID,
            targetId: item.TARGET_ID,
            communityName: item.NAME,
          };
          return newData;
        });
      setFilterList(newFilterList);
      setOpenSwitchCommunity(newFilterList.length > 0);

      const newAuthRegionLimit = JSON.parse(challengeInfo.authRegions);
      const newAuthRegion =
        newAuthRegionLimit &&
        newAuthRegionLimit.map((item: any) => {
          const newData = {
            location: item.TARGET_ADDRESS,
            amd: item.TARGET_AMD,
          };
          return newData;
        });
      setSaveAuthRegionLimit(newAuthRegion);
      setOpenSwitchAuthRegionRestrict(newAuthRegionLimit.length > 0);

      const newAuthAddressLimit = challengeInfo.authAddress
        ? JSON.parse(challengeInfo?.authAddress || '')
        : '';
      const newAuthAddress =
        newAuthAddressLimit &&
        newAuthAddressLimit.map((item: any) => {
          const newData = {
            location: item.TARGET_ADDRESS,
            amd: item.TARGET_AMD,
          };
          return newData;
        });
      setSaveAuthAddressLimit(newAuthAddress);
      setOpenSwitchAuthAddressRestrict(newAuthAddressLimit.length > 0);

      const newLocationList = JSON.parse(challengeInfo.locationFilters);
      const newLocation =
        newLocationList &&
        newLocationList.map((item: any) => {
          const newData = {
            location: item.TARGET_ADDRESS,
            amd: item.TARGET_AMD,
          };
          return newData;
        });
      setSaveLocationLimit(newLocation);

      const newGeohashData = JSON.parse(challengeInfo.geohash);

      if (challengeInfo.geohash === 0) {
        setGeohashData([]);
        setRegionLimit([]);
      } else {
        setGeohashData([newGeohashData]);
      }
      // console.log('challengeInfo.geohash :', challengeInfo.geohash);
      // console.log('newGeohashData.length :', newGeohashData.length);
      // console.log('newLocationList.length :', newLocationList.length);
      setOpenSwitchLocation(
        newGeohashData.length > 0 || newLocationList.length > 0
      );

      const newRegionFilterList = JSON.parse(challengeInfo.regionFilters);
      const newRegion =
        newRegionFilterList &&
        newRegionFilterList.map((item: any) => item.TARGET_ADDRESS);
      setRegionLimit(newRegion);

      var isSaved = false;
      const newGeohashGroupLimit =
        newRegionFilterList &&
        newRegionFilterList.map((item: any) => {
          const geo = {
            load: true,
            name: item.TARGET_ADDRESS,
            id: item.TARGET_ID,
            group: isSaved === false ? challengeInfo.geohash : '',
            creator: '',
            createDate: '',
          };
          isSaved = true;
          return geo;
        });
      setGeohashGroupLimit(newGeohashGroupLimit);

      const newAttendFilters = JSON.parse(challengeInfo.attendFilters);
      const newGenderFilter =
        newAttendFilters !== undefined && newAttendFilters !== null
          ? newAttendFilters
              .filter(
                (item: any) =>
                  item.FILTER_TYPE === '1' &&
                  (item.FILTER_TARGET === 1 || item.FILTER_TARGET === 2)
              )
              .map((item: any) => {
                const newData = {
                  filterType: item.FILTER_TYPE,
                  filterTarget: item.FILTER_TARGET,
                };
                return newData;
              })
          : null;
      const newAgeFilter =
        newAttendFilters !== undefined && newAttendFilters !== null
          ? newAttendFilters
              .filter((item: any) => item.FILTER_TYPE === '2')
              .map((item: any) => {
                const newData = {
                  filterType: item.FILTER_TYPE,
                  filterTarget: item.FILTER_TARGET,
                };
                return newData;
              })
          : null;
      const newGender =
        newGenderFilter !== null && newGenderFilter.length > 0
          ? newGenderFilter[0].filterTarget
          : 0;
      const newAge =
        newAgeFilter !== null && newAgeFilter.length > 0
          ? newAgeFilter[0].filterTarget
          : null;
      setGender(newGender);
      setOpenSwitchGender(
        newGenderFilter !== null && newGenderFilter.length > 0
      );
      const newFormatAge = ageFormat(newAge);
      setAge(newFormatAge.join(',').replaceAll(',~,', '~'));
      setOpenSwitchAge(newAgeFilter !== null && newAgeFilter.length > 0);
      setTargetId('');
    }
  }, [challengeInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem', m: '2rem 0' }}>
      <Button
        variant='contained'
        onClick={() => setOpenTarget(true)}
        disabled={challengeInfo.challengeType === 18}
      >
        공개 대상 불러오기
      </Button>
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center'>
          <Typography sx={{ mr: '.4rem' }}>커뮤니티 및 그룹</Typography>
          <SwSwitch
            disabled={challengeInfo.challengeType === 18}
            checked={openSwitchCommunity}
            onChange={() => setOpenSwitchCommunity(!openSwitchCommunity)}
          />
        </Box>
        <Box sx={{ display: 'flex', m: '.8rem 0' }}>
          <TextField
            sx={{ width: '22rem', mb: 0, mr: '.3rem' }}
            placeholder='커뮤니티 및 그룹 아이디를 입력해 주세요.'
            disabled={
              !openSwitchCommunity || challengeInfo.challengeType === 18
            }
            value={searchWord}
            onKeyDown={handleKeyDown}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
          />
          <Button
            variant='contained'
            disabled={
              !openSwitchCommunity || challengeInfo.challengeType === 18
            }
            onClick={() => setOpenSearchCommunity(true)}
          >
            조회
          </Button>
        </Box>
        <Paper
          sx={{
            backgroundColor: '#FCFCFC',
            p: '2rem 2rem 1rem',
          }}
        >
          <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
            <Typography fontWeight={500}>공개 커뮤니티 및 그룹</Typography>
            {filterList &&
              filterList.map((item: any, idx: number) => (
                <CommunityBox key={idx.toString()}>
                  <Typography>
                    {item.filterType === 'community'
                      ? `커뮤니티 : ${item.communityName}, ID:${item.targetId}`
                      : `그룹    : ${item.communityName}, ID:${item.targetId}`}
                  </Typography>
                  {challengeInfo.challengeType !== 18 && (
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteFilter(idx, 'filter')}
                    />
                  )}
                </CommunityBox>
              ))}
          </Box>
          {challengeInfo.challengeType !== 18 && (
            <Box sx={{ textAlign: 'right' }}>
              <Button
                color='error'
                onClick={() => {
                  setFilterList([]);
                }}
              >
                <DeleteOutlineIcon />
                전체 삭제
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
      <Divider />
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <Typography sx={{ mr: '.4rem' }}>
            동네(프로필 인증 지역) 제한
          </Typography>
          <SwSwitch
            checked={openSwitchAuthRegionRestrict}
            onChange={() =>
              setOpenSwitchAuthRegionRestrict(!openSwitchAuthRegionRestrict)
            }
          />
        </Box>
        <Box>
          <SwSelectLocation
            limitKu={true}
            includeAmd={true}
            selectOne={true}
            showButton={false}
            showDepth1={false}
            location={authRegionLimit}
            setLocation={setAuthRegionLimit}
            showContainer={false}
            disabled={!openSwitchAuthRegionRestrict}
            mapId='authRegion_map'
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>위치</Typography>
          {saveAuthRegionLimit.length > 0 &&
            saveAuthRegionLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={`${getRegionAmdCode(location.amd)} ${splitLocaitonLimit(
                  location.location
                )}`}
                variant='outlined'
                onDelete={() => deleteAuthRegion(idx, 'authRegionLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              disabled={!openSwitchAuthRegionRestrict}
              onClick={() => deleteAllAuthRegion()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
      </Box>
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <Typography sx={{ mr: '.4rem' }}>
            <Badge
              variant='dot'
              color='warning'
              invisible={saveAuthAddressLimit.length === 0}
            >
              주소 제한
            </Badge>
          </Typography>
          <SwSwitch
            checked={openSwitchAuthAddressRestrict}
            onChange={() =>
              setOpenSwitchAuthAddressRestrict(!openSwitchAuthAddressRestrict)
            }
          />
        </Box>
        <Box>
          <SwSelectLocation
            limitKu={true}
            includeAmd={true}
            selectOne={true}
            showButton={false}
            showDepth1={false}
            location={authAddressLimit}
            setLocation={setAuthAddressLimit}
            showContainer={false}
            disabled={!openSwitchAuthAddressRestrict}
            mapId='authAddress_map'
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>지역</Typography>
          {saveAuthAddressLimit.length > 0 &&
            saveAuthAddressLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={`${getRegionAmdCode(location.amd)} ${splitLocaitonLimit(
                  location.location
                )}`}
                variant='outlined'
                onDelete={() => deleteAuthAddress(idx, 'authAddressLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              disabled={!openSwitchAuthAddressRestrict}
              onClick={() => deleteAllAuthAddress()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
      </Box>
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <Typography sx={{ mr: '.4rem' }}>위치(GPS) 제한</Typography>
          <SwSwitch
            checked={openSwitchLocation}
            onChange={() => setOpenSwitchLocation(!openSwitchLocation)}
          />
        </Box>
        <Box>
          <SwSelectLocation
            limitKu={true}
            includeAmd={true}
            selectOne={true}
            showButton={false}
            showDepth1={false}
            location={locationLimit}
            setLocation={setLocationLimit}
            showContainer={false}
            disabled={!openSwitchLocation}
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>입력된 지역</Typography>
          {saveLocationLimit.length > 0 &&
            saveLocationLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={`${getRegionAmdCode(location.amd)} ${splitLocaitonLimit(
                  location.location
                )}`}
                variant='outlined'
                onDelete={() => deleteLocation(idx, 'locationLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              disabled={!openSwitchLocation}
              onClick={() => deleteAllLocation()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
        <Box className='flex_center' sx={{ m: '2rem 0rem 1rem 0' }}>
          <SwSelectGeohashGroup
            geohashGroup={geohashGroupLimit}
            setGeohashGroup={setGeohashGroupLimit}
            showContainer={false}
            disabled={!openSwitchLocation}
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>지역</Typography>
          {regionLimit.length > 0 &&
            regionLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={location}
                variant='outlined'
                // onDelete={() => deleteRegion(idx, 'regionLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              disabled={!openSwitchLocation}
              onClick={() => deleteAllRegion()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
      </Box>
      <Paper>
        <RegionFilterMap
          geohashData={geohashData}
          setGeohashData={setGeohashData}
          setTargetGeohashList={setGeohashList}
          elementId='map-region'
        />
      </Paper>
      <Divider />
      <Box sx={{ pt: '1rem' }}>
        <Box className='flex_center'>
          <Typography sx={{ mr: '.4rem' }}>성별</Typography>
          <SwSwitch
            checked={openSwitchGender}
            onChange={() => setOpenSwitchGender(!openSwitchGender)}
          />
        </Box>
        <Box sx={{ mt: '.8rem' }}>
          <TextField
            sx={{ width: '18rem' }}
            select
            value={gender}
            onChange={evt => setGender(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              선택
            </MenuItem>
            <MenuItem key={1} value={1}>
              남성
            </MenuItem>
            <MenuItem key={2} value={2}>
              여성
            </MenuItem>
          </TextField>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ pt: '1rem', mb: '1rem' }}>
        <Box className='flex_center' sx={{ mb: '.4rem' }}>
          <Typography sx={{ mr: '.4rem' }}>나이</Typography>
          <SwSwitch
            checked={openSwitchAge}
            onChange={() => setOpenSwitchAge(!openSwitchAge)}
          />
        </Box>
        <Typography fontSize='.8rem' fontWeight={350} color='#999999'>
          여러 나이는 ,(콤마)로 구분하고 나이 범위는 ~(물결)로 입력해 주세요.
          예) 10, 27, 15~18, 30~40
        </Typography>
        <Box sx={{ mt: '.8rem' }}>
          <TextField
            disabled={!openSwitchAge}
            sx={{ width: '18rem' }}
            value={age}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setAge(evt.target.value)}
          />
        </Box>
      </Box>
      <Divider />
      <ButtonBox className='flex_end ' sx={{ mt: '2rem' }}>
        <Box>
          <Button
            variant='contained'
            onClick={() => {
              const newCount = getFilterCount();
              console.log('newCount:', newCount);
              newCount ? addFilter() : setOpenConfirm(true);
            }}
          >
            정보 저장
          </Button>
        </Box>
      </ButtonBox>
      <SwLoadPortalFilter
        open={openTarget}
        onclose={() => setOpenTarget(false)}
        setFilterLoad={setFilterLoad}
      />
      <SwSearchCommunityFilter
        title='커뮤니티/그룹 이름, 아이디 검색'
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchCommunity}
        onClose={() => {
          setOpenSearchCommunity(false);
        }}
        setTarget={setSearchCommunity}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents='전국 챌린지로 개설됩니다. 저장하시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          addFilter();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </Paper>
  );
};
