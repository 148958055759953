import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSearchChallengeUser } from '../../commonComponent/SwSearchChallengeUser';
import { DrawTable } from '../rewardDrawing/DrawTable';
import { MultilineTextField } from '../../styles/Styles';
import {
  BRAND_POINT_TYPE,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  ChallengeTypeBox,
  ChallengeTypeTitle,
  ColorTableCell,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ChallengeStyles';
import {
  IGuideTool,
  IChallengeCreate,
  IChallengeReqeust,
  DefaultChallengeCreate,
} from '../../../models/challenge';
import {
  getChallengeRewardList,
  getChallengeRewardUserList,
  drawManualCoupon,
  drawManualTicket,
} from '../../../common/api/ApiChallenge';
import {
  KEY_CHALLENGE_REWARD_LIST,
  KEY_CHALLENGE_REWARD_USER_LIST,
  KEY_REWARD_DRAWING_RESULT,
} from '../../../common/key';
import { RESULT_OK } from '../../../common/resultCode';
import { getRewardDrawingResult } from '../../../common/api/ApiReward';
import { RewardDrawUserGivetype0 } from './RewardDrawUserGivetype0';
import { RewardDrawUserGivetype2 } from './RewardDrawUserGivetype2';
import { RewardDrawUserUsetype } from './RewardDrawUserUsetype';

const columns = [
  '순서',
  '리워드 이름',
  '수량',
  '발급(응모) 인원',
  '지급 방법',
  '사용 방법',
  '상태',
];

const drawColumn = ['번호', '연락처', '참여자 닉네임'];

const guidetool: IGuideTool = {
  title: '소제목',
  contents: '안내내용',
  image: '',
};

interface Props {
  rewardGivetype: any;
  rewardUsetype: any;
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
}

export const RewardPutDel: React.FC<Props> = ({
  rewardGivetype,
  rewardUsetype,
  challengeInfo,
  setChallengeInfo,
}) => {
  const queryClient = useQueryClient();
  const putInputRef = useRef<any>();
  const delInputRef = useRef<any>();
  const guideTitleRef = useRef<any>();
  const guideContentsRef = useRef<any>();
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [challengeId, setChallengeId] = useState<string>('');
  const [challengeType, setChallengeType] = useState<number>(1);
  const [rewardId, setRewardId] = useState<string>('');
  const [giveType, setGiveType] = useState<string>('');
  const [useType, setUseType] = useState<string>('0/0');
  const [isDuplicate, setIsDuplicate] = useState<string>('');
  const [rewardMaxCount, setRewardMaxCount] = useState<number>();
  const [title, setTitle] = useState<string>('');
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<IGuideTool[]>([guidetool]);
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>('');
  const [rewardImage, setRewardImage] = useState<string>('');
  const [rewardImageFile, setRewardImageFile] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [rewardLocation, setRewardLocation] = useState<string>('');
  const [rewardStatus, setRewardStatus] = useState<number>(1);
  const [rewardValue, setRewardValue] = useState<number>(0);
  const [rewardSequence, setRewardSequence] = useState<number>(1);
  const [rewardPointPerGet, setRewardPointPerGet] = useState<number>(0);
  const [rewardStampPerGet, setRewardStampPerGet] = useState<number>(0);
  const [winnerUserList, setWinnerUserList] = useState<any>([]);
  const [nonwinnerUserList, setNonwinnerUserList] = useState<any>([]);
  const [rewardUserList, setRewardUserList] = useState<any>([]);
  const [putUserList, setPutUserList] = useState<any>([]);
  const [delUserList, setDelUserList] = useState<any>([]);
  const [targetUserList, setTargetUserList] = useState<any>([]);
  const [filterList, setFilterList] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<any>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const {
    data,
    isLoading,
    refetch: challengeRewardRefetch,
  } = useQuery(
    KEY_CHALLENGE_REWARD_LIST,
    () => {
      console.log('challengeId: ', challengeId);
      if (challengeId.length > 0) return getChallengeRewardList(challengeId);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res !== null) {
          const newRewardList = res.reward.map((item: any, idx: number) => {
            const newGiveType =
              rewardGivetype[item.rewardGiveType]
                .challengeRewardGivetypeDescription !== undefined
                ? rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                : '-';
            const newUseType = rewardUsetype
              .filter(
                (useItem: any) =>
                  useItem.challengeRewardUsetype === item.rewardUseType &&
                  useItem.challengeRewardUsesubtype === item.rewardUsetypeSub
              )
              .map((useItem: any) => useItem.challengeRewardUsetypeDescription);

            const newReward = {
              sequence: idx + 1,
              title: item.rewardTitle,
              count: item.rewardMaxCount,
              applyCount: item.drawUserCount,
              resultCount: item.attendUserCount,
              giveTypeStr: newGiveType,
              giveType: item.rewardGiveType,
              useType: newUseType,
              status: rewardStatus === 0 ? 'Close' : 'Open',
            };
            return newReward;
          });

          setRequestList(newRewardList);
          setRewardList(res.reward);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const clickRow = (idx: number) => {
    console.log('click row :', idx, ', rewardList[idx]:', rewardList[idx]);
    setSelectedRow(idx);
    setRewardSequence(idx + 1);
    setRewardId(rewardList[idx].rewardId);
    setGiveType(String(rewardList[idx].rewardGiveType));
    setUseType(
      `${rewardList[idx].rewardUseType}/${rewardList[idx].rewardUsetypeSub}`
    );
    setIsDuplicate(rewardList[idx].rewardIsDuplicate);
    const newRangeDate = [
      timestamp2Localestring(rewardList[idx].rewardUseStime, 1000),
      timestamp2Localestring(rewardList[idx].rewardUseEtime, 1000),
    ];
    setRangeDate(newRangeDate);
    setRewardMaxCount(rewardList[idx].rewardMaxCount);
    setTitle(rewardList[idx].rewardTitle);
    setRewardImage(`${imageUrlPrefix}${rewardList[idx].rewardImgpathMain}`);
    setRewardLocation(rewardList[idx].rewardAddress);
    setRewardPointPerGet(rewardList[idx].rewardPointPerGet);
    setRewardStampPerGet(rewardList[idx].rewardStampPerGet);
    setRewardValue(rewardList[idx].rewardValue);

    const newDescription = JSON.parse(rewardList[idx].rewardDescription);
    console.log('newDescription :', newDescription);
    const newGuideList =
      newDescription &&
      newDescription.map((item: any) => {
        const newGuide = {
          title: item.t,
          contents: item.c,
          image: item.i,
        };
        return newGuide;
      });
    setGuideLists(newGuideList);
  };

  useEffect(() => {
    console.log('RewardList: ', rewardList);
  }, [rewardList]);

  useEffect(() => {
    challengeRewardRefetch();
  }, [challengeId]);

  useEffect(() => {
    if (rewardGivetype) {
      console.log('ChallengeOpenRegion rewardGivetype :', rewardGivetype);
    }

    if (rewardUsetype) {
      console.log('ChallengeOpenRegion rewardUsetype :', rewardUsetype);
    }
  }, [rewardGivetype, rewardUsetype]);

  useEffect(() => {
    if (targetUserList.length > 0) {
      console.log('targetUserList :', targetUserList);
      if (request.searchType === 0) {
        const newTargetList = targetUserList.filter((target: any) => {
          const newUser = putUserList.filter(
            (user: any) => target.id === user.id
          );
          if (newUser && newUser.length > 0) return false;
          return true;
        });
        const newPutList = [...putUserList, ...newTargetList];
        setPutUserList(newPutList);
      } else {
        const newTargetList = targetUserList.filter((target: any) => {
          const newUser = delUserList.filter(
            (user: any) => target.id === user.id
          );
          if (newUser && newUser.length > 0) return false;
          return true;
        });
        const newDelList = [...delUserList, ...newTargetList];
        setDelUserList(newDelList);
        console.log('targetUserList setDelUserList:', delUserList);
      }
    }
  }, [targetUserList]);

  useEffect(() => {
    if (challengeInfo) {
      console.log('RewardPutDel challengeInfo :', challengeInfo);
      setChallengeId(challengeInfo.challengeId);
      setChallengeType(challengeInfo.challengeType);
      setImageUrlPrefix(challengeInfo.imageUrlPrefix);

      setRangeDate([null, null]);
      setRewardMaxCount(0);
      setTitle('');
      setRewardImage('');
      setGiveType('');
      setUseType('');
      setSelectedRow(-1);
    }
  }, [challengeInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Paper>
        <Typography sx={{ m: '1rem 0 ', fontWeight: 'bold' }}>
          리워드 목록
        </Typography>
        <TableContainer
          sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column: any, idx: number) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => clickRow(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  <TableCell>{row.sequence}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  {row.giveType === 0 ? (
                    <TableCell>{row.resultCount}</TableCell>
                  ) : (
                    <TableCell>{`${row.resultCount}(${row.applyCount})`}</TableCell>
                  )}
                  <TableCell>{row.giveTypeStr}</TableCell>
                  <TableCell>{row.useType}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ p: '1.5rem 0' }}>
        <Paper>
          <Typography sx={{ m: '1rem 0 ', fontWeight: 'bold' }}>
            쿠폰 상세정보
          </Typography>
          <TableContainer>
            <Table stickyHeader>
              <TableBody>
                <TableRow>
                  <ColorTableCell>쿠폰 이름</ColorTableCell>
                  <TableCell>{title}</TableCell>
                </TableRow>
                <TableRow>
                  <ColorTableCell>수량</ColorTableCell>
                  <TableCell>{rewardMaxCount}</TableCell>
                </TableRow>
                <TableRow>
                  <ColorTableCell>쿠폰 이미지</ColorTableCell>
                  <TableCell>
                    {rewardImage !== '' && (
                      <img
                        src={rewardImage}
                        alt=''
                        style={{ width: '12rem', height: '12rem' }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <ColorTableCell>지급 방법</ColorTableCell>
                  <TableCell>
                    {rewardGivetype[giveType] &&
                      rewardGivetype[giveType]
                        .challengeRewardGivetypeDescription}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <ColorTableCell>사용 방법</ColorTableCell>
                  <TableCell>
                    {rewardUsetype
                      .filter(
                        (useItem: any) =>
                          `${useItem.challengeRewardUsetype}/${useItem.challengeRewardUsesubtype}` ===
                          useType
                      )
                      .map(
                        (useItem: any) =>
                          useItem.challengeRewardUsetypeDescription
                      )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <ColorTableCell>사용 기간</ColorTableCell>
                  <TableCell>
                    {rangeDate[0] !== null &&
                      `${
                        rangeDate[0]
                          .replaceAll('T', ' ')
                          .replaceAll('.000Z', '')
                          .split(' ')[0]
                      }~${
                        rangeDate[1]
                          .replaceAll('T', ' ')
                          .replaceAll('.000Z', '')
                          .split(' ')[0]
                      }`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Divider />
        {giveType === '2' && (
          <>
            <RewardDrawUserGivetype2
              giveType={2}
              challengeId={challengeId}
              rewardId={rewardId}
            />
            <Divider sx={{ mb: '1rem' }} />
          </>
        )}
        <RewardDrawUserGivetype0
          giveType={Number(giveType)}
          challengeId={challengeId}
          rewardId={rewardId}
          useType={useType}
        />
        {useType === BRAND_POINT_TYPE && (
          <>
            <Divider sx={{ mt: '1rem' }} />
            <RewardDrawUserUsetype
              giveType={Number(giveType)}
              useType={useType}
              challengeId={challengeId}
              rewardId={rewardId}
              rewardValue={rewardValue}
              rewardInfo={rewardList[selectedRow]}
            />
          </>
        )}
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Paper>
  );
};
