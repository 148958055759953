import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useRef, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSearchDataPortal } from '../../commonComponent/SwSearchDataPortal';
import { SwSearchAmd } from '../../commonComponent/SwSearchAmd';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { WalkwayRegionSort } from '../../appTab/walkway/WalkwayRegionSort';
import {
  IDataPortalRequest,
  IDataPortal,
  IDataPortalQnA,
} from '../../../models/dataportal';
import {
  getDataPortalAccountAmdList,
  postDataPortalAccountRegister,
  deleteDataPortalAccount,
  getDataPortalAccountExist,
} from '../../../common/api/ApiDataPortal';
import {
  KEY_ACCOUNT_LIST,
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_DATAPORTAL_ACCOUNT_EXIST,
} from '../../../common/key';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSearchWalkwayLocal } from '../../commonComponent/SwSearchWalkwayLocal';
import { getAccountList } from '../../../common/api/ApiAccount';
import { SwSearchContractCompany } from '../../commonComponent/SwSearchContractCompany';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';

interface Props {
  user: any;
  setReload: Dispatch<React.SetStateAction<boolean>>;
}

const CreatePaper = styled(Paper)({
  margin: '2rem 0',
  padding: '1.5rem',
});

const ContentBox = styled(Box)({
  padding: '0 1rem',
});

const ContentTitle = styled(Typography)({
  padding: '.5rem',
  paddingLeft: 0,
  marginBottom: '.5rem',
  fontWeight: 500,
});

const authorityIds = ['challenge', 'community', 'program', 'qna'];

const authorityItem = ['all', 'challenge', 'community', 'program', 'qna'];

export const PortalInformation: React.FC<Props> = ({ user, setReload }) => {
  const idRef = useRef<any>();
  const passwordRef = useRef<any>();
  const searchRef = useRef<any>();
  const operatorRef = useRef<any>();
  const operatorNumberRef = useRef<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [openPortal, setOpenPortal] = useState<boolean>(false);
  const [openAmd, setOpenAmd] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const [authority, setAuthority] = useState<any>(0);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [location, setLocation] = useState<string>('');
  const [locationAddress, setLocationAddress] = useState<any>('');
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [address, setAddress] = useState<any>('');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [portalCode, setPortalCode] = useState<string>('');
  const [portalId, setPortalId] = useState<string>('walkon_');
  const [password, setPassword] = useState<string>('');
  const [agency, setAgency] = useState<string>('');
  const [manager, setManager] = useState<string>('');
  const [phoneno, setPhoneno] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [operator, setOperator] = useState<string>('0');
  const [walkwayLocalId, setWalkwayLocalId] = useState<string>('');
  const [walkwayLocalName, setWalkwayLocalName] = useState<string>('');
  const [walkwayCommand, setWalkwayCommand] = useState<any>();
  const [regionInfo, setRegionInfo] = useState<any>();
  const [regionName, setRegionName] = useState<any>('');
  const [amdCode, setAmdCode] = useState<string>('');
  const [searchPortalAccount, setSearchPortalAccount] = useState<any>();
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [empList, setEmpList] = useState<any>();
  const [title, setTitle] = useState<any>('');
  const [isManager, setIsManager] = useState<boolean>(false);
  const [dataPortal, setDataPortal] = useState<any>([]);
  const [findDataPortal, setFindDataPortal] = useState<string>('');
  const [connectDataPortal, setConnectDataPortal] = useState<any>([]);
  const [inhibitDuplicated, setInhibitDuplicated] = useState<boolean>(true);
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });
  const [idDisabled, setIdDisabled] = useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = useState<number>(-1);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchAgency, setSearchAgency] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openPostCode, setOpenPostCode] = useState<number>(0);

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setDialogMessage(msg);
  };

  const {
    data,
    isLoading,
    refetch: accountRefetch,
  } = useQuery(KEY_ACCOUNT_LIST, () => getAccountList(0, 0, ''), {
    onSuccess: res => {
      setEmpList(res.account);
    },
    onError: e => {
      console.log(e);
    },
  });

  const { mutate: registerDataPortalAccountMutation, isError: isMutateError } =
    useMutation(postDataPortalAccountRegister, {
      onSuccess: res => {
        console.log('postDataPortalAccountRegister res : ', res);
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('저장되었습니다.');
          setReload(true);
        } else {
          showError(`저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`);
        }
      },
      onError: error => {
        showError(`저장하는 동안 오류가 발생했습니다.(${error})`);
      },
    });

  const {
    mutate: deleteDataPortalAccountMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteDataPortalAccount, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('포털계정이 삭제되었습니다.');
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '포털계정을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const registerDataPortalAccount = () => {
    console.log('registerDataPortalAccountMutation rangeDate : ', rangeDate);
    const newKeyList = JSON.stringify([{ name: agency, key: agency }]);
    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;
    const newConstime = rangeDate[0] === null ? null : new Date(rangeDate[0]);
    const newActivate = rangeDate[1] === null ? null : new Date(rangeDate[1]);
    // const newConType = authorityItem
    //   .map((item: any, idx: number) => {
    //     if (item === authority) return idx;
    //     return 0;
    //   })
    //   .filter((item: any) => item !== 0);
    var authValue = 0;
    const newAuthority = checkedButtons.map((item: any) => {
      // eslint-disable-next-line no-bitwise
      if (item === 'challenge') authValue |= 0x01;
      // eslint-disable-next-line no-bitwise
      else if (item === 'community') authValue |= 0x02;
      // eslint-disable-next-line no-bitwise
      else if (item === 'program') authValue |= 0x04;
      // eslint-disable-next-line no-bitwise
      else if (item === 'qna') authValue |= 0x08;
    });
    const newWalkwayLocalId =
      walkwayCommand?.localId?.length > 0
        ? walkwayCommand?.localId
        : walkwayLocalId?.length > 0
        ? walkwayLocalId
        : 0;
    const newAddress = location !== '' && location !== null ? location : '';
    const newConnectedIds =
      dataPortal.length > 0
        ? dataPortal.map((item: any) => item.portalId)
        : connectDataPortal !== undefined && connectDataPortal.length > 0
        ? connectDataPortal.map((item: any) => item.portalId)
        : [];

    const newData: IDataPortal = {
      regType: 'account',
      portalId: portalId,
      password: password,
      role: authValue,
      walkWayLocalId: newWalkwayLocalId,
      amdCode: amdCode !== null ? amdCode : '',
      constime: newConstime !== null ? newConstime.getTime() / 1000 : 0,
      activate: newActivate !== null ? newActivate.getTime() / 1000 : 0,
      key: agency,
      keyList: newKeyList,
      x: newLa,
      y: newLo,
      name1: manager,
      phoneno1: phoneno,
      email1: email,
      name2: '',
      phoneno2: '',
      email2: '',
      operator: operator,
      memo: '',
      locationAddress: newAddress,
      portalCode: portalCode,
      address: address,
      inhibitDuplicated: inhibitDuplicated ? 1 : 0,
      isManager: isManager ? 1 : 0,
      connectedIds: newConnectedIds,
    };
    console.log('registerDataPortalAccountMutation : ', newData);
    registerDataPortalAccountMutation(newData);
  };

  const { refetch: accountExistRefetch } = useQuery(
    KEY_DATAPORTAL_ACCOUNT_EXIST,
    () => {
      if (portalId !== '') return getDataPortalAccountExist(portalId);
      return null;
    },
    {
      onSuccess: (res: any) => {
        console.log('accountExistRefetch res :', res);
        if (res !== null) {
          setIsDuplicated(res !== null && res.resultCode === RESULT_OK ? 0 : 1);
          setOpenSnackbar(true);
          setDialogMessage(
            res !== null && res.resultCode === RESULT_OK
              ? '등록 가능한 아이디입니다.'
              : '중복 아이디입니다.'
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const checkValid = () => {
    const newConnectedIds =
      dataPortal.length > 0
        ? dataPortal.map((item: any) => item.portalId)
        : connectDataPortal !== undefined && connectDataPortal.length > 0
        ? connectDataPortal.map((item: any) => item.portalId)
        : [];

    var val = 0;

    if (val === 0 && isDuplicated === -1) val = 10;
    if (val === 0 && isDuplicated === 1) val = 11;

    if (val === 0 && (portalId === null || portalId.length === 0)) val = 1;
    if (val === 0 && (password === null || password.length === 0)) val = 2;
    // else if (agency === null || agency.length === 0) val = 3;
    // if (
    //   val === 0 &&
    //   (resultLatLng.split(' ')[0] === null ||
    //     resultLatLng.split(' ')[0].length === 0)
    // )
    //   val = 4;
    if (val === 0 && rangeDate[0] === null) val = 5;
    if (
      val === 0 &&
      (operator === null || operator.length === 0 || operator === '0')
    )
      val = 6;
    if (val === 0 && isManager && newConnectedIds.length === 0) val = 7;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '데이터 포탈 아이디를 입력해 주세요.';
          break;
        case 2:
          message = '데이터 포탈 비밀번호를 입력해 주세요.';
          break;
        case 3:
          message = '계약 기관을 선택해주세요.';
          break;
        case 4:
          message = '위치정보를 입력해주세요.';
          break;
        case 5:
          message = '데이터 포탈 운영기간을 입력해주세요.';
          break;
        case 6:
          message = '워크온 담당자를 선택해 주세요.';
          break;
        case 7:
          message = '하위 포털을 연결해주세요.';
          break;
        case 10:
          message = '사용할 포탈 아이디를 중복체크 해주세요.';
          break;
        case 11:
          message =
            '중복된 포털 아이디입니다. 포털 아이디 입력 후 중복 중복체크를 해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
    }

    return val;
  };

  const deleteAccount = () => {
    if (portalId.length === 0) {
      setDialogMessage('삭제할 포탈 계정을 선택해 주세요');
      setOpenDialog(true);
      return;
    }
    deleteDataPortalAccountMutation(portalId);
  };

  const checkDataPortalAccount = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }
    registerDataPortalAccount();
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      authorityIds?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      const newData = [...checkedButtons, id];
      setCheckedButtons(newData);
      if (
        newData.includes(authorityIds[0]) &&
        newData.includes(authorityIds[1]) &&
        newData.includes(authorityIds[2]) &&
        newData.includes(authorityIds[3])
      ) {
        setCheckedAll(true);
      }
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
      setCheckedAll(false);
    }
  };

  const onChangeAuthority = (evt: any) => {
    const newValue = evt.target.value;
    setAuthority(newValue);
  };

  const checkDuplicated = () => {
    if (portalId.length > 0) {
      accountExistRefetch();
    } else {
      setOpenSnackbar(true);
      setDialogMessage('생성할 포탈 아이디를 입력해 주세요');
    }
  };

  const reset = () => {
    console.log('reset');
    setIdDisabled(false);
    setIsDuplicated(-1);
    setSearchAgency('');
    setPortalId('walkon_');
    setPassword('');
    setManager('');
    setPhoneno('');
    setResultLatLng('');
    setRangeDate([null, null]);
    setWalkwayLocalName('');
    setRegionName('');
    setOperator('0');
    setAgency('');
    setCheckedAll(false);
    setAddress('');
    setLocationAddress('');
    setLocation('');
    setPortalCode('');
    setAuthority(0);
    setIsManager(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setOpenPortal(true);
    }
  };

  const handleIsManager = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsManager(true);
    } else {
      setIsManager(false);
    }
  };

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      setAmdCode(targetData.bcode); // 법정동코드
      setLocationAddress(newAddress);
    }
  }, [targetData]);

  useEffect(() => {
    console.log('WalkwayCommand : ', walkwayCommand);
    if (walkwayCommand) {
      setWalkwayLocalName(walkwayCommand?.localName);
    }
  }, [walkwayCommand]);

  useEffect(() => {
    console.log('regionInfo : ', regionInfo);
    if (regionInfo) {
      setRegionName(regionInfo?.name);
      setAmdCode(regionInfo?.amd);
    }
  }, [regionInfo]);

  useEffect(() => {
    if (searchPortalAccount) {
      console.log('searchPortalAccount : ', searchPortalAccount);
      setAgency(searchPortalAccount.name);
      setManager(searchPortalAccount.userName1);
      setPhoneno(searchPortalAccount.userPhoneno1);
      setOperator(searchPortalAccount.operator);
      setAddress(searchPortalAccount.address);
      setLocationAddress(searchPortalAccount.locationAddress);
      setDetailAddress(searchPortalAccount.detailAddress);
      setLocation(searchPortalAccount.address);
      setPortalCode(searchPortalAccount.portalCode);
    }
  }, [searchPortalAccount]);

  useEffect(() => {
    setReload(false);
    if (user) {
      console.log('user:', user);
      setIsDuplicated(0);
      setPortalId(user.portalId);
      setPortalCode(user.portalCode);
      setIdDisabled(true);
      const newRangeDate = [];
      newRangeDate[0] = timestamp2Localestring(user.constime, 1000);
      newRangeDate[1] = timestamp2Localestring(user.activate, 1000);
      setRangeDate(newRangeDate);
      setAgency(user.parent);
      setPassword(user.password);
      setManager(user.userName1 ? user.userName1 : '');
      setPhoneno(user.userPhoneno1 ? user.userPhoneno1 : '');
      setEmail(user.userEmail1);
      setResultLatLng(`${user.y} ${user.x}`);
      setOperator(user.operator ? user.operator.trim() : '');
      setAuthority(user.role);
      setWalkwayLocalId(user.walkWayLocalId);
      setWalkwayLocalName(user.walkWayLocalName ? user.walkWayLocalName : '');
      setRegionName(user.amdName ? user.amdName : '');
      setAmdCode(user.amdCode);
      setSearchAgency('');
      setAddress(user.address);
      setLocationAddress(user.locationAddress);
      setDetailAddress(user.detailAddress);
      setLocation(
        user.address !== null && user.address !== '' ? `${user.address}` : ''
      );
      setInhibitDuplicated(user.inhibitDuplicated !== 0);

      setCheckedButtons([]);
      if (user.role === 0) {
        setCheckedAll(false);
      }
      // eslint-disable-next-line no-bitwise
      if (user.role & 0x01)
        setCheckedButtons(current => [...current, authorityIds[0]]);
      // eslint-disable-next-line no-bitwise
      if (user.role & 0x02)
        setCheckedButtons(current => [...current, authorityIds[1]]);
      // eslint-disable-next-line no-bitwise
      if (user.role & 0x04)
        setCheckedButtons(current => [...current, authorityIds[2]]);
      // eslint-disable-next-line no-bitwise
      if (user.role & 0x08)
        setCheckedButtons(current => [...current, authorityIds[3]]);
      // eslint-disable-next-line no-bitwise
      if ((user.role & 0x0f) === 0x0f) setCheckedAll(true);
      else setCheckedAll(false);

      setIsManager(user.isManager === 1);
      if (user.isManager === 1) {
        const newConnectedIds = JSON.parse(user.connectedIds);
        const newPortal = newConnectedIds.map((item: any) => {
          const newData = {
            portalId: item,
            name: '',
          };
          return newData;
        });
        setConnectDataPortal(newPortal !== null ? newPortal : []);
      } else {
        console.log('##> newPortal');
        setConnectDataPortal([]);
      }
    } else reset();
  }, [user]);

  return (
    <CreatePaper>
      <ContentBox>
        <ContentTitle>데이터 포탈 아이디</ContentTitle>
        <TextField
          sx={{ width: '18rem' }}
          placeholder='아이디를 입력해 주세요.'
          inputRef={idRef}
          value={portalId}
          disabled={idDisabled}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setPortalId(evt.target.value.trim());
            setIsDuplicated(-1);
          }}
        />
        <Button
          sx={{ ml: '1rem' }}
          variant='contained'
          color='info'
          disabled={idDisabled}
          onClick={() => checkDuplicated()}
        >
          중복체크
        </Button>
        <Divider />
      </ContentBox>
      <ContentBox>
        <ContentTitle>데이터 포탈 비밀번호</ContentTitle>
        <TextField
          sx={{ width: '18rem' }}
          placeholder='비밀번호를 입력해 주세요.'
          inputRef={passwordRef}
          type={view ? 'text' : 'password'}
          value={password}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setPassword(evt.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setView(!view)}>
                  {view ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Divider />
      </ContentBox>
      <ContentBox>
        <ContentTitle>계약 기관 선택</ContentTitle>
        <Box>
          <TextField
            sx={{ width: '30rem' }}
            placeholder='계약 기관 아이디로 검색해 주세요.'
            inputRef={searchRef}
            value={searchAgency}
            onKeyDown={handleKeyDown}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchAgency(evt.target.value)}
            helperText=''
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setOpenPortal(true)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TextField
          sx={{ width: '30rem', m: '.6rem 0' }}
          value={agency}
          InputProps={{ readOnly: true }}
        />
        <ContentTitle>포탈 운영자 정보</ContentTitle>
        <TextField
          sx={{ width: '18rem', mr: '.6rem', mb: '.6rem' }}
          inputRef={operatorRef}
          InputProps={{ readOnly: true }}
          value={manager}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setManager(evt.target.value)}
        />
        <TextField
          sx={{ width: '18rem' }}
          inputRef={operatorNumberRef}
          InputProps={{ readOnly: true }}
          value={phoneno}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setPhoneno(evt.target.value)}
        />
        <Box>
          <Box className='flex_start'>
            <ContentTitle>위치 정보</ContentTitle>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='contained'
              color='info'
              onClick={() => {
                setOpenPostCode(1);
              }}
            >
              주소찾기
            </Button>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='contained'
              color='inherit'
              onClick={() => {
                setResultLatLng('');
                setLocationAddress('');
              }}
            >
              위치 정보 초기화
            </Button>
          </Box>
          <SwFindLatLngbyKakaomap
            setLatLng={setResultLatLng}
            location={locationAddress}
            setLocation={setLocationAddress}
            value={resultLatLng}
            disabled={
              !(locationAddress === null || locationAddress.length === 0)
            }
          />
          {/* <Box className='flex_start'>
            <Typography>상세 주소</Typography>
            <TextField
              sx={{ ml: '1rem', mt: '.5rem', width: '30%' }}
              value={detailAddress}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                if (locationAddress === null || locationAddress.length === 0)
                  setDetailAddress(evt.target.value);
              }}
            />
          </Box> */}
          {openPostCode && (
            <SwSearchPostCode
              open={!!openPostCode}
              onClose={() => setOpenPostCode(0)}
              setTarget={setTargetData}
            />
          )}
        </Box>
        <Divider />
      </ContentBox>
      <ContentBox>
        <Box className='flex_start'>
          <Checkbox
            checked={isManager}
            onChange={evt => handleIsManager(evt)}
          />
          <ContentTitle>통합 계정 여부</ContentTitle>
        </Box>
        {isManager && (
          <DataportalMultiConnect
            findDataportal={findDataPortal}
            setFindDataportal={setFindDataPortal}
            connectDataportal={connectDataPortal}
            setConnectDataportal={setConnectDataPortal}
            setTargetInfo={setDataPortal}
            isDetail={true}
            title='하위 포탈 연결'
          />
        )}
        <Divider />
      </ContentBox>
      <ContentBox>
        <ContentTitle>데이터 포탈 운영 기간</ContentTitle>
        <SwDateRangePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
        <Divider />
      </ContentBox>
      <ContentBox>
        <Box className='flex_start'>
          <ContentTitle>워크웨이 지역 연결(선택)</ContentTitle>
          <Button
            variant='contained'
            size='small'
            sx={{ ml: '1rem' }}
            onClick={() => setOpen(true)}
          >
            워크웨이 지역 조회
          </Button>
        </Box>
        <TextField sx={{ width: '30rem' }} disabled value={walkwayLocalName} />
        {/* <WalkwayRegionSort
          setWalkwayCommand={setWalkwayCommand}
          smallSpace={true}
        /> */}
        <Divider />
      </ContentBox>
      <ContentBox>
        <Box className='flex_start'>
          <ContentTitle>지역별 데이터(선택)</ContentTitle>
          <Button
            variant='contained'
            size='small'
            sx={{ ml: '1rem' }}
            onClick={() => setOpenAmd(true)}
          >
            지역 조회
          </Button>
        </Box>
        <TextField sx={{ width: '30rem' }} disabled value={regionName} />
        <Divider />
      </ContentBox>
      <ContentBox>
        <div className='flex_center'>
          <ContentTitle sx={{ mb: 0, mr: '6rem' }}>권한</ContentTitle>
          <FormGroup sx={{ flexDirection: 'row' }}>
            <FormControlLabel
              control={
                <Checkbox
                  value='all'
                  checked={checkedAll}
                  onChange={evt => handleSelectAllClick(evt)}
                />
              }
              label='전체'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={authorityIds[0]}
                  checked={checkedButtons.includes(authorityIds[0])}
                  onChange={evt =>
                    changeHandler(evt.currentTarget.checked, authorityIds[0])
                  }
                />
              }
              label='챌린지'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={authorityIds[1]}
                  checked={checkedButtons.includes(authorityIds[1])}
                  onChange={evt =>
                    changeHandler(evt.currentTarget.checked, authorityIds[1])
                  }
                />
              }
              label='커뮤니티'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={authorityIds[2]}
                  checked={checkedButtons.includes(authorityIds[2])}
                  onChange={evt =>
                    changeHandler(evt.currentTarget.checked, authorityIds[2])
                  }
                />
              }
              label='프로그램'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={authorityIds[3]}
                  checked={checkedButtons.includes(authorityIds[3])}
                  onChange={evt =>
                    changeHandler(evt.currentTarget.checked, authorityIds[3])
                  }
                />
              }
              label='질의응답'
            />
          </FormGroup>
        </div>
        <Divider sx={{ mt: 0 }} />
      </ContentBox>
      <ContentBox>
        <Box className='flex_start'>
          <Typography>동시 접속 차단</Typography>
          <Checkbox
            checked={inhibitDuplicated}
            onChange={() => setInhibitDuplicated(!inhibitDuplicated)}
            sx={{ p: 0, ml: '1rem', mr: '.5rem' }}
          />
          <Typography>{inhibitDuplicated ? '설정' : '미설정'}</Typography>
        </Box>
        <Divider sx={{ mt: 0 }} />
      </ContentBox>
      <ContentBox>
        <ContentTitle>워크온 담당자</ContentTitle>
        <TextField
          sx={{ width: '18rem' }}
          select
          value={operator}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setOperator(evt.target.value)}
        >
          <MenuItem key={0} value='0'>
            선택
          </MenuItem>
          {empList?.length > 0 &&
            empList.map((emp: any, idx: number) => (
              <MenuItem key={emp.id} value={emp.id}>
                {emp.name}
              </MenuItem>
            ))}
        </TextField>
      </ContentBox>

      <Box
        sx={{
          p: '1rem 0 2rem',
          display: 'flex',
        }}
        className='flex_between'
      >
        <Box>
          <Button
            color='info'
            variant='outlined'
            sx={{ mr: '1rem' }}
            onClick={reset}
          >
            신규 작성
          </Button>
        </Box>
        <Box>
          <Button
            color='info'
            variant='outlined'
            sx={{ mr: '1rem' }}
            onClick={deleteAccount}
          >
            삭제
          </Button>
          <Button
            color='info'
            variant='contained'
            onClick={checkDataPortalAccount}
          >
            저장
          </Button>
        </Box>
      </Box>
      <SwSearchContractCompany
        title={title !== '' ? title : '포털아이디'}
        searchKey='all'
        searchWord={searchRef.current?.value}
        isDetail={true}
        open={openPortal}
        onClose={() => {
          setOpenPortal(false);
        }}
        setTarget={setSearchPortalAccount}
      />
      <SwSearchWalkwayLocal
        open={open}
        onClose={() => setOpen(!open)}
        setTarget={setWalkwayCommand}
      />
      <SwSearchAmd
        open={openAmd}
        onClose={() => setOpenAmd(!openAmd)}
        setTarget={setRegionInfo}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </CreatePaper>
  );
};
