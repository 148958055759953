import { Box, Button, Chip, Collapse } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { CommunityForm } from './CommunityForm';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  getCommunityRequestList,
  getCommunityRequestDetail,
} from '../../../common/api/ApiCommunity';
import {
  KEY_COMMUNITY_REQUEST_LIST,
  KEY_COMMUNITY_REQUEST_DETAIL,
} from '../../../common/key';
import { ICommunity } from '../../../models/community';
import {
  addHyphenToPhoneno,
  timestamp2Localestring,
} from '../../../common/helper';

interface Props {}

const requestlists = [
  '커뮤니티 이름',
  '신청일자',
  '신청자',
  '연락처',
  '담당자',
];
const clicklist = ['신청자', '신청일자', '담당자'];

export const CommunityCreate: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const [isSend, setIsSend] = useState<boolean>(false);
  const [communityId, setCommunityId] = useState<string>('');
  const [communityDetail, setCommunityDetail] = useState<any>();
  const [communityList, setCommunityList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [communityData, setCommunityData] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [chosenRequest, setChosenRequest] = useState<any>([]);

  const {
    data,
    isLoading,
    refetch: communityRequestRefetch,
  } = useQuery(
    KEY_COMMUNITY_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getCommunityRequestList();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newCommunity =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newData = [
              item.name,
              timestamp2Localestring(item.createDate),
              item.ownerName,
              item.ownerPhoneno !== null && item.ownerPhoneno !== ''
                ? addHyphenToPhoneno(item.ownerPhoneno)
                : '-',
              item.operatorName,
            ];
            return newData;
          });
        setRequestList(newCommunity);
        setCommunityList(res ? res.creationRequest : []);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: communityRequestDetailRefetch } = useQuery(
    KEY_COMMUNITY_REQUEST_DETAIL,
    () => {
      console.log('communityId:', communityId);
      if (communityId.length > 0) return getCommunityRequestDetail(communityId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        res &&
          res.creationRequestDetail &&
          setCommunityData(res.creationRequestDetail);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newCommunityId: string) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_REQUEST_DETAIL, () =>
      getCommunityRequestDetail(newCommunityId)
    );
  };

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[2], Chosen[1], Chosen[3]];
    setIsSend(false);
    setChosenRequest([newChosen]);
    setCommunityId(communityList[i].communityId);
    prefetchDetail(communityList[i].communityId);
  };

  useEffect(() => {
    if (checkRequest) {
      communityRequestRefetch();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <>
      <Box sx={{ m: '1.5rem 0' }}>
        <Chip
          icon={<FiberNewOutlinedIcon />}
          label='확인'
          variant='outlined'
          color='primary'
          onClick={() => setCheckRequest(!checkRequest)}
        />
      </Box>
      {requestlists.length > 0 && (
        <Collapse in={checkRequest}>
          <RequestTable
            columns={requestlists}
            rows={requestList}
            title='요청'
            onclick={chooseRequest}
          />

          <Box sx={{ m: '2rem' }} />
          <RequestTable columns={clicklist} rows={chosenRequest} />
        </Collapse>
      )}
      <CommunityForm
        isSend={isSend}
        setIsSend={setIsSend}
        communityData={communityData}
        setCommunityData={setCommunityData}
      />
    </>
  );
};
