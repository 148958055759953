import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { ContentManageCategoryForm } from './ContentManageCategoryForm';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { ButtonBox, ContentTitle, MarginButton } from './ContentManageStyles';
import {
  KEY_CONTENT_MAMANGE_CONTENT_LIST,
  KEY_CONTENT_MAMANGE_LIST,
  KEY_PROGRAM_LIST,
} from '../../../common/key';
import { WhiteTextField, SwSwitch, TabBox } from '../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { newUuidId } from '../../commonComponent/SwUuidCreate';
import { ContentManageContentForm } from './ContentManageContentForm';
import {
  batchGenerateMagazineHtml,
  deleteContentManageCategory,
  deleteContentManageContent,
  getContentManageCategoryList,
  getContentManageContentList,
} from '../../../common/api/ApiContentManage';
import { useSession } from '../../../hooks/session';
import { SwMagazineCategory } from '../../commonComponent/program/SwMagazineCategory';
import { getMagazineSubType } from '../../../common/helperProgram';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const TagsChip = styled(Chip)({
  margin: '.2rem',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderColor: 'rgba(0, 0, 0, 0.5)',
});

const TagsHighChip = styled(Chip)({
  margin: '.2rem',
  color: '#FF6B81',
  borderColor: '#FF6B81',
});

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
});

const searchOption = [
  { value: 'all', title: '전체' },
  { value: 'title', title: '카테고리 제목' },
];

const movieSearchOption = [
  { value: 'all', title: '전체' },
  { value: 'content_title', title: '영상 제목' },
  { value: 'channel', title: '채널이름' },
  { value: 'youtube_key', title: '유튜브 key' },
  { value: 'keyword', title: '키워드' },
];

const magazineSearchOption = [
  { value: 'all', title: '전체' },
  { value: 'content_title', title: '매거진 제목' },
  { value: 'keyword', title: '키워드' },
];

const qnaSearchOption = [
  { value: 'all', title: '전체' },
  { value: 'content_title', title: '질의응답 제목' },
];

const lifestyleSearchOption = [
  { value: 'all', title: '전체' },
  { value: 'content_title', title: '생활습관 제목' },
];

const audioSearchOption = [
  { value: 'all', title: '전체' },
  { value: 'content_title', title: '오디오 제목' },
  { value: 'keyword', title: '키워드' },
];

const requestColumnlists = [
  '순서',
  '제목',
  '유형',
  '등록 일시',
  '수정 일시',
  '접근 권한',
  '상태',
  '삭제',
];
const requestColumnMovieLists = [
  '순서',
  '썸네일',
  '영상 제목',
  '채널 이름',
  '유튜브 key',
  '키워드',
  '유형',
  '등록 일시',
  '수정 일시',
  '상태',
  '삭제',
];
const requestColumnMagazineLists = [
  '순서',
  '매거진 제목',
  '키워드',
  '맞춤 콘텐츠',
  '유형',
  '등록 일시',
  '수정 일시',
  '상태',
  '삭제',
];
const requestColumnQnalists = [
  '순서',
  '카테고리 제목',
  '유형',
  '등록 일시',
  '수정 일시',
  '접근 권한',
  '상태',
  '삭제',
];

const requestColumnLifeStylelists = [
  '순서',
  '생활습관 제목',
  '유형',
  '등록 일시',
  '수정 일시',
  '상태',
  '삭제',
];

const requestColumnQnaContentlists = [
  '순서',
  '질문',
  '유형',
  '등록 일시',
  '수정 일시',
  '접근 권한',
  '상태',
  '삭제',
];

const requestColumnAudioLists = [
  '순서',
  '제목',
  '유형',
  '등록 일시',
  '수정 일시',
  '접근 권한',
  '상태',
  '삭제',
];

const requestColumnAudioContentLists = [
  '순서',
  '썸네일',
  '오디오 제목',
  '키워드',
  '유형',
  '등록 일시',
  '수정 일시',
  '상태',
  '삭제',
];

// const clickColumnlist = ['고객명', '신청일자', '담당자'];
const clickColumnlist = ['카테고리 제목', '유형', '등록일시'];
const clickColumnMovielist = ['영상 제목', '유튜브key', '등록일시'];
const clickColumnMagazinelist = ['매거진 제목', '키워드', '등록일시'];
const clickColumnQnalist = ['질문', '질문유형', '등록일시'];
const clickColumnLifeStylelist = ['생활습관 제목', '등록일시'];
const clickColumnAudiolist = ['오디오 제목', '키워드', '등록일시'];

interface Props {
  contentManageType: string;
}

export const ContentManageForm: React.FC<Props> = ({ contentManageType }) => {
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const location = useLocation();
  const { loginVal } = useSession();
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [depth, setDepth] = useState<number>(1);
  const [categoryHistory, setCategoryHistory] = useState<any[]>([]);
  const [subType, setSubType] = useState<any>();
  const [categoryClass, setCategoryClass] = useState<string>('category');
  const [tabValue, setTabValue] = useState(1);
  const [categoryType, setCategoryType] = useState<number>(
    contentManageType === 'movie'
      ? 1
      : contentManageType === 'magazine'
      ? 2
      : contentManageType === 'qna' && tabValue === 1
      ? 3
      : contentManageType === 'qna' && tabValue === 2
      ? 5
      : contentManageType === 'lifestyle'
      ? 4
      : 6
  );
  const [categoryTitle, setCategoryTitle] = useState<string>(
    contentManageType === 'movie'
      ? '영상'
      : contentManageType === 'magazine'
      ? '매거진'
      : contentManageType === 'qna'
      ? '질의응답'
      : contentManageType === 'lifestyle'
      ? '생활습관'
      : '오디오'
  );
  const [contentManageId, setContentManageId] = useState<string>('');
  const [contentManageList, setContentManageList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);

  const [rewardDrawUserList, setRewardDrawUserList] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('contentManage');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSwitch, setOpenSwitch] = useState<boolean>(true);
  const [closeSwitch, setCloseSwitch] = useState<boolean>(true);
  const [contentManageFilterInfo, setContentManageFilterInfo] = useState<any>();
  const [filter, setFilter] = useState<any>(null);
  const [contentManageInfo, setContentManageInfo] = useState<any>({
    save: false,
    depth: 1,
    status: 0,
  });
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [selectedTitles, setSelectedTitles] = useState<any[]>([1, 0, 0, 0, 0]);
  const [deleteId, setDeleteId] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [showCategoryForm, setShowCategoryForm] = useState<boolean>(false);
  const [showContentForm, setShowContentForm] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openMagazineCategory, setOpenMagazineCategory] =
    useState<boolean>(false);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  let requestParentCategoryId: any = '';

  const {
    data,
    isLoading,
    refetch: contentManageRefetch,
  } = useQuery(
    KEY_CONTENT_MAMANGE_LIST,
    () => {
      console.log(
        'checkRequest: ',
        checkRequest,
        'requestParentCategoryId: ',
        requestParentCategoryId
      );
      const newDate = new Date().getTime() / 1000;
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newSearchWord = searchRef.current.value;
      const newSearchKey = selectRef.current.value;
      const newRequest = {
        type: categoryType,
        parentCategoryId:
          requestParentCategoryId !== null ? requestParentCategoryId : '',
        status: status,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: newSearchWord,
        sortWay: 2, // desc
      };
      if (checkRequest && categoryType !== 0)
        return getContentManageCategoryList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('CMS requestList:', res);
        const newSelectedTitles = [...selectedTitles];
        const newContentManage =
          res &&
          res.contentManage &&
          res.contentManage.map((item: any, idx: number) => {
            const currentDate = new Date();
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const status = 0;
            const newCreateDate = timestamp2Localestring(item.createDate, 1000);
            const newUpdateDate = timestamp2Localestring(item.updateDate, 1000);
            const newData = [
              item.seq,
              item.title,
              categoryClass === 'category' ? '카테고리' : '콘텐츠',
              newCreateDate,
              newUpdateDate,
              '-',
              item.status === 0 ? 'close' : 'open',
            ];
            setDepth(
              item.depth !== undefined ? item.depth : categoryHistory.length
            );
            setSubType(item.subType);
            setParentCategoryId(
              item.parentCategoryId !== null ? item.parentCategoryId : ''
            );
            // if (item.subType !== null) {
            //   console.log('subType:', item.subType);
            //   newSelectedTitles[item.subType] = 1;
            // }
            if (contentManageId === item.categoryId) {
              setSelectedRow(idx);
              setContentManageInfo(item);
            }
            return newData;
          });
        setRequestList(newContentManage);
        setContentManageList(res ? res.contentManage : []);
        setTotalRecords(res.pagination.totalRecordCount);
        setSelectedTitles(newSelectedTitles);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const makeMovieContentTableItem = (newContentManage: any) => {
    const tmpContentManage = newContentManage.map((item: any, idx: number) => {
      const currentDate = new Date();
      const newDate = timestamp2Localestring(item.createDate, 1000);
      const status = 0;
      const tmpKeyword =
        item.keyword !== undefined &&
        item.keyword !== null &&
        item.keyword.length > 0
          ? JSON.parse(item.keyword)
          : [];
      const newKeyword = tmpKeyword.map((keyword: string, i: number) => ({
        t: keyword,
        h: 0,
      }));
      const newCreateDate = timestamp2Localestring(item.createDate, 1000);
      const newUpdateDate = timestamp2Localestring(item.updateDate, 1000);
      const newData = [
        item.seq,
        item.image,
        item.title,
        item.source,
        item.videoCode,
        newKeyword,
        categoryClass === 'category' ? '카테고리' : '콘텐츠',
        newCreateDate,
        newUpdateDate,
        item.status === false ? 'close' : 'open',
      ];
      setDepth(item.depth !== undefined ? item.depth : categoryHistory.length);
      if (contentManageId === item.contentId) {
        setSelectedRow(idx);
        setContentManageInfo(item);
      }
      return newData;
    });
    return tmpContentManage;
  };

  const makeMagazineContentTableItem = (newContentManage: any) => {
    const tmpContentManage = newContentManage.map((item: any, idx: number) => {
      const currentDate = new Date();
      const newDate = timestamp2Localestring(item.createDate, 1000);
      const status = 0;
      const newKeyword =
        item.keyword !== undefined &&
        item.keyword !== null &&
        item.keyword.length > 0
          ? JSON.parse(item.keyword)
          : [];
      // const newKeyword = tmpKeyword.map((keyword: any) => keyword.t);
      const newCustomContent =
        item.customContent !== undefined && item.customContent.length > 0
          ? JSON.parse(item.customContent)
          : [];
      const newCreateDate = timestamp2Localestring(item.createDate, 1000);
      const newUpdateDate = timestamp2Localestring(item.updateDate, 1000);
      const newData = [
        item.seq,
        item.title,
        newKeyword,
        newCustomContent.join('/'),
        categoryClass === 'category' ? '카테고리' : '콘텐츠',
        newCreateDate,
        newUpdateDate,
        item.status === false ? 'close' : 'open',
      ];
      setDepth(item.depth !== undefined ? item.depth : categoryHistory.length);
      if (contentManageId === item.contentId) {
        setSelectedRow(idx);
        setContentManageInfo(item);
      }
      return newData;
    });
    return tmpContentManage;
  };

  const makeQnaContentTableItem = (newContentManage: any) => {
    const tmpContentManage = newContentManage.map((item: any, idx: number) => {
      const currentDate = new Date();
      const newDate = timestamp2Localestring(item.createDate, 1000);
      const status = 0;
      const newCreateDate = timestamp2Localestring(item.createDate, 1000);
      const newUpdateDate = timestamp2Localestring(item.updateDate, 1000);
      const newData = [
        item.seq,
        item.title,
        categoryClass === 'category' ? '카테고리' : '콘텐츠',
        newCreateDate,
        newUpdateDate,
        '-',
        item.status === false ? 'close' : 'open',
      ];
      setDepth(item.depth !== undefined ? item.depth : categoryHistory.length);
      if (contentManageId === item.contentId) {
        setSelectedRow(idx);
        setContentManageInfo(item);
      }
      return newData;
    });
    return tmpContentManage;
  };

  const makeLifeStyleContentTableItem = (newContentManage: any) => {
    const tmpContentManage = newContentManage.map((item: any, idx: number) => {
      const currentDate = new Date();
      const newDate = timestamp2Localestring(item.createDate, 1000);
      const status = 0;
      const newCreateDate = timestamp2Localestring(item.createDate, 1000);
      const newUpdateDate = timestamp2Localestring(item.updateDate, 1000);
      const newData = [
        item.seq,
        item.title,
        categoryClass === 'category' ? '카테고리' : '콘텐츠',
        newCreateDate,
        newUpdateDate,
        item.status === false ? 'close' : 'open',
      ];
      setDepth(item.depth !== undefined ? item.depth : categoryHistory.length);
      if (contentManageId === item.contentId) {
        setSelectedRow(idx);
        setContentManageInfo(item);
      }
      return newData;
    });
    return tmpContentManage;
  };

  const makeAudioContentTableItem = (newContentManage: any) => {
    const tmpContentManage = newContentManage.map((item: any, idx: number) => {
      const currentDate = new Date();
      const newDate = timestamp2Localestring(item.createDate, 1000);
      const status = 0;
      const newKeyword =
        item.keyword !== undefined &&
        item.keyword !== null &&
        item.keyword.length > 0
          ? JSON.parse(item.keyword)
          : [];

      const newCreateDate = timestamp2Localestring(item.createDate, 1000);
      const newUpdateDate = timestamp2Localestring(item.updateDate, 1000);
      const newData = [
        item.seq,
        item.thumbImage,
        item.title,
        newKeyword,
        categoryClass === 'category' ? '카테고리' : '콘텐츠',
        newCreateDate,
        newUpdateDate,
        item.status === false ? 'close' : 'open',
      ];
      setDepth(item.depth !== undefined ? item.depth : categoryHistory.length);
      if (contentManageId === item.contentId) {
        setSelectedRow(idx);
        setContentManageInfo(item);
      }
      return newData;
    });
    return tmpContentManage;
  };

  const { refetch: contentManageContentRefetch } = useQuery(
    KEY_CONTENT_MAMANGE_CONTENT_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      const newDate = new Date().getTime() / 1000;
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newSearchWord = searchRef.current.value;
      const newSearchKey = selectRef.current.value;
      const newRequest = {
        type: categoryType,
        categoryId: categoryId !== null ? categoryId : '',
        status: status,
        favorite: 0,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: newSearchWord,
        sortWay: 2, // desc
      };
      if (checkRequest && categoryClass === 'content')
        return getContentManageContentList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('CMS requestList content :', res);
        if (res !== null) {
          const newContentManage =
            categoryType === 1
              ? makeMovieContentTableItem(res.contentManage)
              : categoryType === 2
              ? makeMagazineContentTableItem(res.contentManage)
              : categoryType === 3 || categoryType === 5
              ? makeQnaContentTableItem(res.contentManage)
              : categoryType === 4
              ? makeLifeStyleContentTableItem(res.contentManage)
              : categoryType === 6
              ? makeAudioContentTableItem(res.contentManage)
              : makeMovieContentTableItem(res.contentManage);
          setRequestList(newContentManage);
          setContentManageList(res ? res.contentManage : []);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newData: any) => {
    queryClient.prefetchQuery(KEY_CONTENT_MAMANGE_LIST, () =>
      getContentManageCategoryList(newData)
    );
  };

  const prefetchPage = (newPage: number) => {
    const newDate = new Date().getTime() / 1000;
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newSearchWord = searchRef.current.value;
    const newSearchKey = selectRef.current.value;
    const newRequest = {
      type: categoryType,
      parentCategoryId: parentCategoryId,
      status: status,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      page: newPage,
      rowsPerPage: rowsPerPage,
      searchKey: newSearchKey,
      searchWord: newSearchWord,
      sortWay: 2, // desc
    };
    prefetch(newRequest);
  };

  const prefetchParentCategorySearchWord = (
    newParentCategory: string,
    newSearchKey: string,
    newSearchWord: string
  ) => {
    const newDate = new Date().getTime() / 1000;
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newRequest = {
      type: categoryType,
      parentCategoryId: newParentCategory,
      status: status,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      page: 1,
      rowsPerPage: rowsPerPage,
      searchKey: newSearchKey,
      searchWord: newSearchWord,
      sortWay: 2, // desc
    };
    prefetch(newRequest);
  };

  const prefetchParentCategory = (newParentCategory: string) => {
    const newDate = new Date().getTime() / 1000;
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newSearchWord = searchRef.current.value;
    const newSearchKey = selectRef.current.value;
    const newRequest = {
      type: categoryType,
      parentCategoryId: newParentCategory,
      status: status,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      page: page,
      rowsPerPage: rowsPerPage,
      searchKey: newSearchKey,
      searchWord: newSearchWord,
      sortWay: 2, // desc
    };
    prefetch(newRequest);
  };

  const prefetchContent = (newCategoryId: string, newPage: number) => {
    const newDate = new Date().getTime() / 1000;
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newSearchWord = searchRef.current.value;
    const newSearchKey = selectRef.current.value;
    const newRequest = {
      type: categoryType,
      categoryId: newCategoryId,
      status: status,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      favorite: 0,
      page: newPage,
      rowsPerPage: rowsPerPage,
      searchKey: newSearchKey,
      searchWord: newSearchWord,
      sortWay: 2, // desc
    };
    queryClient.prefetchQuery(KEY_CONTENT_MAMANGE_CONTENT_LIST, () =>
      getContentManageContentList(newRequest)
    );
  };

  const prefetchCategoryType = (type: number, newParentCategory: string) => {
    const newDate = new Date().getTime() / 1000;
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newSearchWord = searchRef.current.value;
    const newSearchKey = selectRef.current.value;
    const newRequest = {
      type: type,
      parentCategoryId: newParentCategory,
      status: status,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      favorite: 0,
      page: page,
      rowsPerPage: rowsPerPage,
      searchKey: newSearchKey,
      searchWord: newSearchWord,
      sortWay: 2, // desc
    };
    prefetch(newRequest);
  };

  const searchContentManage = () => {
    setFilter(null);
    setPage(1);
    prefetchPage(1);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);

    console.log(
      'categoryClass:',
      categoryClass,
      'requestParentCategoryId : ',
      requestParentCategoryId
    );

    if (categoryClass === 'category') {
      prefetchPage(newPage);
    } else {
      const newIdx = categoryHistory.length - 1;
      const newCategory =
        categoryHistory[newIdx].categoryId !== undefined &&
        categoryHistory[newIdx].categoryId !== null
          ? categoryHistory[newIdx].categoryId
          : '';
      requestParentCategoryId = newCategory;
      prefetchContent(requestParentCategoryId, newPage);
    }
  };

  const addCategory = () => {
    if (categoryClass === 'content' && requestList.length > 0) {
      setAlertMessage(
        '콘텐츠가 목록에 생성되어 있으므로 카테고리 추가가 불가능합니다.'
      );
      setAlertOpen(true);
    } else {
      setCategoryClass('category');
      setContentManageInfo((prevState: any) => ({
        ...prevState,
        categoryId: null,
        parentCategoryId: parentCategoryId,
        type: categoryType,
        subType: subType,
        depth: depth,
        title: '',
        description: '',
        status: 1,
        save: false,
      }));
      if (
        (categoryType === 2 && categoryHistory.length === 2) ||
        (categoryType === 1 &&
          (categoryHistory.length === 1 || categoryHistory.length === 2)) ||
        (categoryType === 6 &&
          (categoryHistory.length === 1 || categoryHistory.length === 2))
      ) {
        const newTitles = selectedTitles.filter((item: any) => item !== 1);
        if (categoryType === 2 && newTitles.length === 0) {
          setAlertMessage('더 이상 생성할 수 있는 카테고리가 없습니다.');
          setAlertOpen(true);
        } else setOpenMagazineCategory(true);
      } else {
        setOpenMagazineCategory(false);
        setShowContentForm(false);
        setShowCategoryForm(true);
      }
    }
  };

  const addContent = () => {
    if (categoryClass === 'category' && requestList.length > 0) {
      setAlertMessage(
        '카테고리가 목록에 생성되어 있으므로 콘텐츠 추가가 불가능합니다.'
      );
      setAlertOpen(true);
    } else {
      setCategoryClass('content');
      setContentManageInfo((prevState: any) => ({
        ...prevState,
        idx: -1,
        categoryId: categoryId,
        contentId: null,
        questionId: null,
        optionList: [],
        save: false,
      }));
      setShowCategoryForm(false);
      setShowContentForm(true);
    }
  };

  const {
    mutate: deleteContentManageCategoryMutation,
    isError: isDeleteCategoryMutateError,
  } = useMutation(deleteContentManageCategory, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        prefetchParentCategory(parentCategoryId);
        setOpenSnackbar(true);
        setDialogMessage('카테고리를 삭제했습니다.');
        setShowCategoryForm(false);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `카테고리를 삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '카테고리를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteContentManageContentMutation,
    isError: isDeleteContentMutateError,
  } = useMutation(deleteContentManageContent, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('콘텐츠를 삭제했습니다.');
        contentManageContentRefetch();
        setShowContentForm(false);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `콘텐츠를 삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '콘텐츠를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteCategory = (id: string) => {
    const newData = {
      categoryId: id,
    };
    deleteContentManageCategoryMutation(newData);
  };

  const deleteContent = (id: string) => {
    const newData = {
      contentId: id,
    };
    deleteContentManageContentMutation(newData);
  };

  const saveData = () => {
    console.log('contentManageInfo:', contentManageInfo);
    if (contentManageInfo !== undefined && contentManageInfo !== null) {
      setContentManageInfo((prevState: any) => ({
        ...prevState,
        save: true,
        type: categoryType,
      }));
    } else {
      setContentManageInfo((prevState: any) => ({
        save: true,
        type: categoryType,
      }));
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchContentManage();
    }
  };

  const onDownloadContentManageList = () => {
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newRequest = {
      status: status,
      startDate: filter !== null ? filter.rangeDate[0] : 0,
      endDate: filter !== null ? filter.rangeDate[1] : 0,
      page: page,
      rowsPerPage: rowsPerPage,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
    };
    // downloadContentManageList(newRequest);
  };

  const chooseRequest = (i: number) => {
    const Chosen = requestList[i];
    const newData =
      categoryClass === 'content' && categoryType === 2
        ? Chosen[2].map((item: any) => item.t).join('/')
        : categoryClass === 'content' && categoryType === 6
        ? Chosen[3].map((item: any) => item.t).join('/')
        : Chosen[2];
    setSelectedRow(i);
    const newChosen =
      categoryClass === 'category'
        ? [Chosen[2], Chosen[1], Chosen[3]]
        : categoryType === 1
        ? [Chosen[2], Chosen[4], Chosen[7]]
        : categoryType === 2
        ? [Chosen[1], newData, Chosen[5]]
        : categoryType === 3 || categoryType === 5
        ? [Chosen[1], Chosen[2], Chosen[3]]
        : categoryType === 4
        ? [Chosen[1], Chosen[3]]
        : categoryType === 6
        ? [Chosen[2], newData, Chosen[5]]
        : [Chosen[2], Chosen[1], Chosen[3]];
    setChosenRequest([newChosen]);
    setContentManageId(contentManageList[i].contentManageId);
    const newContentInfo = { ...contentManageList[i] };
    newContentInfo.depth = categoryHistory.length;
    setContentManageInfo(newContentInfo);
    setCategoryId(contentManageList[i].categoryId);
    // show data
    if (categoryClass === 'category') {
      setShowContentForm(false);
      setShowCategoryForm(true);
    } else {
      setShowCategoryForm(false);
      setShowContentForm(true);
    }
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== contentManageInfo.rewardCount) {
      contentManageRefetch();
    }
  };

  const reloadContentManage = (newData: any) => {
    if (newData !== undefined && newData !== null) {
      if (categoryClass === 'category') {
        setContentManageId(newData.id);
        requestParentCategoryId = newData.parentCategoryId;
        contentManageRefetch();
      } else if (categoryClass === 'content') {
        setContentManageId(newData.id);
        requestParentCategoryId = newData.parentCategoryId;
        prefetchContent(newData.id, 1);
      }
    }
  };

  const searching = () => {
    const newIdx = categoryHistory.length - 1;
    const newParentCategoryId =
      newIdx >= 0 ? categoryHistory[newIdx].categoryId : '';
    const newCategory = newParentCategoryId !== null ? newParentCategoryId : '';
    requestParentCategoryId = newCategory;

    setPage(1);
    categoryClass === 'category'
      ? contentManageRefetch()
      : contentManageContentRefetch();
  };

  const doubleClickHandle = (idx: number) => {
    console.log('doubleClickHandle :', contentManageList[idx]);
    if (categoryClass === 'content') return;

    setPage(1);
    setRequestList([]);
    setParentCategoryId(contentManageList[idx].categoryId);
    requestParentCategoryId = contentManageList[idx].categoryId;
    const newHistory = [
      ...categoryHistory,
      {
        title: contentManageList[idx].title,
        categoryId: contentManageList[idx].categoryId,
        parentCategoryId: contentManageList[idx].parentCategoryId,
      },
    ];
    setCategoryHistory(newHistory);
    console.log('newHistory:', newHistory);

    if (contentManageList[idx].contentCount === 0 && newHistory.length < 3) {
      setCategoryClass('category');
      prefetchParentCategorySearchWord(
        requestParentCategoryId,
        selectRef.current.value,
        searchRef.current.value
      );
    } else {
      setCategoryClass('content');
      prefetchContent(contentManageList[idx].categoryId, 1);
    }

    setShowCategoryForm(false);
    setShowContentForm(false);
    setChosenRequest([]);
    setSelectedRow(-1);
    setSelectedTitles([1, 0, 0, 0, 0]);
  };

  const gotoTarget = (idx: number) => {
    if (idx === categoryHistory.length - 1) return;
    const newIdx = idx === -1 ? 0 : idx + 1;
    const newParentCategoryId = categoryHistory[newIdx].parentCategoryId;
    const newCategory = newParentCategoryId !== null ? newParentCategoryId : '';
    setCategoryClass('category');
    console.log('newParentCategoryId: ', newParentCategoryId);
    setParentCategoryId(newCategory);
    requestParentCategoryId = newCategory;
    setSelectedRow(-1);

    const newCategoryHistory = categoryHistory.filter(
      (item: any, i: number) => i <= idx
    );
    console.log('newCategoryHistory:', newCategoryHistory);
    setCategoryHistory(newCategoryHistory);
    setRequestList([]);

    searchRef.current.value = '';
    selectRef.current.value = 'all';
    setSearchKey('all');
    setSearchWord('');
    prefetchParentCategorySearchWord(newCategory, 'all', '');

    setShowCategoryForm(false);
    setShowContentForm(false);
    setChosenRequest([]);
    setSelectedRow(-1);
    setSelectedTitles([1, 0, 0, 0, 0]);
  };

  const tabChange = (event: SyntheticEvent, newValue: number) => {
    let newType = 3;
    switch (newValue) {
      case 1:
        setTabValue(1);
        break;
      case 2:
        setTabValue(2);
        newType = 5;
        break;
      default:
        setTabValue(1);
    }
    setCategoryClass('category');
    setCategoryType(newType);
    setParentCategoryId('');
    setCategoryHistory([]);
    requestParentCategoryId = '';
    setParentCategoryId(requestParentCategoryId);
    prefetchCategoryType(newType, requestParentCategoryId);
  };

  const searchContentMange = () => {
    setPage(1);
    if (categoryClass === 'category') {
      prefetchPage(1);
    } else {
      prefetchContent(categoryId, 1);
    }
  };

  // function makeKeyword(keyword: any) {
  //   console.log('keyword: ', keyword);
  //   return (
  //     <Box sx={{ width: '10rem' }}>
  //       {keyword.map((item: any, idx: number) =>
  //         item.h === 0 ? (
  //           <TagsChip
  //             key={`keyword_chil_${idx}`}
  //             label={item.t}
  //             variant='outlined'
  //           />
  //         ) : (
  //           <TagsHighChip
  //             key={`keyword_chil_${idx}`}
  //             label={item.t}
  //             variant='outlined'
  //           />
  //         )
  //       )}
  //     </Box>
  //   );
  // }

  function makeKeyword(keyword: any) {
    console.log('keyword: ', keyword);
    return (
      <Grid container spacing={0.5} sx={{ width: '15rem' }}>
        {keyword.map((item: any, idx: number) =>
          item.h === 0 ? (
            <Grid key={`keyword_chip_${idx}`} item xs>
              <TagsChip label={item.t} variant='outlined' />
            </Grid>
          ) : (
            <Grid key={`keyword_chip_${idx}`} item xs>
              <TagsHighChip label={item.t} variant='outlined' />
            </Grid>
          )
        )}
      </Grid>
    );
  }

  const {
    mutate: batchGenerateMagazineHtmlMutation,
    isError: isBatchHtmlMutateError,
  } = useMutation(batchGenerateMagazineHtml, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('매거진 HTML를 생성했습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `매거진 HTML를 생성하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '매거진 HTML를 생성하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const generateMagazineHtml = () => {
    batchGenerateMagazineHtmlMutation();
  };

  useEffect(() => {
    searchContentMange();
  }, [openSwitch, closeSwitch]);

  // useEffect(() => {
  //   searchContentMange();
  // }, [searchKey, searchWord]);

  useEffect(() => {
    searchContentMange();
  }, [rangeDate]);

  useEffect(() => {
    searchContentMange();
  }, [rowsPerPage]);

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      contentManageRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (filter !== null && filter !== undefined) {
      console.log('filter :', filter);
      setPage(1);
      prefetchPage(1);
    }
  }, [filter]);

  return (
    <>
      {(categoryType === 3 || categoryType === 5) && (
        <Box sx={{ m: '0 0' }}>
          <TabBox className='flex_between'>
            <Tabs value={tabValue} onChange={tabChange}>
              <Tab label='설문' value={1} />
              <Tab label='퀴즈' value={2} />
            </Tabs>
          </TabBox>
        </Box>
      )}
      <Box className='flex_between' sx={{ m: '1rem 0' }}>
        <Box className='flex_start'>
          <Button
            onClick={() => {
              gotoTarget(-1);
            }}
            sx={{ fontSize: '1.0rem', color: '#000000' }}
          >
            {categoryTitle}
          </Button>
          {categoryHistory.map((row: any, idx: number) => (
            <Button
              key={`category_history_${idx}`}
              sx={{ fontSize: '1.0rem', color: '#000000' }}
              onClick={() => {
                gotoTarget(idx);
              }}
            >{`> ${row.title}`}</Button>
          ))}
          <Typography sx={{ fontSize: '1.0rem', color: '#000000' }}>
            목록
          </Typography>
        </Box>
      </Box>
      <Box className='flex_between' sx={{ m: '1.5rem 0' }}>
        <Box>
          <SelectSearchBox
            searchOption={
              categoryClass === 'category'
                ? searchOption
                : categoryType === 1
                ? movieSearchOption
                : categoryType === 2
                ? magazineSearchOption
                : categoryType === 3 || categoryType === 5
                ? qnaSearchOption
                : categoryType === 4
                ? lifestyleSearchOption
                : audioSearchOption
            }
            optionValue={selectRef}
            searchWord={searchRef}
            defaultOption='all'
            isSearching={searching}
            placeholderString='검색'
          />
        </Box>
        <Box sx={{ mr: '2rem' }} className='flex_between'>
          <Box className='flex'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
          <TextField
            select
            value={rowsPerPage}
            defaultValue={10}
            onChange={evt => {
              setRowsPerPage(Number(evt.target.value));
            }}
            sx={{
              ml: '1rem',
              mb: 0,
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                height: '2rem',
              },
            }}
          >
            <MenuItem value={5}>5개씩 보기</MenuItem>
            <MenuItem value={10}>10개씩 보기</MenuItem>
            <MenuItem value={15}>15개씩 보기</MenuItem>
            <MenuItem value={20}>20개씩 보기</MenuItem>
          </TextField>
          {/* <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onDownloadContentManageList()}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button> */}
        </Box>
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>
              {categoryClass === 'category' ? '카테고리' : '콘텐츠'}
              <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
          <Box className='flex_between'>
            <Typography sx={{ mr: '.4rem' }}>Open</Typography>
            <SwSwitch
              defaultChecked={true}
              value={openSwitch}
              onChange={() => setOpenSwitch(!openSwitch)}
            />
            <Typography sx={{ ml: '1rem', mr: '.4rem' }}>Close</Typography>
            <SwSwitch
              defaultChecked={true}
              value={closeSwitch}
              onChange={() => setCloseSwitch(!closeSwitch)}
            />
          </Box>
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: '30vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {categoryClass === 'category' &&
                  (categoryType === 1 || categoryType === 2) &&
                  requestColumnlists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {categoryClass === 'category' &&
                  (categoryType === 3 || categoryType === 5) &&
                  requestColumnQnalists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {(categoryClass === 'category' ||
                  categoryClass === 'content') &&
                  categoryType === 4 &&
                  requestColumnLifeStylelists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {categoryClass === 'content' &&
                  categoryType === 1 &&
                  requestColumnMovieLists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {categoryClass === 'content' &&
                  categoryType === 2 &&
                  requestColumnMagazineLists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {categoryClass === 'content' &&
                  (categoryType === 3 || categoryType === 5) &&
                  requestColumnQnaContentlists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {categoryClass === 'category' &&
                  categoryType === 6 &&
                  requestColumnAudioLists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {categoryClass === 'content' &&
                  categoryType === 6 &&
                  requestColumnAudioContentLists.map((column, idx) => (
                    <TableCell
                      key={`category_tbl_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={`category_request_${idx}`}
                  onDoubleClick={() => {
                    doubleClickHandle(idx);
                  }}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) =>
                    categoryClass === 'content' &&
                    ((categoryType === 1 && rowIdx === 1) ||
                      (categoryType === 6 && rowIdx === 1)) ? (
                      <>
                        <TableCell
                          key={`category_cell_${rowIdx}`}
                          className={
                            contentManageList[idx].status === 0
                              ? 'not_important_row'
                              : ''
                          }
                        >
                          {row[rowIdx] !== undefined &&
                          row[rowIdx] !== null &&
                          row[rowIdx].length > 0 ? (
                            <img
                              src={`${imageUrlPrefix}${row[rowIdx]}`}
                              alt=''
                              width={50}
                              height={50}
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </>
                    ) : categoryClass === 'content' &&
                      ((categoryType === 1 && rowIdx === 5) ||
                        (categoryType === 2 && rowIdx === 2) ||
                        (categoryType === 6 && rowIdx === 3)) ? (
                      <>
                        <TableCell
                          key={`category_cell_${rowIdx}`}
                          className={
                            contentManageList[idx].status === 0
                              ? 'not_important_row'
                              : ''
                          }
                        >
                          {row[rowIdx] !== undefined &&
                            row[rowIdx] !== null &&
                            row[rowIdx].length > 0 &&
                            makeKeyword(row[rowIdx])}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          key={`category_cell_${rowIdx}`}
                          className={
                            contentManageList[idx].status === 0
                              ? 'not_important_row'
                              : ''
                          }
                        >
                          {row[rowIdx]}
                        </TableCell>
                      </>
                    )
                  )}
                  <TableCell>
                    <Button
                      size='small'
                      variant='outlined'
                      color='info'
                      onClick={evt => {
                        evt.stopPropagation();
                        setDeleteId(
                          categoryClass === 'category'
                            ? contentManageList[idx].categoryId
                            : contentManageList[idx].contentId
                        );
                        setOpenDelete(true);
                      }}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <RequestTable
        columns={
          categoryClass === 'category'
            ? clickColumnlist
            : categoryType === 1
            ? clickColumnMovielist
            : categoryType === 2
            ? clickColumnMagazinelist
            : categoryType === 3 || categoryType === 5
            ? clickColumnQnalist
            : categoryType === 4
            ? clickColumnLifeStylelist
            : categoryType === 6
            ? clickColumnAudiolist
            : clickColumnlist
        }
        rows={chosenRequest}
      />
      <Paper sx={{ p: '0rem 0', backgroundColor: '#F5F7FA' }}>
        {showCategoryForm === true && (
          <Box>
            <ContentManageCategoryForm
              contentManageInfo={contentManageInfo}
              setContentManageInfo={setContentManageInfo}
              reloadContentManage={reloadContentManage}
            />
          </Box>
        )}
        {showContentForm === true && (
          <Box>
            <ContentManageContentForm
              contentManageInfo={contentManageInfo}
              setContentManageInfo={setContentManageInfo}
              reloadContentManage={reloadContentManage}
            />
          </Box>
        )}
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box>
            <MarginButton
              disabled={
                parentCategoryId === null ||
                categoryHistory.length === 0 ||
                (categoryClass === 'category' && requestList.length > 0)
              }
              variant='outlined'
              color='info'
              onClick={() => {
                addContent();
              }}
            >
              콘텐츠 추가
            </MarginButton>
            <MarginButton
              disabled={
                categoryHistory.length === 3 ||
                (categoryClass === 'content' && requestList.length > 0)
              }
              variant='outlined'
              color='info'
              onClick={() => {
                addCategory();
              }}
            >
              카테고리 추가
            </MarginButton>
            {/* <MarginButton
              disabled={
                categoryHistory.length === 3 ||
                (categoryClass === 'content' && requestList.length > 0)
              }
              variant='outlined'
              color='info'
              onClick={() => {
                generateMagazineHtml();
              }}
            >
              배치 HTML 생성
            </MarginButton> */}
          </Box>
          <Box>
            <Button variant='contained' color='info' onClick={() => saveData()}>
              저장
            </Button>
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwMagazineCategory
        selectedTitles={selectedTitles}
        setSelectedTitles={setSelectedTitles}
        open={openMagazineCategory}
        onClose={() => setOpenMagazineCategory(false)}
        onSelected={title => {
          setContentManageInfo((prevState: any) => ({
            ...prevState,
            title: title,
            subType: getMagazineSubType(title),
          }));
          setSubType(getMagazineSubType(title));
          setShowContentForm(false);
          setShowCategoryForm(true);
        }}
      />
      <SwConfirmDialog
        contents={
          categoryClass === 'category'
            ? '카테고리 삭제 시 소속 콘텐츠/하위 카테고리가 모두 삭제됩니다. 삭제하시겠습니까?'
            : '삭제하시겠습니까?'
        }
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false);
          categoryClass === 'category'
            ? deleteCategory(deleteId)
            : deleteContent(deleteId);
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </>
  );
};
