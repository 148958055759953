import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ChangeEvent, useEffect, useState, Dispatch, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_PROGRAM_MISSION_MOVIE_DETAIL_INFO,
} from '../../../common/key';
import { IMAGE_SIZE_10MB_MESSAGE, pointPerAchieve } from './SwProgramCommon';
import { SwFileSetting } from '../SwFileSetting';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { useSession } from '../../../hooks/session';
import { GrayTypography } from '../../appTab/programManage/ProgramStyles';
import { generateUuidId } from '../../../common/helper';
import {
  putProgramCurriculumMissionDelete,
  getProgramMissionDetailInfo,
  postProgramMissionMovie,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { IUploadFile } from '../../../models/common';
import { SwSelectTemplate } from './SwSelectTemplate';
import { MultilineTextField } from '../../styles/Styles';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { getFileSize } from '../../../common/helperProgram';
import { SwWeekAndTimePicker } from '../dateSetting/SwWeekAndTimePicker';

interface Props {
  data: any;
  setData: Dispatch<React.SetStateAction<any>>;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onDelete: () => void;
  disabled?: boolean;
}

export const SwMissionMovie: React.FC<Props> = ({
  data,
  setData,
  open,
  onClose,
  onSave,
  onDelete,
  disabled = false,
}) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  // const [missionId, setMissionId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [inputType, setInputType] = useState<string>('direct');
  const [link, setLink] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [coverFile, setCoverFile] = useState<any>(null);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [openChangeInputType, setOpenChangeInputType] = useState<number>(0);
  const [missionId, setMissionId] = useState<string>('');
  const [weekId, setWeekId] = useState<string>('');
  const [isEdit, setIsEdit] = useState<number>(0);
  const [selectTarget, setSelectTarget] = useState<any>();
  const [hasWeekAndTime, setHasWeekAndTime] = useState<string>('no');
  const [attendWeeks, setAttendWeeks] = useState<any[]>([]);
  const [attendTimes, setAttendTimes] = useState<any[]>([]);

  const reset = () => {
    setTitle('');
    setPoint(10);
    setLink('');
    setCoverImage('');
    setCoverFile(null);
    setInputType('direct');
    setSelectTarget([]);
    setHasWeekAndTime('no');
    setAttendWeeks([]);
    setAttendTimes([]);
  };

  const resetTemplate = () => {
    setSelectTarget([]);
    setPoint(10);
  };

  const makeVideoContent = (mission: any) => {
    if (mission.inputType === 1) {
      setInputType('direct');
      setTitle(mission.title);
      setPoint(mission.successScore);
      setLink(mission.movieCode !== undefined ? mission.movieCode : '');
      setCoverImage(mission.image !== undefined ? mission.image : '');
    } else {
      const newData: any = {
        title: mission.title,
        description: mission.description,
        source: mission.source,
        image: mission.image,
      };
      setInputType('template');
      setLink(mission.movieCode !== undefined ? mission.movieCode : '');
      setSelectTarget([newData]);
    }
    const newTimes = [
      mission.attendStartTime
        ? `${mission.attendStartTime
            .toString()
            .padStart(2, '0')}:00~${mission.attendEndTime
            .toString()
            .padStart(2, '0')}:00`
        : '00:00~24:00',
    ];
    const newWeeks: [] =
      mission.attendWeeks && mission.attendWeeks.length > 0
        ? JSON.parse(mission.attendWeeks)
        : [];
    setAttendTimes(newTimes !== null ? newTimes : []);
    setAttendWeeks(newWeeks);
    setHasWeekAndTime(newWeeks.length > 0 ? 'yes' : 'no');
  };

  const {
    data: detailInfo,
    isLoading,
    refetch: programMissionRefetch,
  } = useQuery(
    KEY_PROGRAM_MISSION_MOVIE_DETAIL_INFO,
    () => {
      console.log('missionId: ', missionId);
      if (missionId.length > 0) {
        const newData: any = {
          type: 'movie',
          missionId: missionId,
        };
        return getProgramMissionDetailInfo(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('mission detailInfo :', res);
        if (res !== null && res.mission !== null) {
          makeVideoContent(res.mission);
        } else if (missionId.length > 0) {
          reset();
          setOpenSnackbar(true);
          setDialogMessage('데이터가 없습니다.');
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const saveData = () => {
    const newData = {
      missionId: missionId, // generateUuidId(),
      missionTitle: title,
      successScore: point,
      successCount: 1,
      link: link,
      image: coverImage,
    };
    setData(newData);
    onSave();
  };

  const {
    mutate: deleteProgramCurriculumMissionMutate,
    isError: isMutateDeleteError,
  } = useMutation(putProgramCurriculumMissionDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제되었습니다.');
        onSave();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const { mutate: postMissionMovieMutate, isError: isMutateError } =
    useMutation(postProgramMissionMovie, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('등록되었습니다.');
          onSave();
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage('등록이 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const registerCurriculumMission = (newUploadUrl: any) => {
    let isValid = 0;

    if (inputType === 'direct' && isValid === 0 && title.length === 0) {
      isValid = 1;
    }

    const newImage =
      coverFile !== null && newUploadUrl !== null && newUploadUrl.length > 0
        ? newUploadUrl
        : coverImage !== null && coverImage.length > 0
        ? coverImage
        : '';

    if (isValid === 0) {
      const newMissionData = {
        weekId: weekId,
        missionId: missionId,
        title: title,
        successScore: point,
        successCount: 1,
        link: link,
        image: newImage,
        description: inputType === 'direct' ? '' : selectTarget[0].description,
        source: inputType === 'direct' ? '' : selectTarget[0].source,
        inputType: inputType === 'direct' ? 1 : 2,
        attendWeeks: attendWeeks,
        attendTimes:
          attendTimes !== null && attendTimes.length > 0 ? attendTimes[0] : '',
        contentId:
          inputType === 'direct'
            ? ''
            : selectTarget[0].hasRelatedContent === 1
            ? selectTarget[0].contentId
            : '',
      };
      console.log('postMissionMovieMutate :', newMissionData);
      postMissionMovieMutate(newMissionData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '이름을 입력해 주세요';
          break;
        case 7:
          message = '커버 이미지를 등록해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setUploadUrl(res.uploadUrl.file.path);
        console.log('uploadFile uploadUrl:', uploadUrl);
        registerCurriculumMission(res.uploadUrl.file.path);
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const checkValid = () => {
    if (inputType === 'direct') {
      if (coverFile === null) {
        registerCurriculumMission(null);
      } else {
        if (coverFile !== null && getFileSize(coverFile) >= 10) {
          setOpenSnackbar(true);
          setDialogMessage(IMAGE_SIZE_10MB_MESSAGE);
          return;
        }

        if (coverFile !== null) {
          const uploadfile: IUploadFile = {
            type: 'program',
            file: coverFile,
          };
          uploadFileMutate(uploadfile);
        } else {
          registerCurriculumMission(null);
        }
      }
    } else {
      registerCurriculumMission(null);
    }
  };

  const deleteMissionMovie = () => {
    const newMissionData = {
      weekId: weekId,
      missionId: missionId,
      type: 'movie',
    };
    console.log('deleteMissionMovieMutate :', newMissionData);
    deleteProgramCurriculumMissionMutate(newMissionData);
  };

  const handleChange = (newInputType: number) => {
    setInputType(newInputType === 1 ? 'direct' : 'template');
    if (newInputType === 1) {
      reset();
    } else {
      resetTemplate();
    }
  };

  useEffect(() => {
    console.log('selectTarget: ', selectTarget);
    if (selectTarget !== undefined && selectTarget.length > 0) {
      setTitle(selectTarget[0].title);
      setLink(selectTarget[0].videoCode);
      setCoverImage(
        selectTarget[0].image !== undefined && selectTarget[0].image !== null
          ? selectTarget[0].image
          : ''
      );
      setCoverFile(null);
    }
  }, [selectTarget]);

  useEffect(() => {
    if (missionId !== undefined && missionId !== null && missionId.length > 0) {
      programMissionRefetch();
      data.isEdit === 2
        ? setIsEdit(2)
        : data.isEdit === 1
        ? setIsEdit(1)
        : setIsEdit(0);
    }
  }, [missionId]);

  useEffect(() => {
    if (open) {
      console.log('movie data:', data);
      setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
      setMissionId(data.missionId);
      setWeekId(data.weekId);

      if (data.missionId === null || data.missionId.length === 0) {
        reset();
        setIsEdit(0);
      }
    } else {
      setMissionId('');
      setWeekId('');
      setIsEdit(0);
      reset();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '75vh',
          margin: '40rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>{isEdit === 1 ? '영상 자세히보기' : '영상 추가하기'}</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      {isEdit === 1 ? (
        <DialogContent sx={{ p: '1rem 2rem' }}>
          <Box>
            <Typography className='title'>{title}</Typography>
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>달성시 획득 점수</Typography>
            <TextField sx={{ width: '16rem', mr: '1rem' }} value={point} />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>
              미션에 참여할 수 있는 요일/시간대 제한
            </Typography>
            {hasWeekAndTime !== 'no' ? (
              <SwWeekAndTimePicker
                times={attendTimes}
                setTimes={setAttendTimes}
                weeks={attendWeeks}
                setWeeks={setAttendWeeks}
                showButton={false}
                showSubTitle={true}
                maxLimit={1}
                disabled={true}
              />
            ) : (
              <Typography className=''>설정 안함</Typography>
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>링크 정보</Typography>
            <TextField fullWidth value={link} />
            <Divider />
          </Box>
          {inputType === 'direct' ? (
            <>
              {coverImage !== null && coverImage !== '' ? (
                <Box>
                  <Typography className='title'>커버 이미지</Typography>
                  <Box sx={{ m: '1rem 0' }}>
                    <img
                      src={`${imageUrlPrefix}${coverImage}`}
                      alt={`${imageUrlPrefix}${coverImage}`}
                      style={{
                        maxWidth: '10vw',
                        borderRadius: '14px',
                        width: '10rem',
                        height: 'auto',
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Paper sx={{ background: '#FAFBFB' }}>
              <Box className='flex_between' sx={{ m: '1rem 1rem' }}>
                <Box>
                  <img
                    src={`${imageUrlPrefix}${selectTarget[0].image}`}
                    alt=''
                    style={{
                      maxWidth: '10vw',
                      borderRadius: '14px',
                      width: '10rem',
                      height: 'auto',
                    }}
                  />
                </Box>
                <Box sx={{ ml: '1rem', mt: '1rem', width: '100%' }}>
                  <Typography sx={{ fontWeight: 700, mb: '.5rem' }}>
                    {selectTarget[0].title}
                  </Typography>
                  <Typography sx={{ color: '#858C97' }}>
                    {selectTarget[0].source}
                  </Typography>
                  <Divider sx={{ p: 0, m: '.5rem 0' }} />
                  <MultilineTextField
                    sx={{
                      color: '#858C97',
                      '& fieldset': { border: 'none' },
                    }}
                    multiline
                    fullWidth
                    rows={3}
                    value={selectTarget[0].description}
                  />
                </Box>
              </Box>
            </Paper>
          )}
        </DialogContent>
      ) : (
        <DialogContent sx={{ p: '1rem 2rem' }}>
          <Box>
            <Typography className='title'>제목</Typography>
            <TextField
              sx={{ width: '60%' }}
              placeholder='제목을 입력해주세요.'
              helperText={titleHelperText}
              inputRef={titleRef}
              onChange={evt => {
                setTitle(evt.target.value.substring(0, 20));
              }}
              value={title}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{title?.length} / 20</span>
                  </InputAdornment>
                ),
              }}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>달성시 획득 점수</Typography>
            <TextField
              sx={{ width: '16rem', mr: '1rem' }}
              select
              defaultValue={10}
              value={point}
              onChange={evt => setPoint(Number(evt.target.value))}
            >
              {pointPerAchieve.map(option => (
                <MenuItem key={`health_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>
              미션에 참여할 수 있는 요일/시간대 제한
            </Typography>
            <RadioGroup
              row
              aria-labelledby='movie-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              value={hasWeekAndTime}
              onChange={evt => {
                setHasWeekAndTime(evt.currentTarget.value);
                setAttendTimes([]);
                setAttendWeeks([]);
              }}
            >
              <FormControlLabel
                value='no'
                control={<Radio />}
                label='설정 안함'
              />
              <FormControlLabel value='yes' control={<Radio />} label='설정' />
            </RadioGroup>
            {hasWeekAndTime !== 'no' && (
              <SwWeekAndTimePicker
                times={attendTimes}
                setTimes={setAttendTimes}
                weeks={attendWeeks}
                setWeeks={setAttendWeeks}
                showButton={false}
                showSubTitle={true}
                maxLimit={1}
              />
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>영상 선택</Typography>
            <RadioGroup
              row
              aria-labelledby='movie-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              value={inputType}
              onChange={evt => {
                evt.currentTarget.value === 'direct'
                  ? setOpenChangeInputType(1)
                  : setOpenChangeInputType(2);
              }}
            >
              <FormControlLabel
                value='direct'
                control={<Radio />}
                label='직접입력'
              />
              <FormControlLabel
                value='template'
                control={<Radio />}
                label='템플릿'
              />
            </RadioGroup>
            {inputType === 'direct' ? (
              <TextField
                fullWidth
                onChange={evt => setLink(evt.target.value)}
                value={link}
                placeholder='sFPHNGpQvZ0'
                helperText='유튜브 링크의 v=XXXXXXXXXX 값을 입력해 주세요.'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                    padding: '.5rem',
                  },
                }}
              />
            ) : (
              <Box>
                {selectTarget !== undefined && selectTarget.length > 0 && (
                  <Paper sx={{ background: '#FAFBFB' }}>
                    <Box className='flex_between' sx={{ m: '1rem 1rem' }}>
                      <Box>
                        <img
                          src={`${imageUrlPrefix}${selectTarget[0].image}`}
                          alt=''
                          style={{
                            maxWidth: '10vw',
                            borderRadius: '14px',
                            width: '10rem',
                            height: 'auto',
                          }}
                        />
                      </Box>
                      <Box sx={{ ml: '1rem', mt: '1rem', width: '100%' }}>
                        <Typography sx={{ fontWeight: 700, mb: '.5rem' }}>
                          {selectTarget[0].title}
                        </Typography>
                        <Typography sx={{ color: '#858C97' }}>
                          {selectTarget[0].source}
                        </Typography>
                        <Divider sx={{ p: 0, m: '.5rem 0' }} />
                        <MultilineTextField
                          sx={{
                            color: '#858C97',
                            '& fieldset': { border: 'none' },
                          }}
                          multiline
                          fullWidth
                          rows={3}
                          value={selectTarget[0].description}
                        />
                      </Box>
                    </Box>
                  </Paper>
                )}
                <Box className='flex_center'>
                  <Button
                    sx={{ mr: '1rem', borderColor: '#00A2FF' }}
                    variant='outlined'
                    color='info'
                    size='small'
                    onClick={() => setOpenChangeInputType(2)}
                  >
                    🏷️템플릿에서 선택
                  </Button>
                </Box>
              </Box>
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>커버 이미지</Typography>
            <GrayTypography>*16:9(종횡비) 비율 권장</GrayTypography>
            <GrayTypography>
              *용량 10MB 이하의 JPG, PNG만 업로드 가능
            </GrayTypography>
            <SwFileSetting
              setImage={setCoverImage}
              image={
                coverFile !== null
                  ? coverImage
                  : coverImage !== ''
                  ? `${imageUrlPrefix}${coverImage}`
                  : ''
              }
              setFile={setCoverFile}
              file={coverFile}
            />
          </Box>
        </DialogContent>
      )}
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogActions>
        {isEdit === 1 ? (
          <Box className='flex_end'>
            <Button
              sx={{ mr: '1rem' }}
              variant='outlined'
              color='info'
              size='small'
              onClick={deleteMissionMovie}
            >
              삭제
            </Button>
            <Button
              disabled={disabled}
              variant='contained'
              color='info'
              size='small'
              onClick={() => {
                setIsEdit(2);
              }}
            >
              수정
            </Button>
          </Box>
        ) : isEdit === 2 ? (
          <Box className='flex_end'>
            <Button
              sx={{ mr: '1rem' }}
              variant='outlined'
              color='info'
              size='small'
              onClick={onClose}
            >
              취소
            </Button>
            <Button
              variant='contained'
              color='info'
              size='small'
              onClick={checkValid}
            >
              수정완료
            </Button>
          </Box>
        ) : (
          <Box className='flex_end'>
            <Button
              sx={{ mr: '1rem' }}
              variant='outlined'
              color='info'
              size='small'
              onClick={onClose}
            >
              취소
            </Button>
            <Button
              variant='contained'
              color='info'
              size='small'
              onClick={checkValid}
            >
              작성 완료
            </Button>
          </Box>
        )}
      </DialogActions>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={false}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwSelectTemplate
        categoryType={1}
        open={openTemplate}
        onClose={() => {
          setOpenTemplate(false);
        }}
        setTarget={setSelectTarget}
      />
      <SwConfirmDialog
        contents='기존에 선택한 영상이 사라집니다. 영상을 변경하시겠습니까?'
        open={!!openChangeInputType}
        onClose={() => setOpenChangeInputType(0)}
        onConfirm={() => {
          handleChange(openChangeInputType);
          setOpenChangeInputType(0);
          openChangeInputType === 2 && setOpenTemplate(true);
        }}
        confirm={1}
      />
    </Dialog>
  );
};
