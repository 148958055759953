import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { xapi } from '../../../hooks/session';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import {
  DraggablePaper,
  RowsCell,
  MultilineTextField,
} from '../../styles/Styles';
import {
  calculateDays,
  generateUuidId,
  NO_SELECT_ADVERTISER,
  REWARD_HEALTH_CERT_CONTENT,
  REWARD_HEALTH_CERT_TITLE,
  REWARD_MEMORIAL_CERT_CONTENT,
  REWARD_MEMORIAL_CERT_TITLE,
  timestamp2Localestring,
  timestamp2string,
  TOOLTIP_CHALLEGE_REWARD_DUPLICATED,
  TOOLTIP_CHALLEGE_REWARD_LOCATION,
  TOOLTIP_CHALLEGE_REWARD_MAX_COUNT,
  TOOLTIP_CHALLEGE_REWARD_RANGE,
  TOOLTIP_FONT_SIZE,
  trimWhiteSpace,
} from '../../../common/helper';
import {
  ButtonBox,
  ChallengeTypeBox,
  ChallengeTypeTitle,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ChallengeStyles';
import {
  IGuideTool,
  IChallengeCreate,
  IChallengeReward,
  IChallengeRewardBatch,
  DefaultChallengeCreate,
} from '../../../models/challenge';
import {
  deleteChallengeReward,
  getChallengeRewardList,
  registerChallengeReward,
} from '../../../common/api/ApiChallenge';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  KEY_CHALLENGE_REWARD_LIST,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NONE_QUESTION,
} from '../../../common/key';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwRewardApplyForm } from '../../commonComponent/SwRewardApplyForm';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { brandPointAdvertiserState } from '../../../common/atom';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { RESULT_OK } from '../../../common/resultCode';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';

const PointPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '8rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.2rem',
  boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
  borderBottom: '1px solid white',
  fontSize: '1rem',
});

const columns = [
  '순서',
  '리워드 이름',
  '수량',
  '지급 방법',
  '사용 방법',
  '상태',
  '삭제',
];

const columns11 = [
  '순서',
  '리워드 이름',
  '가게 이름',
  '수량',
  '지급 방법',
  '사용 방법',
  '상태',
  '삭제',
];

const guidetool: IGuideTool = {
  title: '소제목',
  contents: '안내내용',
  image: '',
};

const inforDataTemplate = [
  {
    title: '이름',
    content: '이름을 입력해주세요',
  },
  {
    title: '주소',
    content: '주소를 입력해주세요',
  },
  {
    title: '연락처',
    content: '연락처를 입력해주세요',
  },
];

const MAX_GUIDE_PAGE = 6;

const USE_BUTTON = '0/0';
const GIFTYCON = '0/1';
const COUPON = '3/0';
const BARCODE = '3/1';
const APPLY_FORM = '4/0';
const HEALTH_TICKET = '5/1';
const MEMORIAL_TICKET = '5/2';
const BRAND_POINT_TICKET = '6/0';

interface Props {
  rewardGivetype: any;
  rewardUsetype: any;
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
  saveRewardCount: (count: number) => void;
  reloadChallenge: (id: string) => void;
}

export const ChallengeReward: React.FC<Props> = ({
  rewardGivetype,
  rewardUsetype,
  challengeInfo,
  setChallengeInfo,
  saveRewardCount,
  reloadChallenge,
}) => {
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const guideTitleRef = useRef<any>();
  const guideContentsRef = useRef<any>();
  const scrollRef = useRef<any>();
  const infoTitleRef = useRef<any>();
  const infoContentRef = useRef<any>();
  const findLocalStoreRef = useRef<any>();
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [challengeId, setChallengeId] = useState<string>('');
  const [challengeType, setChallengeType] = useState<number>(1);
  const [rewardId, setRewardId] = useState<string>('');
  const [localStoreId, setLocalStoreId] = useState<string>('');
  const [localStoreName, setLocalStoreName] = useState<string>('');
  const [giveType, setGiveType] = useState<string>('');
  const [useType, setUseType] = useState<string>('0/0');
  const [isDuplicate, setIsDuplicate] = useState<string>('0');
  const [rewardMaxCount, setRewardMaxCount] = useState<number>(0);
  const [address, setAddress] = useState<string>('');
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });
  const [title, setTitle] = useState<string>('');
  const [guideTitle, setGuideTitle] = useState<any>('');
  const [guideContent, setGuideContent] = useState<any>('');
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<IGuideTool[]>([]);
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>('');
  const [formList, setFormList] = useState<any[]>([]);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [rewardImage, setRewardImage] = useState<string>('');
  const [rewardImageFile, setRewardImageFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [rewardLocation, setRewardLocation] = useState<string>('');
  const [rewardStatus, setRewardStatus] = useState<number>(1);
  const [rewardSequence, setRewardSequence] = useState<number>(1);
  const [rewardPointPerGet, setRewardPointPerGet] = useState<number>(0);
  const [rewardStampPerGet, setRewardStampPerGet] = useState<number>(0);
  const [uploadCouponFile, setUploadCouponFile] = useState<any>(null);
  const [uploadCouponFileName, setUploadCouponFileName] = useState<any>('');
  const [uploadCouponUrl, setUploadCouponUrl] = useState<any>('');
  const [couponLink, setCouponLink] = useState<any>('');
  const [couponCount, setCouponCount] = useState<any>(0);
  const [link, setLink] = useState<string>('');
  const [phoneNo, setPhoneNo] = useState<string>('');
  const [rewardStepPerGet, setRewardStepPerGet] = useState<boolean>(true);
  const [brandPointIndex, setBrandPointIndex] =
    useState<number>(NO_SELECT_ADVERTISER);
  const [applyformVersion, setApplyformVersion] = useState<number>(1);
  const [rewardValue, setRewardValue] = useState<number>(0);
  const [rewardCreateTime, setRewardCreateTime] = useState<number>(0);
  const [certTitle, setCertTitle] = useState<string>('');
  const [certContent, setCertContent] = useState<string>('');
  const [drawDate, setDrawDate] = useState<any[]>([]);
  const [authCount, setAuthCount] = useState<number>();
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [disabledEdit, setDisabledEdit] = useState<boolean>(false);
  const [openPoint, setOpenPoint] = useState<boolean>(false);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [selectTabId, setSelectTabId] = useState<number>(-1);
  const [checkOption, setCheckOption] = useState<boolean>(false);
  const [infoData, setInfoData] = useState<any>(inforDataTemplate);
  const guideItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  var guideIndex = 0;

  const {
    data,
    isLoading,
    refetch: challengeRewardRefetch,
  } = useQuery(
    KEY_CHALLENGE_REWARD_LIST,
    () => {
      console.log('challengeId: ', challengeId);
      if (challengeId.length > 0) return getChallengeRewardList(challengeId);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res !== null) {
          const newRewardList = res.reward.map((item: any, idx: number) => {
            const newGiveType =
              rewardGivetype[item.rewardGiveType]
                .challengeRewardGivetypeDescription;
            const newUseType = rewardUsetype
              .filter(
                (useItem: any) =>
                  useItem.challengeRewardUsetype === item.rewardUseType &&
                  useItem.challengeRewardUsesubtype === item.rewardUsetypeSub
              )
              .map((useItem: any) => useItem.challengeRewardUsetypeDescription);

            const newReward = {
              sequence: idx + 1,
              title: item.rewardTitle,
              storeName: item.storeName !== null ? item.storeName : '',
              count: item.rewardMaxCount,
              giveType: newGiveType,
              useType: newUseType,
              status: item.rewardStatus === 0 ? 'Close' : 'Open',
            };
            return newReward;
          });

          setRequestList(newRewardList);
          setRewardList(res.reward);
          saveRewardCount(newRewardList.length);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeRewardMutation,
    isError: isRewardrMutateError,
  } = useMutation(registerChallengeReward, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('챌린지 리워드 정보가 저장되었습니다.');
        reloadChallenge(challengeId);
        challengeRewardRefetch();
      } else {
        setOpenSnackbar(true);
        setAlertMessage(
          '챌린지 리워드 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '챌린지 리워드 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteChallengeRewardMutation,
    isError: isRewardrDeleteMutateError,
  } = useMutation(deleteChallengeReward, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setAlertMessage('챌린지 리워드 정보가 삭제되었습니다.');
      reloadChallenge(challengeId);
      challengeRewardRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '챌린지 리워드 정보를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const ChangePageContents = () => {
    setGuideTitle(
      guideLists[guidePage - 1] ? guideLists[guidePage - 1].title : '소제목'
    );
    setGuideContent(
      guideLists[guidePage - 1]
        ? guideLists[guidePage - 1].contents
        : '안내내용'
    );

    const newImage = guideLists[guidePage - 1]
      ? guideLists[guidePage - 1].image
      : '';
    setGuideImage(newImage);
  };

  function addGuideInfo() {
    if (guideContent.length > 0) {
      const newTitle =
        guideTitle !== null && guideTitle.length > 0 ? guideTitle : '';
      const newContents = guideContent;
      const newGuideLists = guideLists;
      const newGuideList = guideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuideList,
        title: trimWhiteSpace(newTitle),
        contents: trimWhiteSpace(newContents),
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      return newGuideLists;
    }
    return [];
  }

  const onChangeRewardGuideView = (_event: any, newguide: number) => {
    if (guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }
    setGuidePage(newguide);
    setGuideImage(guideLists[newguide - 1]?.image);
    setGuideImageFile(guideLists[newguide - 1]?.imageFile);
  };

  const addRewardGuideView = () => {
    if (guideLists.length === MAX_GUIDE_PAGE) {
      setAlertMessage('리워드 안내 내용은 최대 6까지 추가할 수 있습니다.');
      setAlertOpen(true);
      return;
    }

    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      const newGuide = [...newGuideLists, guidetool];
      setGuideLists(newGuide);
      setGuideImage('');
      setGuideImageFile(null);
      setGuidePage(guidePage + 1);
    }
  };

  const updateRewardGuideView = () => {
    if (guideContent.length > 0) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = [...guideLists];
      const newGuide = newGuideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        contents: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      setGuideLists(newGuideLists);
    }
  };

  const removeRewardGuideView = () => {
    console.log(guidePage);
    ChangePageContents();
    const newGuide = guideLists.filter((_guide, idx) => idx !== guidePage - 1);
    // const newGuidePage = guidePage - 1 < 1 ? guidePage + 1 : guidePage - 1;
    setGuideLists(newGuide);
    newGuide.length < guidePage && setGuidePage(guidePage - 1);
    // setGuidePage(guidePage);
    if (newGuide.length === 0) {
      setGuideTitle('');
      setGuideContent('');
      setGuidePage(1);
    }
  };

  const removeReward = (idx: number) => {
    const newData = {
      challengeId: challengeId,
      rewardId: rewardList[idx].rewardId,
    };
    deleteChallengeRewardMutation(newData);
  };

  const clickRow = (event: any, idx: number) => {
    setRewardSequence(idx + 1);

    if (event.target.classList.contains('MuiButton-root')) {
      return;
    }
    setSelectedRow(idx);
    setRewardId(rewardList[idx].rewardId);
    setLocalStoreId(
      rewardList[idx].storeId !== null ? rewardList[idx].storeId : ''
    );
    setLocalStoreName(
      rewardList[idx].storeName !== null ? rewardList[idx].storeName : ''
    );
    setGiveType(`${rewardList[idx].rewardGiveType}`);
    setUseType(
      `${rewardList[idx].rewardUseType}/${rewardList[idx].rewardUsetypeSub}`
    );
    setIsDuplicate(rewardList[idx].rewardIsDuplicate);
    const newRangeDate = [
      timestamp2Localestring(rewardList[idx].rewardUseStime, 1000),
      timestamp2Localestring(rewardList[idx].rewardUseEtime, 1000),
    ];
    setRangeDate(newRangeDate);
    setRewardMaxCount(rewardList[idx].rewardMaxCount);
    setTitle(rewardList[idx].rewardTitle);
    setRewardImage(
      rewardList[idx].rewardImgpathMain?.length > 0
        ? rewardList[idx].rewardImgpathMain.replace(
            'https://s3.ap-northeast-1.amazonaws.com/walkon-dev',
            ''
          )
        : ''
    );
    setRewardLocation(rewardList[idx].rewardAddress);
    setAddress(
      rewardList[idx].rewardAddress !== null
        ? rewardList[idx].rewardAddress
        : ''
    );
    rewardList[idx].rewardLocationLat !== 0 &&
    rewardList[idx].rewardLocationLng !== 0
      ? setResultLatLng(
          `${rewardList[idx].rewardLocationLat} ${rewardList[idx].rewardLocationLng}`
        )
      : setResultLatLng('');
    setRewardPointPerGet(rewardList[idx].rewardPointPerGet);
    setRewardStampPerGet(rewardList[idx].rewardStampPerGet);
    setRewardStepPerGet(rewardList[idx].rewardStepPerGet === 1);
    setImageUrlPrefix(rewardList[idx].imageUrlPrefix);
    (challengeType === 19 || challengeType === 21) &&
    rewardList[idx].rewardStampPerGet > 0
      ? setCheckOption(true)
      : setCheckOption(false);

    setCouponLink(rewardList[idx].rewardCouponLink);
    setCouponCount(rewardList[idx].rewardCouponCount);
    setUploadCouponFileName('');
    setUploadCouponFile(null);
    setGuidePage(1);

    const newDescription = JSON.parse(rewardList[idx].rewardDescription);
    const newGuideList =
      newDescription &&
      newDescription.map((item: any) => {
        const newGuide = {
          title: item.t,
          contents: item.c,
          image: item.i,
          imageFile: null,
        };
        return newGuide;
      });
    setGuideLists(newGuideList);

    const applyForm =
      rewardList[idx].rewardApplyform !== undefined &&
      rewardList[idx].rewardApplyform !== null
        ? JSON.parse(rewardList[idx].rewardApplyform)
        : [];
    const newAddedForm: any[] = [];
    const newApplyForm =
      applyForm.map !== undefined
        ? applyForm.map((item: any) => {
            const newOptions =
              item.options !== undefined && item.options.length > 0
                ? item.options.map((opt: any, i: number) => {
                    const d = { idx: i, content: opt };
                    return d;
                  })
                : [];
            item.sub_options !== undefined && item.sub_options.length > 0
              ? item.sub_options.map((opt: any, i: number) => {
                  const found = newAddedForm.filter(
                    (addFrm: any) => addFrm.title === opt.k
                  );
                  if (found === null || found.length === 0) {
                    let newOpts: any = [];
                    if (opt.type === 4) {
                      newOpts = opt.options.map((o: string) => {
                        const newData = {
                          content: o,
                        };
                        return newData;
                      });
                    }
                    newAddedForm.push({
                      type: opt.type === null ? 5 : Number(opt.type),
                      title: opt.k,
                      content: opt.i,
                      options: opt.type === 4 ? newOpts : opt.options,
                      hasSubOption: 0,
                      subOptions: [],
                      parentTitle: item.k,
                      parentOptionIndex: i,
                    });
                  }
                })
              : [];

            const newSubItems =
              item.sub_options !== undefined && item.sub_options.length > 0
                ? item.sub_options.map((opt: any, i: number) => opt)
                : [];
            const newSubOptions =
              item.options !== undefined && item.options.length > 0
                ? item.options.map((opt: string, i: number) => {
                    let tmpTitle = '';
                    if (newSubItems.length === 0) {
                      tmpTitle = KEY_WORD_NEXT_QUESTION;
                    } else {
                      const found = newSubItems.filter(
                        (st: any) => st.pk === opt
                      );
                      tmpTitle =
                        found !== null && found.length > 0
                          ? found[0].k
                          : KEY_WORD_NONE_QUESTION;
                    }
                    return tmpTitle;
                  })
                : [];
            const found = newSubOptions.filter(
              (opt: any) => opt !== KEY_WORD_NEXT_QUESTION
            );
            const newData = {
              type: item.type === null ? 5 : Number(item.type),
              title: item.k,
              content: item.i,
              options: newOptions,
              hasSubOption: found !== null && found.length > 0 ? 1 : 0,
              subOptions: newSubOptions,
              parentTitle: '',
              parentOptionIndex: -1,
            };
            return newData;
          })
        : inforDataTemplate;
    setInfoData([...newAddedForm, ...newApplyForm]);
    setFormList([...newAddedForm, ...newApplyForm]);
    setSelectTabId(-1);
    setLink(rewardList[idx].link !== null ? rewardList[idx].link : '');
    setPhoneNo(rewardList[idx].phoneNo !== null ? rewardList[idx].phoneNo : '');
    setRewardStatus(rewardList[idx].rewardStatus);

    const newIdx = brandPointAdvertiserList
      .map((item: any, i: number) =>
        item.brandPointId === rewardList[idx].brandPointId ? i : -1
      )
      .filter(item => item !== -1);

    if (newIdx !== null && newIdx.length > 0) {
      setBrandPointIndex(newIdx[0]);
    } else {
      setBrandPointIndex(NO_SELECT_ADVERTISER);
    }
    setApplyformVersion(rewardList[idx].applyformVersion);
    setRewardValue(rewardList[idx].rewardValue);
    setCertTitle(
      rewardList[idx].certTitle !== null ? rewardList[idx].certTitle : ''
    );
    setCertContent(
      rewardList[idx].certContent !== null ? rewardList[idx].certContent : ''
    );
    setDrawDate(
      rewardList[idx].rewardAutoDrawDate !== null
        ? [rewardList[idx].rewardAutoDrawDate * 1000]
        : []
    );
    setRewardCreateTime(rewardList[idx].rewardCreateTime);
  };

  function makeNewApplyForm() {
    console.log('--> formList:', formList);
    const newForm = formList
      .filter((item: any) => item.parentTitle === '')
      .map((item: any, idx: number) => {
        const newOptions =
          item.options !== undefined &&
          item.options !== null &&
          item.options.length > 0
            ? item.options.map((opt: any) => opt.content)
            : null;
        let newData: any;
        if (item.hasSubOption === 1) {
          const subOptions = item.subOptions
            .filter((subOption: string) => subOption !== KEY_WORD_NEXT_QUESTION)
            .map((subOption: string, i: number) => {
              const newSubForm = formList
                .filter((sub: any) => sub.title === subOption)
                .map((sub: any) => {
                  let newSubOptions: any;
                  if (sub.type === 4) {
                    const newOpt = sub.options.map((opt: any) => opt.content);
                    newSubOptions = {
                      type: sub.type,
                      pk: item.options[i].content,
                      k: sub.title,
                      v: '',
                      i: sub.content,
                      options: newOpt,
                    };
                  } else {
                    newSubOptions = {
                      type: sub.type,
                      pk: item.options[i].content,
                      k: sub.title,
                      v: '',
                      i: sub.content,
                    };
                  }
                  return newSubOptions;
                });
              return newSubForm !== null && newSubForm.length > 0
                ? newSubForm[0]
                : '';
            })
            .filter((subForm: any) => subForm !== '');
          newData =
            newOptions === null || newOptions.length === 0
              ? {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: '',
                  i: trimWhiteSpace(item.content),
                }
              : subOptions !== null && subOptions.length > 0
              ? {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: '',
                  i: trimWhiteSpace(item.content),
                  options: newOptions,
                  sub_options: subOptions,
                }
              : {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: '',
                  i: trimWhiteSpace(item.content),
                  options: newOptions,
                };
        } else {
          newData =
            newOptions !== null
              ? {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: '',
                  i: trimWhiteSpace(item.content),
                  options: newOptions,
                }
              : {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: '',
                  i: trimWhiteSpace(item.content),
                };
        }
        return newData;
      });
    return newForm;
  }

  const addChallengeReward = (
    newRewarId: string,
    newMainImageUrl: string,
    newGuideList: any
  ) => {
    const newUseSTime =
      rangeDate[0] === null
        ? 0
        : useType === HEALTH_TICKET || useType === MEMORIAL_TICKET
        ? challengeInfo.challengeAttendStime
        : new Date(rangeDate[0]).getTime() / 1000;
    const newUseETime =
      rangeDate[1] === null
        ? 0
        : useType === HEALTH_TICKET || useType === MEMORIAL_TICKET
        ? challengeInfo.challengeAttendEtime
        : new Date(rangeDate[1]).getTime() / 1000;
    const newMainImage =
      rewardImageFile !== null
        ? newMainImageUrl
        : rewardImage.length > 0
        ? rewardImage
        : '';
    const newCreator = challengeInfo.challengeCreator;
    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;
    const newDescription =
      newGuideList.length > 0
        ? newGuideList.map((item: any, idx: number) => {
            const newData = {
              idx: `${idx + 1}`.toString(),
              t: item.title,
              c: item.contents,
              i: item.uploadUrl !== null ? item.uploadUrl : '',
            };
            return newData;
          })
        : [];
    const newUseType = useType.split('/');

    let applyForm = '';
    let applyFormOld = '';
    if (useType === APPLY_FORM) {
      const newFormOld = formList.map((item: any, idx: number) => {
        console.log('item:', item);
        const newOptions =
          item.options !== undefined &&
          item.options !== null &&
          item.options.length > 0
            ? item.options.map((opt: any) => opt.content)
            : null;
        const newData = {
          k: trimWhiteSpace(item.title),
          v: '',
          i:
            newOptions !== null
              ? newOptions.join(',')
              : trimWhiteSpace(item.content),
        };
        return newData;
      });
      applyFormOld = JSON.stringify(newFormOld);

      const newForm = makeNewApplyForm();
      applyForm = JSON.stringify(newForm);
    }

    const newBrandPointId =
      useType === BRAND_POINT_TICKET
        ? brandPointAdvertiserList[brandPointIndex].brandPointId
        : '';

    const newDrawDate = drawDate.map(item => {
      const tmpDate = new Date(item);
      return tmpDate.getTime() / 1000;
    });

    if (newMainImage === undefined || newMainImage.length === 0) {
      setAlertMessage('리워드 이미지를 선택해주세요');
      setAlertOpen(true);
      return;
    }

    const newData: IChallengeReward = {
      rewardId: newRewarId,
      challengeId: challengeId,
      rewardTitle: trimWhiteSpace(title),
      rewardSponsored: challengeInfo.challengeCreatorName,
      rewardMaxCount: Number(rewardMaxCount),
      rewardUseStime: newUseSTime,
      rewardUseEtime: newUseETime,
      rewardUseDay: 0,
      rewardStatus: rewardStatus,
      rewardUseType: Number(newUseType[0]),
      rewardImgpathMain: newMainImage,
      rewardImgpathSub: '',
      rewardDescription: JSON.stringify(newDescription),
      rewardPointPerGet: rewardPointPerGet,
      rewardStampPerGet: rewardStampPerGet,
      rewardStepPerGet: rewardStepPerGet ? 1 : 0,
      rewardAddress: trimWhiteSpace(address),
      rewardContact: '',
      rewardCouponLink: couponLink,
      rewardIsDuplicate: Number(isDuplicate),
      rewardGiveType: Number(giveType),
      rewardSummary: '',
      rewardLocationLat: newLa,
      rewardLocationLng: newLo,
      rewardCreator: challengeInfo.challengeCreator,
      rewardEditor: '',
      rewardUsetypeSub: Number(newUseType[1]),
      rewardApplyform: useType === APPLY_FORM ? applyForm : '',
      link: link,
      phoneNo: phoneNo,
      brandPointId: newBrandPointId,
      storeId: localStoreId,
      applyformVersion: applyformVersion,
      rewardValue: rewardValue,
      certTitle: certTitle,
      certContent: certContent,
      rewardAutoDrawDate: newDrawDate[0],
    };

    const newRewards: IChallengeRewardBatch = {
      challengeId: challengeId,
      rewards: [newData],
    };
    console.log('addReward newData: ', newRewards);
    registerChallengeRewardMutation(newRewards);
  };

  const checkValidApplyForm = () => {
    const newFormlist = formList
      .filter((item: any) => item.parentTitle === '')
      .filter((item: any, idx: number) => {
        if (item.hasSubOption === 1) {
          const newOptions =
            item.options !== undefined &&
            item.options !== null &&
            item.options.length > 0
              ? item.options.map((opt: any) => opt.content)
              : null;
          const subOptions = item.subOptions.filter(
            (subOption: string) => subOption !== KEY_WORD_NEXT_QUESTION
          );

          return newOptions.length !== subOptions.length;
        }
        return false;
      });

    return newFormlist;
  };

  const checkValid = () => {
    const newCreator = challengeInfo.creator;
    const newForm = formList.map((item: any, idx: number) => {
      console.log('item:', item);
      const newOptions =
        item.options !== undefined &&
        item.options !== null &&
        item.options.length > 0
          ? item.options.map((opt: any) => opt.content)
          : null;
      const addTitle = newOptions !== null ? newOptions.join(',') : '';
      const newData =
        newOptions !== null
          ? {
              type: item.type,
              k: item.title,
              v: '',
              i: item.content,
              options: newOptions,
            }
          : {
              type: item.type,
              k: item.title,
              v: '',
              i: item.content,
            };
      return newData;
    });

    var val = 0;
    if (
      val === 0 &&
      useType !== HEALTH_TICKET &&
      useType !== MEMORIAL_TICKET &&
      (rangeDate[0] === null || rangeDate[1] === null)
    )
      val = 1;
    if (val === 0 && Number(rewardMaxCount) === 0) val = 2;
    if (val === 0 && title.length === 0) val = 3;
    if (val === 0 && useType === APPLY_FORM && newForm.length === 0) val = 4;
    if (
      val === 0 &&
      ((address !== null && resultLatLng === null) ||
        (address === null && resultLatLng !== null) ||
        (address.length !== 0 && resultLatLng.length === 0) ||
        (address.length === 0 && resultLatLng.length !== 0))
    )
      val = 5;
    if (
      val === 0 &&
      (useType === HEALTH_TICKET || useType === MEMORIAL_TICKET) &&
      certTitle.length === 0
    )
      val = 6;
    if (
      val === 0 &&
      (useType === HEALTH_TICKET || useType === MEMORIAL_TICKET) &&
      certContent.length === 0
    )
      val = 7;
    if (
      val === 0 &&
      challengeType === 11 &&
      giveType === '2' &&
      drawDate.length === 0
    )
      val = 8;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '리워드 사용 기간을 지정해주세요.';
          break;
        case 2:
          message = '리워드 수량을 입력해주세요';
          break;
        case 3:
          message = '리워드 이름을 입력해주세요';
          break;
        case 4:
          message = '정보 입력양식을 입력해주세요';
          break;
        case 5:
          message = '위치정보> 주소찾기 버튼을 사용해서 주소를 입력해주세요';
          break;
        case 6:
          message = '증서 이름을 입력해 주세요';
          break;
        case 7:
          message = '증서 내용을 입력해 주세요';
          break;
        case 8:
          message = '리워드 추첨일자를 지정해주세요';
          break;
        case 9:
          message = '필요한 청취 횟수를 입력해 주세요.';
          break;
        case 10:
          message = '필요한 완주 횟수를 입력해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    const newFormList = checkValidApplyForm();
    if (newFormList !== null && newFormList.length > 0) {
      newFormList.map((frm: any) => {
        const length =
          frm.options !== undefined &&
          frm.options !== null &&
          frm.options.length > 0
            ? frm.options.length
            : 0;

        let skip = false;
        frm.subOptions
          .filter((item: string, idx: number) => idx < length)
          .map((item: string, idx: number) => {
            if (item === KEY_WORD_NEXT_QUESTION && skip === false) {
              skip = true;
              setOpenSnackbar(true);
              setAlertMessage(
                `${frm.title} - ${frm.options[idx].content} 의 다음 질문 항목을 선택해 주세요.`
              );
            }
          });
      });
      val = 9;
    }

    return val;
  };

  async function uploadMultipleFiles(newRewarId: string) {
    var newUploadUrl = '';
    if (rewardImageFile !== null) {
      const newUploadFile: any = await uploadFile('challenge', rewardImageFile);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newGuideList = await Promise.all(
      guideLists.map(async (item: any, idx: number) => {
        var imageUrl = null;
        var newUploadFile: any = null;
        if (item.imageFile !== null) {
          newUploadFile = await uploadFile('challenge', item.imageFile);
        }

        return {
          title: item.title,
          contents: item.contents,
          image:
            item.image === null || item.image === undefined ? '' : item.image,
          imageFile: item.imageFile,
          uploadUrl:
            item.imageFile !== null
              ? newUploadFile.data.uploadUrl.file.path
              : null,
        };
      })
    );

    addChallengeReward(newRewarId, newUploadUrl, newGuideList);
  }

  const checkChallengeReward = (newRewarId: string) => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }

    // Guide 마지막 페이지 수정사항 저장
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }

    uploadMultipleFiles(newRewarId);
  };

  const uploadCoupon = () => {
    if (uploadCouponFile !== null) {
      const uploadfile: IUploadFile = {
        type: 'challenge',
        file: uploadCouponFile,
      };
      console.log('challenge reward coupon uploadfile :', uploadfile);
      var formData = new FormData();
      formData.append('file', uploadCouponFile);

      xapi
        .post(
          `/admin/v2/apis/upload/challenge/${challengeId}/reward/${rewardId}/coupon`,
          formData
        )
        .then(res => {
          if (res.status !== 200) throw new Error('http 에러');
          console.log('Upload Coupon List :', res);
          setOpenSnackbar(true);
          setAlertMessage(
            `쿠폰 ${res.data.coupon.length}개가 등록되어 있습니다.`
          );
          setCouponCount(res.data.coupon.length);
          challengeRewardRefetch();
          return res.data;
        });
    }
  };

  const copyChallengeReward = () => {
    const newRewardId = generateUuidId();
    setRewardId(newRewardId);
    checkChallengeReward(newRewardId);
  };

  const registerCoupon = () => {
    console.log('registerCoupon !!!');
    uploadCoupon();
  };

  const resetReward = () => {
    setTitle('');
    setRangeDate([null, null]);
    setRewardMaxCount(0);
    setRewardPointPerGet(0);
    setRewardStampPerGet(0);
    setRewardStepPerGet(false);
    setCouponLink('');
    setUploadCouponFileName('');
    setUploadCouponFile(null);
    setRewardImage('');
    setRewardImageFile(null);
    setGuideImage('');
    setGuideImageFile(null);
    setIsDuplicate('0');
    setSelectedRow(-1);
    setAddress('');
    setRewardLocation('');
    setResultLatLng('');
    setGuidePage(1);

    setGuideLists([]);
    setInfoData([]);
    setFormList([]);
    setSelectTabId(-1);
    setLink('');
    setPhoneNo('');
    setRewardStatus(1);

    setGuideTitle('');
    setGuideContent('');
    setApplyformVersion(1);
    setRewardValue(0);
    setCertTitle('');
    setCertContent('');
    setDrawDate([]);
    setRewardCreateTime(0);
  };

  const resetGiveUseType = () => {
    if (rewardGivetype) {
      console.log('ChallengeReward rewardGivetype :', rewardGivetype);
      setGiveType('0');
    }

    if (rewardUsetype) {
      console.log('ChallengeReward rewardUsetype :', rewardUsetype);
      const useTypeDefault = rewardUsetype.filter(
        (item: any) => item.challengeRewardUsetypeName === '직원 확인하기'
      );
      if (useTypeDefault.length > 0) {
        setUseType(
          `${useTypeDefault[0].challengeRewardUsetype}/${useTypeDefault[0].challengeRewardUsesubtype}`
        );
      }
    }
  };

  const clickInfoData = (i: number) => {
    setSelectTabId(i);
    infoTitleRef.current.value = infoData[i].title;
    infoContentRef.current.value = infoData[i].content;
  };

  const onChangeInfoData = () => {
    const newData = infoData.map((item: any, idx: number) => {
      const newInfo =
        idx === selectTabId
          ? {
              title: infoTitleRef.current.value,
              content: infoContentRef.current.value,
            }
          : item;
      return newInfo;
    });
    setInfoData(newData);
  };

  // Guide Drag & Drop
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: guideLists,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setGuideLists(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setGuideLists(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    guideItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    guideIndex = Number(evt.currentTarget.dataset.position);
    setGuidePage(guideIndex + 1);
    setListEvent({
      ...listEvent,
      clicked: newContain ? guideIndex : null,
    });
    setGuideTitle(guideLists[guideIndex].title);
    setGuideContent(guideLists[guideIndex].contents);
    setGuideImage(guideLists[guideIndex].image);
    setGuideImageFile(guideLists[guideIndex].imageFile);
  };

  const searchLocalStore = () => {
    setTargetTitle('가게');
    setSearchKey('localstore');
    setSearchWord(findLocalStoreRef.current?.value);
    setOpenSearchResult(true);
  };

  const changeUseType = (newType: string) => {
    setUseType(newType);
    if (newType === HEALTH_TICKET) {
      setCertTitle(REWARD_HEALTH_CERT_TITLE);
      setCertContent(REWARD_HEALTH_CERT_CONTENT);
    } else if (newType === MEMORIAL_TICKET) {
      setCertTitle(REWARD_MEMORIAL_CERT_TITLE);
      setCertContent(REWARD_MEMORIAL_CERT_CONTENT);
    } else {
      setCertTitle('');
      setCertContent('');
    }
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      if (type === 'store') searchLocalStore();
    }
  };

  const getRewardDateRange = (newRangeDate: any[]) => {
    const oneDay = 86400;
    const currentDay = new Date(Date.now()).getTime() / 1000;
    if (rewardCreateTime !== 0) {
      return [
        (rewardCreateTime + oneDay * 30) * 1000,
        (rewardCreateTime + oneDay * 60) * 1000,
      ];
    }
    return [
      (currentDay + oneDay * 30) * 1000,
      (currentDay + oneDay * 60) * 1000,
    ];
  };

  useEffect(() => {
    // challengeInfo.challengeStepsMax
    const currentDay =
      selectedRow !== -1
        ? rewardCreateTime * 1000
        : new Date(Date.now()).getTime() + 9 * 60 * 60 * 1000;
    const newDiffDay = calculateDays(currentDay, drawDate[0]);
    if (
      challengeInfo &&
      newDiffDay * challengeInfo.challengeStepsMax < rewardPointPerGet
    ) {
      setOpenSnackbar(true);
      setAlertMessage(
        `추첨일자까지 목표한 걸음수를 쌓을 수 없습니다. 다시 선택해 주세요.`
      );
    }
  }, [drawDate]);

  useEffect(() => {
    console.log('searchResult :', searchResult);
    if (searchResult.length > 0) {
      setLocalStoreId(searchResult[0].id);
      setLocalStoreName(searchResult[0].name);
    }
  }, [searchResult]);

  useEffect(() => {
    console.log('reward formList:', formList);
  }, [formList]);

  useEffect(() => {
    console.log('ChallengeReward RewardList: ', rewardList);
  }, [rewardList]);

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      setAddress(newAddress);
      setRewardLocation(newAddress);
    }
  }, [targetData]);

  useEffect(() => {
    updateRewardGuideView();
  }, [guideTitle, guideContent]);

  useEffect(() => {
    if (guideLists.length > 0) ChangePageContents();
  }, [guidePage, guideLists]);

  useEffect(() => {
    challengeRewardRefetch();
  }, [challengeId]);

  useEffect(() => {
    resetGiveUseType();
  }, [rewardGivetype, rewardUsetype]);

  useEffect(() => {
    if (challengeInfo) {
      setChallengeId(challengeInfo.challengeId);
      setChallengeType(challengeInfo.challengeType);
      setImageUrlPrefix(challengeInfo.imageUrlPrefix);
      if (challengeInfo.challengeType === 5) setDisabledEdit(true);
      else setDisabledEdit(false);
    }
    resetReward();
    resetGiveUseType();
  }, [challengeInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Paper>
        <Typography sx={{ m: '1rem 0 ' }}>리워드 목록</Typography>
        <TableContainer
          sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {challengeType !== 11 &&
                  columns.map((column, idx) => (
                    <TableCell
                      key={`reward_head_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                {challengeType === 11 &&
                  columns11.map((column, idx) => (
                    <TableCell
                      key={`reward_head_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={`reward_body_${idx}`}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                  onClick={event => clickRow(event, idx)}
                >
                  <TableCell>{row.sequence}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  {challengeType === 11 && (
                    <TableCell>{row.storeName}</TableCell>
                  )}
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{row.giveType}</TableCell>
                  <TableCell>{row.useType}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell className='removeButton' sx={{ p: '.1rem .6rem' }}>
                    <Button
                      color='info'
                      variant='contained'
                      onClick={() => {
                        setOpenMore(2);
                      }}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ p: '1.5rem 0' }}>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>리워드 순서</Typography>
            <TextField
              sx={{ width: '8rem' }}
              type='number'
              value={rewardSequence}
            />
          </Box>
          <SwUuidCreate
            disabled={disabledEdit}
            title='리워드'
            id={rewardId}
            setId={setRewardId}
          />
        </Stack>
        <Divider />
        {challengeType === 11 && (
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>가게 연결</Typography>
              <TextField
                placeholder='가게를 검색해 주세요.'
                sx={{ marginRight: '1rem', width: '21rem' }}
                inputRef={findLocalStoreRef}
                onKeyDown={evt => {
                  handleKeyDown(evt, 'store');
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          searchLocalStore();
                        }}
                        sx={{ pr: 0 }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                disabled={true}
                sx={{ width: '24rem' }}
                value={localStoreName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          findLocalStoreRef.current.value = '';
                          setLocalStoreId('');
                          setLocalStoreName('');
                        }}
                        sx={{ pr: 0 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            <Box>
              <Typography className='title'>가게 아이디</Typography>
              <TextField sx={{ width: '24rem' }} value={localStoreId} />
            </Box>
            <Divider />
          </Box>
        )}
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 2rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>지급 방법</Typography>
            <TextField
              disabled={disabledEdit}
              select
              sx={{ width: '15rem' }}
              value={giveType}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setGiveType(evt.target.value)}
            >
              {rewardGivetype &&
                rewardGivetype
                  .filter(
                    (item: any) => item.challengeRewardGivetypeIsUse === 1
                  )
                  .map((item: any, idx: number) => (
                    <MenuItem
                      key={`reward_givetype_${idx}`}
                      value={`${item.challengeRewardGivetype}`}
                    >
                      {item.challengeRewardGivetypeDescription}
                    </MenuItem>
                  ))}
            </TextField>
          </Box>
          <Box className='flex_start' alignItems='flex-start'>
            <Box>
              <Typography className='title'>사용 방법</Typography>
              <TextField
                disabled={disabledEdit}
                select
                sx={{ width: '15rem' }}
                value={useType}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => changeUseType(evt.target.value)}
              >
                {rewardUsetype &&
                  rewardUsetype.map((item: any, idx: number) => (
                    <MenuItem
                      key={`reward_usetype_${idx}`}
                      value={`${item.challengeRewardUsetype}/${item.challengeRewardUsesubtype}`}
                    >
                      {item.challengeRewardUsetypeName}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            {(useType === HEALTH_TICKET || useType === MEMORIAL_TICKET) && (
              <Box sx={{ ml: '1rem' }}>
                <Typography className='title'>증서 이름</Typography>
                <TextField
                  sx={{ width: '25rem' }}
                  value={certTitle}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setCertTitle(evt.target.value.substring(0, 16))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>{certTitle.length} / 16</span>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography className='title'>증서 내용</Typography>
                <TextField
                  multiline
                  rows={5}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: 'auto',
                      width: '25rem',
                    },
                  }}
                  value={certContent}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setCertContent(evt.target.value.substring(0, 150))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>{certContent.length} / 150</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
            {useType === BRAND_POINT_TICKET && (
              <Box sx={{ ml: '1rem' }}>
                <Typography className='title'>포인트 지급 브랜드</Typography>
                <TextField
                  select
                  sx={{ width: '10rem', mr: '.5rem' }}
                  value={brandPointIndex}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setBrandPointIndex(Number(event.target.value));
                  }}
                >
                  <MenuItem key='advertiser_99' value={NO_SELECT_ADVERTISER}>
                    선택
                  </MenuItem>
                  {brandPointAdvertiserList.map((item: any, idx: number) => (
                    <MenuItem key={`advertiser_${idx}`} value={idx}>
                      {item.pointName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            {useType === BRAND_POINT_TICKET && brandPointIndex !== 99 && (
              <Box sx={{ ml: '1rem' }}>
                <Typography className='title'>지급 포인트</Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ marginRight: '1rem' }}
                  value={rewardValue}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setRewardValue(Number(evt.target.value))}
                />
              </Box>
            )}
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>중복 발급</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLEGE_REWARD_DUPLICATED}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              disabled={disabledEdit}
              select
              sx={{ width: '10rem' }}
              value={isDuplicate}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setIsDuplicate(evt.target.value)}
            >
              <MenuItem key={0} value={0}>
                중복발급 불가
              </MenuItem>
              <MenuItem key={1} value={1}>
                중복발급 가능
              </MenuItem>
            </TextField>
          </Box>
        </Stack>
        <Divider />
        {challengeType === 6 || challengeType === 11 || challengeType === 17 ? (
          <Box>
            <div className='flex_center'>
              <Typography className='title'>필요한 마일리지</Typography>
              <TextField
                disabled={disabledEdit}
                value={rewardPointPerGet}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setRewardPointPerGet(Number(evt.target.value))}
              />
            </div>
            <Divider />
          </Box>
        ) : challengeType === 7 || challengeType === 9 ? (
          <Box>
            <div className='flex_center'>
              <Typography className='title'>
                {challengeType === 7
                  ? '필요한 스탬프 개수'
                  : '필요한 완주 횟수'}
              </Typography>
              <TextField
                disabled={disabledEdit}
                value={rewardStampPerGet}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setRewardStampPerGet(Number(evt.target.value))}
              />
            </div>
            <Divider />
          </Box>
        ) : challengeType === 12 ||
          challengeType === 13 ||
          challengeType === 14 ||
          challengeType === 15 ||
          challengeType === 16 ? (
          <Box>
            <Box className='flex_start'>
              <Checkbox
                checked={rewardStepPerGet}
                onChange={() => setRewardStepPerGet(!rewardStepPerGet)}
                sx={{ p: 0, mr: '.5rem' }}
              />
              <Typography className='title' sx={{ mr: '3rem' }}>
                걸음 수 목표 달성
              </Typography>
              <Typography className='title'>
                {challengeType === 12
                  ? '필요한 인증횟수'
                  : challengeType === 13
                  ? '필요한 완주 횟수'
                  : challengeType === 14
                  ? '필요한 스탬프 개수'
                  : challengeType === 16
                  ? '필요한 청취 횟수'
                  : ''}
              </Typography>
              {(challengeType === 12 ||
                challengeType === 13 ||
                challengeType === 14 ||
                challengeType === 16) && (
                <TextField
                  placeholder='숫자 입력'
                  sx={{ m: '.5rem' }}
                  value={rewardStampPerGet}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setRewardStampPerGet(Number(evt.target.value))}
                />
              )}
            </Box>
            <Divider />
          </Box>
        ) : challengeType === 18 ? (
          <>
            <Box>
              <Typography className='title'>필요 순위</Typography>
              <TextField
                disabled={disabledEdit}
                select
                sx={{ width: '15rem' }}
                value={rewardStampPerGet}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setRewardStampPerGet(Number(evt.target.value))}
              >
                <MenuItem value={0}>순위 선택</MenuItem>
                {new Array(JSON.parse(challengeInfo?.challengeFilters)?.length)
                  .fill(0)
                  .map((_, idx: number) => (
                    <MenuItem
                      key={`group_challenge_${idx + 1}`}
                      value={idx + 1}
                    >
                      {`${idx + 1}위`}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Divider />
          </>
        ) : challengeType === 19 ||
          challengeType === 20 ||
          challengeType === 21 ? (
          <>
            <Box className='flex_start'>
              {challengeType === 20 && (
                <Box sx={{ mr: '1rem' }}>
                  <Typography className='title'>필요한 스탬프 개수</Typography>
                  <TextField
                    disabled={disabledEdit}
                    value={rewardStampPerGet}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => setRewardStampPerGet(Number(evt.target.value))}
                  />
                </Box>
              )}
              {(challengeType === 19 || challengeType === 21) && (
                <>
                  <Box className='flex_start' sx={{ mt: '.5rem' }}>
                    <Checkbox
                      checked={checkOption}
                      onChange={() => {
                        const newOptin = !checkOption;
                        setCheckOption(newOptin);
                        if (!newOptin) setRewardStampPerGet(0);
                        if (challengeType === 21 && newOptin)
                          setRewardStampPerGet(challengeInfo.challengeGpsGoal);
                      }}
                      sx={{ p: 0, mr: '.5rem' }}
                    />
                    <Typography className='title' sx={{ mr: '3rem' }}>
                      {challengeType === 19
                        ? '완주 횟수 달성'
                        : '길 코스 목표 달성'}
                    </Typography>
                  </Box>
                  {challengeType === 19 && checkOption && (
                    <Box sx={{ mr: '1rem' }}>
                      <Typography className='title'>
                        필요한 완주 횟수
                      </Typography>
                      <TextField
                        disabled={disabledEdit}
                        value={rewardStampPerGet}
                        onChange={(
                          evt: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => setRewardStampPerGet(Number(evt.target.value))}
                      />
                    </Box>
                  )}
                </>
              )}
              <Box>
                <Typography className='title'>필요한 청취 횟수</Typography>
                <TextField
                  placeholder='숫자 입력'
                  value={rewardPointPerGet}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setRewardPointPerGet(Number(evt.target.value))}
                />
              </Box>
            </Box>
            <Divider />
          </>
        ) : (
          <></>
        )}
        {useType === '3/0' || useType === '3/1' ? (
          <Box>
            <div className='flex_center'>
              <Typography className='title' sx={{ width: '8rem' }}>
                쿠폰 사용처 링크
              </Typography>
              <TextField
                disabled={disabledEdit}
                sx={{ width: '28rem', mb: '.5rem' }}
                value={couponLink}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setCouponLink(evt.target.value)}
              />
            </div>

            <div className='flex_center'>
              <Typography className='title' sx={{ width: '8rem' }}>
                쿠폰 번호 넣기
              </Typography>
              <Box className='flex_center' sx={{ mb: '1rem' }}>
                <TextField
                  disabled
                  sx={{ width: '28rem', m: '0 .5rem 0 0' }}
                  value={
                    uploadCouponFile !== null
                      ? uploadCouponFile?.name
                      : uploadCouponFileName
                  }
                />
                <Button
                  variant='contained'
                  disabled={disabledEdit}
                  onClick={() => {
                    const newFile1 = document.getElementById('adminfile1');
                    newFile1?.click();
                  }}
                >
                  엑셀 파일선택
                </Button>
                <input
                  className='nonedisplay'
                  id='adminfile1'
                  type='file'
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    evt.target.files &&
                      setUploadCouponFile(evt.target.files[0]);
                    evt.target.value = '';
                  }}
                />
              </Box>
            </div>

            <div className='flex_center'>
              <Box>
                <Typography className='title'>
                  {`쿠폰 개수 : ${couponCount}`}
                </Typography>
                <Button
                  disabled={disabledEdit}
                  variant='outlined'
                  onClick={() => {
                    registerCoupon();
                  }}
                >
                  쿠폰 번호 등록
                </Button>
              </Box>
            </div>
            <Divider />
          </Box>
        ) : useType === '4/0' ? (
          <>
            <SwRewardApplyForm
              draggableName='draggable_form'
              formList={formList}
              setFormList={setFormList}
            />
            <Divider />
          </>
        ) : (
          <></>
        )}
        {challengeType === 11 && giveType === '2' && (
          <Box>
            <Typography className='title'>리워드 추첨일자</Typography>
            <Box className='flex_start'>
              <SwDatesPickerWithScroll
                dates={drawDate}
                setDates={setDrawDate}
                allowPastDate={false}
                showButton={false}
                maxLimit={1}
              />
              <Button
                sx={{ ml: '1rem', mr: '1rem' }}
                color='info'
                variant='outlined'
                onClick={() => {
                  setDrawDate([]);
                }}
              >
                Reset
              </Button>
            </Box>
            <Divider />
          </Box>
        )}
        {useType !== HEALTH_TICKET && useType !== MEMORIAL_TICKET && (
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>리워드 사용 기간</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLEGE_REWARD_RANGE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <SwDateRangePicker
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
              disabled={disabledEdit}
            />
            <Divider />
          </Box>
        )}
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>리워드 수량</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_CHALLEGE_REWARD_MAX_COUNT}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <TextField
            disabled={disabledEdit}
            sx={{ width: '10rem' }}
            value={rewardMaxCount}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setRewardMaxCount(Number(evt.target.value))}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>리워드 이름</Typography>
          <TextField
            disabled={disabledEdit}
            sx={{ width: '28rem' }}
            value={title}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setTitle(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>리워드 이미지</Typography>
          <SwFileSetting
            disabled={disabledEdit}
            image={
              rewardImageFile !== null
                ? rewardImage
                : rewardImage !== ''
                ? `${imageUrlPrefix}${rewardImage}`
                : ''
            }
            setImage={setRewardImage}
            setFile={setRewardImageFile}
            file={rewardImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>리워드 상세 정보(선택)</Typography>
          <Box>
            <TextField
              disabled={disabledEdit}
              placeholder='링크/홈페이지 입력'
              sx={{ width: '28rem', mr: '.5rem' }}
              value={link}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setLink(evt.target.value)}
            />
            <TextField
              disabled={disabledEdit}
              placeholder='전화번호 입력(-)포함'
              sx={{ width: '15rem' }}
              value={phoneNo}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setPhoneNo(evt.target.value)}
            />
          </Box>
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>리워드 안내 내용 (선택)</Typography>
            <div>
              <Button disabled={disabledEdit} onClick={updateRewardGuideView}>
                저장
              </Button>
              <Button disabled={disabledEdit} onClick={addRewardGuideView}>
                추가
              </Button>
              {guideLists.length > 0 && (
                <Button disabled={disabledEdit} onClick={removeRewardGuideView}>
                  삭제
                </Button>
              )}
            </div>
          </Box>
          <TextField
            disabled={disabledEdit}
            sx={{ width: '16rem' }}
            value={guideTitle}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setGuideTitle(evt.target.value)}
          />
          <TextField
            value={guideContent}
            fullWidth
            multiline
            rows={5}
            sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setGuideContent(evt.target.value)}
          />
          <SwFileSetting
            disabled={disabledEdit}
            image={
              guideImageFile !== null
                ? guideImage
                : guideImage !== ''
                ? `${imageUrlPrefix}${guideImage}`
                : ''
            }
            setImage={setGuideImage}
            setFile={setGuideImageFile}
            file={guideImageFile}
          />
          {/* <SwPagination
            page={guidePage}
            handleChangePage={onChangeRewardGuideView}
            count={guideLists.length}
          /> */}
          <Typography className='title'>리워드 안내 목록</Typography>
          {guideLists?.map((item: any, idx: number) => (
            <DraggablePaper
              className='draggable'
              key={`reward_drag_${idx}`}
              id={idx.toString()}
              variant='outlined'
              onDragStart={dragStart}
              onDragEnd={dragEnd}
              onDragOver={dragOver}
              onDrop={drop}
              onDragLeave={dragLeave}
              onDragEnter={dragEnter}
              draggable
              data-position={idx}
              onClick={selectList}
              sx={{ width: '50%' }}
            >
              <div>
                <span>{idx}. </span>
                <span>{item.title}</span>
              </div>
              <DragHandleIcon />
            </DraggablePaper>
          ))}
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Box className='flex_start'>
              <Typography className='title'>위치 정보(선택)</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLEGE_REWARD_LOCATION}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='contained'
              color='info'
              onClick={() => {
                setOpenPostCode(1);
              }}
            >
              주소찾기
            </Button>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='contained'
              color='inherit'
              onClick={() => {
                setResultLatLng('');
                setRewardLocation('');
                setAddress('');
              }}
            >
              위치 정보 초기화
            </Button>
          </Box>
          <SwFindLatLngbyKakaomap
            disabled={disabledEdit}
            elementId='pointMap'
            setLatLng={setResultLatLng}
            value={resultLatLng}
            location={rewardLocation}
            setLocation={setRewardLocation}
          />
          {openPostCode && (
            <SwSearchPostCode
              open={!!openPostCode}
              onClose={() => setOpenPostCode(0)}
              setTarget={setTargetData}
            />
          )}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            disabled={disabledEdit}
            sx={{ width: '12rem' }}
            select
            defaultValue={1}
            value={rewardStatus}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setRewardStatus(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
      </Box>
      <Box className='flex_end' sx={{ mt: '0rem' }}>
        <Box>
          <Button
            disabled={disabledEdit}
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={copyChallengeReward}
          >
            복사하기
          </Button>
          <Button
            disabled={disabledEdit}
            variant='contained'
            onClick={() => checkChallengeReward(rewardId)}
          >
            정보 저장
          </Button>
        </Box>
      </Box>
      <SwSearchTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      {openMore === 2 && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography sx={{ mt: '0.7rem' }}>
              {`'${
                rewardList[rewardSequence - 1].rewardTitle
              }' 쿠폰을 삭제하시겠습니까?`}
            </Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => {
                removeReward(rewardSequence - 1);
              }}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
};
