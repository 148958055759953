import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../common/key';

interface Props {
  planValue: number;
  setPlanValue: Dispatch<React.SetStateAction<number>>;
}

export const SwSupplyPlanButton: React.FC<Props> = ({
  planValue,
  setPlanValue,
}) => {
  const MAX_VALUE = 1000;

  const plusValue = () => {
    const newValue = planValue + 1 <= MAX_VALUE ? planValue + 1 : MAX_VALUE;
    setPlanValue(newValue);
  };

  const minusValue = () => {
    const newValue = planValue - 1 >= 0 ? planValue - 1 : 0;
    setPlanValue(newValue);
  };

  return (
    <Box>
      <Button variant='outlined' color='info' onClick={plusValue}>
        +
      </Button>
      <Button variant='outlined' color='info'>
        {planValue}
      </Button>
      <Button variant='outlined' color='info' onClick={minusValue}>
        -
      </Button>
    </Box>
  );
};
