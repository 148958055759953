import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { DepositManage } from './DepositManage';
import { RefundManage } from './RefundManage';
import { PaymentManage } from './PaymentManage';

interface Props {}

export const PaymentManagement: React.FC<Props> = () => {
  const { isMobile } = useResize();
  return (
    <div
      className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}
      style={{ padding: '3rem 2rem' }}
    >
      <Helmet>
        <title>결제 관리</title>
      </Helmet>
      <DepositManage />
      <RefundManage />
      <PaymentManage />
    </div>
  );
};
