import { Dispatch, Fragment, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Button, Grid, Paper, styled, Typography } from '@mui/material';
import { SwNaverMap } from './map/SwNaverMap';
import {
  KEY_STAMPTOUR_REQUEST_DETAIL,
  KEY_WALKCOURSE_REQUEST_DETAIL,
} from '../../common/key';
import { getStamptourRequestDetail } from '../../common/api/ApiStamptour';
import { useSession } from '../../hooks/session';
import { getWalkcourseRequestDetail } from '../../common/api/ApiWalkcourse';

interface Props {
  challengeInfo: any;
  targetInfo: any;
  setTargetInfo: Dispatch<React.SetStateAction<any>>;
}

const TitleTypography = styled(Typography)({
  padding: '.5rem .5rem .5rem .15rem',
  marginBottom: '.33rem',
  fontWeight: 500,
  fontSize: '1rem',
});
const GridRow = styled(Grid)({
  border: '1px solid #E7EDF6',
  backgroundColor: '#F9FAFC',
  borderLeft: 'none',
  padding: '.7em',
});
const GridCell = styled(Grid)({
  border: '1px solid #E7EDF6',
  borderLeft: 'none',
  padding: '.7em',
});

export const SwSelectGpsContent: React.FC<Props> = ({
  challengeInfo,
  targetInfo,
  setTargetInfo,
}) => {
  const { loginVal } = useSession();
  const [markers, setMarkers] = useState<any>([]);
  const [content, setContent] = useState<any[]>([]);
  const [challengeType, setChallengeType] = useState<any>(-1);
  const [stamptourId, setStamptourId] = useState<string>('');
  const [walkcourseId, setWalkcourseId] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const { refetch: stamptourRequestDetailRefetch } = useQuery(
    KEY_STAMPTOUR_REQUEST_DETAIL,
    () => {
      if (stamptourId && stamptourId.length > 0)
        return getStamptourRequestDetail(stamptourId);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.creationRequestDetail) {
          const tmpData = res.creationRequestDetail.stamp.map((stamp: any) => {
            const newDesc: any[] = [];
            if (stamp.description === null) {
              newDesc.push({
                description:
                  stamp.stampDescription !== null ? stamp.stampDescription : '',
                image:
                  stamp.stampImgpathMain !== null ? stamp.stampImgpathMain : '',
                idx: 1,
              });
            }
            const newImage =
              stamp.description !== null
                ? JSON.parse(stamp.description)
                : newDesc;
            const temp = {
              id: stamp.stampId,
              name: stamp.stampName,
              lat: stamp.stampPointLat,
              lng: stamp.stampPointLng,
              radius: stamp.stampRadius,
              image:
                newImage !== null && newImage.length > 0
                  ? newImage[0].image
                  : '',
            };
            return temp;
          });
          setContent(tmpData);

          const newMakers = tmpData.map((item: any, idx: number) => {
            const newPin = {
              lat: item.lat,
              lng: item.lng,
              content: `<div class='stamp_marker'><span class='stamp_marker_index'>${
                idx + 1
              }</span></div>`,
            };
            return newPin;
          });
          setMarkers(newMakers);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: walkcourseRequestDetailRefetch } = useQuery(
    KEY_WALKCOURSE_REQUEST_DETAIL,
    () => {
      if (walkcourseId && walkcourseId.length > 0)
        return getWalkcourseRequestDetail(walkcourseId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          const newSpot = res.creationRequestDetail.spot.map((spot: any) => {
            const temp = {
              id: spot.spotId,
              name: spot.spotName,
              lat: spot.spotPointLat,
              lng: spot.spotPointLng,
              radius: spot.spotRadius,
              image: spot.spotImage.length > 0 ? spot.spotImage : '',
            };
            return temp;
          });
          setContent(newSpot);

          const newMakers = newSpot.map((item: any, idx: number) => {
            const newPin = {
              lat: item.lat,
              lng: item.lng,
              content: `<div class='stamp_marker'><span class='stamp_marker_index'>${
                idx + 1
              }</span></div>`,
            };
            return newPin;
          });
          setMarkers(newMakers);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  useEffect(() => {
    if (stamptourId !== null) stamptourRequestDetailRefetch();
  }, [stamptourId]);

  useEffect(() => {
    if (walkcourseId !== null) walkcourseRequestDetailRefetch();
  }, [walkcourseId]);

  useEffect(() => {
    setChallengeType(challengeInfo.challengeType);
    setStamptourId('');
    setWalkcourseId('');
    if (challengeInfo.challengeType === 20) {
      setStamptourId(challengeInfo.challengeStampTourId);
    } else if (
      challengeInfo.challengeType === 19 ||
      challengeInfo.challengeType === 21
    ) {
      setWalkcourseId(challengeInfo.challengeWalkCourseId);
    }
  }, [challengeInfo]);

  return (
    <>
      <Box sx={{ m: '1rem 0' }}>
        <Box className='flex_center' style={{ alignItems: 'flex-start' }}>
          {(challengeType === 19 ||
            challengeType === 20 ||
            challengeType === 21) && (
            <>
              <Box sx={{ width: '50%' }}>
                <SwNaverMap markerData={markers} />
              </Box>
              <Box sx={{ width: '50%' }}>
                <Grid
                  container
                  sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
                >
                  <GridRow item xs={2}>
                    {challengeType === 20 ? '스탬프 번호' : '장소 번호'}
                  </GridRow>
                  <GridRow item xs={3}>
                    이미지
                  </GridRow>
                  <GridRow item xs={4}>
                    이름
                  </GridRow>
                  <GridRow item xs={3}>
                    선택
                  </GridRow>
                  {content !== undefined && content.length > 0 ? (
                    content.map((item: any, idx: number) => (
                      <Fragment key={idx.toString()}>
                        <GridCell item xs={2}>
                          {idx + 1}
                        </GridCell>
                        <GridCell item xs={3}>
                          <img
                            src={
                              item.image.length > 0
                                ? `${imageUrlPrefix}${item.image}`
                                : ''
                            }
                            alt=''
                            style={{ width: '50%' }}
                          />
                        </GridCell>
                        <GridCell item xs={4}>
                          {item.name}
                        </GridCell>
                        <GridCell item xs={3}>
                          <Button
                            variant='outlined'
                            color={
                              targetInfo && targetInfo.id === item.id
                                ? 'info'
                                : 'primary'
                            }
                            onClick={() => setTargetInfo(item)}
                          >
                            {targetInfo && targetInfo.id === item.id
                              ? '해제'
                              : '선택'}
                          </Button>
                        </GridCell>
                      </Fragment>
                    ))
                  ) : (
                    <Fragment key='0'>
                      <GridCell item xs={12}>
                        콘텐츠가 비어있습니다.
                      </GridCell>
                    </Fragment>
                  )}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
