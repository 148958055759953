import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useRef, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { RequestPagingTable } from '../../commonComponent/table/SwRequestPagingTable';
import { PrivateInquiryContents } from './PrivateInquiryContents';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwRequestTable } from '../../commonComponent/table/SwRequestTable';
import {
  addHyphenToPhoneno,
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { getDataPortalQnAList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_QNA_LIST } from '../../../common/key';

interface Props {}

const columns = [
  '유형',
  '요청 기관 포탈',
  '요청 기관명',
  '연락처',
  '담당자',
  '1:1 문의 제목',
  '문의 요청 시간',
  '문의 답변 시간',
  '답변자',
];

const typeItem = [
  '일반 문의',
  '계약/구매문의',
  '서비스 개선건의',
  '오류 및 문제사항',
];

export const PrivateInquiry: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const [request, setRequest] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [qnaList, setQnaList] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<any>('all');
  const [searchWord, setSearchWord] = useState<any>('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [waitCount, setWaitCount] = useState<number>(0);
  const {
    data,
    isLoading,
    refetch: refetchDataPortalQnA,
  } = useQuery(
    KEY_DATAPORTAL_QNA_LIST,
    () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      setReload(false);
      if (request) return getDataPortalQnAList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('PrivateInquiry res :', res);
        if (res != null) {
          const newQna = res.qna.qna?.map((item: any) => {
            const newData = [
              typeItem[item.type],
              item.name,
              item.portalName,
              item.customerPhoneno !== null && item.customerPhoneno !== ''
                ? addHyphenToPhoneno(item.customerPhoneno)
                : '-',
              item.conwe,
              item.title,
              item.requestDate ? timestamp2Localestring(item.requestDate) : '',
              item.completeDate
                ? timestamp2Localestring(item.completeDate)
                : '',
              item.adminName,
              item.des,
            ];
            return newData;
          });
          setWaitCount(res.qna.qnaWait);
          setRequestList(newQna);
          setQnaList(res.qna.qna);
          setTotalRecords(res.qna.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    queryClient.prefetchQuery(KEY_DATAPORTAL_QNA_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord.trim()),
      };
      if (request) return getDataPortalQnAList(newData);
      return null;
    });
  };

  const handleChangeSearchWord = (newSearchWOrd: string) => {
    queryClient.prefetchQuery(KEY_DATAPORTAL_QNA_LIST, () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWOrd.trim()),
      };
      if (request) return getDataPortalQnAList(newData);
      return null;
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleChangeSearchWord(searchWord);
    }
  };

  useEffect(() => {
    if (reload) {
      refetchDataPortalQnA();
    }
  }, [reload]);

  return (
    <div
      className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}
      style={{ padding: '3rem 2rem' }}
    >
      <Helmet>
        <title>1:1 문의</title>
      </Helmet>
      <Paper>
        <Box className='flex_start' sx={{ padding: '0 1rem' }}>
          <Typography>
            1:1 문의 답변 대기 <span className='bluecontent'>{waitCount}</span>{' '}
            개
          </Typography>
          <TextField
            placeholder='포탈 아이디, 요청 기관명, 문의 제목, 내용, 답변자로 검색해 주세요.'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', ml: '1em' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => handleChangeSearchWord(searchWord)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <RequestPagingTable
          columns={columns}
          rows={requestList}
          page={page}
          rowsPerPage={rowsPerPage}
          totalCount={totalRecords}
          // title={`1:1 문의 답변 대기 ${waitCount}개`}
          onclick={i => setSelectedRequest(qnaList[i])}
          handleChangePage={handleChangePage}
        />
      </Paper>
      <PrivateInquiryContents request={selectedRequest} setReload={setReload} />
    </div>
  );
};
