import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';

const regionNumber = [
  '010',
  '070',
  '02',
  '031',
  '032',
  '033',
  '041',
  '042',
  '043',
  '044',
  '051',
  '052',
  '053',
  '054',
  '055',
  '061',
  '062',
  '063',
  '064',
];

interface Props {
  index: number;
  phoneno: any[];
  changePhoneno: (data: any) => void;
  disabled?: boolean;
}

export const SwSelectPhonenoEvent: React.FC<Props> = ({
  index,
  phoneno,
  changePhoneno,
  disabled = false,
}) => {
  const [number1, setNumber1] = useState<any>('010');
  const [number2, setNumber2] = useState<any>('');
  const [number3, setNumber3] = useState<any>('');

  const makePhoneno = (type: number, value: string) => {
    type === 1
      ? changePhoneno({ idx: index, phoneno: [value, number2, number3] })
      : type === 2
      ? changePhoneno({ idx: index, phoneno: [number1, value, number3] })
      : type === 3
      ? changePhoneno({ idx: index, phoneno: [number1, number2, value] })
      : '';
  };

  useEffect(() => {
    if (phoneno !== undefined && phoneno !== null && phoneno.length === 3) {
      phoneno[0] === '' ? setNumber1('010') : setNumber1(phoneno[0]);
      phoneno[1] === '' ? setNumber2('') : setNumber2(phoneno[1]);
      phoneno[2] === '' ? setNumber3('') : setNumber3(phoneno[2]);
    }
  }, [phoneno]);

  return (
    <>
      <Box>
        <Box className='flex_center'>
          <TextField
            sx={{ width: '11rem', mr: '1rem', mb: 0 }}
            select
            defaultValue={0}
            disabled={disabled}
            value={number1}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setNumber1(event.target.value);
              makePhoneno(1, event.target.value);
            }}
          >
            {regionNumber &&
              regionNumber.map((option: string, idx: number) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            sx={{ width: '11rem', mr: '1rem', mb: 0 }}
            disabled={disabled}
            type='number'
            value={number2}
            inputProps={{ maxLength: 4 }}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              if (event.target.value.length <= 4) {
                setNumber2(event.target.value);
                makePhoneno(2, event.target.value);
              }
            }}
          />
          <TextField
            sx={{ width: '11rem', mr: '1rem', mb: 0 }}
            disabled={disabled}
            type='number'
            value={number3}
            inputProps={{ maxLength: 4 }}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              if (event.target.value.length <= 4) {
                setNumber3(event.target.value);
                makePhoneno(3, event.target.value);
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};
