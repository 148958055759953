import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
  GrayTypography,
} from './ContentManageStyles';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  getContentManageContentDetailInfo,
  registerContentManageContent,
} from '../../../common/api/ApiContentManage';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { KEY_CONTENT_MAMANGE_CONTENT_DETAIL_INFO } from '../../../common/key';
import { SwSelectKeyword } from '../../commonComponent/program/SwSelectKeyword';
import { SwConnectMission } from '../../commonComponent/program/SwConnectMission';

const INIT_UNIT_SELECTOR = 'g';
const intakeUnitList = ['g', 'mg', 'ml', 'mcg'];
const keywordIntakeUnitList = ['g', 'mg', '㎍', 'ml'];

interface Props {
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

export const ContentManageContentMovieForm: React.FC<Props> = ({
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const { loginVal } = useSession();
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [subType, setSubType] = useState<number>(1);
  const [contentId, setContentId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [contentSource, setContentSource] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [status, setStatus] = useState<number>(1);
  const [seq, setSeq] = useState<number>(0);
  const [relatedMissions, setRelatedMissions] = useState<any>();
  const [savedRelatedMissions, setSavedRelatedMissions] = useState<any>();
  const [thumbImage, setThumbImage] = useState<string>('');
  const [thumbImageFile, setThumbImageFile] = useState<any>(null);
  const [useProgram, setUseProgram] = useState<boolean>(false);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [selectedContentManage, setSelectedContentManage] =
    useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [openKeyword, setOpenKeyword] = useState<boolean>(false);
  const [keywordInfo, setKeywordInfo] = useState<any[]>([]);
  const [highKeywordInfo, setHighKeywordInfo] = useState<any[]>([]);
  const keywordItems = document.querySelectorAll('.keyword_draggable');
  const highKeywordItems = document.querySelectorAll('.high_keyword_draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [highListEvent, setHighListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const setupKeyword = (newContent: any) => {
    const tmpKeyword =
      newContent.keyword !== undefined && newContent.keyword.length > 0
        ? JSON.parse(newContent.keyword)
        : [];
    // const newKeyword = tmpKeyword
    //   .filter((item: any) => item.h === 0)
    //   .map((item: any) => {
    //     const newData: any = {
    //       intake: item.v !== undefined ? item.v : 0,
    //       unit: item.u !== undefined ? item.u : 'select',
    //       title: item.t,
    //       isHigh: item.h,
    //       showUnit: item.u !== undefined ? 1 : 0,
    //     };
    //     return newData;
    //   });
    // const newHighKeyword = tmpKeyword
    //   .filter((item: any) => item.h === 1)
    //   .map((item: any) => {
    //     const newData: any = {
    //       intake: item.v !== undefined ? item.v : 0,
    //       unit: item.u !== undefined ? item.u : 'select',
    //       title: item.t,
    //       isHigh: item.h,
    //       showUnit: item.u !== undefined ? 1 : 0,
    //     };
    //     return newData;
    //   });
    const newKeyword = tmpKeyword.map((item: any) => {
      const newData: any = {
        intake: 0,
        unit: 'select',
        title: item,
        isHigh: 0,
        showUnit: 0,
      };
      return newData;
    });
    const newHighKeyword: any[] = [];
    setKeywordInfo(newKeyword);
    setHighKeywordInfo(newHighKeyword);
  };

  const { refetch: contentManageContentDetailRefetch } = useQuery(
    KEY_CONTENT_MAMANGE_CONTENT_DETAIL_INFO,
    () => {
      console.log('contentId: ', contentId);
      const newRequest = {
        type: 1, // movie
        contentId: contentId !== null ? contentId : '',
      };
      if (contentId !== undefined && contentId !== null && contentId.length > 0)
        return getContentManageContentDetailInfo(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('CMS content detailInfo :', res);
        if (res !== null && res.content !== null) {
          const newContent = res.content;
          setSubType(
            newContent.subType !== undefined && newContent.subType !== null
              ? newContent.subType
              : 1
          );
          setTitle(newContent.title);
          setDescription(newContent.description);
          setStatus(newContent.status);
          setContentSource(newContent.source);
          setLink(newContent.videoCode);
          setThumbImage(newContent.image);
          setThumbImageFile(null);
          setupKeyword(newContent);
          setSeq(newContent.seq);
          setUseProgram(newContent.useProgram === 1);
          setSavedRelatedMissions(
            newContent.relatedMissions ? newContent.relatedMissions : []
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetailInfo = (newData: any) => {
    queryClient.prefetchQuery(KEY_CONTENT_MAMANGE_CONTENT_DETAIL_INFO, () =>
      getContentManageContentDetailInfo(newData)
    );
  };

  const {
    mutate: registerContentManageContentMutation,
    isError: isMutateError,
  } = useMutation(registerContentManageContent, {
    onSuccess: res => {
      console.log('registerContentManageContent res:', res);
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        reloadContentManage({
          id: categoryId,
          parentCategoryId: parentCategoryId,
        });
      } else {
        setAlertOpen(true);
        setAlertMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '콘텐츠를 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addContentManageContent = (newUploadUrl: string) => {
    const newImage =
      thumbImageFile !== null
        ? newUploadUrl
        : thumbImage !== null || thumbImage !== ''
        ? thumbImage
        : '';
    const newIdx =
      contentManageInfo.idx !== undefined && contentManageInfo.idx !== null
        ? contentManageInfo.idx
        : -1;
    const tmpKeywordList = [...keywordInfo, ...highKeywordInfo];
    const newKeywordList = tmpKeywordList.map((item: any) => {
      const newData: any = {
        t: item.title,
        h: item.isHigh,
      };
      if (item.showUnit) {
        newData.v = item.intake !== undefined ? item.intake : '';
        newData.u =
          item.unit !== undefined && item.unit !== 'select' ? item.unit : '';
      }
      return newData;
    });
    const strKeywordList = newKeywordList.map((item: any) => item.t);

    console.log('--> relatedMissions:', relatedMissions);
    const newRelatedMissions: any[] = [];
    if (relatedMissions !== null) {
      relatedMissions.survey &&
        relatedMissions.survey.map((mission: any, idx: number) => {
          newRelatedMissions.push({
            contentId: contentId,
            relatedContentId: mission.relatedContentId,
            type: mission.type,
            seq: idx + 1,
            path: mission.path,
          });
        });
      relatedMissions.quiz &&
        relatedMissions.quiz.map((mission: any, idx: number) => {
          newRelatedMissions.push({
            contentId: contentId,
            relatedContentId: mission.relatedContentId,
            type: mission.type,
            seq: idx + 1,
            path: mission.path,
          });
        });
      relatedMissions.lifestyle &&
        relatedMissions.lifestyle.map((mission: any, idx: number) => {
          newRelatedMissions.push({
            contentId: contentId,
            relatedContentId: mission.relatedContentId,
            type: mission.type,
            seq: idx + 1,
            path: mission.path,
          });
        });
    }

    const newData: any = {
      idx: newIdx,
      type: 'movie',
      categoryId: categoryId,
      contentId: contentId,
      source: contentSource,
      title: title,
      description: description,
      videoCode: link,
      keyword: strKeywordList.length > 0 ? JSON.stringify(strKeywordList) : '',
      image: newImage,
      status: status,
      seq: seq,
      useProgram: useProgram ? 1 : 0,
      relatedMissions: newRelatedMissions,
    };

    if (newImage === null || newImage.length === 0) {
      setAlertMessage('이미지를 선택해주세요');
      setOpenSnackbar(true);
      return;
    }
    console.log('addContentManageContent:', newData);
    registerContentManageContentMutation(newData);
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (thumbImageFile !== null) {
      const newUploadFile: any = await uploadFile('program', thumbImageFile);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    addContentManageContent(newUploadUrl);
  }

  // start : drag & drop
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: keywordInfo,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setKeywordInfo(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    keywordItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setKeywordInfo(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    keywordItems.forEach(item => item.classList.remove('drag_over'));
  };

  // High Keyword
  const dragStartHigh = (evt: DragEvent<HTMLDivElement>) => {
    setHighListEvent({
      ...highListEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: highKeywordInfo,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const dropHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = highListEvent.currentDrag;
    const filterList = highListEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== highListEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      highListEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setHighKeywordInfo(newList);

    setHighListEvent({
      currentDrag: null,
      over: null,
      ...highListEvent,
    });
  };

  const dragEndHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    highKeywordItems.forEach(item => item.classList.remove('drag_over_high'));
    // changeCommunityGroupOrder();
  };

  const dragEnterHigh = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setHighListEvent({ ...highListEvent, over: newOver });
  };

  const dragOverHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over_high');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = highListEvent.currentDrag;
    const filterList = highListEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      highListEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    highListEvent.over !== newOver && setHighKeywordInfo(newList);
  };

  const dragLeaveHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    highKeywordItems.forEach(item => item.classList.remove('drag_over_high'));
  };

  // stop : drag & drop

  const checkValid = () => {
    var val = 0;

    if (val === 0 && title.length === 0) val = 1;
    if (val === 0 && description.length === 0) val = 2;
    if (val === 0 && contentSource.length === 0) val = 3;
    if (val === 0 && categoryId.length === 0) val = 4;
    if (val === 0 && link.length === 0) val = 5;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '제목을 입력해주세요.';
          break;
        case 2:
          message = '영상 설명을 입력해주세요';
          break;
        case 3:
          message = '출처를 입력해주세요';
          break;
        case 4:
          message = '카테고리를 선택해주세요';
          break;
        case 5:
          message = '유튜브key 입력해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setOpenSnackbar(true);
    }

    return val;
  };

  const saveData = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return false;
    }

    uploadMultipleFiles();
    return true;
  };

  const changeHandler = (mission: any) => {
    console.log('--> changeHandler:', mission);
    setRelatedMissions(mission);
  };

  useEffect(() => {
    if (
      contentManageInfo.contentId !== undefined &&
      contentManageInfo.contentId !== null
    ) {
      contentManageContentDetailRefetch();
    } else {
      setTitle('');
      setDescription('');
      setStatus(1);
      setContentSource('');
      setLink('');
      setThumbImage('');
      setThumbImageFile(null);
      setKeywordInfo([]);
      setHighKeywordInfo([]);
      setSeq(0);
      setRelatedMissions(null);
    }
  }, [contentId]);

  useEffect(() => {
    console.log('contentManageInfo -> :', contentManageInfo);
    if (contentManageInfo !== undefined && contentManageInfo !== null) {
      if (contentManageInfo.save) {
        if (saveData() === false) return;
      }
      setContentId(
        contentManageInfo.contentId !== undefined &&
          contentManageInfo.contentId !== null
          ? contentManageInfo.contentId
          : newUuidId()
      );
      setCategoryId(
        contentManageInfo.categoryId !== undefined &&
          contentManageInfo.categoryId !== null
          ? contentManageInfo.categoryId
          : ''
      );
      setParentCategoryId(
        contentManageInfo.parentCategoryId !== undefined &&
          contentManageInfo.parentCategoryId !== null
          ? contentManageInfo.parentCategoryId
          : ''
      );
      setSubType(
        contentManageInfo.subType !== null ? contentManageInfo.subType : 1
      );
    }
  }, [contentManageInfo]);

  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>영상 제목</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={title}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setTitle(evt.target.value.substring(0, 30))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{title?.length} / 30</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>출처</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={contentSource}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setContentSource(evt.target.value.substring(0, 20))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{contentSource?.length} / 20</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>영상 설명</Typography>
          </Box>
          <TextField
            value={description}
            fullWidth
            multiline
            rows={5}
            sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setDescription(evt.target.value.substring(0, 1000))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{contentSource?.length} / 1000</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <TextField
            fullWidth
            onChange={evt => setLink(evt.target.value)}
            value={link}
            placeholder='sFPHNGpQvZ0'
            helperText='유튜브 링크의 v=XXXXXXXXXX 값을 입력해 주세요.'
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 'auto',
                padding: '.5rem',
              },
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>키워드</Typography>
          </Box>
          <Box>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setOpenKeyword(true);
              }}
            >
              키워드 추가
            </Button>
          </Box>
        </Box>
        <Box>
          {keywordInfo.map((keyword: any, idx: number) => (
            <Box key={`drag_keyword_${idx}`} sx={{ display: 'flex' }}>
              <DraggablePaper
                className='keyword_draggable'
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                onDragOver={dragOver}
                onDrop={drop}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                draggable
                data-position={idx}
                sx={{ width: '60rem' }}
              >
                <Box className='flex_between'>
                  <Box className='flex_start'>
                    <DragHandleIcon />
                    <Box sx={{ ml: '1rem', width: '15rem' }}>
                      <Typography>{keyword.title}</Typography>
                    </Box>
                  </Box>
                  <Box className='flex_end' sx={{ width: '40rem' }}>
                    {keyword.showUnit === 99 && (
                      <Box>
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          placeholder='수치입력'
                          value={
                            keyword.intake !== undefined ? keyword.intake : ''
                          }
                          onChange={(
                            evt: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newKeywordInfo = [...keywordInfo];
                            newKeywordInfo[idx].intake = checkNaN(
                              evt.target.value,
                              keyword.intake
                            );
                            setKeywordInfo(newKeywordInfo);
                          }}
                        />
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          select
                          defaultValue='select'
                          value={
                            keyword.unit !== undefined ? keyword.unit : 'select'
                          }
                          onChange={evt => {
                            const newKeywordInfo = [...keywordInfo];
                            newKeywordInfo[idx].unit = evt.target.value;
                            setKeywordInfo(newKeywordInfo);
                          }}
                        >
                          <MenuItem key='magazine_unit_select' value='select'>
                            선택
                          </MenuItem>
                          {keywordIntakeUnitList.map(
                            (option: any, i: number) => (
                              <MenuItem
                                key={`magazine_keyword_unit_${option}`}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={`keyword_cb_${idx}`}
                              sx={{ color: '#808383' }}
                              onChange={e => {
                                if (e.currentTarget.checked) {
                                  keyword.isHigh = 1;
                                  const newHighKeywordInfo = [
                                    ...highKeywordInfo,
                                    keyword,
                                  ];
                                  setHighKeywordInfo(newHighKeywordInfo);

                                  const newKeywordInfo = keywordInfo.filter(
                                    (item: any, i: number) => i !== idx
                                  );
                                  setKeywordInfo(newKeywordInfo);
                                }
                              }}
                              checked={keyword.isHigh}
                            />
                          }
                          label='고함량 여부'
                        />
                      </Box>
                    )}
                    <Button
                      onClick={() => {
                        const newKeywordInfo = keywordInfo.filter(
                          (item: any, i: number) => i !== idx
                        );
                        setKeywordInfo(newKeywordInfo);
                      }}
                    >
                      <CancelIcon
                        sx={{ fontSize: '1.2rem', color: '#000000' }}
                      />
                    </Button>
                  </Box>
                </Box>
              </DraggablePaper>
            </Box>
          ))}
        </Box>
        {highKeywordInfo.length > 0 && (
          <Box sx={{ mt: '1rem' }}>
            <Typography>고함량 키워드</Typography>
            <Typography sx={{ color: '#999999' }}>
              앱 내에서 고함량으로 표시됩니다.
            </Typography>
          </Box>
        )}
        <Box>
          {highKeywordInfo.map((keyword: any, idx: number) => (
            <Box key={`drag_high_keyword_${idx}`} sx={{ display: 'flex' }}>
              <DraggablePaper
                className='high_keyword_draggable'
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStartHigh}
                onDragEnd={dragEndHigh}
                onDragOver={dragOverHigh}
                onDrop={dropHigh}
                onDragLeave={dragLeaveHigh}
                onDragEnter={dragEnterHigh}
                draggable
                data-position={idx}
                sx={{ width: '70%' }}
              >
                <Box className='flex_between'>
                  <Box className='flex_start'>
                    <DragHandleIcon />
                    <Box sx={{ ml: '1rem', width: '15rem' }}>
                      <Typography>{keyword.title}</Typography>
                    </Box>
                  </Box>
                  <Box className='flex_end'>
                    {keyword.showUnit === 1 && (
                      <Box>
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          placeholder='수치입력'
                          value={
                            keyword.intake !== undefined ? keyword.intake : ''
                          }
                          onChange={(
                            evt: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newKeywordInfo = [...highKeywordInfo];
                            newKeywordInfo[idx].intake = checkNaN(
                              evt.target.value,
                              keyword.intake
                            );
                            setHighKeywordInfo(newKeywordInfo);
                          }}
                        />
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          select
                          defaultValue='select'
                          value={
                            keyword.unit !== undefined ? keyword.unit : 'select'
                          }
                          onChange={evt => {
                            const newKeywordInfo = [...highKeywordInfo];
                            newKeywordInfo[idx].unit = evt.target.value;
                            setHighKeywordInfo(newKeywordInfo);
                          }}
                        >
                          <MenuItem key='magazine_unit_select' value='select'>
                            선택
                          </MenuItem>
                          {keywordIntakeUnitList.map(
                            (option: any, i: number) => (
                              <MenuItem
                                key={`magazine_keyword_unit_${option}`}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={`keyword_cb_${idx}`}
                              sx={{ color: '#808383' }}
                              onChange={e => {
                                if (!e.currentTarget.checked) {
                                  keyword.isHigh = 0;
                                  const newKeywordInfo = [
                                    ...keywordInfo,
                                    keyword,
                                  ];
                                  setKeywordInfo(newKeywordInfo);

                                  const newHighKeywordInfo =
                                    highKeywordInfo.filter(
                                      (item: any, i: number) => i !== idx
                                    );
                                  setHighKeywordInfo(newHighKeywordInfo);
                                }
                              }}
                              checked={keyword.isHigh}
                            />
                          }
                          label='고함량 여부'
                        />
                      </Box>
                    )}
                    <Button
                      onClick={() => {
                        const newHighKeywordInfo = highKeywordInfo.filter(
                          (item: any, i: number) => i !== idx
                        );
                        setHighKeywordInfo(newHighKeywordInfo);
                      }}
                    >
                      <CancelIcon
                        sx={{ fontSize: '1.2rem', color: '#000000' }}
                      />
                    </Button>
                  </Box>
                </Box>
              </DraggablePaper>
            </Box>
          ))}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>썸네일 이미지</Typography>
          <SwFileSetting
            image={
              thumbImageFile != null
                ? thumbImage
                : thumbImage !== ''
                ? `${imageUrlPrefix}${thumbImage}`
                : ''
            }
            setImage={setThumbImage}
            setFile={setThumbImageFile}
            file={thumbImageFile}
          />
          <GrayTypography>*16:9(종횡비) 비율 권장</GrayTypography>
          <GrayTypography>
            *용량 10MB 이하의 JPG, PNG만 업로드 가능
          </GrayTypography>
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>순서</Typography>
          </Box>
          <TextField
            value={seq}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSeq(checkNaN(evt.target.value, seq))}
          />
          <Divider />
        </Box>
        <Box>
          <SwConnectMission
            relatedMissions={savedRelatedMissions}
            changeHandler={changeHandler}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            defaultValue={1}
            value={status}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setStatus(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
        <Box className='flex_start'>
          <Checkbox
            checked={useProgram}
            onChange={() => setUseProgram(!useProgram)}
            sx={{ p: 0, mr: '.5rem' }}
          />
          <Typography className='title'>프로그램용 콘텐츠</Typography>
        </Box>
      </CreatePaper>
      <SwSelectKeyword
        open={openKeyword}
        onClose={() => setOpenKeyword(false)}
        type={subType}
        keywordInfo={keywordInfo}
        setKeywordInfo={setKeywordInfo}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
