import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSession } from '../../../hooks/session';

import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { IUploadFile } from '../../../models/common';

interface Props {
  open: boolean;
  onClose: () => void;
  content: any;
}

export const ReportContentDrawup: React.FC<Props> = ({
  open,
  onClose,
  content,
}) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const [noticeIdx, setNoticeIdx] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageFile, setImageFile] = useState<any>(null);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  useEffect(() => {}, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '56vh',
          minWidth: '44vw',
        },
      }}
    >
      <DialogTitle sx={{ pl: '24px !important' }}>
        <Box className='flex_between'>
          <span>로그 보기</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent>
        <>
          <MultilineTextField
            inputRef={contentRef}
            multiline
            rows={25}
            fullWidth
            defaultValue={content}
          />
        </>
      </DialogContent>
      {/* <Divider sx={{ p: 0, m: 0 }} /> */}
      {/* <DialogActions></DialogActions> */}
    </Dialog>
  );
};
